import React, { Component } from 'react';
import { Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Col,
    Label,
} from 'reactstrap';

import AsyncSelect from 'react-select/lib/Async';
import _uniqBy from 'lodash/uniqBy';
import _isEqual from 'lodash/isEqual';

import contactService from "../../../services/contactService";
import companyService from "../../../services/companyService";


export default class GarageContacts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabledContactData: true,
        };

        this.contactsTypingTimer = null;
        this.managementCompanyTypingTimer = null;
        this.doorCompanyTypingTimer = null;

        this.typingInterval = 600;

        this.updateGarage = this.updateGarage.bind(this);
        this.toggleContactData = this.toggleContactData.bind(this);
        this.onDoorCompanyChange = this.onDoorCompanyChange.bind(this);
        this.onPropertyManagementCompanyChange = this.onPropertyManagementCompanyChange.bind(this);

        this.loadContactsOptions = this.loadContactsOptions.bind(this);
        this.onPersonResponsibleChange = this.onPersonResponsibleChange.bind(this);

        this.loadCompaniesOptions = this.loadCompaniesOptions.bind(this);
        this.loadDoorCompaniesOptions = this.loadDoorCompaniesOptions.bind(this);

        this.onContactClick = this.onContactClick.bind(this);
        this.onCompanyClick = this.onCompanyClick.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        return {
            garage: state.garage || props.garage
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_isEqual(prevProps.garage, this.props.garage)) {
            // props have been changed
            this.setState({
                garage: this.props.garage
            })
        }

        const {property_management_company, door_maintenance_company} = this.state.garage;

        if(property_management_company && typeof property_management_company !== 'object' && !property_management_company.hasOwnProperty('label')) {
            companyService.getCompany(property_management_company).then(resp => {
                this.setState({
                    garage: {
                        ...this.state.garage,
                        property_management_company: {
                            value: resp.data.id,
                            label: resp.data.name
                        }
                    }
                })
            }).catch(err => {console.log(err)})
        }


        if(door_maintenance_company && typeof door_maintenance_company !== 'object' && !door_maintenance_company.hasOwnProperty('label')) {
            companyService.getCompany(door_maintenance_company).then(resp => {
                this.setState({
                    garage: {
                        ...this.state.garage,
                        door_maintenance_company: {
                            value: resp.data.id,
                            label: resp.data.name
                        }
                    }
                })
            }).catch(err => {console.log(err)})
        }
    }

    toggleContactData() {
        this.props.toggleContactData()
    }

    updateGarage() {
        const {door_maintenance_company, property_management_company} = this.state.garage;
        this.props.updateGarage({
            ...this.state.garage,
            door_maintenance_company: door_maintenance_company ? door_maintenance_company.value : null,
            property_management_company: property_management_company ? property_management_company.value : null,
        }) .then(() => {
            this.setState({
                disabledContactData: true
            })
        })
            .catch(() => {
                this.setState({
                    disabledContactData: false
                })
            });

    }


    onDoorCompanyChange(company) {
        this.setState({
            garage: {
                ...this.state.garage,
                door_maintenance_company: company ? company || null : null
            }
        });
    }

    onPropertyManagementCompanyChange(company) {
        this.setState({
            garage: {
                ...this.state.garage,
                property_management_company: company ? company || null : null
            }
        });
    }
    onPersonResponsibleChange(contacts) {
        this.setState({
            garage: {
                ...this.state.garage,
                person_responsible: contacts.map(contact => contact.value).slice(0, 5)
            }
        })
    }

    getContantsOptionsPromise(value, initOptions=null) {
        return new Promise(resolve => {
            clearTimeout(this.contactsTypingTimer);

            this.contactsTypingTimer = setTimeout(() => {
                contactService.getContacts(1, value)
                    .then((response) => {
                        let options = initOptions ? [...initOptions, ...response.data.results] : response.data.results;
                        resolve({
                            options: _uniqBy(options.map((contact) => {
                                return {value: contact.id, label: contact.first_name + " " + contact.last_name}
                            }), "value")
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.typingInterval)
        })
    }

    loadContactsOptions(value) {

        let initValuePromises = [];
        const { person_responsible } = this.state.garage;

        if(person_responsible && person_responsible.length) {
            initValuePromises = person_responsible.map((id) => {
                return contactService.getContact(id)
            })
        }

        if(initValuePromises){
            return Promise.all(initValuePromises).then((persons) => {
                return this.getContantsOptionsPromise(value, persons.map(resp => resp.data))
            }).catch((err) => {
                return this.getContantsOptionsPromise(value)
            })
        } else {
            return this.getContantsOptionsPromise(value);
        }


    }

    loadCompaniesOptions(value) {
        return new Promise(resolve => {
            clearTimeout(this.managementCompanyTypingTimer);
            this.managementCompanyTypingTimer = setTimeout(() => {
                companyService.getCompanies(1, value)
                    .then((response) => {
                        resolve({
                            options: response.data.results.map((company) => {
                                return {value: company.id, label: company.name}
                            })
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.typingInterval)
        })
    }

    loadDoorCompaniesOptions(value) {
        return new Promise(resolve => {
            clearTimeout(this.doorCompanyTypingTimer);
            this.doorCompanyTypingTimer = setTimeout(() => {
                companyService.getCompanies(1, value)
                    .then((response) => {
                        resolve({
                            options: response.data.results.map((company) => {
                                return {value: company.id, label: company.name}
                            })
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.typingInterval)
        })
    }

    onContactClick(contact) {
        this.props.history.push("/contacts/" + contact.value);
    }

    onCompanyClick(company) {
        this.props.history.push("/companies/" + company.value);
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Kontakt
                    <div className="card-header-actions">
                        {
                            !this.props.garageContacts
                                ?
                                <div>
                                    <Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateGarage}>
                                        <i className="fa fa-dot-circle-o"/> Speichern
                                    </Button>

                                    <Button type="reset" size="sm" color="danger" onClick={this.toggleContactData}>
                                        <i className="fa fa-ban"/> Abbrechen
                                    </Button>
                                </div>
                                :
                                <a className="card-header-action btn btn-setting" onClick={this.toggleContactData}><i className="icon-pencil"></i></a>
                        }
                    </div>
                </CardHeader>
                <CardBody>
                    { this.props.loading ?
                        <div className="sk-rotating-plane"/>
                        :
                        <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                            <FormGroup row>
                                <Col md="6">
                                    <Label htmlFor="contactPerson">Anschpreperson*</Label>
                                </Col>
                                <Col xs="12" md="6">
                                    <AsyncSelect
                                        value={this.state.garage.person_responsible ? this.state.garage.person_responsible : null}
                                        onChange={this.onPersonResponsibleChange}
                                        loadOptions={this.loadContactsOptions}
                                        multi
                                        onValueClick={this.onContactClick}
                                        disabled={this.props.garageContacts}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="6">
                                    <Label htmlFor="property_management_company">Hausverwaltung*</Label>
                                </Col>
                                <Col xs="12" md="6">
                                    <AsyncSelect
                                        value={!this.state.garage.property_management_company ? null : this.state.garage.property_management_company}
                                        onChange={this.onPropertyManagementCompanyChange}
                                        loadOptions={this.loadCompaniesOptions}
                                        onValueClick={this.onCompanyClick}
                                        disabled={this.props.garageContacts}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="6">
                                    <Label htmlFor="door_maintenance_company">Tür Wartungsfirma*</Label>
                                </Col>
                                <Col xs="12" md="6">
                                    <AsyncSelect
                                        value={!this.state.garage.door_maintenance_company ? null : this.state.garage.door_maintenance_company}
                                        onChange={this.onDoorCompanyChange}
                                        loadOptions={this.loadDoorCompaniesOptions}
                                        onValueClick={this.onCompanyClick}
                                        disabled={this.props.garageContacts}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    }

                </CardBody>
            </Card>
        )
    }

}
