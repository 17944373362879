import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Col, Label, InputGroup, Input, InputGroupText, InputGroupAddon} from 'reactstrap';
import { AppSwitch } from '@coreui/react'
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _isEqual from 'lodash/isEqual'
import datetimeFormatter from '../../../utils/datetimeFormatter';
import privilegeService from '../../../services/privilegeService';
import garageService from '../../../services/garageService';
import getGaragesQuery from '../../../queries/getGarages';


export default class UserPrivileges extends Component {
    constructor(props) {
        super(props);


        this.state = {
            modalOpen: false,
            user: this.props.user,
            privileges: [],
            loading: true,
            form: this.emptyForm
        };

        this.handleChange = this.handleChange.bind(this);
        this.submitNewPrivilege = this.submitNewPrivilege.bind(this);
        this.removePrivilege = this.removePrivilege.bind(this);
        this.pickOutPrivilege = this.pickOutPrivilege.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onGarageChanged = this.onGarageChanged.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.onStartsAtChanged = this.onStartsAtChanged.bind(this);
        this.onExpiresAtChanged = this.onExpiresAtChanged.bind(this);
        this.addNewPrivilege = this.addNewPrivilege.bind(this);
        this.updatePrivilege = this.updatePrivilege.bind(this);
        this.mapErrors = this.mapErrors.bind(this);
        /*this.toggleLongTerm = this.toggleLongTerm.bind(this);*/

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_isEqual(prevProps.user, this.props.user)) {
            // props have been changed
            this.setState({
                user: this.props.user,
            })
        }
    }

    get emptyForm() {
        return {
            starts_at: new Date(),
            expires_at: new Date(),
            active: false,
            discount_percent: '',
            privileged_object_id: null,
            // privilege_type: false,
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let form = this.state.form;

        form[name] = value;

        this.setState({
            form: form,
        })
    }


    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    toggleActive() {
        this.setState({
            form: {
                ...this.state.form,
                active: !this.state.form.active
            }
        });
    }

    /*toggleLongTerm() {
        this.setState({
            form: {
                ...this.state.form,
                privilege_type: !this.state.form.privilege_type
            }
        });
    }*/

    onGarageChanged(selectedGarage) {
        this.setState({
            form: {
                ...this.state.form,
                privileged_object_id: selectedGarage.value
            }
        });
    }

    submitNewPrivilege() {
        privilegeService.createPrivilege({
            ...this.state.form,
            starts_at: datetimeFormatter.forSubmitting(this.state.form.starts_at),
            expires_at: datetimeFormatter.forSubmitting(this.state.form.expires_at),
            object_id: this.state.user.id,
            content_type: "user",
            privileged_object: "GARAGE",
            // privilege_type: !this.state.form.privilege_type ? "" : "LT",
        })
            .then((response) => {
                let newPrivilege = {
                    id: response.data.id,
                    discount_percent: response.data.discount_percent,
                    privileged_object: response.data.privileged_object,
                    privileged_object_id: response.data.privileged_object_id,
                    starts_at: response.data.starts_at,
                    expires_at: response.data.expires_at,
                    privileged_garage: !response.data.privileged_garage ? null : {
                        name: response.data.privileged_garage.name
                    },
                    active: response.data.active,
                    // privilege_type: response.data.privilege_type
                };

                this.setState({
                    privileges: [...this.state.privileges, newPrivilege],
                    modalOpen: !this.state.modalOpen
                });

                this.success();
            })
            .catch((err) => {
                this.toggleModal();
                this.setState({
                    errors: Object.entries(err.response.data.error)
                }, () => {
                    this.mapErrors();
                });
            });
    }

    updatePrivilege() {

        let data = {
            ...this.state.form,
            starts_at: datetimeFormatter.forSubmitting(this.state.form.starts_at),
            expires_at: datetimeFormatter.forSubmitting(this.state.form.expires_at),
        };
        privilegeService.updatePrivilege(data)
            .then((resp) => {
                let updatedPriv = {
                    ...this.state.form,
                    ...resp.data,
                    starts_at: datetimeFormatter.forPicker(this.state.form.starts_at),
                    expires_at: datetimeFormatter.forPicker(this.state.form.expires_at),
                };

                this.setState({
                    modalOpen: false,
                    privileges: this.state.privileges.map((priv) => {
                        if(priv.id === updatedPriv.id) {
                            return updatedPriv
                        }
                        return priv
                    })
                });
                this.success()
            })
            .catch((err) => {
                this.toggleModal();
                this.setState({
                    errors: Object.entries(err.response.data.error)
                }, () => {
                    this.mapErrors();
                });
            })
    }

    mapErrors() {
        String.prototype.capitalize = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        };

        return (
            this.state.errors.map((error) => {
                if (error[0] === 'non_field_errors') {
                    this.unsuccessful(error[1][0].capitalize() + ".")
                } else {
                    this.unsuccessful(error[0].capitalize() + ": " + error[1])
                }
            })
        )
    }

    onStartsAtChanged(timestamp){
        this.setState({ form: {...this.state.form, starts_at: timestamp} })
    }

    onExpiresAtChanged(timestamp) {
        this.setState({ form: {...this.state.form, expires_at: timestamp} });
    }

    removePrivilege(id) {
        privilegeService.deletePrivilege(id)
            .then((response) => {

                let newPrivileges = this.state.privileges.filter(privilege => {
                    if (privilege.id !== id) {
                        return privilege
                    }
                });

                this.setState({
                    privileges: newPrivileges
                });

                this.success();
            })
            .catch((err) => {
                this.unsuccessful(err);
            });
    }

    pickOutPrivilege(priv) {
        this.setState({
            form: {
                ...priv,
                starts_at: datetimeFormatter.forPicker(priv.starts_at),
                expires_at: datetimeFormatter.forPicker(priv.expires_at),
            },
            modalOpen: true,
        });
    }

    addNewPrivilege() {
        this.setState({
            form: this.emptyForm,
            modalOpen: true
        })
    }

    isNew() {
        return !this.state.form.hasOwnProperty("id") || typeof this.state.form.id === "undefined" || this.state.form.id === null
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    componentDidMount() {

        privilegeService.getForUser(this.state.user.id)
            .then((resp) => {
                this.setState({
                    loading: false,
                    privileges: resp.data
                })
            }).catch((err) => {
            this.unsuccessful(err);
        });

        garageService.getService(getGaragesQuery())
            .then((garages) => {
                this.setState({
                    garages: garages.data.getGarages.garages.map((garage) => {
                        return {value: garage.id, label: garage.name }
                    })
                }, () => {
                    let allGarages = [...this.state.garages, {value: 'ALL', label: 'Alle Garagen'}];

                    this.setState({
                        garages: allGarages
                    })
                });
            })
            .catch((err) => {
                this.unsuccessful(err);
            });
    }

    renderEditModal() {
        return (
            <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}>Garage zuweisen</ModalHeader>
                <ModalBody>
                    <Form action="" method="post" className="form-horizontal pt-2">
                        <FormGroup row>
                            <Col lg="4">
                                <Label htmlFor="garage">Garage</Label>
                            </Col>
                            <Col xs="12" lg="8">
                                <Select
                                    disabled={!this.isNew()}
                                    value={this.state.form.privileged_object_id}
                                    options={this.state.garages}
                                    onChange={this.onGarageChanged}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="4">
                                <Label htmlFor="new-password-repeat">Rabatt</Label>
                            </Col>
                            <Col xs="12" lg="8">
                                <InputGroup>
                                    <Input type="number" placeholder="z.B. 30%" name="discount_percent" value={this.state.form.discount_percent} onChange={this.handleChange} max={100} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            %
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="4">
                                <Label htmlFor="active">Aktiv</Label>
                            </Col>
                            <Col xs="12" lg="8">
                                <AppSwitch className={'mx-1'} variant={'3d'} color={'success'} checked={this.state.form.active} onChange={this.toggleActive} />
                            </Col>
                        </FormGroup>
                        {/*<FormGroup row>
                            <Col md="4">
                                <Label htmlFor="longTerm">Dauerparker</Label>
                            </Col>
                            <Col xs="12" lg="8">
                                <AppSwitch className={'mx-1'} variant={'3d'} color={'success'} checked={this.state.form.privilege_type === "LT" ? true : false} onChange={this.toggleLongTerm} disabled={!this.isNew()} />
                            </Col>
                        </FormGroup>*/}
                        <FormGroup row>
                            <Col md="4">
                                <Label htmlFor="new-password">Start</Label>
                            </Col>
                            <Col xs="12" lg="8">
                                <DateTimePicker locale="de" showLeadingZeros={true} className="form-control" value={this.state.form.starts_at} onChange={this.onStartsAtChanged} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="4">
                                <Label htmlFor="new-password">Ende</Label>
                            </Col>
                            <Col xs="12" lg="8">
                                <DateTimePicker locale="de" showLeadingZeros={true} className="form-control" value={this.state.form.expires_at} onChange={this.onExpiresAtChanged}/>
                            </Col>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.isNew() ? this.submitNewPrivilege : this.updatePrivilege} disabled={!this.state.form.privileged_object_id || !this.state.form.discount_percent}>
                        {this.isNew() ? "Garage zuweisen" : "Aktualisieren"}
                    </Button>

                    <Button color="secondary" onClick={this.toggleModal}>
                        Abbrechen
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }


    render() {

        const containerStyle = {
            zIndex: 1999
        };

        String.prototype.toProperCase = function () {
            return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        };

        return (
            <Card>
                {this.renderEditModal()}
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <CardHeader>
                    Privilegien
                    <div className="card-header-actions">
                        <Button type="submit" size="sm" color="success" className={"mr-1"} style={{cursor: this.state.user.inactive_reason ? "not-allowed" : "pointer"}} onClick={this.state.user.inactive_reason ? null : this.addNewPrivilege}>Privileg hinzufügen</Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table responsive striped>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Rabatt</th>
                            <th>Aktiv</th>
                            <th>Objekttyp</th>
                            <th style={{textAlign: "right"}}>Objekt ID</th>
                            <th style={{textAlign: "right"}}>Objektname</th>
                            <th style={{textAlign: "right"}}>Aktion</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.state.privileges.map((privilege) => {
                            return (
                                <tr key={privilege.id}>
                                    <td>{privilege.id}</td>
                                    <td>{privilege.discount_percent}%</td>
                                    <td>{!privilege.active ? "Nein" : "Ja"}</td>
                                    <td>{privilege.privileged_object.toProperCase()}</td>
                                    <td style={{textAlign: "right"}}>{privilege.privileged_object_id}</td>
                                    <td style={{textAlign: "right"}}>{!privilege.privileged_garage ? "Alle Garagen" : privilege.privileged_garage.name}</td>
                                    <td style={{textAlign: "right"}}>
                                        <Button onClick={() => this.pickOutPrivilege(privilege)} className="btn btn-sm btn-warning mr-1">Bearbeiten</Button>
                                        <Button onClick={() => this.removePrivilege(privilege.id)} className="btn btn-sm btn-danger">Entfernen</Button>
                                    </td>
                                </tr>
                            )
                        }) }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );
    }
}

