import gql from "graphql-tag";


export default function query(params) {
    return gql`   
        {
            NFCOrderList(user_id: ${params.user_id}) {
                errors
                NFCOrders {
                    id
                    price
                    created_at
                }
            }
        }
    `;
}
