import React, { Component } from 'react';
import {
    Button,
    Row,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import _isEqual from 'lodash/isEqual'

import AsyncSelect from 'react-select/lib/Async';

import garageOwnerService from "../../../services/garageOwnerService";
import datetimeFormatter from "../../../utils/datetimeFormatter";
import companyService from "../../../services/companyService";
import {Link} from "react-router-dom";
import moment from "moment";



export default class GarageBasicData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabledGarageData: true,
            garage: props.garage
        };

        this.typingTimer = null;
        this.typingInterval = 600;

        this.toggleGarageData = this.toggleGarageData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateGarage = this.updateGarage.bind(this);
        this.changeOwner = this.changeOwner.bind(this);
        this.loadCompanies = this.loadCompanies.bind(this);
        this.onGarageOwnerClick = this.onGarageOwnerClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_isEqual(prevProps.garage, this.props.garage)) {
            // props have been changed
            this.setState({
                garage: this.props.garage
            })
        }

        const {garage_owner} = this.state.garage;

        if(garage_owner && typeof garage_owner !== 'object' && !garage_owner.hasOwnProperty('label')) {
            garageOwnerService.getOwner(garage_owner).then(resp => {
                this.setState({
                    garage: {
                        ...this.state.garage,
                        garage_owner: {
                            value: resp.data.id,
                            label: resp.data.display_name
                        }
                    }
                })
            }).catch(err => {console.log(err)})
        }
    }

    componentDidMount() {
        /*if (this.state.garage.garage_owner) {
            companyService.getCompany(this.state.garage.garage_owner)
                .then(owner => {
                    this.setState({
                        oldOwner: owner.data
                    })
                })
                .catch(err => {
                    console.log("ERRORED IS", err)
                })
        }*/

        const {garage_owner} = this.state.garage;
        if(garage_owner && typeof garage_owner !== 'object' && !garage_owner.hasOwnProperty('label')) {
            garageOwnerService.getOwner(garage_owner).then(resp => {
                this.setState({
                    garage: {
                        ...this.state.garage,
                        garage_owner: {
                            value: resp.data.id,
                            label: resp.data.display_name
                        }
                    }
                })
            }).catch(err => {console.log(err)})
        }
    }


    toggleGarageData() {
        this.props.toggleGarageBasicData()
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let parent = target.getAttribute('parent');

        let newState = {
            garage: {
                ...this.state.garage,
                [name]: value
            }
        };

        if(parent !== null) {
            newState = {
                garage: {
                    ...this.state.garage,
                    [parent]: {
                        ...this.state.garage[parent],
                        [name]: value
                    }
                }
            }
        }

        this.setState(newState);
    }

    updateGarage() {
        this.props.updateGarage({
            ...this.state.garage,
            garage_owner: this.state.garage.garage_owner ? this.state.garage.garage_owner.value : null
        }).then(() => {
            this.setState({
                disabledGarageData: true
            })
        })
            .catch(() => {
                this.setState({
                    disabledGarageData: false
                })
            })
    }

    changeOwner(owner) {
        this.setState({
            garage: {
                ...this.state.garage,
                garage_owner: !owner ? null : owner
            }
        });
    }

    onGarageOwnerClick(owner) {
        this.props.history.push("/garageowners/" + owner.value);
    }

    loadCompanies(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);

            this.typingTimer = setTimeout(() => {
                garageOwnerService.getOwners(1, value)
                    .then((response) => {
                        resolve({
                            options: response.data.results.map((owner) => {
                                return {value: owner.id, label: owner.display_name }
                            })
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.typingInterval)
        })
    }


    render() {
        return (
            <Card>
                <CardHeader>
                    Garagendaten
                    <div className="card-header-actions">
                        {
                            !this.props.garageBasicData
                                ?
                                <div>
                                    <Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateGarage}>
                                        <i className="fa fa-dot-circle-o"/> Speichern
                                    </Button>
                                    <Button type="reset" size="sm" color="danger" onClick={this.toggleGarageData}>
                                        <i className="fa fa-ban"/> Abbrechen
                                    </Button>
                                </div>
                                :
                                <a className="card-header-action btn btn-setting" onClick={this.toggleGarageData}><i className="icon-pencil"></i></a>
                        }
                    </div>
                </CardHeader>
                <CardBody className="pb-0">
                    {
                        this.props.loading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="name">Name*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" id="name" name="name" value={this.state.garage.name} disabled={this.props.garageBasicData} onChange={this.handleChange} required/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="ID">ID</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" name="id" id="id" value={this.state.garage.id} disabled/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="garage_owner">Garagenbesitzer</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        {/*{this.state.oldOwner &&
                                        <Row style={{paddingRight: 20, textAlign: 'right'}}>
                                           <small> war   <Link target={'blank'} to={`/companies/${this.state.oldOwner.id}`}>{this.state.oldOwner.name}</Link> </small>
                                        </Row>
                                        }*/}
                                        <AsyncSelect
                                            value={!this.state.garage.garage_owner ? null : this.state.garage.garage_owner}
                                            onChange={this.changeOwner}
                                            loadOptions={this.loadCompanies}
                                            onValueClick={this.onGarageOwnerClick}
                                            disabled={this.props.garageBasicData}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="strasse">Check-In Straße*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" id="street" parent="address" name="street" value={this.state.garage.address.street} disabled={this.props.garageBasicData} onChange={this.handleChange} required />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="nr">Check-In Nr*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" id="street_number" parent="address" name="street_number" value={this.state.garage.address.street_number} disabled={this.props.garageBasicData} onChange={this.handleChange} required />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="plz">Check-In PLZ*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" id="zip_code" parent="address" name="zip_code" value={this.state.garage.address.zip_code} disabled={this.props.garageBasicData} onChange={this.handleChange} required />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="plz">Check-In Stadt*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" id="city" parent="address" name="city" value={this.state.garage.address.city} disabled={this.props.garageBasicData} onChange={this.handleChange} required />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="plz">Land*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="select" id="country" parent="address" name="country" value={this.state.garage.address.country ? this.state.garage.address.country : ""} disabled={this.props.garageBasicData} onChange={this.handleChange} required >
                                            <option value="">-</option>
                                            <option value="AT">Österreich</option>
                                            <option value="DE">Deutschland</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="revenue_share">Revenue Share**</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="number" name="revenue_share" id="revenue_share" min="0" max="1" step="0.1" value={this.state.garage.revenue_share === 0 ? 0 : !this.state.garage.revenue_share ? "" : this.state.garage.revenue_share} disabled={this.props.garageBasicData} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="revenue_share_valid_from">Revenue Share Gültig Ab**</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" name="revenue_share_valid_from" id="revenue_share_valid_from" value={!this.state.garage.revenue_share_valid_from ? "" : moment(this.state.garage.revenue_share_valid_from).format("DD.MM.YYYY")} disabled onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="plz">Erstellt am</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text" id="created_at" parent="created_at" name="created_at" value={datetimeFormatter.forDisplay(this.state.garage.created_at)} disabled onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <small>
                                            *<strong>Pflichtfeld</strong>.
                                        </small> <br />
                                        <small>
                                            **<strong>Revenue Share</strong>: Required for LIVE garages
                                        </small>
                                    </Col>
                                </FormGroup>
                            </Form>
                    }
                </CardBody>
            </Card>
        )
    }

}
