import gql from "graphql-tag";


export default function(params){

    return gql`
        mutation {
            createGarage(
                name: "${params.name}"
                garage_owner: ${params.garage_owner === '' ? null : params.garage_owner}
                address: {
                    street: "${params.street}"
                    street_number: "${params.street_number}"
                    zip_code: "${params.zip_code}"
                    city: "${params.city}"
                    country: "${params.country}"
                }
                position: {
                    latitude: ${params.latitude === '' ? null : params.latitude}
                    longitude: ${params.longitude === '' ? null : params.longitude}
                }
                check_out_address: {
                    street: "${params.check_out_address_street}"
                    street_number: "${params.check_out_address_street_number}"
                    zip_code: "${params.check_out_address_zip_code}"
                    city: "${params.check_out_address_city}"
                }
                check_out_coordinates: {
                    latitude: ${!params.check_out_latitude === '' ? null : params.check_out_latitude}
                    longitude: ${!params.check_out_longitude === '' ? null : params.check_out_longitude}
                }
                exit_type_foot: ${params.exit_type_foot}
                entrance_type_foot: ${params.entrance_type_foot}
                echarger: ${params.echarger}
                narrow: ${params.narrow}
                barrierfree: ${params.barrierfree}
                video_surveilance: ${params.video_surveilance}
                footprint: ${params.footprint === '' ? null : params.footprint}
                exit_type: "${params.exit_type}"
                garage_height: ${params.garage_height === '' ? null : params.garage_height}
                indoor: ${params.indoor}
                levels: ${params.levels === '' ? null : params.levels}
                type: "${params.type}"
                tolerance_minutes: ${params.tolerance_minutes === '' ? null : params.tolerance_minutes}
                free_minutes: ${params.free_minutes === '' ? null : params.free_minutes}
                person_responsible: ${params.person_responsible === null ? null : [params.person_responsible]}
                property_management_company: ${params.property_management_company === '' ? null : params.property_management_company}
                door_maintenance_company: ${params.door_maintenance_company === '' ? null : params.door_maintenance_company}
                note: ""
                max_weight: ${params.max_weight === '' ? null : params.max_weight}
                handicapped_spots: ${params.handicapped_spots === '' ? null : params.handicapped_spots}
                security_guard: ${params.security_guard === '' ? null : params.security_guard}
                gateway_type: "${params.gateway_type}"
                smart_revenue: ${params.smart_revenue}
                smart_access: ${params.smart_access}
            ) {
                errors
                garage {
                    id
                    name
                }
            }
        }
            `;
}
