import gql from "graphql-tag";
export default function(params){

    return gql`
        mutation {
            triggerPCHealthCheck(
                id: "${params}"
            ) {
                errors
                gateway {
                    id
                }
            }
        }`;
}
