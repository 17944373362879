import React, { Component } from 'react';
import FileDrop from 'react-file-drop';
import GarageImage from './GarageImage';

import {
    Carousel,
    CarouselItem,
    CarouselControl,
} from 'reactstrap';

import {
    Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';

import garageService from "../../../services/garageService";

import createGaragePhotoMutation from "../../../mutations/createGaragePhoto";
import updateGaragePhotoMutation from "../../../mutations/updateGaragePhoto";
import deleteGaragePhotoMutation from "../../../mutations/deleteGaragePhoto";
import PlaceholderImage from "./PlaceholderImage";


export default class GarageGallery extends  Component {

    constructor(props) {
        super(props);

        this.state = {
            photos: this.props.photos || [],
            modalOpen: false,
            uploading: false,
            activeIndex: 0
        };

        this.toggleEditPhotos = this.toggleEditPhotos.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.deletePhoto = this.deletePhoto.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.updateGaragePhoto = this.updateGaragePhoto.bind(this);
    }


    handleDrop(files, event) {
        this.setState({
            uploading: true
        });

        garageService.uploadFile({garage_id: this.props.garageId}, 'upload/garage-photo', files[0], 'garage-photo')
            .then((resp) => {
                let photos = this.state.photos;
                photos.push(resp.data);

                this.setState({
                    photos: photos,
                    uploading: false
                });
                this.props.success();
            })
            .catch(err => {
                console.log(JSON.stringify(err));

                this.setState({
                    uploading: false
                });
            })
    }


    updateGaragePhoto(data) {
        garageService.createService(updateGaragePhotoMutation(data))
            .then((response) => {

                let photo = response.data.updateGaragePhoto.garagePhoto;
                let photos = this.state.photos.map((_photo) => {
                    if(_photo.id === photo.id) {
                        return photo
                    } else {
                        return {
                            ..._photo,
                        }
                    }
                });

                this.setState({
                    photos: photos
                });

                this.props.success();
            })
            .catch((err) => {
                console.log(err);
                this.props.unsuccessful('Erfolglos!')
            });
    }


    toggleEditPhotos() {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    deletePhoto(id) {
        garageService.createService(deleteGaragePhotoMutation({id}))
            .then((response) => {
                this.setState({
                    photos: this.state.photos.filter((photo) => {
                        return photo.id != id
                    })
                });
                this.props.success();
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
            });

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.photos.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.photos.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    getModalPhotos(){
        return this.state.photos.map((photo) => (
            <Col md="4" key={photo.id}>
                <GarageImage {...photo} updateGaragePhoto={this.updateGaragePhoto} deletePhoto={this.deletePhoto} />
            </Col>
        ));

    }

    getSliderPhotos() {

        let data = [...this.state.photos];
        data.sort((a,b) => b.type - a.type);

        return data.map((photo) => (
            <CarouselItem
                key={photo.id}
                onExiting={this.onExiting}
                onExited={this.onExited}
            >
                <GarageImage {...photo} />
            </CarouselItem>

        ));
    }

    renderModal() {

        return (
            <Modal isOpen={this.state.modalOpen} toggle={this.toggleEditPhotos}
                   className={'modal-info modal-lg'}>
                <ModalHeader toggle={this.toggleNfc}>Bilder bearbeiten</ModalHeader>
                <ModalBody>
                    <Row style={{borderBottom: "1px solid #e4e7ea"}}>
                        <Col md={12}>
                            <FileDrop onDrop={this.handleDrop} className="filedrop">
                                <div>
                                    {this.state.uploading ? (
                                        <div className="sk-rotating-plane"/>
                                    ) : (
                                        <div>Hier das Bild anhängen </div>
                                    )}
                                </div>
                            </FileDrop>
                        </Col>
                    </Row>

                    <Row style={{marginTop: "20px"}}>
                        {this.getModalPhotos()}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.toggleEditPhotos}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render(){
        return (
            <React.Fragment>
                {this.renderModal()}
                <Card>
                    <CardHeader>
                        Garagenbilder
                        <div className="card-header-actions">
                            <a className="card-header-action btn btn-setting" onClick={this.toggleEditPhotos}><i className="icon-pencil"/></a>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {
                            this.state.photos.length < 1 ? <PlaceholderImage /> :
                            <Carousel
                                interval={false}
                                activeIndex={this.state.activeIndex}
                                next={this.next}
                                previous={this.previous}
                            >
                                {this.getSliderPhotos()}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        )

    }
}
