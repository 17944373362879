import React, {Component} from 'react';
import {Col, Row, Card, CardBody, CardHeader, Form, FormGroup, Label, Button} from 'reactstrap';
import DateTimePicker from "react-datetime-picker";

import statsService from "../../../services/statsService";
import datetimeFormatter from "../../../utils/datetimeFormatter";

const humanizeDuration = require('humanize-duration');

const prettifyDuration = (duration) => {
    return !duration ? 0 : humanizeDuration(duration * 60 * 1000, {language: 'de', round: true})
};

const creditifySums = (sum) => {
    return !sum ? 0 + " Credits" : sum.toFixed(2) + " Credits"
}


class UserStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            userDateFrom: null,
            userDateTo: null,
            id: this.props.user,
        };

        this.onDateChanged = this.onDateChanged.bind(this);
        this.refreshStats = this.refreshStats.bind(this);
        this.fetchUserStats = this.fetchUserStats.bind(this);
    }

    fetchUserStats() {

        let params = {};

        if (this.state.userDateFrom !== null) {
            params['since'] = datetimeFormatter.forStats(this.state.userDateFrom);
        }
        if (this.state.userDateTo !== null) {
            params['to'] = datetimeFormatter.forStats(this.state.userDateTo);
        }

        statsService.getSingleUserStats(this.state.id, params)
            .then((userStats) => {
                this.setState({
                    userStats: userStats.data,
                    loading: false
                })
            })
            .catch((err) => {
                console.log(JSON.stringify(err))
            })
    }

    onDateChanged(fromTo, val) {
        this.setState({
            ['userDate' + fromTo[0].toUpperCase() + fromTo.slice(1)]: val
        }, this.fetchUserStats);

    }

    refreshStats() {
        this.setState({
            userDateFrom: null,
            userDateTo: null,
        }, () => this.fetchUserStats())
    }

    componentDidMount() {
        this.fetchUserStats()
    }


    render() {
        return (
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="align-self-center my-auto">
                                    <Form inline>
                                        <FormGroup className="pr-2">
                                            <Label htmlFor="dateFrom" className="pr-3">Ab: </Label>
                                            <DateTimePicker locale="de" className="form-control"
                                                            value={datetimeFormatter.forPicker(this.state.userDateFrom)}
                                                            onChange={this.onDateChanged.bind(this, 'from')}
                                                            showLeadingZeros={true}
                                            />
                                        </FormGroup>
                                        <FormGroup className="pr-1">
                                            <Label htmlFor="dateTo" className="pr-3">Bis: </Label>
                                            <DateTimePicker locale="de" className="form-control"
                                                            value={datetimeFormatter.forPicker(this.state.userDateTo)}
                                                            onChange={this.onDateChanged.bind(this, 'to')}
                                                            showLeadingZeros={true}
                                            />
                                        </FormGroup>
                                        <FormGroup className="pr-1">
                                            <Button className="btn btn-block btn-success"
                                                    onClick={this.refreshStats}><i
                                                className="fa fa-refresh fa-lg text-white"></i></Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.state.loading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <>
                                    <Row>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Paid credits spent on parking
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{creditifySums(this.state.userStats.credits_real_parking)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Bonus credits spent on parking
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{creditifySums(this.state.userStats.credits_bonus_parking)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Paid credits spent on reservations
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{creditifySums(this.state.userStats.credits_real_reservation)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Bonus credits spent on reservations
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{creditifySums(this.state.userStats.credits_bonus_reservation)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Paid credits spent on parking & reservations
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{creditifySums(this.state.userStats.credits_real_parking_reservation)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Bonus credits spent on parking & reservations
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{creditifySums(this.state.userStats.credits_bonus_parking_reservation)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Total duration of all parkings
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{prettifyDuration(this.state.userStats.total_duration_parking)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Average duration of parking
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{prettifyDuration(this.state.userStats.avg_duration_parking)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Amount of parkings
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{this.state.userStats.num_of_parkings}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Total duration of reservations
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{prettifyDuration(this.state.userStats.total_duration_reservation)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Average duration of a reservation
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{prettifyDuration(this.state.userStats.avg_duration_reservation)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={2}>
                                            <Card>
                                                <CardHeader>
                                                    Amount of reservations
                                                </CardHeader>
                                                <CardBody>
                                                    <h5>{this.state.userStats.num_of_reservations}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Card>
                                                <CardHeader className="text-center">
                                                    Bought credits
                                                </CardHeader>
                                                <CardBody className="text-center">
                                                    <h5>{creditifySums(this.state.userStats.bought_credits)}</h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        );
    }
}

export default UserStats;
