import urls from '../utils/urls';
import axios from "axios";

import errorHandlerService from './errorHandlerService';

const couponService = {

    getCoupons: (pageSize=10, page=null, search="") => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/vouchers/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                params: {
                    page,
                    page_size: pageSize,
                    search: search || null,

                }
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.getCoupons, pageSize, page, search
                    )
                }
            });
        });

    },

    getCouponUsers: (couponId, pageSize=10, page=1, search="") => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/vouchers/${couponId}/users/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                params: {
                    page,
                    page_size: pageSize,
                    search: search || null,

                }
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.getCouponUsers, couponId, pageSize, page, search
                    )
                }
            });
        });
    },

    getCoupon: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/vouchers/${id}/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                }
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.getCoupon, id)
                }
            });
        });
    },

    createCoupon: (coupon) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${urls.apigateway}/vouchers/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(coupon)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.createCoupon, coupon)
                }
            });
        });
    },

    deleteCoupon: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${urls.apigateway}/vouchers/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.deleteCoupon, id)
                }
            });
        });
    },

    assignUsers: (id, users) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${urls.apigateway}/vouchers/${id}/users/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify({users})
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.assignUsers, id, users)
                }
            });
        });
    },

    unassignUsers: (id, user) => {

        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${urls.apigateway}/vouchers/${id}/users/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify({users: [user]})
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.unassignUsers, id, user)
                }
            });
        });
    },

    updateCoupon: (coupon) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/vouchers/${coupon.id}/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(coupon)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        couponService.updateCoupon, coupon)
                }
            });
        });
    },
 };

export default couponService;
