import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Table
} from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';

import AssignedGateway from "../AssignedGateway";
import garageService from "../../../services/garageService";
import assignNode from "../../../mutations/assignPayucaConnectNode";
import assignMaster from "../../../mutations/assignPayucaConnectMaster";
import unassignMaster from '../../../mutations/unassignPayucaConnectMaster';
import unassignNode from '../../../mutations/unassignPayucaConnectNode';
import getPayucaConnectGateways from '../../../queries/getPayucaConnectGateways';

export default class GarageGateways extends Component {

    constructor(props) {
        super(props);
        this.state = {            
            addGatewayModalOpen: false,
            selectedGateway: null
        };

        this.typingTimer = null;
        this.typingInterval = 600;

        this.toggleAddGateway = this.toggleAddGateway.bind(this);
        this.onGatewaySelected = this.onGatewaySelected.bind(this);
        this.assignGateway = this.assignGateway.bind(this);
        this.removeGateway = this.removeGateway.bind(this);
        this.loadGateways = this.loadGateways.bind(this);
    }


    toggleAddGateway() {
        this.setState({
            addGatewayModalOpen: !this.state.addGatewayModalOpen,
        });
    }

    loadGateways(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    offset: 0,
                    assigned: 'UNASSIGNED',
                    searchTerm: value
                };

                params.is_master = this.props.gateways.length <= 0

                garageService.getService(getPayucaConnectGateways(params))
                    .then((gateways) => {
                        if (gateways.data.getPCGateways.gateways.length) {
                            resolve({
                                options: gateways.data.getPCGateways.gateways
                                    .map((gateway) => {
                                        return {
                                            value: gateway.id,
                                            label: gateway.gwid,
                                            device_id: gateway.device_id || null
                                        }
                                    })
                            })
                        } else {
                            resolve({
                                options: []
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(JSON.stringify(err));
                    });
            }, this.typingInterval)


        })
    }

    assignGateway() {

        let data = {
            id: this.state.selectedGateway.value,
        };
        if (this.state.selectedGateway.device_id) {
            data.garageId = this.props.garageId;
        } else {
            data.master_id = this.getMasterId();
        }

        const mutation = this.state.selectedGateway.device_id ? assignMaster(data) : assignNode(data);

        garageService.createService(mutation)
            .then((response) => {
                this.setState({
                    addGatewayModalOpen: !this.state.addGatewayModalOpen,
                    selectedGateway: null,
                });
                this.props.getGarageByID({ id: this.props.garageId })
                this.props.success();
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    removeGateway(selectedGateway) {
        let data = {
            id: selectedGateway.id
        };

        const mutation = selectedGateway.device_id ? unassignMaster(data) : unassignNode(data);

        garageService.createService(mutation)
            .then((response) => {
                this.props.getGarageByID({ id: this.props.garageId });
                this.props.success();
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    onGatewaySelected(gateway) {
        this.setState({
            selectedGateway: gateway
        });
    }

    getMasterId() {
        return this.props.gateways.filter((gateway) => gateway.device_id)[0].id
    }
    renderModal() {
        return (
            <Modal isOpen={this.state.addGatewayModalOpen} toggle={this.toggleAddGateway}
                   className='modal-success'>
                <ModalHeader toggle={this.toggleAddGateway}>Gateway zuordnen</ModalHeader>
                <ModalBody>
                    <AsyncSelect
                        value={this.state.selectedGateway}
                        onChange={this.onGatewaySelected}
                        loadOptions={this.loadGateways}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.assignGateway}>Gateway zuweisen</Button>{' '}
                    <Button color="secondary" onClick={this.toggleAddGateway}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        )
    }


    render() {
        return (
            <React.Fragment>
                {this.renderModal()}
                <Card>
                    <CardHeader>
                        Gateways
                        <div className="card-header-actions">
                            <Button className="btn-success btn-sm"
                                    onClick={this.toggleAddGateway}
                            >
                                Gateway zuordnen
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Table responsive striped size="sm">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Bezeichnung</th>
                                <th>Device ID</th>
                                <th>Typ</th>
                                <th>Zustand</th>
                                <th>Ist Meister</th>
                                <th style={{textAlign: "right"}}>Aktion</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.gateways.length ? this.props.gateways.map((gateway) => {
                                return <AssignedGateway data={gateway}
                                                        newPCGateway={this.props.newPCGateways}
                                                        removeGateway={() => {
                                                            this.removeGateway(gateway)
                                                        }}
                                                        key={gateway.id}/>
                            }): null}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

}
