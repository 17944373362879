import gql from "graphql-tag";

export default function(params) {
    return gql`
        query {
            getMyDatanetCustomers {
                errors
                customers {
                    _uid
                    name
                }
            }
        }`;
}
