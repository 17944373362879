import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {Link} from "react-router-dom";
import { Redirect } from 'react-router'

import logo from '../../assets/img/brand/logo.svg';
import userService from "../../services/userService";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            failedLogin: false,
            redirect: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.login = this.login.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        document.title = "PAYUCA Cockpit | Anmeldung";
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    login() {
        userService.login({
            username: this.state.username,
            password: this.state.password,
        })
        .then((response) => {
            if (response.status === 200) {
                this.setState({
                    redirect: true
                })
            } else {
                this.setState({
                    failedLogin: true
                })
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    toggleModal() {
        this.setState({
            failedLogin: !this.state.failedLogin,
        });
    }

    render() {

        if(this.state.redirect) {
            return <Redirect to="/garages"/>
        }

        return (
            <div className="app appLogin flex-row align-items-center animated fadeIn">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <img src={logo} style={{width: 200}} className="p-3" alt="PAYUCA Logo"/>
                            <Card className="p-4">
                                <CardBody>
                                    <h1>Anmelden</h1>
                                    <p className="text-muted">Melde dich in deinem Konto an</p>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-user"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" placeholder="E-Mail-Adresse" name="username" value={this.state.username} onKeyPress={this.state.username.length < 5 ? null : this.handleKeyPress} onChange={this.handleChange} />
                                    </InputGroup>
                                    <InputGroup className="mb-4">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-lock"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="password" placeholder="Passwort" name="password" value={this.state.password} onKeyPress={this.state.password.length < 5 ? null :this.handleKeyPress} onChange={this.handleChange} />
                                    </InputGroup>
                                    <Row>
                                        <Col xs="6">
                                            <Button color="primary" className="px-4" onClick={this.login}>Anmelden</Button>
                                        </Col>
                                        <Col xs="6" className="text-right">
                                            <Link to="/forgotPassword"><Button color="link" className="px-0">Passwort vergessen?</Button></Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={this.state.failedLogin} toggle={this.toggleModal}
                       className={'modal-danger ' + this.props.className}>
                    <ModalHeader toggle={this.toggleModal}>Anmeldung war nicht erfolgreich</ModalHeader>
                    <ModalBody>
                        <p>
                            <strong>Etwas ist schief gelaufen!</strong>
                        </p>
                        <p>
                            Bitte versuche es in ein paar Minuten noch einmal
                        </p>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default Login;
