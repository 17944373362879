import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Table
} from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';

import AssignedGateway from "../AssignedGateway";
import gatewayService from "../../../services/gatewayService";
import getPoolGatewaysQuery from "../../../queries/getPoolGateways";
import garageService from "../../../services/garageService";
import assignGatewayMutation from "../../../mutations/assignGateway";
import removeGatewayMutation from "../../../mutations/returnToPool";
import unassignMaster from '../../../mutations/unassignMaster';
import unassignNode from '../../../mutations/unassignNode';


export default class GarageGateways extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            newGateways: this.props.newGateways,
            gateways: this.props.gateways,
            addGatewayModalOpen: false,
            selectedGateway: null
        };

        this.typingTimer = null;
        this.typingInterval = 600;

        this.toggleAddGateway = this.toggleAddGateway.bind(this);
        this.onGatewaySelected = this.onGatewaySelected.bind(this);
        this.assignGateway = this.assignGateway.bind(this);
        this.removeGateway = this.removeGateway.bind(this);
        this.loadGateways = this.loadGateways.bind(this);
    }


    toggleAddGateway() {
        this.setState({
            addGatewayModalOpen: !this.state.addGatewayModalOpen,
        });
    }

    loadGateways(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    offset: 0,
                    limit: 10,
                    searchTerm: value
                };

                gatewayService.getService(getPoolGatewaysQuery(params))
                    .then((gateways) => {
                        if (gateways.data.getPoolGateways.gateways.length) {
                            resolve({
                                options: gateways.data.getPoolGateways.gateways.map((gateway) => {
                                    return {
                                        value: gateway.id,
                                        label: gateway.device_id + " | " + (!gateway.name ? "" : gateway.name),
                                        customer_id: gateway.customer_id
                                    }
                                })
                            })
                        } else {
                            resolve({
                                options: []
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(JSON.stringify(err));
                    });
            }, this.typingInterval)


        })
    }

    assignGateway() {

        let data = {
            gatewayID: this.state.selectedGateway.value,
            garageTarget: this.props.garageId,
            customer_id: this.state.selectedGateway.customer_id,
        };

        garageService.createService(assignGatewayMutation(data))
            .then((response) => {
                this.setState({
                    gateways: [...this.state.gateways, response.data.assignGateway.gateway],
                    addGatewayModalOpen: !this.state.addGatewayModalOpen,
                    selectedGateway: null,
                });
                this.props.success();
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    removeGateway(selectedGateway) {
        let data = {
            gwid: selectedGateway.gwid,
            gatewayID: selectedGateway.id,
            garageFrom: this.props.garageId
        };

        const mutation = selectedGateway.is_master ? unassignMaster(data) : selectedGateway.id ? removeGatewayMutation(data) : unassignNode(data);

        garageService.createService(mutation)
            .then((response) => {
                this.setState({
                    gateways: this.state.gateways.filter((gateway) => {
                        return gateway !== selectedGateway;
                    })
                }, () => {
                    this.props.success();
                });

            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    onGatewaySelected(gateway) {
        this.setState({
            selectedGateway: gateway
        });
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.addGatewayModalOpen} toggle={this.toggleAddGateway}
                   className='modal-success'>
                <ModalHeader toggle={this.toggleAddGateway}>Gateway zuordnen</ModalHeader>
                <ModalBody>
                    <AsyncSelect
                        value={this.state.selectedGateway}
                        onChange={this.onGatewaySelected}
                        loadOptions={this.loadGateways}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.assignGateway}>Gateway zuweisen</Button>{' '}
                    <Button color="secondary" onClick={this.toggleAddGateway}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        )
    }


    render() {
        return (
            <React.Fragment>
                {this.renderModal()}
                <Card>
                    <CardHeader>
                        Gateways
                        <div className="card-header-actions">
                            <Button className="btn-success btn-sm"
                                    onClick={this.toggleAddGateway}
                            >
                                Gateway zuordnen
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Table responsive striped size="sm">
                            <thead>
                            <tr>
                                <th>{`${this.state.newGateways ? "GWID" : "ID"}`}</th>
                                <th>Bezeichnung</th>
                                <th>Device ID</th>
                                <th>Typ</th>
                                <th>Zustand</th>
                                {!!this.state.newGateways && <th>Ist Meister</th>}
                                <th style={{textAlign: "right"}}>Aktion</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.gateways.map((gateway) => {
                                return <AssignedGateway data={gateway}
                                                        newGateway={this.state.newGateways}
                                                        removeGateway={() => {
                                                            this.removeGateway(gateway)
                                                        }}
                                                        key={gateway.id}/>
                            })}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

}
