import gql from "graphql-tag";


export default function (params) {
    return gql`
        mutation {
            adminEndParking(
                userId: ${params.userId}
                endTime: "${params.endTime}"
                openDoor: ${params.openDoor}
                licence_plate: "${params.licence_plate}"
                grantFreeParking: ${params.grantFreeParking === "true" ? true : false}
                transaction_id: "${params.transactionId}"
                garage_id: "${params.garageId}"
                gwid: "${params.gwid}"
            ) {
                errors
                transaction {
                    id
                }
            }
        }`;
}
