import gql from "graphql-tag";

export default function (params) {
  return gql`
   mutation { 
      createVersionBlockEntry(
          version: "${params.version}", 
          blocksIOS: ${params.blocksIOS}, 
          blocksAndroid: ${params.blocksAndroid}, 
          message_de: "${params.custom_message_de}", 
          message_en: "${params.custom_message_en}"
    ) { 
        errors, 
        versionBlock { 
          id, 
          message_de, 
          message_en, 
          version, 
          ios, 
          android 
        } 
      } 
    }
    `;
}
