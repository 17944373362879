import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row, Form, FormGroup, Input, Label, Button} from 'reactstrap';
import 'spinkit/css/spinkit.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Widget from "../../components/Widget/Widget";
import userService from "../../services/userService";

class OAuthApp extends Component {


    constructor(props) {
        super(props);

        this.state = {
            disabled: true,
            loading: true,
            authOptions: [
                {value: "password", label: "password"},
                {value: "client-credentials", label: "client-credentials"}
            ],
            app: {
                name: '',
                client_id: '',
                client_secret: '',
                app_token: '',
                client_type: '',
                scope: '',
                authorization_grant_type: []
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleDisabled = this.toggleDisabled.bind(this);
        this.authHandler = this.authHandler.bind(this);
        this.updateApp = this.updateApp.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let app = this.state.app;

        app[name] = value;

        this.setState({
            [name]: value,
            app: app
        });

    }

    authHandler(authTypes) {
        this.setState({
            app: {
                ...this.state.app,
                authorization_grant_type: authTypes.map(authType => authType.value)
            }
        });
    }

    unsuccessful(msg='Erfolglos') {
        return toast.error(msg)
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    toggleDisabled() {
        this.setState( {
            disabled: !this.state.disabled
        })
    }

    copyOnClick() {
        let copyText = document.getElementById("app_token");
        copyText.select();
        document.execCommand("copy");
    }

    updateApp() {

        return new Promise((resolve, reject) => {

            userService.updateOAuthApp({
                id: this.state.app.id,
                name: this.state.app.name,
                scope: this.state.app.scope,
                authorization_grant_type: this.state.app.authorization_grant_type,
                client_type: this.state.app.client_type
            })
                .then((response) => {
                    resolve();
                    if (response.status === 200) {
                        this.setState({
                            app: response.data,
                            disabled: true,
                        });
                        this.success();
                    } else {
                        this.setState({
                            disabled: !this.state.disabled ? false : true,
                        });
                        this.unsuccessful();
                    }
                })
                .catch((err) => {
                    reject();

                    let errors = err.response.data.error;
                    this.unsuccessful(errors);

                    this.setState({
                       app: {
                           ...this.state.appForm
                       }
                    });
                });
        })
    }

    componentDidMount() {

        const { match: { params } } = this.props;

        userService.getOAuthApp(params.id)
            .then((resp) => {
                this.setState({
                    app: resp.data,
                    appForm: resp.data,
                    loading: false,
                }, () => {
                    document.title = "PAYUCA Cockpit | OAuth App";
                });

            })
            .catch((err) => {
                this.unsuccessful(err);
            });

    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        return (
            <div className="animated">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Widget icon="icon-layers" color="info" header={"PAYUCA OAuth App | " + this.state.app.name}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardHeader>
                                App hinzufügen
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateApp}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleDisabled}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="12" lg="6">
                                        <Form action="" method="post" encType="multipart/form-data"
                                              className="form-horizontal">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">Name</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="name" name="name"
                                                           value={this.state.app.name}
                                                           onChange={this.handleChange}
                                                           disabled={this.state.disabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="clientID">Client ID</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="client_id" name="client_id"
                                                           value={this.state.app.client_id}
                                                           onChange={this.handleChange}
                                                           disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="clientSecret">Client Secret</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="clientSecret" name="clientSecret"
                                                           defaultValue="Not public information!"
                                                           disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="clientType">Client Type</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" id="client_type" name="client_type"
                                                           value={this.state.app.client_type}
                                                           onChange={this.handleChange}
                                                           disabled={this.state.disabled}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="public">Public</option>
                                                        <option value="confidential">Confidential</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="Authorization Grant Type">Authorization Grant Type</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={!this.state.app.authorization_grant_type ? null : this.state.app.authorization_grant_type}
                                                        options={this.state.authOptions}
                                                        onChange={this.authHandler}
                                                        disabled={this.state.disabled}
                                                        multi
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col xs="12" sm="12" lg="6">
                                        <Form action="" method="post" encType="multipart/form-data"
                                              className="form-horizontal">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">Scope</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="textarea" name="scope" id="scope" rows="3"
                                                           value={this.state.app.scope}
                                                           onChange={this.handleChange}
                                                           disabled={this.state.disabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">App-Token</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="textarea" name="app_token" id="app_token" rows="7"
                                                           value={this.state.app.app_token}
                                                           readOnly="readonly"
                                                           style={{cursor: "pointer"}}
                                                           onClick={this.copyOnClick}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default OAuthApp;
