import urls from '../utils/urls';
import axios from "axios";
import errorHandlerService from "./errorHandlerService";

const privilegeService = {

    createPrivilege: (privilege) => {

        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${urls.apigateway}/privileges/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(privilege)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        privilegeService.createPrivilege, privilege
                    )
                }
            });
        });
    },

    getForUser: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/privileges/user/${id}/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        privilegeService.getForUser, id
                    )
                }
            });
        });
    },

    deletePrivilege: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${urls.apigateway}/privileges/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        privilegeService.deletePrivilege, id
                    )
                }
            });
        });
    },

    updatePrivilege: (privilege) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/privileges/${privilege.id}/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(privilege)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        privilegeService.updatePrivilege, privilege
                    )
                }
            });
        });
    },
};

export default privilegeService;
