import React, {Component} from 'react';
import { toast } from 'react-toastify';
import {Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Collapse, Button} from 'reactstrap';
import isEmpty from 'validator/lib/isEmpty'
import isNumeric from 'validator/lib/isNumeric'
import datetimeFormatter from "../../../utils/datetimeFormatter";

class ParkingSpace extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props.data,
            garage: this.props.garage,
            accordion: false,

            saveDisabled: this.isFormEmpty(this.props.data)
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.deleteParkingSpace = this.deleteParkingSpace.bind(this);

    }

    toggleAccordion() {
        this.setState({
            accordion: !this.state.accordion,
        });
    }

    isFormEmpty(values) {
        return isEmpty(values.number) && isEmpty(values.type) && isEmpty(String(values.level)) && isEmpty(values.state) && !isNumeric(values.width + '') && !isNumeric(values.height + '') && !isNumeric(values.length + '')
    }

    handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        let theState = this.state;
        theState[name] = value;
        theState['saveDisabled'] = this.isFormEmpty(theState);

        this.setState(theState);
    }

    handleSave() {
        this.props.handleSave({...this.state})
    }

    deleteParkingSpace() {
      const {
        garage: { takenSpaces, totalSpaces },
      } = this.state;

      if (takenSpaces >= totalSpaces) {
        return toast.error(
          'Sorry, es ist nicht möglich Stellplätze zu löschen während alle besetzt sind'
        );
      }

        this.props.deleteParkingSpace({...this.state})
    }

    render() {
        return (
            <Col xs="12" sm="12" md="12" lg="4">
                <Card className={this.state.active ? "border-success" : "border-danger"}>
                    <CardHeader>
                        Stellplatz {!this.state.number ? this.props.index + 1 : this.state.number}
                        <div className="card-header-actions">
                            {
                                this.state.accordion
                                    ?
                                    <div><Button type="submit" size="sm" color="success" className={"mr-1"}
                                                 onClick={this.handleSave} disabled={this.state.saveDisabled}><i
                                        className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset"
                                                                                                       size="sm"
                                                                                                       color="danger"
                                                                                                       onClick={this.toggleAccordion}><i
                                        className="fa fa-ban"></i> Abbrechen</Button></div>
                                    :
                                    <a className="card-header-action btn btn-setting" onClick={this.toggleAccordion}
                                       aria-controls="collapseOne"><i className="icon-pencil"></i></a>
                            }
                        </div>
                    </CardHeader>
                    <Collapse isOpen={this.state.accordion} data-parent="#accordion" id="collapseOne"
                              aria-labelledby="headingOne">
                        <CardBody className="pb-0">
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="ID">ID</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="text" name="id" id="id" value={this.state.id} disabled/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="number">Stellplatz Nummer</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="text" name="number" id="number" value={this.state.number}
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="active">Aktiv</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="select" name="active" id="active" value={this.state.active}
                                           onChange={this.handleChange}>
                                        <option value={null}>--</option>
                                        <option value={true}>Ja</option>
                                        <option value={false}>Nein</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="level">Niveau</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="select" name="level" id="level" value={this.state.level}
                                           onChange={this.handleChange} placeholder={0}>
                                        <option value={0}>0 (EG)</option>
                                        <option value={10}>10 OG</option>
                                        <option value={9}>9 OG</option>
                                        <option value={8}>8 OG</option>
                                        <option value={7}>7 OG</option>
                                        <option value={6}>6 OG</option>
                                        <option value={5}>5 OG</option>
                                        <option value={4}>4 OG</option>
                                        <option value={3}>3 OG</option>
                                        <option value={2}>2 OG</option>
                                        <option value={1}>1 OG</option>
                                        <option value={-1}>-1 UG</option>
                                        <option value={-2}>-2 UG</option>
                                        <option value={-3}>-3 UG</option>
                                        <option value={-4}>-4 UG</option>
                                        <option value={-5}>-5 UG</option>
                                        <option value={-6}>-6 UG</option>
                                        <option value={-7}>-7 UG</option>
                                        <option value={-8}>-8 UG</option>
                                        <option value={-9}>-9 UG</option>
                                        <option value={-10}>-10 UG</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="state">Zustand</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="text" name="state" id="state" value={this.state.state}
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="stack_parking">Stapelparkplatz</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="select" id="stack_parking" name="stack_parking"
                                           value={this.state.stack_parking} onChange={this.handleChange}>
                                        <option value={true}>Ja</option>
                                        <option value={false}>Nein</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="width">Stellplatzbreite</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="number" name="width" id="width" value={this.state.width}
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="height">Stellplatzhöhe</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="number" name="height" id="height" value={this.state.height}
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="length">Stellplatzlänge</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="number" name="length" id="length" value={this.state.length}
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="type">Info</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="text" name="type" id="type" value={this.state.type}
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="state_changed">Aktualisiert am</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="text" name="state_changed" id="state_changed"
                                           value={datetimeFormatter.forDisplay(this.state.state_changed)} disabled
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <Button type="submit" size="sm" color="danger" className={"mr-1 btn-block"}
                                            onClick={this.deleteParkingSpace}>Stellplatz löschen</Button>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        );
    }
}

export default ParkingSpace;
