import React, {Component} from 'react';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import dictionary from '../../utils/dictionary';
import HealthStatus from '../../components/HealthStatus';

class AssignedGateway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.data,
        };
        this.renderGatewayType = this.renderGatewayType.bind(this);
    }

    renderGatewayType() {
        let types = this.state.type;

        switch (types) {
            case "CHECK_IN":
                return "Check In";
            case "CHECK_OUT":
                return "Check Out";
            case "CHECK_IN_OUT":
                return "Check In/Out";
            case "MIDGATEWAY":
                return "Zwischentür";
            case "MIDGATEWAY_ONLINE":
                return "Zwischentür (Online)";
            case "MIDGATEWAY_OFFLINE":
                return "Zwischentür (Offline)";
            case "NFC_WRITE":
                return "NFC Gateway";
            case "EXIT_BARRIER":
                return "Ausfahrtsschranken";
            case "EXIT_BARRIER_ONLINE":
                return "Ausfahrtsschranken (Online)";
            case "EXIT_BARRIER_OFFLINE":
                return "Ausfahrtsschranken (Offline)";
            case "REPEATER":
                return "Signalverstärker";
            default:
                return "k.A";
        }
    }

    renderHealthStyle(data) {
        if (data.health_status) {
            return <HealthStatus healthStatus={data.health_status} />
        }

        const status = data.state;
        let state = status.toLowerCase();

        const color = state === 'online' ? 'text-success' : state === 'attention' ? 'text-warning' : 'text-danger';
        return <div><i className={`fa fa-circle ${color} Blink`}/>{status}</div>
    }

    render() {
        return (
            <tr>
                {!this.props.newGateway && !this.props.newPCGateway && <td><Link to={"/gateways/"+this.state.id}>{this.state.id}</Link></td>}
                {!!this.props.newGateway && <td><Link to={`/gateways2/${this.state.gwid}`}>{this.state.gwid}</Link></td>}
                {!!this.props.newPCGateway && <td><Link to={"/payuca-connect/"+this.state.id}>{this.state.id}</Link></td>}
                <td>{this.state.name || dictionary.NOT_AVAILABLE}</td>
                <td>{this.state.device_id || dictionary.NOT_AVAILABLE}</td>
                <td>{this.renderGatewayType()}</td>
                <td>
                    {this.renderHealthStyle(this.state)}
                </td>
                {(!!this.props.newGateway || !!this.props.newPCGateway) &&
                    <td>
                        {`${(this.state.is_master || !this.state.master_id) ? "Ja" : "Nein"}`}
                    </td>
                }
                <td>
                    <Button className="btn btn-sm btn-danger pull-right" onClick={() => this.props.removeGateway(this.state.id)}>
                        Aufheben
                    </Button>
                </td>
            </tr>
        );
    }
}

export default AssignedGateway;
