import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Widget from "../../components/Widget/Widget";
import {Link} from "react-router-dom";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import CouponsTable from "../../components/CouponsTable";

class Coupons extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            table: [],
        }

    }

    componentDidMount() {
        document.title = "PAYUCA Cockpit | Coupons"
    }

    render() {

        return (
            <div className="animated">
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-tag" color="info" header="PAYUCA Coupons" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Link to="/coupons/addCoupon" className="noDecoLink">
                            <Widget
                                icon="icon-plus"
                                color="success"
                                header="Gutschein hinzufügen"
                                invert
                                style={{cursor: "pointer"}}
                            />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <CouponsTable by="coupons"/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Coupons;
