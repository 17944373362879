import gql from "graphql-tag";

export default function getVersionBlockInfoQuery(id) {
  return gql` { 
        getVersionBlock(id: "${id}") { 
            errors, 
            versionBlock { 
                id,
                ios,
                android,
                message_de,
                message_en,
                version,
                updated_at,
                created_at,
                deleted_at,
            } 
        } 
    }`;
}
