import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {Link} from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import contactService from "../../services/contactService";
import CustomSearchField from "../../components/CustomSearchField";

class Contacts extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contacts: [],
            page: 1,
            totalSize: 0
        };

        this.typingTimer = null;
        this.typingInterval = 700;

        this.onSearch = this.onSearch.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.fetchContacts = this.fetchContacts.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);
        this.getClearButton = this.getClearButton.bind(this);

    }

    static ToContact(cell, row) {
        const id = row.id;
        return <Link to={`/contacts/${id}`}> {cell} </Link>;
    }

    static ToCompany(cell) {
        if (!cell) {
            return "k.A"
        } else {
            const id = cell.id;
            return <Link to={`/companies/${id}`}> {cell.name} {cell.company_type} </Link>;
        }
    }

    componentDidMount() {

        contactService.getContacts(this.state.page)
            .then((resp) => {

                if(resp.data.hasOwnProperty("results"))
                    this.setState({
                        contacts: resp.data.results,
                        loading: false,
                        totalSize: resp.data.count
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        document.title = "PAYUCA Kontaktpersonen";
    }

    fetchContacts() {
        contactService.getContacts(this.state.page, this.state.searchQuery)
            .then((resp) => {
                this.setState({
                    loading: false,
                    contacts:  resp.data.results || [],
                    totalSize: resp.data.count
                });

            })
            .catch((err) => {
                console.log(err);
            });
    }


    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }


    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                contacts: [],
                searchQuery: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchContacts, this.typingInterval)
            })
        }
    }


    get tableOptions() {
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,


            noDataText: this.getNoDataTableContent(),
            searchField: this.getSearchField,
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,

            page: this.state.page,
            sizePerPage: 10
        };
    }


    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "No contacts found"
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.searchQuery !== "") {
            this.setState({
                page: 1,
                loading: true,
                contacts: [],
                searchQuery: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchContacts, this.typingInterval)
            })
        }
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            page
        }, this.fetchContacts)
    }


    render() {

        return (
            <div className="animated">
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-people" color="info" header="PAYUCA Kontaktpersonen" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Link to="/contacts/addContact" className="noDecoLink">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Kontakt hinzufügen"
                            invert
                            style={{cursor: "pointer"}}
                        />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <BootstrapTable data={this.state.contacts} version="4" remote fetchInfo={{dataTotalSize: this.state.totalSize}} striped hover pagination search options={this.tableOptions}>
                                    <TableHeaderColumn width="150" dataField="first_name"  dataFormat={Contacts.ToContact}>Vorname</TableHeaderColumn>
                                    <TableHeaderColumn width="150" dataField="last_name"  dataFormat={Contacts.ToContact}>Nachname</TableHeaderColumn>
                                    <TableHeaderColumn width="150" isKey={true} dataField="email"  dataFormat={Contacts.ToContact}>E-Mail-Adresse</TableHeaderColumn>
                                    <TableHeaderColumn width="150" dataField="position"  dataFormat={Contacts.ToContact}>Position</TableHeaderColumn>
                                    <TableHeaderColumn width="150" dataField="company"  dataFormat={Contacts.ToCompany}>Firma</TableHeaderColumn>
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Contacts;
