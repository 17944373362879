import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader,
    Row
} from 'reactstrap';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import Widget from "../../components/Widget/Widget";
import contactService from "../../services/contactService";
import {Redirect} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import companyService from "../../services/companyService";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

class AddContact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            success: false,
            first_name: '',
            last_name: '',
            gender: '',
            pre_title: '',
            after_title: '',
            street: '',
            street_number: '',
            zip: '',
            city: '',
            country: '',
            occupation: '',
            position: '',
            phone_number: '',
            cell_number: '',
            fax_number: '',
            email: '',
            company: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleSuccess = this.toggleSuccess.bind(this);
        this.addContact = this.addContact.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.loadCompanies = this.loadCompanies.bind(this);
        this.onCompanyClick = this.onCompanyClick.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    saveChanges(company) {
        this.setState({
            company: company
        });
    }

    selectCountry = (country) => {
        this.setState({
            country: country.value
        });
    };

    countryList() {
        let options = [];
        let countryList = countries.getNames("de");

        Object.entries(countryList).forEach(([key, val]) => { options.push({value: key, label: val})});

        this.setState({
            options: options
        })
    }

    toggleSuccess() {
        this.setState({
            success: !this.state.success,
        });
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    onCompanyClick(company) {
        this.props.history.push("/companies/" + company.value);
    }

    loadCompanies(value) {
        return new Promise(resolve => {

            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                companyService.getCompanies(1, value)
                    .then(response => {
                        resolve({
                            options: response.data.results.map(company => {
                                return {value: company.id, label: company.name + " / " + (!company.iban ? "-" : company.iban) + " / " + (!company.invoice_email ? "-" : company.invoice_email)}
                            })
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }, this.typingInterval)
        })
    }

    addContact() {
        contactService.addContact({
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            gender: this.state.gender,
            pre_title: this.state.pre_title,
            after_title: this.state.after_title,
            email: this.state.email,
            phone_number: this.state.phone_number,
            cell_number: this.state.cell_number,
            fax_number: this.state.fax_number,
            street: this.state.street,
            street_number: this.state.street_number,
            zip: this.state.zip,
            company: this.state.company ? this.state.company.value : null,
            city: this.state.city,
            country: this.state.country,
            position: this.state.position,
            occupation: this.state.occupation,
        })
        .then((response) => {
            if (response.status) {
                this.setState({
                    success: !this.state.success
                })
            }
        })
        .catch((error) => {
            let errors = error.response.data.error;

            Object.entries(errors).map((error) => {
                return this.unsuccessful(error[0] + ": " + error[1][0]);
            })

        });

    }

    componentDidMount() {
        document.title = "PAYUCA | Kontaktperson hinzufügen";
        this.countryList()
    }


    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        if(this.state.success) {
            return <Redirect to="/contacts"/>
        }

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-user" color="info" header="Kontaktperson hinzufügen" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Speichern"
                            invert
                            style={{cursor: this.state.first_name.length < 2 || this.state.last_name.length < 2 ? "not-allowed" : "pointer"}}
                            onClick={this.state.first_name.length < 2 || this.state.last_name.length < 2 ? null : this.addContact}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Kontaktperson Daten
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                        <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Geschlecht</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" name="gender" id="gender" value={this.state.gender} onChange={this.handleChange}>
                                                        <option value=''>Geschlecht wählen</option>
                                                        <option value='M'>Männlich</option>
                                                        <option value='F'>Weiblich</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="pre_title">Vortitel</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="pre_title" name="pre_title" value={this.state.pre_title} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="first_name">Vorname *</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="first_name" name="first_name" value={this.state.first_name} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="last_name">Nachname *</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="last_name" name="last_name" value={this.state.last_name} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="after_title">Nachtitel</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="after_title" name="after_title" value={this.state.after_title} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="company">Firma</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <AsyncSelect
                                                        value={this.state.company ? this.state.company : null}
                                                        onChange={this.saveChanges}
                                                        loadOptions={this.loadCompanies}
                                                        onValueClick={this.onCompanyClick}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="position">Position</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="position" name="position" value={this.state.position} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="occupation">Beruf</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="occupation" name="occupation" value={this.state.occupation} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="6">
                                        <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email-input">E-Mail-Adresse</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="email" id="email" name="email" value={this.state.email} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="phone_number">Telefonnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="phone_number" name="phone_number" value={this.state.phone_number}  onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="cell_number">Handynummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="cell_number" name="cell_number" value={this.state.cell_number} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="fax_number">Fax</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="fax_number" name="fax_number" value={this.state.fax_number} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="street">Straße</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="street" name="street" value={this.state.street} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="street_number">Hausnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="street_number" name="street_number" value={this.state.street_number} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="number-input">Postleitzahl</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="zip" name="zip" value={this.state.zip} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="text-input">Stadt</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="city" name="city" value={this.state.city} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="country">Land</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={this.state.country}
                                                        onChange={this.selectCountry}
                                                        options={this.state.options}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Success modal */}
                <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
                       className={'modal-success ' + this.props.className}>
                    <ModalHeader toggle={this.toggleSuccess}>Kontaktperson hinzugefügt</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddContact;
