import urls from '../utils/urls';
import axios from "axios";

import errorHandlerService from './errorHandlerService';


const garageOwnerService = {

    getOwners:(page=null, search="") => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/contacts/garageowners/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en'
                    },
                    params: {
                        page,
                        search: search || null
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        garageOwnerService.getOwners, page, search
                    )
                }
            });
        });
    },

    getOwner: (id) => {

        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/contacts/garageowners/${id}/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en'
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        garageOwnerService.getOwner, id
                    )
                }
            });
        });

    },

    updateOwner: (owner) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/contacts/garageowners/${owner.id}/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(owner)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        garageOwnerService.updateOwner, owner
                    )
                }
            });
        });
    },

    addOwner: (owner) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${urls.apigateway}/contacts/garageowners/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(owner)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        garageOwnerService.addOwner, owner
                    )
                }
            });
        });
    },
};

export default garageOwnerService;
