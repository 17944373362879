import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    Col, FormGroup,
    Input, Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import 'react-select/dist/react-select.min.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Access from "../../components/SupportWidget/Access/Access";
import Widget from "../../components/Widget/Widget";

import ParkingSpaceList from "./GarageComponents/ParkingSpaceList";
import InventoryItemList from "./GarageComponents/InventoryItemList";
import GarageGallery from './GarageComponents/GarageGallery';
import GarageContract from './GarageComponents/GarageContract';
import GarageBasicData from './GarageComponents/GarageBasicData';
import GarageTechnicalData from "./GarageComponents/GarageTechnicalData";
import GarageNote from "./GarageComponents/GarageNote";
import GarageGateways from "./GarageComponents/GarageGateways";
import GarageContacts from "./GarageComponents/GarageContacts";
import GarageCurrentParkers from "./GarageComponents/GarageCurrentParkers";
import TransactionsTable from "../../components/TransactionsTable";
import GaragePCGateways from "./GarageComponents/GaragePCGateways";

import garageService from "../../services/garageService";

import getGarageById from '../../queries/getGarageById';
import updateGarageMutation from '../../mutations/updateGarage';
import updateGarageState from '../../mutations/updateGarageState';
import Logs from "../../components/Logs";
import {history} from "../../App";
import createParkingSpace from "../../mutations/createParkingSpace";
import updateParkingSpaceMutation from "../../mutations/updateParkingSpace";
import deleteParkingSpaceMutation from "../../mutations/deleteParkingSpace";
import deleteInventoryEntryMutation from "../../mutations/deleteInventoryEntry";
import createInventoryEntryMutation from "../../mutations/createInventoryEntry";
import updateInventoryEntryMutation from "../../mutations/updateInventoryEntry";
import GarageReport from "./GarageComponents/GarageReport";
import CheckOutData from "./GarageComponents/CheckOutData";
import moment from "moment";


class Garage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            access: false,
            stateChangeModalVisible: false,
            parkingsit: false,
            inventoryToggle: false,
            contractOpen: false,

            inventory: [],
            spaces: [],

            garageDataLoading: true,
            addSpaceDisabled: false,
            addItemDisabled: false,

            garageBasicData: true,
            garageTechnicalData: true,
            garageContacts: true,
            garageNote: true,
            disableCheckOutData: true,
            newGateways: false,
            newPCGateways: false,
            oldOwner: null,

            typeOfProblem: '',
            date: '',
            time: ''
        };


        this.toggleAccess = this.toggleAccess.bind(this);

        this.toggleParkingSit = this.toggleParkingSit.bind(this);
        this.toggleInventory = this.toggleInventory.bind(this);
        this.toggleContract = this.toggleContract.bind(this);
        this.toggleStateChangeLock = this.toggleStateChangeLock.bind(this);

        this.toggleGarageBasicData = this.toggleGarageBasicData.bind(this);
        this.toggleTechnicalData = this.toggleTechnicalData.bind(this);
        this.toggleContactData = this.toggleContactData.bind(this);
        this.toggleNote = this.toggleNote.bind(this);
        this.toggleCheckOutData = this.toggleCheckOutData.bind(this);

        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);

        this.stateChange = this.stateChange.bind(this);
        this.updateGarage = this.updateGarage.bind(this);
        this.updateGarageState = this.updateGarageState.bind(this);
        this.confirmGarageStateChange = this.confirmGarageStateChange.bind(this);

        this.addSpace = this.addSpace.bind(this);
        this.createParkingSpace = this.createParkingSpace.bind(this);
        this.updateParkingSpace = this.updateParkingSpace.bind(this);
        this.deleteParkingSpace = this.deleteParkingSpace.bind(this);

        this.addItem = this.addItem.bind(this);
        this.createInventoryItem = this.createInventoryItem.bind(this);
        this.updateInventoryItem = this.updateInventoryItem.bind(this);
        this.deleteInventoryEntry = this.deleteInventoryEntry.bind(this);

        this.findInactiveSpaces = this.findInactiveSpaces.bind(this);
        this.getGarageByID = this.getGarageByID.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    stateChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            garage: {
                ...this.state.garage,
                [name]: value
            }
        });

        this.toggleStateChangeLock();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        })
    }

    confirmGarageStateChange() {
        let maintenance_estimated_end = (this.state.date && this.state.time) ? this.combineDateAndTime(this.state.date, this.state.time) : null;
        const data = {
            id: this.state.garage.id,
            state: this.state.garage.state,
            maintenance_estimated_end,
            maintenance_reason: this.state.typeOfProblem || null
        }

        this.updateGarageState(data)
            .then(r => {
                if (this.state.garage.state === 'MAINTENANCE') {
                    this.setState({
                        typeOfProblem: r.data.updateGarage.garage.maintenance_reason || '',
                        date: moment(r.data.updateGarage.garage.maintenance_estimated_end).format('YYYY-MM-DD') || '',
                        time: moment(r.data.updateGarage.garage.maintenance_estimated_end).format('HH:mm') || '',
                    });
                }
                if (this.state.stateChangeModalVisible) {
                    this.toggleStateChangeLock()
                }
            })
    }

    toggleStateChangeLock() {
        if (this.state.stateChangeModalVisible) {
            this.setState({
                stateChangeModalVisible: !this.state.stateChangeModalVisible,
            }, () => {
                this.getGarageByID({id: this.state.garage.id})
            });
        } else {
            this.setState({
                stateChangeModalVisible: !this.state.stateChangeModalVisible,
            });
        }
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg = 'Erfolglos!') {
        return toast.error(msg);
    }

    toggleAccess() {
        this.setState({
            access: !this.state.access,
        });
    }

    toggleParkingSit() {
        this.setState({
            parkingsit: !this.state.parkingsit,
            inventoryToggle: false,
            contractOpen: false
        })
    }

    toggleInventory() {
        this.setState({
            inventoryToggle: !this.state.inventoryToggle,
            parkingsit: false,
            contractOpen: false
        })
    }

    toggleContract() {
        this.setState({
            contractOpen: !this.state.contractOpen,
            parkingsit: false,
            inventoryToggle: false
        })
    }

    toggleGarageBasicData() {
        this.setState({
            garageBasicData: !this.state.garageBasicData,
            disableCheckOutData: true,
            garageTechnicalData: true,
            garageContacts: true,
            garageNote: true,
        });
    }

    toggleCheckOutData() {
        this.setState({
            disableCheckOutData: !this.state.disableCheckOutData,
            garageBasicData: true,
            garageTechnicalData: true,
            garageContacts: true,
            garageNote: true,
        });
    }

    toggleTechnicalData() {
        this.setState({
            garageTechnicalData: !this.state.garageTechnicalData,
            disableCheckOutData: true,
            garageBasicData: true,
            garageContacts: true,
            garageNote: true,
        });
    }

    toggleContactData() {
        this.setState({
            garageContacts: !this.state.garageContacts,
            disableCheckOutData: true,
            garageTechnicalData: true,
            garageBasicData: true,
            garageNote: true,
        });
    }

    toggleNote() {
        this.setState({
            garageNote: !this.state.garageNote,
            disableCheckOutData: true,
            garageTechnicalData: true,
            garageBasicData: true,
            garageContacts: true,
        });
    }

    updateGarageState(data) {
        return new Promise((resolve, reject) => {
            garageService.createService(updateGarageState(data))
                .then((response) => {
                    resolve(response);
                    this.setState({
                        garage: {
                            ...this.state.garage,
                            state: response.data.updateGarage.garage.state,
                        },
                    }, () => {
                        this.success();
                    });
                })
                .catch((err) => {
                    reject();
                    let errors = err.networkError.result.errors;
                    this.unsuccessful(errors.map((error) => error.message).join(" "));
                });
        })
    }

    combineDateAndTime = function (date, time) {
        return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').toISOString();
    };

    updateGarage(data) {
        return new Promise((resolve, reject) => {
            garageService.createService(updateGarageMutation(data))
                .then((response) => {
                    resolve();
                    this.setState({
                        garage: {
                            ...this.state.garage,
                            ...response.data.updateGarage.garage,
                        },
                    }, () => {
                        this.success();
                    });
                })
                .catch((err) => {
                    reject();
                    let errors = err.networkError.result.errors;
                    this.unsuccessful(errors.map((error) => error.message).join(" "));
                });
        })
    }


    componentDidMount() {
        const {match: {params}} = this.props;
        this.getGarageByID(params);
    }

    getGarageByID(params) {
        garageService.getService(getGarageById(params))
            .then((garage) => {
                garage = garage.data.getGarage.garage;
                this.setState({
                    garage: garage,
                    nameHeader: garage.name,
                    typeOfProblem: garage.maintenance_reason || '',
                    date: moment(garage.maintenance_estimated_end).format('YYYY-MM-DD') || '',
                    time: moment(garage.maintenance_estimated_end).format('HH:mm') || '',
                    contract: garage.garageContract,
                    spaces: garage.parkingSpaces,
                    inventory: garage.inventoryEntries,
                    newGateways: (garage.garage_gateways && garage.garage_gateways.length),
                    newPCGateways: (garage.payuca_connect_gateways && garage.payuca_connect_gateways.length),
                    garageDataLoading: false,
                }, () => {
                    this.findInactiveSpaces();
                    document.title = "PAYUCA Garage | " + this.state.garage.name;

                })
            })
            .catch((err) => {
                if (err.networkError.statusCode === 404) {
                    history.push('/404')
                }
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    renderActiveTab() {
        return (
            <React.Fragment>
                {this.state.parkingsit ?
                    <ParkingSpaceList
                        spaces={this.state.spaces}
                        totalSpaces={this.state.garage.total_spaces}
                        takenSpaces={this.state.garage.taken_spaces}
                        inactiveSpaces={this.state.inactiveSpaces}
                        garageId={this.state.garage.id}
                        addSpaceDisabled={this.state.addSpaceDisabled}
                        createParkingSpace={this.createParkingSpace}
                        updateParkingSpace={this.updateParkingSpace}
                        deleteParkingSpace={this.deleteParkingSpace}
                        addSpace={this.addSpace}
                    /> : ""
                }
                {this.state.inventoryToggle ?
                    <InventoryItemList
                        items={this.state.inventory}
                        addItemDisabled={this.state.addItemDisabled}
                        addItem={this.addItem}
                        createInventoryItem={this.createInventoryItem}
                        updateInventoryItem={this.updateInventoryItem}
                        deleteInventoryEntry={this.deleteInventoryEntry}
                        garageId={this.state.garage.id}
                    /> : ""
                }
                {this.state.contractOpen ?
                    <GarageContract
                        contract={this.state.contract}
                        garageId={this.state.garage.id}
                        garageName={this.state.garage.name}
                        success={this.success}
                        unsuccessful={this.unsuccessful}
                        revenue_share={this.state.garage.revenue_share}
                        revenue_share_valid_from={this.state.garage.revenue_share_valid_from}
                    /> : ""
                }
            </React.Fragment>
        )
    }

    renderStateChangeModal() {
        return (
            <Modal isOpen={this.state.stateChangeModalVisible} toggle={this.toggleStateChangeLock}
                   className={'modal-warning ' + this.props.className}>
                <ModalHeader toggle={this.toggleStateChangeLock}>Garagenzustand ändern</ModalHeader>
                <ModalBody>
                    <p>Bist du sicher, dass du den Zustand der Garage ändern möchtest?</p>
                    {
                        this.state.garage && this.state.garage.state === 'MAINTENANCE' ?
                            <>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="typeOfProblem">Art des Problems*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="select" id="typeOfProblem" value={this.state.typeOfProblem}
                                               onChange={this.handleChange} name="typeOfProblem" required>
                                            <option value="">-</option>
                                            <option value="CLEANING">Reinigung</option>
                                            <option value="GATEWAY">Türen und Tore</option>
                                            <option value="ELECTRICITY">Elektrik</option>
                                            <option value="HARDWARE">Hardware</option>
                                            <option value="SOFTWARE">Software</option>
                                            <option value="MAINTENANCE">Wartungsarbeiten</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="date">Datum*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input
                                            required
                                            type="date"
                                            id="date"
                                            name="date"
                                            className="form-control"
                                            value={this.state.date}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="time">Time*</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input
                                            required
                                            type="time"
                                            id="time"
                                            name="time"
                                            className="form-control"
                                            value={this.state.time}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </> : null
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onClick={this.confirmGarageStateChange}>Zustand ändern</Button>{' '}
                    <Button color="secondary" onClick={this.toggleStateChangeLock}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        )
    }

    // Parking Space Methods

    addSpace() {
        this.setState({
            spaces: [...this.state.spaces || [], {
                id: '',
                level: 0,
                number: '',
                active: false,
                state: '',
                type: '',
                height: '',
                length: '',
                width: '',
                stack_parking: ''
            }]
        }, () => {
            const last = this.state.spaces[this.state.spaces.length - 1];
            if (last.id === '') {
                this.setState({
                    addSpaceDisabled: true,
                });
            }
        });
    }

    findInactiveSpaces() {
        let spaces = this.state.spaces;

        this.setState({
            inactiveSpaces: spaces.filter(space => !space.active).length
        })
    }

    createParkingSpace(_data) {

        let data = {..._data, id: this.state.garage.id};

        garageService.createService(createParkingSpace(data))
            .then((response) => {
                let spaces = this.state.spaces;
                spaces.pop();

                let parkingSpace = response.data.createParkingSpace.parkingSpace;

                for (let property in parkingSpace) {

                    if (parkingSpace[property] === null || parkingSpace[property] === "null") {
                        parkingSpace[property] = '';
                    }
                }

                spaces.push(response.data.createParkingSpace.parkingSpace);

                this.setState({
                    spaces: spaces,
                    garage: {
                        ...this.state.garage,
                        total_spaces: this.state.garage.total_spaces + 1
                    },
                    addSpaceDisabled: false,
                }, () => {
                    this.success();
                });
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            })
    }

    updateParkingSpace(_data) {

        let data = {..._data, garage_id: this.state.garage.id};
        garageService.createService(updateParkingSpaceMutation(data))
            .then((response) => {
                let spaces = this.state.spaces;

                let newSpaces = spaces.map((space) => {

                    if (space.id === data.id) {
                        let parkingSpace = response.data.updateParkingSpace.parkingSpace;

                        for (let property in parkingSpace) {
                            if (!parkingSpace[property]) {
                                parkingSpace[property] = '';
                            }
                        }
                        return parkingSpace
                    } else {
                        return space;
                    }
                });

                this.setState({
                    spaces: newSpaces
                }, () => {
                    this.success();
                });

            })
            .catch((err) => {
                console.log(JSON.stringify(err));
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    deleteParkingSpace(data) {

        garageService.createService(deleteParkingSpaceMutation(data))
            .then((response) => {

                let parkingSpace = response.data.deleteParkingSpace.parkingSpace;
                let spaces = this.state.spaces;


                let newSpaces = spaces.filter((item) => {
                    if (item.id !== parkingSpace.id) {
                        return item !== parkingSpace.id
                    }
                });

                this.setState({
                    spaces: newSpaces,
                    garage: {
                        ...this.state.garage,
                        total_spaces: this.state.garage.total_spaces - 1
                    },
                }, () => {
                    this.success();
                });

            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }


    // Inventory Methods

    addItem() {
        this.setState({
            inventory: [...this.state.inventory || [], {
                id: '',
                name: '',
                count: '',
                description: ''
            }]
        }, () => {
            const last = this.state.inventory[this.state.inventory.length - 1];
            if (last.id === '') {
                this.setState({
                    addItemDisabled: true,
                })
            }
        });
    }

    createInventoryItem(_data) {

        let data = {..._data, garage_id: this.state.garage.id};


        garageService.createService(createInventoryEntryMutation(data))
            .then((response) => {
                let inventory = this.state.inventory;
                inventory.pop();

                let inventoryEntry = response.data.createInventoryEntry.inventoryEntry;

                for (let property in inventoryEntry) {

                    if (inventoryEntry[property] === null || inventoryEntry[property] === "null") {
                        inventoryEntry[property] = '';
                    }
                }

                inventory.push(inventoryEntry);

                this.setState({
                    inventory: inventory,
                    addItemDisabled: false,
                }, () => {
                    this.success();
                });
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            })
    }

    deleteInventoryEntry(data) {
        garageService.createService(deleteInventoryEntryMutation(data))
            .then((response) => {
                let inventoryEntry = response.data.deleteInventoryEntry.inventoryEntry;
                let inventory = this.state.inventory;

                let newInventory = inventory.filter((item) => {
                    if (item.id !== inventoryEntry.id) {
                        return item !== inventoryEntry.id
                    }
                });

                this.setState({
                    inventory: newInventory
                }, () => {
                    this.success();
                });

            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    updateInventoryItem(_data) {
        let data = {..._data, garage_id: this.state.garage.id};
        garageService.createService(updateInventoryEntryMutation(data))
            .then((response) => {
                let inventory = this.state.inventory;

                let newInventory = inventory.map((item) => {

                    if (item.id === data.id) {
                        let inventoryEntry = response.data.updateInventoryEntry.inventoryEntry;

                        for (let property in inventoryEntry) {
                            if (!inventoryEntry[property]) {
                                inventoryEntry[property] = '';
                            }
                        }
                        return inventoryEntry
                    } else {
                        return item;
                    }
                });

                this.setState({
                    inventory: newInventory
                }, () => {
                    this.success();
                });

            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }


    render() {

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="10">
                        <Widget icon="icon-home" color="info" header={this.state.nameHeader}/>
                    </Col>
                    <GarageReport garage_id={!this.state.garage ? 0 : this.state.garage.id}/>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody className="pb-0">
                                <Row className="row-grid">
                                    <Col xs="12" sm="12" md="12" lg="3">
                                        <Button
                                            className={this.state.parkingsit ? "btn-dark btn-block btn-lg" : "btn-outline-dark btn-block btn-lg"}
                                            onClick={this.toggleParkingSit}>Stellplatzsituation</Button>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="3">
                                        <Button
                                            className={this.state.inventoryToggle ? "btn-dark btn-block btn-lg" : "btn-outline-dark btn-block btn-lg"}
                                            onClick={this.toggleInventory}>Inventar</Button>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="3">
                                        <Button
                                            className={this.state.contractOpen ? "btn-dark btn-block btn-lg" : "btn-outline-dark btn-block btn-lg"}
                                            onClick={this.toggleContract}>Vertragsdaten</Button>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="3">
                                        {
                                            this.state.garageDataLoading ?
                                                <div className="sk-rotating-plane"/>
                                                :
                                                <Input type="select" bsSize="lg" name="state" id="state"
                                                       value={this.state.garage.state} onChange={this.stateChange}>
                                                    <option value="LIVE">Live</option>
                                                    <option value="DRAFT">Entwurf</option>
                                                    <option value="MAINTENANCE">Wartung</option>
                                                    <option value="INACTIVE">Offline</option>
                                                    <option value="DECLINED">Demontage</option>
                                                    <option value="COMING_SOON">Kommt bald</option>
                                                </Input>
                                        }
                                    </Col>
                                </Row>
                                {
                                    !this.state.garageDataLoading && this.state.garage.state === 'MAINTENANCE' ?
                                        <>
                                            <Row>
                                                <Col>
                                                    <hr/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={3}>
                                                    <FormGroup>
                                                        <Label htmlFor="typeOfProblem">Art des Problems*</Label>
                                                        <Input type="select" id="typeOfProblem"
                                                               value={this.state.typeOfProblem}
                                                               onChange={this.handleChange} name="typeOfProblem"
                                                               required>
                                                            <option value="">-</option>
                                                            <option value="CLEANING">Reinigung</option>
                                                            <option value="GATEWAY">Türen und Tore</option>
                                                            <option value="ELECTRICITY">Elektrik</option>
                                                            <option value="HARDWARE">Hardware</option>
                                                            <option value="SOFTWARE">Software</option>
                                                            <option value="MAINTENANCE">Wartungsarbeiten</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>
                                                        <Label htmlFor="date">Datum*</Label>
                                                        <Input
                                                            required
                                                            type="date"
                                                            id="date"
                                                            name="date"
                                                            className="form-control"
                                                            value={this.state.date}
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>
                                                        <Label htmlFor="time">Time*</Label>
                                                        <Input
                                                            required
                                                            type="time"
                                                            id="time"
                                                            name="time"
                                                            className="form-control"
                                                            value={this.state.time}
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>
                                                        <Label>Aktion</Label>
                                                        <Button
                                                            onClick={this.confirmGarageStateChange}
                                                            block color={'success'}>Bestätigen</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </> : null
                                }
                                {
                                    this.state.parkingsit || this.state.inventoryToggle || this.state.contractOpen ? "" :
                                        <Row className="pb-1 pt-1">
                                            <Col xs="12" sm="12" md="12" lg="12">
                                                <small className="pull-right">Notwendige Felder für das State "Kommt
                                                    bald": <strong>vollständige Adresse, volle Position, min. ein
                                                        Garagenbild</strong></small> <br/>
                                                <small className="pull-right">Notwendige Felder für das State
                                                    "Live": <strong>alle Felder & min. ein Stellplatz</strong></small>
                                            </Col>
                                        </Row>
                                }
                                {this.renderActiveTab()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="6">
                        {
                            !this.state.garageDataLoading ?
                                <GarageBasicData
                                    toggleGarageBasicData={this.toggleGarageBasicData}
                                    garageBasicData={this.state.garageBasicData}
                                    loading={this.state.garageDataLoading}
                                    garage={this.state.garage}
                                    history={this.props.history}
                                    updateGarage={this.updateGarage}
                                />
                                :
                                "Loading"
                        }
                    </Col>
                    <Col xs="12" sm="12" md="6">
                        {
                            this.state.garageDataLoading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <GarageGallery photos={this.state.garage.garagePhotos}
                                               garageId={this.state.garage.id}
                                               success={this.success}
                                               unsuccessful={this.unsuccessful}
                                />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        {
                            this.state.garageDataLoading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <CheckOutData
                                    loading={this.state.garageDataLoading}
                                    disableCheckOutData={this.state.disableCheckOutData}
                                    toggleCheckOutData={this.toggleCheckOutData}
                                    garage={this.state.garage}
                                    history={this.props.history}
                                    updateGarage={this.updateGarage}

                                />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        {
                            this.state.garageDataLoading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <GarageCurrentParkers garage_id={this.state.garage.id}/>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        {(this.state.garageDataLoading) ?
                            <div className="sk-rotating-plane"/>
                            :
                            (
                                (() => {
                                    if (this.state.garage.gateway_type === 'PC') {
                                        return (
                                            <GaragePCGateways
                                                newPCGateways={this.state.newPCGateways}
                                                gateways={this.state.garage.payuca_connect_gateways}
                                                gatewayType={this.state.garage.gateway_type}
                                                garageId={this.state.garage.id}
                                                success={this.success}
                                                unsuccessful={this.unsuccessful}
                                                getGarageByID={this.getGarageByID}
                                            />
                                        );
                                    } else {
                                        return (
                                            <GarageGateways
                                                newGateways={this.state.newGateways}
                                                gateways={this.state.newGateways ? this.state.garage.garage_gateways : this.state.garage.garageGateways}
                                                garageId={this.state.garage.id}
                                                success={this.success}
                                                unsuccessful={this.unsuccessful}
                                            />
                                        );
                                    }
                                })()
                            )

                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        {
                            this.state.garageDataLoading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <TransactionsTable
                                    by="garage"
                                    garage_id={this.state.garage.id}
                                />
                        }
                    </Col>
                </Row>
                {this.state.date && this.state.time ? this.combineDateAndTime(this.state.date, this.state.time) : null}
                <Row>
                    <Col xs="12" sm="12" md="8" lg="8">
                        <GarageTechnicalData
                            garageTechnicalData={this.state.garageTechnicalData}
                            garage={this.state.garage}
                            loading={this.state.garageDataLoading}
                            updateGarage={this.updateGarage}
                            disabledTechnicalData={this.state.disabledTechnicalData}
                            toggleTechnicalData={this.toggleTechnicalData}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4">
                        <GarageContacts
                            garageContacts={this.state.garageContacts}
                            garage={this.state.garage}
                            loading={this.state.garageDataLoading}
                            history={this.props.history}
                            updateGarage={this.updateGarage}
                            toggleContactData={this.toggleContactData}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <GarageNote
                            garageNote={this.state.garageNote}
                            garage={this.state.garage}
                            updateGarage={this.updateGarage}
                            loading={this.state.garageDataLoading}
                            toggleNote={this.toggleNote}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        {
                            this.state.garageDataLoading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <Logs
                                    object_id={this.state.garage.id}
                                    object_content_type="GARAGE"
                                />
                        }
                    </Col>
                </Row>

                {/* Access user */}
                <Modal isOpen={this.state.access} toggle={this.toggleAccess}
                       className={'modal-success ' + this.props.className}>
                    <ModalHeader toggle={this.toggleAccess}>Zugang zur Garage</ModalHeader>
                    <ModalBody>
                        <Access/>
                    </ModalBody>
                </Modal>

                {this.renderStateChangeModal()}

            </div>
        );
    }
}

export default Garage;
