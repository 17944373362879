import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';
import Widget from "../../components/Widget/Widget";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'ladda/dist/ladda-themeless.min.css';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import {Redirect} from "react-router-dom";
import _uniqBy from 'lodash/uniqBy';

import contactService from "../../services/contactService";
import companyService from "../../services/companyService";
import {history} from "../../App";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))


class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            first_name: '',
            last_name: '',
            gender: '',
            pre_title: '',
            after_title: '',
            address: '',
            address_number: '',
            zip: '',
            city: '',
            country: '',
            occupation: '',
            position: '',
            phone_number: '',
            mobile_number: '',
            fax_number: '',
            email: '',
            note: '',
            company: null,
            loading: true,
            disabled: true,
            disabledNote: true,
            delete: false,
            loadingEdit: false,
            redirect: false
        };


        this.typingTimer = null;
        this.typingInterval = 600;

        this.handleChange = this.handleChange.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.toggleDisabled = this.toggleDisabled.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.toggleNote = this.toggleNote.bind(this);
        this.loadCompanies = this.loadCompanies.bind(this);
        this.onCompanyClick = this.onCompanyClick.bind(this);
    }


    handleChange(event) {
        if (!this.state.disabled || !this.state.disabled || !this.state.disabledNote) {
            const target = event.target;
            const value = target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        }
    }

    onCompanyChange(company) {
        this.setState({
            company: company
        });
    }

    selectCountry = (country) => {
        this.setState({
            country: country.value
        });
    };

    countryList() {
        let options = [];
        let countryList = countries.getNames("de");

        Object.entries(countryList).forEach(([key, val]) => { options.push({value: key, label: val})});

        this.setState({
            options: options
        })
    }

    onCompanyClick(company) {
        this.props.history.push("/companies/" + company.value);
    }

    loadCompanies(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                companyService.getCompanies(1, value)
                    .then(response => {
                        let options = response.data.results.map(company => {
                            return {value: company.id, label: company.name + " / " + (!company.iban ? "-" : company.iban) + " / " + (!company.invoice_email ? "-" : company.invoice_email)}
                        });
                        resolve({
                            options
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }, this.typingInterval)
        })
    }

    toggleDelete() {
        this.setState({
            delete: !this.state.delete,
        });
    }

    toggleDisabled() {
        this.setState({
            disabled: !this.state.disabled,
        });
    }

    toggleNote() {
        this.setState({
            disabledNote: !this.state.disabledNote,
        })
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    deleteContact() {
        contactService.deleteContact(this.state.id)
            .then((response) => {
                this.setState({
                    redirect: true,
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    updateContact() {
        this.setState({
            loadingEdit: true
        });

        contactService.updateContact({
            id: this.state.id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            gender: this.state.gender,
            pre_title: this.state.pre_title,
            after_title: this.state.after_title,
            email: this.state.email,
            phone_number: this.state.phone_number,
            cell_number: this.state.cell_number,
            fax_number: this.state.fax_number,
            address: this.state.address,
            address_number: this.state.address_number,
            zip: this.state.zip,
            city: this.state.city,
            country: this.state.country,
            position: this.state.position,
            occupation: this.state.occupation,
            note: this.state.note,
            company: this.state.company ? this.state.company.value : null
        })
            .then((response) => {
                if (response.status) {
                    let iban = !response.data.company.iban ? "-" : response.data.company.iban;
                    let invoice_email = !response.data.company.invoice_email ? "-" : response.data.company.invoice_email;
                    let _contact = {
                        ...response.data,
                        company: response.data.company ? {
                            value: response.data.company.id,
                            label: response.data.company.name + " / " + iban + " / " + invoice_email
                        } : null
                    };
                    this.setState({
                        ..._contact,
                        firstNameHeader: response.data.first_name,
                        lastNameHeader: response.data.last_name,
                        disabled: true,
                        disabledNote: true,
                        loadingEdit: false,
                    }, () => {
                        document.title = "Kontaktperson | " + this.state.first_name + " " + this.state.last_name;
                    });
                    this.success();
                } else {
                    this.setState({
                        disabledNote: !this.state.disabledNote ? false : true,
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    disabled: false,
                });

                let errors = err.response.data.error;

                Object.entries(errors).map((error) => {
                    return this.unsuccessful(error[0] + ": " + error[1][0]);
                })
            });
    }

    componentDidMount() {

        const { match: { params } } = this.props;

        contactService.getContact(params.id)
            .then((contact) => {
                let _contact = {
                    ...contact.data,
                    company: contact.data.company ? {
                        value: contact.data.company.id,
                        label: contact.data.company.name
                    } : null
                };
                this.setState({
                    ..._contact,
                    firstNameHeader: contact.data.first_name,
                    lastNameHeader: contact.data.last_name,
                    loading: false,
                });
                document.title = "Kontaktperson | " + this.state.first_name + " " + this.state.last_name;
            })
            .catch((err) => {
                console.log(err)
                if (err.response.status === 404) {
                    history.push('/404')
                }
            })

        this.countryList()
    }

    render() {

        if(this.state.redirect) {
            return <Redirect to="/contacts"/>
        }

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-user" color="info" header={this.state.firstNameHeader + " " + this.state.lastNameHeader}/>
                    </Col>
                    <Col xs="12" sm="12" md="2">
                        <Widget
                            icon="icon-trash"
                            color="danger"
                            header="Kontaktperson löschen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.toggleDelete}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Kontaktperson Daten
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateContact}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleDisabled}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                {!this.state.loadingEdit ?
                                    <Row>
                                        <Col xs="12" sm="12" md="6" lg="6">
                                            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="selectSm">Geschlecht</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="select" name="gender" id="gender" value={this.state.gender} onChange={this.handleChange} disabled={this.state.disabled}>
                                                            <option value="M">Männlich</option>
                                                            <option value="F">Weiblich</option>
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="disabled-input">Vortitel</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="pre_title" name="pre_title" value={!this.state.pre_title ? "k.A." : this.state.pre_title} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="disabled-input">Vorname</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="first_name" name="first_name" value={this.state.first_name} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="disabled-input">Nachname</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="last_name" name="last_name" value={this.state.last_name} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="disabled-input">Nachtitel</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="after_title" name="after_title" value={!this.state.after_title ? "k.A." : this.state.after_title} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="disabled-input">Firma</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <AsyncSelect
                                                            loadOptions={this.loadCompanies}
                                                            onChange={this.onCompanyChange}
                                                            value={this.state.company ? this.state.company : null}
                                                            onValueClick={this.onCompanyClick}
                                                            disabled={this.state.disabled}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="disabled-input">Position</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="position" name="position" value={!this.state.position ? "k.A." : this.state.position} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="disabled-input">Beruf</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="occupation" name="occupation" value={!this.state.occupation ? "k.A." : this.state.occupation} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6">
                                            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="email">E-Mail-Adresse</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="email" id="email" name="email" value={this.state.email} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="phone_number">Telefonnummer</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="phone_number" name="phone_number" value={this.state.phone_number}  onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="mobilePhone">Handynummer</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="cell_number" name="cell_number" value={this.state.cell_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="fax_number">Fax</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="fax_number" name="fax_number" value={this.state.fax_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="text-input">Straße</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="address" name="address" value={!this.state.address ? "k.A." : this.state.address} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="address_number">Hausnummer</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="address_number" name="address_number" value={!this.state.address_number ? "k.A." : this.state.address_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="zip">Postleitzahl</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="zip" name="zip" value={!this.state.zip ? "k.A." : this.state.zip} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="text-input">Stadt</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" id="city" name="city" value={!this.state.city ? "k.A." : this.state.city} onChange={this.handleChange} disabled={this.state.disabled} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="country">Land</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Select
                                                            value={this.state.country}
                                                            onChange={this.selectCountry}
                                                            options={this.state.options}
                                                            disabled={this.state.disabled}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                            :
                                    <div className="sk-rotating-plane"/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Kontaktnotiz
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabledNote
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateContact}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleNote}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleNote}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Input type="textarea" rows="7" name="note" id="note"
                                       value={!this.state.note ? '' : this.state.note}
                                       disabled={this.state.disabledNote} onChange={this.handleChange}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Modals */}

                {/* Delete user */}
                <Modal isOpen={this.state.delete} toggle={this.toggleDelete}
                       className={'modal-danger ' + this.props.className}>
                    <ModalHeader toggle={this.toggleDelete}>Kontaktperson löschen</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteContact}>Kontaktperson löschen</Button>{' '}
                        <Button color="secondary" onClick={this.toggleDelete}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default Contact;
