import gql from "graphql-tag";


export default function(params){
    return gql`
        mutation {
            returnToPool(
                gatewayID: "${params.gatewayID}"
                garageFrom: "${params.garageFrom}"
            ) {
                errors
                gateway {
                    id
                    type
                }
            }
        }`;
}
