import React, {Component} from 'react';


export default class CustomDateFilter extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentDate: new Date()
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }


    handleChange(event) {
        this.setState({
            currentDate: event.target.value
        }, this.handleFilter)
    }

    handleFilter() {
        this.props.filterHandler(this.props.field, this.state.currentDate);
    }


    render() {
        return (
            <div>
                <input
                    type="date"
                    className="form-control"
                    value={this.state.currentDate}
                    onChange={this.handleChange}
                />

            </div>
        )
    }
}
