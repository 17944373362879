import gql from "graphql-tag";


export default function (params) {

    let queryParamsString = Object.keys(params)
      .filter(key => {
        return params[key] && params[key] !== '""';
      })
      .map(key => {
          return key === 'gateway_id' ? `${key}:"${params[key]}"` : `${key}:${params[key]}`;
      })
      .join(",");

    let query = gql`
        mutation {
            adminAccess(
                ${queryParamsString}
            ) {
                errors
                transaction {
                    id
                }
            }
        }`;
        return query;
}
