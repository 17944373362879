import React, {Component} from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Widget from "../../components/Widget/Widget";
import SupportWidget from "../../components/SupportWidget/SupportWidget";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import { toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import 'ladda/dist/ladda-themeless.min.css';
import {Link} from "react-router-dom";
import moment from 'moment';

import UserPrivileges from "./UserComponents/UserPrivileges";
import UserBasicData from "./UserComponents/UserBasicData";
import UserContactData from "./UserComponents/UserContactData";
import UserNFC from "./UserComponents/UserNFC";
import TransactionsTable from "../../components/TransactionsTable";
import garageService from "../../services/garageService";
import getTransactionsQuery from "../../queries/getTransactions";
import userService from "../../services/userService";
import UserFinances from "./UserComponents/UserFinances";
import ActiveParking from "./UserComponents/ActiveParking";
import ActiveReservation from "./UserComponents/ActiveReservation";
import UserCredits from "./UserComponents/UserCredits";
import {history} from "../../App";
import datetimeFormatter from "../../utils/datetimeFormatter";
import paymentService from "../../services/paymentsService";
import striptags from "striptags";
import UserStats from "./UserComponents/UserStats";


class User extends Component {

    static ToCoupon(cell, row) {
        let couponID = row.couponID;
        return <Link to={`/coupons/${couponID}`}>{cell}</Link>;
    }

    static Active(cell) {
        return !cell ? "Nein" : "Ja"
    }

    static Redeemed(cell) {
        return !cell ? "Noch nicht eingelöst" : datetimeFormatter.forDisplay(cell)
    }

    constructor(props) {
        super(props);

        this.state = {
            user: { },

            headerName: '',

            nfcModalVisible: false,
            supportVisible: false,
            lockModalVisible: false,
            deleteModalVisible: false,

            loading: true,
            couponLoading: true,

            reasonLock: '',
            reasonDelete: '',

            userVouchers: []
        };

        this.optionsCoupons = {
            sortIndicator: true,
            hideSizePerPage: true,
            paginationSize: 2,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,
            sizePerPage: 5,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSupportVisibility = this.handleSupportVisibility.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.toggleLock = this.toggleLock.bind(this);
        this.toggleNfc = this.toggleNfc.bind(this);
        this.lock = this.lock.bind(this);
        this.unlock = this.unlock.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.getUser = this.getUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.uploadCredit = this.uploadCredit.bind(this);
        this.mapErrors = this.mapErrors.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        })
    }

    handleSupportVisibility() {
        this.setState({
            supportVisible: !this.state.supportVisible
        })
    }

    lock() {
        let data = {
            is_active: false,
            inactive_reason: this.state.reasonLock,
        }
        userService.lockUser(data, this.state.user.id)
            .then((response) => {
                this.success();
                this.setState({user: response.data, lock: !this.state.lock, lockModalVisible: false})
            })
            .catch((err) => {
                this.unsuccessful(err);
            });
    }

    unlock() {
        let data = {
            is_active: true,
            inactive_reason: '',
        }
        userService.unlockUser(data, this.state.user.id)
            .then((response) => {
                this.success();
                this.setState({
                    user: response.data,
                    reasonLock: '',
                    lockModalVisible: false
                });
            })
            .catch((err) => {
                this.unsuccessful(err);
            });
    }


    toggleDelete() {

        if(this.state.currentParking){
            this.unsuccessful('Benutzer hat aktives Parken!');
            return;
        }

        this.setState({
            deleteModalVisible: !this.state.deleteModalVisible,
        });
    }

    toggleLock() {
        this.setState({
            lockModalVisible: !this.state.lockModalVisible,
        });
    }

    toggleNfc() {
        this.setState({
            nfcModalVisible: !this.state.nfcModalVisible
        });
    }


    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos') {
        return toast.error(msg);
    }

    deleteUser(){
        return new Promise((resolve, reject) => {
          userService
            .deleteUser({
              email: this.state.user.email,
              reason: this.state.reasonDelete
            })
            .then(response => {
              if (response.status === 204) {
                this.setState({
                  editingContactDisabled: true
                });
                this.getUser();
                this.success();
                this.toggleDelete();
              } else {
                this.unsuccessful();
              }
            })
            .catch(err => {
              reject();
              this.unsuccessful();
            });
        });
    }

    updateUser(user) {
        return new Promise((resolve, reject) => {

            userService.updateUser({
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                gender: user.gender,
                pre_title: user.pre_title,
                after_title: user.after_title,
                email: user.email,
                phone_number: user.phone_number,
                address: user.address,
                address_number: user.address_number,
                is_internal: user.is_internal,
                zip: user.zip,
                city: user.city,
                note: user.note,
                internal: user.internal,
                is_longterm_parker: user.is_longterm_parker,
            })
                .then((response) => {
                    resolve();
                    if (response.status === 200) {
                        this.setState({
                            user: response.data,
                            headerName: response.data.first_name + " " + response.data.last_name,
                            editingContactDisabled: true,
                        });
                        this.success();
                    } else {
                        this.setState({
                            editingContactDisabled: !this.state.editingContactDisabled ? false : true,
                        });
                        this.unsuccessful();
                    }
                })
                .catch((err) => {
                    reject();
                    this.setState({
                        errors: Object.entries(err.response.data.error)
                    }, () => {
                        this.mapErrors();
                    });
                });
        })
    }

    mapErrors() {
        String.prototype.capitalize = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        };

        return (
            this.state.errors.map((error) => {
                if (error[0] === 'non_field_errors') {
                    this.unsuccessful(error[1][0].capitalize() + ".")
                } else {
                    this.unsuccessful(error[0].capitalize() + ": " + error[1])
                }
            })
        )
    }

    componentDidMount() {

        const { match: { params } } = this.props;

        this.getUser();

        userService.getUserVoucher(params.id)
            .then((response) => {
                this.setState({
                    couponLoading: false,
                    userVouchers: response.data.results.map((voucher) => {
                        return {
                            couponID: voucher.id,
                            code: voucher.code,
                            active: voucher.active,
                            value: voucher.value,
                            redeemed_at: voucher.redeemed_at
                        }
                    })
                })
            })
            .catch((err) => {
                this.unsuccessful("Es konnte keine Gutscheine laden.");
            });

            garageService.getService(getTransactionsQuery({
                user_id: params.id,
                completed: false,
                type: "PARKING"
            }))
                .then((response) => {

                    let transactions = response.data.getTransactions.transactions;
                    let currentParking = transactions[0];

                    this.setState({
                        currentParking: currentParking || null,
                        loadingCurrentParking: false
                    })
                })
                .catch((err) => {
                    this.unsuccessful(err);
                });
    }

    getUser(){
        const { match: { params } } = this.props;

        userService.getUser(params.id)
            .then((user) => {
                this.setState({
                    user: user.data,
                    headerName: user.data.first_name + " " + user.data.last_name,
                    loading: false,
                }, () => {
                    document.title = "Kunde | " + this.state.headerName;
                });
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    history.push('/404')
                }
            });
    }

    uploadCredit(data) {
        paymentService.updateCredits(data)
            .then(resp => {
                this.setState({
                    user: {
                        ...this.state.user,
                        bonusCredits: resp.data.payuca_bonus_credit,
                        realCredits: resp.data.payuca_credit
                    }
                });
                this.success();
            })
            .catch((err) => {
                let error = err.response.data.description;
                this.unsuccessful(striptags(error).replace(/[^\w\s]/gi, '')+".");
            });
    }


    renderDeleteUserModal() {
        return (
            <Modal data-testid="delete-modal" isOpen={this.state.deleteModalVisible} toggle={this.toggleDelete}
                   className={'modal-danger ' + this.props.className}>
                <ModalHeader toggle={this.toggleDelete}>Kunde löschen</ModalHeader>
                <ModalBody>
                    <Input
                        data-testid="delete-reason"
                        type="text"
                        id="reasonDelete"
                        name="reasonDelete"
                        value={this.state.reasonDelete}
                        onChange={this.handleChange}
                        className="mt-4"
                        placeholder="Begrundung..."
                    />
                </ModalBody>
                <ModalFooter>
                    <Button data-testid="confirm-delete" color="danger" onClick={this.deleteUser}>Kunde löschen</Button>{' '}
                    <Button color="secondary" onClick={this.toggleDelete}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderLockUserModal() {
        return (
            <Modal isOpen={this.state.lockModalVisible} toggle={this.toggleLock}
                   className={'modal-warning ' + this.props.className}>
                <ModalHeader toggle={this.toggleLock}>{this.state.user.is_active === false ? 'Kunde entsperren' : 'Kunde sperren'}</ModalHeader>
                <ModalBody>
                    {
                        this.state.user.is_active === true &&
                        <Input
                            type="text"
                            id="reasonLock"
                            name="reasonLock"
                            value={this.state.reasonLock}
                            onChange={this.handleChange}
                            className="mt-4"
                            placeholder="Begrundung..."
                        />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onClick={this.state.user.is_active === false ? this.unlock : this.lock}>{this.state.user.is_active === false ? 'Kunde entsperren' : 'Kunde sperren'}</Button>{' '}
                    <Button color="secondary" onClick={this.toggleLock}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        return (
            <div className="animated fadeIn">
                <UserNFC
                    userId={this.state.user.id}
                    visible={this.state.nfcModalVisible}
                    toggleNfc={this.toggleNfc}
                    success={this.success}
                    unsuccessful={this.unsuccessful}
                />
                {this.renderDeleteUserModal()}
                {this.renderLockUserModal()}

                {this.state.user.is_active ? "" :
                    <Row>
                        <Col xs="12" sm="12" lg="12">
                            <Card>
                                <CardHeader>
                                    Benutzer gelöscht!
                                </CardHeader>
                                <CardBody>
                                    <h3>Benutzer {this.state.user.first_name + " " + this.state.user.last_name} ist
                                        wegen dem folgenden Grund gelöscht/gesperrt:</h3>
                                    <p className="mb-3">{this.state.user.inactive_reason}</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs="12" sm="6" lg="4">
                        <Widget icon="icon-people" color="info" header={this.state.headerName}/>
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="2">
                        <Widget
                            icon="icon-support"
                            color="success"
                            header="Support"
                            invert
                            style={{cursor: this.state.user.inactive_reason ? "not-allowed" : "pointer"}}
                            onClick={this.state.user.inactive_reason ? null : this.handleSupportVisibility}
                        />
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="2">
                        <Widget
                            icon="icon-drop"
                            color="info"
                            header="NFC Chip"
                            style={{cursor: this.state.user.inactive_reason ? "not-allowed" : "pointer"}}
                            onClick={this.state.user.inactive_reason ? null : this.toggleNfc}
                            invert
                        />
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="2">
                        <Widget
                            icon="icon-lock"
                            color="warning"
                            header={this.state.user.is_active === false ? 'Kunde entsperren' : 'Kunde sperren'}
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.toggleLock}
                        />
                    </Col>
                    <Col xs="6" sm="6" md="6" lg="2">
                        <Widget
                            icon="icon-trash"
                            color="danger"
                            header="Kunde löschen"
                            invert
                            style={{cursor: this.state.user.inactive_reason ? "not-allowed" : "pointer"}}
                            onClick={this.state.user.inactive_reason ? null : this.toggleDelete}
                        />
                    </Col>
                </Row>

                {this.state.supportVisible
                && <SupportWidget
                    user={this.state.user}
                    success={this.success}
                    unsuccessful={this.unsuccessful}
                    uploadCredit={this.uploadCredit}
                />
                }

                <Row>
                    <Col xs="12" sm="12" md="6">
                        <UserBasicData
                            user={this.state.user}
                            updateUser={this.updateUser}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="6">
                        <UserContactData
                            user={this.state.user}
                            updateUser={this.updateUser}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="6">
                        <Row>
                            <Col xs="12" sm="12" lg="6">
                                <Card>
                                    <CardHeader>
                                        Kunde seit
                                        <div className="card-header-actions" />
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup row className="mb-0">
                                            <Col xs="12" md="12">
                                                <Input type="text" id="created_at" name="created_at" value={new Date(this.state.user.created_at).toLocaleDateString('de-DE')} disabled />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" sm="12" lg="6">
                                <ActiveParking user_id={this.state.user.id}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" lg="6">
                                <ActiveReservation user_id={this.state.user.id} />
                            </Col>
                            <Col xs="12" sm="12" lg="6">
                                <UserCredits user={this.state.user} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" sm="12" lg="6">
                        <Card>
                            <CardHeader>
                                Benutzersgutscheine
                            </CardHeader>
                            <CardBody style={{marginBottom: -20}}>
                                {
                                    this.state.couponLoading ?
                                        <div className="sk-rotating-plane"/>
                                        :
                                        <BootstrapTable data={this.state.userVouchers} version="4" condensed striped hover pagination options={this.optionsCoupons}>
                                            <TableHeaderColumn width='150' isKey={true} dataField="code" dataFormat={User.ToCoupon}>Code</TableHeaderColumn>
                                            <TableHeaderColumn width='90' dataField="value" dataFormat={User.ToCoupon}>Wert</TableHeaderColumn>
                                            <TableHeaderColumn width='90' dataField="active" dataFormat={User.Active}>Aktiv</TableHeaderColumn>
                                            <TableHeaderColumn width='200' dataField="redeemed_at" dataFormat={User.Redeemed}>Eingelöst</TableHeaderColumn>
                                        </BootstrapTable>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        {this.state.loading ? (
                            <div className="sk-rotating-plane"/>
                        ) : (
                            <UserPrivileges data={[]} user={this.state.user}/>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        {
                            this.state.loading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <TransactionsTable
                                    user_id={this.state.user.id}
                                    by="user"
                                />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        {
                            this.state.loading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <UserFinances user_id={this.state.user.id} unsuccessful={this.unsuccessful}/>
                        }
                    </Col>
                </Row>
                <UserStats user={this.state.user.id}/>
            </div>
        );
    }
}

export default User;
