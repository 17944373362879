import gql from "graphql-tag";


export default function(params){
    return gql`
             mutation {
                deleteGaragePhoto(
                    id: "${params.id}"
                ) {
                    errors
                    garagePhoto {
                        id
                    }
                }
            }`;
}