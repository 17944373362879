import React, {useEffect, useState} from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';

import 'spinkit/css/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select/dist/react-select.min.css';

import DateTimePicker from "react-datetime-picker";
import datetimeFormatter from "../../../utils/datetimeFormatter";
import userCreditsFormat from "../../../utils/creditsFormatter";
import percentageCalculator from "../../../utils/percentageCalculator";
import Select from "react-select";

const EndParking = ({ user, gateways, currentParking, privileges, endParking, loading, loadingEnd }) => {
    const [state, setState] = useState({
        timestamp: new Date(),
        openDoor: false,
        grantFreeParking: false,
        collapse: false,
        maxDate: new Date(),
        selectedGateway: "",
    });

    useEffect(() => {
        if (gateways.length > 0) {
            setState(prevState => ({...prevState, selectedGateway: gateways[0]}))
        }
    }, [gateways])

    const toggle = () => {
        setState(prevState => ({ ...prevState, collapse: !prevState.collapse }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const saveGateway = (selectedGateway) => {
        setState(prevState => ({ ...prevState, selectedGateway }));
    };

    const openDoor = () => {
        setState(prevState => ({ ...prevState, openDoor: !prevState.openDoor }));
    };

    const endParkingHandler = () => {
        const data = {
            userId: user.id,
            endTime: datetimeFormatter.forSubmitting(state.timestamp),
            openDoor: state.openDoor,
            licence_plate: currentParking.user_licence_plate || "",
            transactionId: currentParking.id,
            garageId: currentParking.transactionGarage.id,
            grantFreeParking: state.grantFreeParking,
            gwid: state.selectedGateway.value || null,
        };
        endParking(data);
    };

    const dateChange = (timestamp) => {
        setState(prevState => ({ ...prevState, timestamp }));
    };

    const renderPrice = () => {
        const { id } = currentParking.transactionGarage;
        const privilege = privileges.find(item => item.privileged_object_id === id || item.privileged_object_id === 'ALL');
        const price = privilege ? percentageCalculator(privilege.discount_percent, currentParking.total_price) : currentParking.total_price;
        return <strong>{userCreditsFormat(price) || "0"} PAYUCA Credits</strong>;
    };

    const renderGarage = () => (
        <Col xs="12" md="8">
            <Input type="text" value={currentParking ? currentParking.transactionGarage.name : "Kein aktiver Parkvorgang."} disabled />
        </Col>
    );

    return (
        <Card className="border-success">
            <CardHeader onClick={toggle} style={{ cursor: !state.collapse && "pointer" }}>
                <i className="icon-close"></i><strong> Parkvorgang beenden</strong>
            </CardHeader>
            <Collapse isOpen={state.collapse}>
                <CardBody>
                    {loading ? (
                        <div className="sk-rotating-plane" />
                    ) : (
                        <Form action="" method="post" className="form-horizontal">
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Garage</strong></Label>
                                </Col>
                                {renderGarage()}
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Gateway</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Select
                                        name="selectedGateway"
                                        value={state.selectedGateway}
                                        options={gateways}
                                        onChange={saveGateway}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Parkvorgang-Ende</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <DateTimePicker
                                        locale="de"
                                        disabled={!currentParking}
                                        className="form-control"
                                        maxDate={state.maxDate}
                                        value={datetimeFormatter.forPicker(state.timestamp)}
                                        onChange={dateChange}
                                        showLeadingZeros={true}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Tor/Tür öffnen</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Button
                                        disabled={!currentParking}
                                        color={!state.openDoor ? "danger" : "success"}
                                        className="btn-block"
                                        onClick={openDoor}
                                    >
                                        {!state.openDoor ? "Nein" : "Ja"}
                                    </Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Kostenlos parken</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input
                                        type="select"
                                        name="grantFreeParking"
                                        value={state.grantFreeParking}
                                        onChange={handleChange}
                                        disabled={!currentParking}
                                    >
                                        <option value={false}>Nein</option>
                                        <option value={true}>Ja</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            {currentParking && (
                                <FormGroup row>
                                    <Col md="4">
                                        <Label><strong>Kfc-Kennzeichen</strong></Label>
                                    </Col>
                                    <Col xs="12" md="8">
                                        {currentParking.user_licence_plate || "K.A."}
                                    </Col>
                                </FormGroup>
                            )}
                            {currentParking && (
                                <FormGroup row>
                                    <Col md="4">
                                        <Label><strong>Kosten</strong></Label>
                                    </Col>
                                    <Col xs="12" md="8">
                                        {currentParking && renderPrice()}
                                    </Col>
                                </FormGroup>
                            )}
                        </Form>
                    )}
                </CardBody>
                {currentParking && (
                    <CardFooter>
                        <Button
                            type="submit"
                            size="sm"
                            color="primary"
                            className="mr-1"
                            onClick={endParkingHandler}
                            disabled={!state.selectedGateway || loadingEnd}
                        >
                            <i className="fa fa-dot-circle-o"></i> {loadingEnd ? "Wird gemacht..." : "Parkvorgang beenden"}
                        </Button>
                    </CardFooter>
                )}
            </Collapse>
        </Card>
    );
};

export default EndParking;
