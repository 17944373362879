import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import Widget from "../../components/Widget/Widget";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'spinkit/css/spinkit.css';
import userService from "../../services/userService";
import {history} from "../../App";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                id: '',
                first_name: '',
                last_name: '',
                gender: '',
                pre_title: '',
                after_title: '',
                email: '',
                phone_number: '',
                address: '',
                address_number: '',
                zip: '',
                city: '',
            },
            passError: false,
            oldPassErrors: [],
            newPassErrors: [],
            newPassRepeatErrors: [],
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            firstNameHeader: '',
            lastNameHeader: '',
            loading: false,
            loadingEdit: false,
            disabledPersonal: true,
            disabledContact: true,
            disabledEmail: true,
            changePass: false,
            table: [],
            showError: false,
            errors: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.togglePersonal = this.togglePersonal.bind(this);
        this.toggleContact = this.toggleContact.bind(this);
        this.toggleEmail = this.toggleEmail.bind(this);
        this.togglePassChange = this.togglePassChange.bind(this);
        this.mapErrors = this.mapErrors.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.lock = this.lock.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let user = this.state.user;

        user[name] = value;

        this.setState({
            user: user,
            [name]: value
        })
    }

    togglePersonal() {
        this.setState({
            disabledPersonal: !this.state.disabledPersonal,
        });
    }

    toggleContact() {
        this.setState({
            disabledContact: !this.state.disabledContact,
        });
    }

    toggleEmail() {
        this.setState({
            disabledEmail: !this.state.disabledEmail,
        });
    }

    togglePassChange() {
        this.setState({
            changePass: !this.state.changePass,
        });
    }

    mapErrors() {
        String.prototype.capitalize = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        };

        return (
            this.state.errors.map((error) => {
                if (error[0] === 'non_field_errors') {
                    this.unsuccessful(error[1][0].capitalize() + ".")
                } else {
                    this.unsuccessful(error[0].capitalize() + ": " + error[1])
                }
            })
        )
    }

    updateEmail() {
        userService.updateCockpitUserEmail({
            email: this.state.user.email
        })
            .then((response) => {
                this.success();
            })
            .catch((err) => {
                this.setState({
                    showError: true,
                    errors: Object.entries(err.response.data.error)
                }, () => {
                    this.mapErrors();
                });

            })
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    updateUser() {
        this.setState({
            disabledPersonal: true,
            loadingEdit: true
        });

        userService.updateCockpitUser({
            first_name: this.state.user.first_name,
            last_name: this.state.user.last_name,
            gender: this.state.user.gender,
            pre_title: this.state.user.pre_title,
            after_title: this.state.user.after_title,
            email: this.state.user.email,
            phone_number: this.state.user.phone_number,
            address: this.state.user.address,
            address_number: this.state.user.address_number,
            zip: this.state.user.zip,
            city: this.state.user.city,
        })
        .then((response) => {
            this.setState({
                user: response.data,
                firstNameHeader: response.data.first_name,
                lastNameHeader: response.data.last_name,
                disabledPersonal: true,
                disabledContact: true,
                loadingEdit: false,
            });
            this.success();
        })
        .catch((err) => {
            this.setState({
                loadingEdit: false,
                errors: Object.entries(err.response.data.error),
            }, () => {
                this.mapErrors();
            });
        });
    }

    changePassword() {

        userService.changeCockpitPassword({
            old_password: this.state.oldPassword,
            password: this.state.newPassword,
            password_repeat: this.state.repeatNewPassword
        })
            .then(() => {
                this.success();
                localStorage.removeItem('Access-Token');
                this.props.history.go("/");
            })
            .catch((err) => {
                this.setState({
                    oldPassErrors: err.response.data.error.old_password,
                    newPassErrors: err.response.data.error.password,
                    newPassRepeatErrors: err.response.data.error.password_repeat,
                    passError: true,
                });
                this.unsuccessful();
            });
    }

    getRenderErrors() {
        let out = [];

        if (this.state.oldPassErrors)
            out.push(this.getOldPassErrors());
        if (this.state.newPassErrors)
            out.push(this.getNewPassErrors());
        if (this.state.newPassRepeatErrors)
            out.push(this.getNewPassRepeatErrors());

        return out
    }

    getOldPassErrors() {
        return (
            this.state.oldPassErrors.map((oldPassErrors, i) => {
                return <p key={i}>{oldPassErrors}</p>
            })
        )
    }

    getNewPassErrors() {
        return (
            this.state.newPassErrors.map((newPassErrors, i) => {
                return <p key={i}>{newPassErrors}</p>
            })
        )
    }

    getNewPassRepeatErrors() {
        return (
            this.state.newPassRepeatErrors.map((newPassRepeatErrors, i) => {
                return <p key={i}>{newPassRepeatErrors}</p>
            })
        )
    }

    lock() {

        userService.lockUser({
            id: this.state.user.id,
            inactive_reason: this.state.reasonLock,
        })
            .then((response) => {
                if (response.status === 204) {
                    this.success();
                    localStorage.removeItem('Access-Token');
                    this.props.history.go("/");
                } else {
                    this.unsuccessful();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount(){

        userService.getCockpitUser()
            .then((user) => {
                this.setState({
                    user: user.data,
                    firstNameHeader: user.data.first_name,
                    lastNameHeader: user.data.last_name,
                }, () => {
                    document.title = "PAYUCA Cockpit-User | " + this.state.firstNameHeader + " " + this.state.lastNameHeader;
                });
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    history.push('/404')
                }
            });
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-people" color="info" header={this.state.firstNameHeader + " " + this.state.lastNameHeader}/>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2">
                        <Widget
                            icon="icon-lock"
                            color="warning"
                            header="Passwort ändern"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.togglePassChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="6">
                        <Card>
                            <CardHeader>
                                Persönliche Daten
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabledPersonal
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateUser}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.togglePersonal}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.togglePersonal}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                {!this.state.loadingEdit ?
                                    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="gender">Geschlecht</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="select" name="gender" id="gender" value={this.state.user.gender} disabled={this.state.disabledPersonal} onChange={this.handleChange}>
                                                    <option value="M">Männlich</option>
                                                    <option value="F">Weiblich</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="disabled-input">Vortitel</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="pre_title" name="pre_title" value={!this.state.user.pre_title ? "k.A." : this.state.user.pre_title} disabled={this.state.disabledPersonal} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="disabled-input">Vorname</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="first_name" name="first_name" value={this.state.user.first_name} disabled={this.state.disabledPersonal} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="disabled-input">Nachname</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="last_name" name="last_name" value={this.state.user.last_name} disabled={this.state.disabledPersonal} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="disabled-input">Nachtitel</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="after_title" name="after_title" value={!this.state.user.after_title ? "k.A." : this.state.user.after_title} disabled={this.state.disabledPersonal} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                    </Form> :
                                    <div className="sk-rotating-plane"/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="6">
                        <Card>
                            <CardHeader>
                                Kontaktdaten
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabledContact
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateUser}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleContact}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleContact}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                {!this.state.loadingEdit ?
                                    <Form action="" method="post" encType="multipart/form-data"
                                          className="form-horizontal">
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="phone-input">Telefonnummer</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="phone" name="phone_number"
                                                       value={!this.state.user.phone_number ? "" : this.state.user.phone_number}
                                                       disabled={this.state.disabledContact}
                                                       onChange={this.handleChange}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="text-input">Straße</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="address" name="address"
                                                       value={this.state.user.address}
                                                       disabled={this.state.disabledContact}
                                                       onChange={this.handleChange}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="number-input">Hausnummer</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="address_number" name="address_number"
                                                       value={this.state.user.address_number}
                                                       disabled={this.state.disabledContact}
                                                       onChange={this.handleChange}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="number-input">Postleitzahl</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="zip" name="zip" value={this.state.user.zip}
                                                       disabled={this.state.disabledContact}
                                                       onChange={this.handleChange}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="text-input">Stadt</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="city" name="city" value={this.state.user.city}
                                                       disabled={this.state.disabledContact}
                                                       onChange={this.handleChange}/>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    :
                                    <div className="sk-rotating-plane"/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                E-Mail-Adresse Ändern
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabledEmail
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateEmail}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleEmail}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleEmail}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                {!this.state.loadingEdit ?
                                    <Form action="" method="post" encType="multipart/form-data"
                                          className="form-horizontal">
                                        <FormGroup row>
                                            <Col md="3" lg="2">
                                                <Label htmlFor="email-input">E-Mail-Adresse</Label>
                                            </Col>
                                            <Col xs="12" md="9" lg="10">
                                                <Input type="email" id="email" name="email"
                                                       value={this.state.user.email}
                                                       disabled={this.state.disabledEmail}
                                                       onChange={this.handleChange}/>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    :
                                    <div className="sk-rotating-plane"/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Pass change user */}
                <Modal isOpen={this.state.changePass} toggle={this.togglePassChange}
                       className={'modal-primary' + this.props.className}>
                    <ModalHeader toggle={this.togglePassChange}>Cockpit Passwort ändern</ModalHeader>
                    <ModalBody>
                        <Form action="" method="post" encType="multipart/form-data" className="form-horizontal pt-2">
                            <FormGroup row>
                                <Col lg="12">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat.
                                    </p>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg="4">
                                    <Label htmlFor="oldPassword">Altes Passwort</Label>
                                </Col>
                                <Col xs="12" lg="8">
                                    <Input type="password" id="oldPassword" name="oldPassword" value={this.state.oldPassword} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="newPassword">Neues Passwort</Label>
                                </Col>
                                <Col xs="12" lg="8">
                                    <Input type="password" id="newPassword" name="newPassword" value={this.state.newPassword} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="repeatNewPassword">Neues Passwort wiederholen</Label>
                                </Col>
                                <Col xs="12" lg="8">
                                    <Input type="password" id="repeatNewPassword" name="repeatNewPassword" value={this.state.repeatNewPassword} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                        </Form>
                        {this.state.passError ?
                            this.getRenderErrors()
                            :
                            ""
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.changePassword}>Cockpit Passwort ändern</Button>{' '}
                        <Button color="secondary" onClick={this.togglePassChange}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default Settings;
