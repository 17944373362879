import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label
} from 'reactstrap';
import Select from 'react-select';
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))


export default class UserContactData extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editingContactDisabled: true,
            user: this.props.user,
            options: []
        };

        this.toggleContact = this.toggleContact.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.countryList = this.countryList.bind(this)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.user && this.props.user !== nextProps.user){
            this.setState({
                user: nextProps.user
            });
        }
    }

    toggleContact() {
        this.setState({
            editingContactDisabled: !this.state.editingContactDisabled,
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let user = this.state.user;

        user[name] = value;

        this.setState({
            user: user,
        })
    }

    countryList() {
        let options = [];
        let countryList = countries.getNames("de");

        Object.entries(countryList).forEach(([key, val]) => { options.push({value: key, label: val})});

        this.setState({
            options: options
        })
    }

    selectCountry = (country) => {
        this.setState({
            user: {
                ...this.state.user,
                country: !country ? null : country.value
            }
        });
    };

    componentDidMount() {
        this.countryList()
    }

    updateUser() {
        this.props.updateUser(this.state.user)
            .then(() => {
                this.setState({
                    editingContactDisabled: true
                })
            })
            .catch(() => {
                this.setState({
                    editingContactDisabled: false
                })
            });
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Kontaktdaten
                    <div className="card-header-actions">
                        {
                            !this.state.editingContactDisabled
                                ?
                                <div><Button type="submit" size="sm" color="success" className={"mr-1"}
                                             onClick={this.updateUser}><i className="fa fa-dot-circle-o"></i> Speichern</Button>
                                    <Button type="reset" size="sm" color="danger" onClick={this.toggleContact}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                :
                                <a className="card-header-action btn btn-setting" style={{cursor: this.state.user.inactive_reason ? "not-allowed" : "pointer"}} onClick={this.state.user.inactive_reason ? null : this.toggleContact}><i className="icon-pencil"></i></a>
                        }
                    </div>
                </CardHeader>
                <CardBody>

                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="email">E-Mail-Adresse</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="email" id="email" name="email" value={this.state.user.email} disabled
                                   onChange={this.handleChange}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="phone_number">Telefonnummer</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="tel" id="phone" name="phone_number"
                                   value={!this.state.user.phone_number ? "" : this.state.user.phone_number}
                                   onChange={this.handleChange} disabled={this.state.editingContactDisabled}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="address">Straße</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" id="address" name="address" value={this.state.user.address}
                                   disabled={this.state.editingContactDisabled} onChange={this.handleChange}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="address_number">Hausnummer</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" id="address_number" name="address_number"
                                   value={this.state.user.address_number} disabled={this.state.editingContactDisabled}
                                   onChange={this.handleChange}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="zip">Postleitzahl</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" id="zip" name="zip" value={this.state.user.zip}
                                   disabled={this.state.editingContactDisabled} onChange={this.handleChange}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="city">Stadt</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" id="city" name="city" value={this.state.user.city}
                                   disabled={this.state.editingContactDisabled} onChange={this.handleChange}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="country">Land</Label>
                        </Col>
                        <Col xs="12" md="9">
                            {/*<Input type="text" id="country" name="country" value={this.state.user.country}
                                    onChange={this.handleChange}/>*/}
                            <Select
                                value={this.state.user.country}
                                onChange={this.selectCountry}
                                options={this.state.options}
                                disabled={this.state.editingContactDisabled}
                            />
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
        )
    }

}
