import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Collapse, Button} from 'reactstrap';
import isEmpty from 'validator/lib/isEmpty'

class InventoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.data,
            accordion: false,

            //added by pepi playboy & divljina
            saveDisabled: this.isFormEmpty(this.props.data)
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.handleSaveInventory = this.handleSaveInventory.bind(this);
        this.deleteInventoryEntry = this.deleteInventoryEntry.bind(this);
    }


    // added by pepi playboy & divljina
    isFormEmpty(values) {
        return isEmpty(values.name) && isEmpty(values.count + '') && isEmpty(values.description)
    }

    toggleAccordion() {
        this.setState({
            accordion: !this.state.accordion,
        });
    }

    handleSaveInventory() {
        this.props.handleSaveInventory({...this.state})
    }

    deleteInventoryEntry() {
        this.props.deleteInventoryEntry({...this.state})
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // added by pepi playboy & divljina
        let theState = this.state;
        theState[name] = value;
        theState['saveDisabled'] = this.isFormEmpty(theState);

        this.setState(theState);
    }

    render() {
        return (
            <Col xs="12" sm="12" md="12" lg="4">
                <Card>
                    <CardHeader>
                        {!this.state.name ? "Inventar Artikel  " + (this.props.index + 1) : this.state.name}
                        <div className="card-header-actions">
                            {
                                this.state.accordion
                                    ?
                                    <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.handleSaveInventory} disabled={this.state.saveDisabled}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleAccordion}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                    :
                                    <a className="card-header-action btn btn-setting" onClick={this.toggleAccordion} aria-controls="collapseOne"><i className="icon-pencil"></i></a>
                            }
                        </div>
                    </CardHeader>
                    <Collapse isOpen={this.state.accordion} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                        <CardBody className="pb-0">
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="Name">Name</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="text" name="name" id="name" value={this.state.name} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="count">Menge</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="text" name="count" id="count" value={this.state.count} onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="description">Beschreibung</Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="textarea" name="description" id="description" value={this.state.description} onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            {
                                !this.state.id ? "" :
                                    <FormGroup row>
                                        <Col xs="12" sm="12" md="12" lg="12">
                                            <Button type="submit" size="sm" color="danger" className={"mr-1 btn-block"} onClick={this.deleteInventoryEntry}>Artikel löschen</Button>
                                        </Col>
                                    </FormGroup>
                            }
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        );
    }
}

export default InventoryItem;
