import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';
import Widget from "../../components/Widget/Widget";
import DateTimePicker from "react-datetime-picker";
import 'react-select/dist/react-select.min.css';

import 'react-select/dist/react-select.min.css';
import 'spinkit/css/spinkit.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import couponService from "../../services/couponService";

import datetimeFormatter from '../../utils/datetimeFormatter';

import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CouponsUsersTable from "./CouponsUsersTable";
import {history} from "../../App";


class Coupon extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            disabled: true,
            reasonDelete: '',
            id: '',
            owner: '',
            value: '',
            code: '',
            starts_at: '',
            created_at: datetimeFormatter.forDisplay(new Date()),
            expires_at: '',
            active: '',
            collective: '',
            showError: false,
            errors: {},
            delete: false
        };

        this.typingTimer = null;
        this.typingInterval = 550;

        this.handleChange = this.handleChange.bind(this);
        this.toggleDisabled = this.toggleDisabled.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.update = this.update.bind(this);
        this.mapErrors = this.mapErrors.bind(this);


        this.validFromHandler = this.validFromHandler.bind(this);
        this.validUntilHandler = this.validUntilHandler.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    toggleDelete() {
        this.setState({
            delete: !this.state.delete,
        });
    }

    toggleDisabled() {
        this.setState({
            disabled: !this.state.disabled,
        });
    }

    delete() {
        couponService.deleteCoupon(this.state.id)
            .then((response) => {
                this.props.history.push("/coupons");
            })
            .catch((err) => {
                console.log(err);
                this.unsuccessful();
            });
    }

    mapErrors() {
        String.prototype.capitalize = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        };

        return (
            this.state.errors.map((error) => {
                if (error[0] === 'non_field_errors') {
                    this.unsuccessful(error[1][0].capitalize() + ".")
                } else {
                    this.unsuccessful(error[0].capitalize() + ": " + error[1])
                }
            })
        )
    }

    update() {
        couponService.updateCoupon({
            id: this.state.id,
            code: this.state.code,
            value: this.state.value,
            starts_at: datetimeFormatter.forSubmitting(this.state.starts_at),
            expires_at: datetimeFormatter.forSubmitting(this.state.expires_at),
            active: this.state.active,
            collective: !!this.state.new_user_voucher ? true : this.state.collective,
            new_user_voucher: this.state.new_user_voucher
        })
            .then((response) => {
                this.success();
                this.setState({
                    disabled: true,
                    id: response.data.id,
                    code: response.data.code,
                    codeHeader: response.data.code,
                    value: response.data.value,
                    starts_at: datetimeFormatter.forPicker(this.state.starts_at),
                    expires_at: datetimeFormatter.forPicker(this.state.expires_at),
                    active: response.data.active,
                    collective: response.data.collective,
                    new_user_voucher: response.data.new_user_voucher,
                    owner: response.data.owner ? response.data.owner.email : "",
                });

            })
            .catch((err) => {
                this.setState({
                    showError: true,
                    errors: Object.entries(err.response.data.error)
                }, () => {
                    this.mapErrors();
                });
            });
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    validFromHandler(starts_at) {
        this.setState({
            starts_at
        });
    }

    validUntilHandler(expires_at){
        this.setState({ expires_at });
    }

    componentDidMount() {

        const { match: { params } } = this.props;

        couponService.getCoupon(params.id)
            .then((coupon) => {
                this.setState({
                    id: coupon.data.id,
                    code: coupon.data.code,
                    codeHeader: coupon.data.code,
                    value: coupon.data.value,
                    created_at: datetimeFormatter.forDisplay(coupon.data.created_at),
                    starts_at: datetimeFormatter.forPicker(coupon.data.starts_at),
                    expires_at: datetimeFormatter.forPicker(coupon.data.expires_at),
                    active: coupon.data.active,
                    collective: coupon.data.collective,
                    new_user_voucher: coupon.data.new_user_voucher,
                    owner: coupon.data.owner ? coupon.data.owner.email : "k.A.",
                    loading: false,
                }, () => {
                    document.title = "Coupon | " + this.state.code;
                })
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    history.push('/404')
                }
            })
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-tag" color="info" header={this.state.codeHeader}/>
                    </Col>
                    <Col xs="12" sm="12" md="2">
                        <Widget
                            icon="icon-minus"
                            color="danger"
                            header="Gutschein löschen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.toggleDelete}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                Gutschein Infos
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.update}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleDisabled}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">ID</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="id" id="id" value={this.state.id} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Code</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="code" id="code" value={this.state.code} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Wert</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="value" id="value" value={this.state.value} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Gültig ab</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <DateTimePicker locale="de-DE" className="form-control"
                                                                    value={this.state.starts_at}
                                                                    onChange={this.validFromHandler}
                                                                    showLeadingZeros={true}
                                                                    disabled={this.state.disabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Gültig bis</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <DateTimePicker locale="de"
                                                                    className="form-control"
                                                                    value={this.state.expires_at}
                                                                    onChange={this.validUntilHandler}
                                                                    showLeadingZeros={true}
                                                                    disabled={this.state.disabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Aktiv</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" name="active" id="active"
                                                           value={this.state.active} onChange={this.handleChange}
                                                           disabled={this.state.disabled}>
                                                        <option value="" disabled defaultValue>Bitte wählen</option>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Sammelgutschein*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" name="collective" id="collective"
                                                           value={this.state.collective}
                                                           onChange={this.handleChange}
                                                           disabled={this.state.disabled}>
                                                        <option value="" disabled defaultValue>Bitte wählen</option>
                                                        <option value={true} name="Ja">Ja</option>
                                                        <option value={false} name="Nein">Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Nur neue Benutzer**</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" name="new_user_voucher" id="new_user_voucher"
                                                           value={this.state.new_user_voucher}
                                                           onChange={this.handleChange}
                                                           disabled={this.state.disabled}>
                                                        <option value="" disabled defaultValue>Bitte wählen</option>
                                                        <option value={true} name="Ja">Ja</option>
                                                        <option value={false} name="Nein">Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Erstellt von</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="owner" id="owner" value={this.state.owner} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="selectSm">Erstellt am</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="created_at" id="created_at" value={this.state.created_at} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" md="12" sm="12">
                                            <small className="pull-right">
                                                *<strong>Sammelgutschein</strong>: Jeder kann diesen Gutschein einlösen.
                                            </small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" md="12" sm="12">
                                            <small className="pull-right">
                                                **<strong>Nur neue Benutzer</strong>: Nur Benutzer mit Registrierungsdatum innerhalb der Gutscheincode Gültigkeit und ohne andere Aufladungen
                                            </small>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <CouponsUsersTable
                    coupon={this.state}
                    success={this.success}
                    unsuccessful={this.unsuccessful}
                />

                {/* Modals */}

                {/* Delete coupon */}
                <Modal isOpen={this.state.delete} toggle={this.toggleDelete}
                       className={'modal-danger ' + this.props.className}>
                    <ModalHeader toggle={this.toggleDelete}>Gutschein löschen</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.delete}>Gutschein löschen</Button>{' '}
                        <Button color="secondary" onClick={this.toggleDelete}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }

}

export default Coupon;
