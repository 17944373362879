import urls from '../utils/urls';
import axios from "axios";

import errorHandlerService from './errorHandlerService';


const contactService = {

    getContacts: (page=null, search="") => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/contacts/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en',
                    },
                    params: {
                        page,
                        search: search || null
                    }
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        contactService.getContacts, page, search
                    )
                }
            });
        });
    },

    getContact: (id) => {

        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/contacts/${id}/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en',
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(err, isNotAuth, resolve, reject, contactService.getContact, id)
                }
            });
        });

    },

    updateContact: (contact) => {

        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/contacts/${contact.id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en'
                },
                data: JSON.stringify(contact)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        contactService.updateContact, contact
                    )
                }
            });
        });


    },

    deleteContact: (id) => {

        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${urls.apigateway}/contacts/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        contactService.deleteContact, id
                    )
                }
            });
        });
    },

    addContact: (contact) => {

        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${urls.apigateway}/contacts/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(contact)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        contactService.addContact, contact
                    )
                }
            });
        });
    },

};

export default contactService;
