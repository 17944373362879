import React, {Component} from 'react';


export default class CustomDropdownFilter extends Component{

    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {
        this.props.filterHandler(event.target.value);
    }


    render() {
        return (
            <div>
                <select data-testid={this.props.testId} onChange={this.handleChange} value={this.props.value} className="form-control">
                    {!this.props.disableNoneState && <option value="">--</option>}
                    {this.props.options.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        )
                    })}
                </select>
            </div>
        )
    }
}