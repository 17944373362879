import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import 'react-select/dist/react-select.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'ladda/dist/ladda-themeless.min.css';

import Widget from "../../components/Widget/Widget";
import {ToastContainer, toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import garageOwnerService from "../../services/garageOwnerService";
import garageService from "../../services/garageService";
import getGaragesQuery from "../../queries/getGarages";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

class AddGarageOwner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            name: '',
            registration_number: '',
            note: '',
            garage: '',
            for_attention_of: '',
            address: '',
            address_number: '',
            city: '',
            invoice_emails: [],
            email: '',
            vat_reg_no: '',
            zip: '',
            country: '',
            iban: '',
            bic: '',
            service_fee: '',
            assignedGarage: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.addEmail = this.addEmail.bind(this);
        this.deleteEmail = this.deleteEmail.bind(this);
        this.addOwner = this.addOwner.bind(this);
        this.onGarageChanged = this.onGarageChanged.bind(this);
        this.loadGarages = this.loadGarages.bind(this);
    }


    handleChange(event) {

            const target = event.target;
            const value = target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
    }

    selectCountry = (country) => {
        this.setState({
            country: !country ? null : country.value
        });
    };

    countryList() {
        let options = [];
        let countryList = countries.getNames("de");

        Object.entries(countryList).forEach(([key, val]) => { options.push({value: key, label: val})});

        this.setState({
            options: options
        })
    }

    addEmail() {
        this.state.invoice_emails.push(this.state.email);
        this.setState(this.state);
        this.setState({
            email: ''
        })
    }

    deleteEmail(i){
        this.state.invoice_emails.splice(i, 1);

        this.setState({
            invoice_emails: this.state.invoice_emails
        })
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    addOwner() {

        garageOwnerService.addOwner({
            ...this.state,
            garage: this.state.assignedGarage.label,
            garage_address: this.state.assignedGarage.garage_address,
            garage_address_number: this.state.assignedGarage.garage_address_number,
            garage_city: this.state.assignedGarage.garage_city,
            garage_zip: this.state.assignedGarage.garage_zip,
            garage_country: this.state.assignedGarage.garage_country
        })
            .then((response) => {
                this.setState({
                    redirect: true
                });
            })
            .catch((err) => {

                let errors = err.response.data.error;

                Object.entries(errors).map((error) => {
                    return this.unsuccessful(error[0] + ": " + error[1][0]);
                })
            });
    }

    onGarageChanged(assignedGarage) {
        this.setState({
            assignedGarage: assignedGarage
        });
    }

    loadGarages(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    garageOwner: true
                };

                garageService.getService(getGaragesQuery(params))
                    .then((garages) => {
                        resolve({
                            options: garages.data.getGarages.garages.map((garage) => {
                                return {value: garage.name, label: garage.name, garage_city: garage.address.city, garage_address: garage.address.street, garage_address_number: garage.address.street_number, garage_zip: garage.address.zip_code, garage_country: garage.address.country }
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            }, this.typingInterval)

        })
    }

    componentDidMount() {
        this.countryList()
    }

    render() {

        let { invoice_emails } = this.state;

        if(this.state.redirect) {
            return <Redirect to="/garageowners"/>
        }

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-people" color="info" header={"Garagenbesitzer hinzufügen"}/>
                    </Col>
                    <Col xs="12" sm="12" md="2">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Hinzufügen"
                            invert
                            onClick={this.addOwner}
                            style={{cursor: "pointer"}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                Garage Daten
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="garage">Betrifft Garage</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <AsyncSelect
                                                        name="assignedGarage"
                                                        loadOptions={this.loadGarages}
                                                        value={this.state.assignedGarage}
                                                        onChange={this.onGarageChanged}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address">Adresse</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_address" id="garage_address" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_address} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address_number">Hausnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_address_number" id="garage_address_number" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_address_number} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="zip">Postleitzahl</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_zip" id="garage_zip" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_zip} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="city">Stadt</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_city" id="garage_city" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_city} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="garage_country">Land</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_country}
                                                        options={this.state.options}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                Firmendaten
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">Firmenwortlaut</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="name" id="name" value={this.state.name} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="for_attention_of">z.H.</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="for_attention_of" id="for_attention_of" value={this.state.for_attention_of} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="registration_number">Firmenbuchnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="registration_number" id="registration_number" value={this.state.registration_number} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="vat_reg_no">UID</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="vat_reg_no" id="vat_reg_no" value={this.state.vat_reg_no} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="iban">IBAN*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="iban" id="iban" value={!this.state.iban ? "" : this.state.iban} onChange={this.handleChange} required={true} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="BIC">BIC*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="bic" id="bic" value={this.state.bic} onChange={this.handleChange} required={true} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="service_fee">Servicegebühr</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="number" min="0" step="1" name="service_fee" id="service_fee" value={this.state.service_fee} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address">Adresse</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="address" id="address" value={this.state.address} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address_number">Hausnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="address_number" id="address_number" value={this.state.address_number} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="zip">Postleitzahl</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="zip" id="zip" value={this.state.zip} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="city">Stadt</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="city" id="city" value={this.state.city} onChange={this.handleChange}  />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="country">Land</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={this.state.country}
                                                        onChange={this.selectCountry}
                                                        options={this.state.options}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <small>* erforderlich</small>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Rechnungs-E-Mails
                            </CardHeader>
                            <CardBody>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="invoice_emails">Rechnungs-E-Mail</Label>
                                    </Col>
                                    <Col xs="12" md="7">
                                        <Input type="text" value={this.state.email} name="email" onChange={this.handleChange} />
                                    </Col>
                                    <Col xs="12" md="2">
                                        <Button className="btn btn-block btn-success" onClick={this.addEmail} disabled={!this.state.email}>E-Mail hinzufügen</Button>
                                    </Col>
                                </FormGroup>

                                <hr className="mt-4 mb-4" />

                                {invoice_emails.map((v, i) => {
                                    return (
                                        <FormGroup row key={i}>
                                            <Col md="3">
                                                <Label htmlFor="invoice_emails">E-Mail-Adresse</Label>
                                            </Col>
                                            <Col xs="12" md="7">
                                                <Input type="text" value={v} name="invoice_emails" onChange={this.handleChange} disabled />
                                            </Col>
                                            <Col md="2">
                                                <Button className="btn btn-block btn-danger" onClick={this.deleteEmail.bind(this, i)}>Löschen</Button>
                                            </Col>
                                        </FormGroup>)

                                })}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Firmen Notiz
                            </CardHeader>
                            <CardBody>
                                <Input type="textarea" rows="7" name="note" id="note"
                                       value={!this.state.note ? '' : this.state.note}
                                       onChange={this.handleChange}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default AddGarageOwner;
