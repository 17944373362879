import gql from "graphql-tag";

export default function query(params) {
    if (params.device_id) {
        return gql`
            mutation {
                createPCGateway(
                    device_id: "${params.device_id}"
                    gwid: "${params.gwid}"
                ) {
                    errors
                    gateway {
                        id
                        device_id
                        garage_id
                        master_id
                        type
                        gwid
                        node_number
                        relay_timings
                        stm_fw
                        rf_fw
                        esp_fw
                        note
                        created_at
                        updated_at
                        deleted_at
                        payuca_connect_garage {
                            name
                            id
                            state
                            echarger
                        }
                    }
                }
            }`;
    }
    return gql`
            mutation {
                createPCGateway(
                    gwid: "${params.gwid}"
                ) {
                    errors
                    gateway {
                        id
                        device_id
                        garage_id
                        master_id
                        type
                        gwid
                        node_number
                        relay_timings
                        stm_fw
                        rf_fw
                        esp_fw
                        note
                        created_at
                        updated_at
                        deleted_at
                        payuca_connect_garage {
                            name
                            id
                            state
                            echarger
                        }
                    }
                }
            }`;
}
