import React, { Component } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  BootstrapTable,
  TableHeaderColumn,
} from "react-bootstrap-table";
import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";
import "spinkit/css/spinkit.css";
import { Link } from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import versionBlockService from "../../services/versionBlockService";
import getVersionBlockEntriesQuery from "../../queries/getVersionBlockEntries";

class VersionBlock extends Component {
  static toVersionBlockEntry(cell, row) {
    const id = row.id;
    return <Link to={`/version-block/${id}`}> {cell} </Link>;
  }

  static blocksTransform(cell, row) {
    const { ios, android } = row;
    if (android && ios) {
      return "Both";
    } else if (android && !ios) {
      return "Android only";
    } else if (!android && ios) {
      return "iOS only";
    } else {
      return "Neither";
    }
  }

  static version(cell, row) {
    const { version } = row;
    return version;
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entries: [],
      page: 1,
      totalSize: 0,
    };

    this.typingTimer = null;
    this.typingInterval = 500;

    this.fetchVersions = this.fetchVersions.bind(this);
  }

  componentDidMount() {
    versionBlockService
      .getEntries(getVersionBlockEntriesQuery())
      .then((response) => {
        const { getVersionBlocks } = response.data;
        const { count, versionBlock } = getVersionBlocks;
        this.setState({
          entries: versionBlock,
          loading: false,
          totalSize: count,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    document.title = "PAYUCA Version Block";
  }

  get tableOptions() {
    return {
      sortIndicator: true,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: false,
      clearSearch: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      noDataText: this.getNoDataTableContent(),
      page: this.state.page,
      sizePerPage: 5,
      defaultSortName: 'version',
      defaultSortOrder: 'desc'
    };
  }


  getNoDataTableContent() {
    if (this.state.loading) {
      return <div className="sk-rotating-plane" />;
    }
    return "Currently no version blocks available.";
  }

  fetchVersions() {
    versionBlockService
        .getEntries(getVersionBlockEntriesQuery())
        .then((response) => {
          const { getVersionBlocks } = response.data;
          const { count, versionBlock } = getVersionBlocks;
          this.setState({
            entries: versionBlock,
            loading: false,
            totalSize: count,
          });
        })
        .catch((err) => {
          console.log(err);
        });
  }

  render() {
    return (
      <div className="animated">
        <Row>
          <Col xs="12" sm="12" lg="10">
            <Widget
              icon="icon-globe"
              color="info"
              header="PAYUCA B2C Blocked Versions"
            />
          </Col>
          <Col xs="12" sm="12" lg="2">
            <Link to="/version-block/add" className="noDecoLink">
              <Widget
                icon="icon-plus"
                color="success"
                header="Block Version"
                invert
                style={{ cursor: "pointer" }}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>
              <CardBody>
                <BootstrapTable
                  data={this.state.entries}
                  version="4"
                  striped
                  fetchInfo={{ dataTotalSize: this.state.totalSize }}
                  pagination={true}
                  options={this.tableOptions}
                  search
                >
                  <TableHeaderColumn
                    width="120"
                    dataField="version"
                    tdStyle={{ whiteSpace: "normal" }}
                    dataSort={true}
                    isKey={true}
                    dataFormat={VersionBlock.toVersionBlockEntry}
                  >
                    Version
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="200"
                    dataField="id"
                    dataFormat={VersionBlock.toVersionBlockEntry}
                  >
                    Block ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="150"
                    dataField="blocks"
                    dataFormat={VersionBlock.blocksTransform}
                  >
                    Blocked OS
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="message_de"
                    thStyle={{ whiteSpace: "normal" }}
                    tdStyle={{ whiteSpace: "normal" }}
                  >
                    German message
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="message_en"
                    thStyle={{ whiteSpace: "normal" }}
                    tdStyle={{ whiteSpace: "normal" }}
                  >
                    English message
                  </TableHeaderColumn>
                </BootstrapTable>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default VersionBlock;
