import React, {Component} from 'react';
import {Card, CardBody, CardHeader, UncontrolledTooltip} from 'reactstrap';
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from "react-bootstrap-table";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import garageService from "../services/garageService";
import getLogs from "../queries/getLogs";
import CustomDropdownFilter from "./CustomDropdownFilter";
import datetimeFormatter from "../utils/datetimeFormatter";


class Logs extends Component {


    constructor(props) {
        super(props);

        this.state = {
            garage_id: this.props.garage_id,
            object_content_type: this.props.object_content_type,
            loading: true,
            page: 1,
            totalSize: 0,
            logs: [],
        };

        this.typingTimer = null;
        this.typingInterval = 600;

        this.onPageChange = this.onPageChange.bind(this);
        this.fetchLogs = this.fetchLogs.bind(this);

        this.getActionFilter = this.getActionFilter.bind(this);
        this.handleActionFilter = this.handleActionFilter.bind(this);
    }

    actionType = {
        'CREATE': 'Erstellt',
        'UPDATE': 'Aktualisiert',
        'DELETE': 'Gelöscht',
        'ASSIGN': 'Zugewiesen',
        'RETURN_TO_POOL': 'Zurück zum Pool'
    };

    static dateFormat = (cell, row) => {
        return datetimeFormatter.forDisplay(row.created_at)
    }

    static enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    static IDTooltipFormatter(cell, row) {
        const id = row.id;

        return (
            <div id={'Tooltip-' + id}>
                {cell}
                <UncontrolledTooltip placement="top" target={'Tooltip-' + id}>
                    {cell}
                </UncontrolledTooltip>
            </div>
        )
    }

    static renderType(cell, row) {
        if (!row.resource_content_type) {
            return row.object_content_type
        } else {
            return row.resource_content_type
        }
    }

    getQueryParams() {
        return {
            offset: (this.state.page - 1) * 10,
            first: 10,
            object_id: this.props.object_id,
            object_content_type: this.state.object_content_type,
            action: this.state.actionFilter
        };
    }

    fetchLogs() {
        garageService.getService(getLogs(this.getQueryParams()))
            .then((logs) => {
                this.setState({
                    logs: logs.data.getLogs.logs,
                    totalSize: logs.data.getLogs.count,
                    loading: false,
                })
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });
    }

    componentDidMount() {
        this.fetchLogs();
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            loading: true,
            logs: [],
            page
        }, this.fetchLogs)
    }

    get tableOptions() {
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,

            page: this.state.page,
            sizePerPage: 10
        }
    }

    getActionFilter() {
        let options = [
            {value: "CREATE", label: "Erstellt"},
            {value: "UPDATE", label: "Aktualisiert"},
            {value: "DELETE", label: "Gelöscht"},
            {value: "ASSIGN", label: "Zugewiesen"},
            {value: "RETURN_TO_POOL", label: "Zurück zum Pool"},
        ];
        return (
            <CustomDropdownFilter
                options={options}
                filterHandler={this.handleActionFilter}/>
        )
    }

    handleActionFilter(val) {
        this.setState({
            loading: true,
            logs: [],
            page: 1,
            actionFilter: val
        }, this.fetchLogs)
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "Keine Logs gefunden"
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Logs
                </CardHeader>
                <CardBody>
                    <BootstrapTable data={this.state.logs} version="4" striped remote fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions}>
                        <TableHeaderColumn width="150" dataField="id" tdStyle={ { whiteSpace: 'normal' } } isKey={true}>ID</TableHeaderColumn>
                        <TableHeaderColumn width="150" dataField="actor_email">Actor</TableHeaderColumn>
                        <TableHeaderColumn width="150" dataField="created_at" dataFormat={Logs.dateFormat}>Datum</TableHeaderColumn>
                        <TableHeaderColumn width="150"
                                           dataField="action"
                                           dataFormat={ Logs.enumFormatter }
                                           formatExtraData={ this.actionType }
                                           filter={{ type: 'CustomFilter', getElement: this.getActionFilter }}>Aktion</TableHeaderColumn>
                        <TableHeaderColumn width="150" dataField={"resource_id"} tdStyle={ { whiteSpace: 'normal' } }>Ressourcen-ID</TableHeaderColumn>
                        <TableHeaderColumn width="150" dataFormat={Logs.renderType}>Objekt Typ</TableHeaderColumn>
                        <TableHeaderColumn width="150" dataField={"old_value"} tdStyle={ { whiteSpace: 'normal' } }>War</TableHeaderColumn>
                        <TableHeaderColumn width="150" dataField="new_value" tdStyle={ { whiteSpace: 'normal' } }>Ist</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        );
    }
}

export default Logs;
