import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {Link} from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import CustomSearchField from "../../components/CustomSearchField";
import garageOwnerService from "../../services/garageOwnerService";

class GarageOwners extends Component {

    static ToGarageOwner(cell, row) {
        const id = row.id;
        return <Link to={`/garageowners/${id}`}> {cell} </Link>;
    }

    static CountryTransform(cell) {
        if (cell === 'AT') {
            return 'Österreich'
        }

        if (cell === 'DE') {
            return 'Deutschland'
        }
    }

    static Emails(cell, row) {
        const emails = row.invoice_emails.toString();
        return emails.replace(/,/g, '<br/>');
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            owners: [],
            page: 1,
            totalSize: 0
        };

        this.typingTimer = null;
        this.typingInterval = 500;

        this.onSearch = this.onSearch.bind(this);
        this.fetchOwners = this.fetchOwners.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);
        this.getClearButton = this.getClearButton.bind(this);
    }

    componentDidMount() {
        garageOwnerService.getOwners(this.state.page)
            .then((response) => {
                if(response.data.hasOwnProperty("results")) {
                    this.setState({
                        owners: response.data.results,
                        loading: false,
                        totalSize: response.data.count
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        document.title = "PAYUCA Garagenbesitzer";
    }


    get tableOptions(){
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            searchField: this.getSearchField,
            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,

            page: this.state.page,
            sizePerPage: 10

        };
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                owners: [],
                searchQuery: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchOwners, this.typingInterval)
            })
        }
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.searchQuery !== "") {
            this.setState({
                page: 1,
                loading: true,
                owners: [],
                searchQuery: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchOwners, this.typingInterval)
            })
        }
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "Keine Garagenbesitzer gefunden"
    }

    fetchOwners() {
        garageOwnerService.getOwners(this.state.page, this.state.searchQuery)
            .then((resp) => {
                this.setState({
                    loading: false,
                    owners:  resp.data.results || [],
                    totalSize: resp.data.count
                });

            })
            .catch((err) => {
                console.log(err);
            });
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            page
        }, this.fetchOwners)
    }

    render() {

        return (
            <div className="animated">
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-globe" color="info" header="PAYUCA Garagenbesitzer" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Link to="/addgarageowner" className="noDecoLink">
                            <Widget
                                icon="icon-plus"
                                color="success"
                                header="Besitzer hinzufügen"
                                invert
                                style={{cursor: "pointer"}}
                            />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <BootstrapTable data={this.state.owners} version="4" striped remote fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions} search>
                                    <TableHeaderColumn width='150' isKey={true} dataField="name" dataFormat={GarageOwners.ToGarageOwner}>Firmenwortlaut</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="country" dataFormat={GarageOwners.CountryTransform}>Land</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="invoice_email" tdStyle={{ whiteSpace: 'normal' }} dataFormat={GarageOwners.Emails}>Rechnungs-E-Mails</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="iban" thStyle={{ whiteSpace: 'normal' }}>IBAN</TableHeaderColumn>
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default GarageOwners;
