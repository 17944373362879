import gql from "graphql-tag";
import {quoteStringOrNull} from "../utils/quoteStringOrNull";

export default function query(params) {
        return gql`
            mutation {
                updatePCGateway(
                    id: "${params.id}"
                    type: ${params.type}
                    gwid: "${params.gwid}"
                    note: "${!params.note ? '' : params.note.replace(/(\r\n|\n|\r)/gm, "\\n")}"
                    name: ${quoteStringOrNull(params.name)}
                    tenant_id: ${quoteStringOrNull(params.tenant_id)}
                    product_type: ${params.product_type}
                ) {
                    errors
                    gateway {
                        id
                        device_id
                        garage_id
                        master_id
                        type
                        product_type
                        gwid
                        node_number
                        relay_timings
                        stm_fw
                        rf_fw
                        esp_fw
                        note
                        name
                        position{
                            lat
                            long
                        }
                        created_at
                        updated_at
                        deleted_at
                        tenant_id
                        payuca_connect_garage {
                            name
                            id
                            state
                            echarger
                        }
                        health_status {
                            nfc_state
                            ble_state
                            rf_state
                            connection
                            main_controller_state
                        }
                    }
                }
            }`;
}
