import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import 'spinkit/css/spinkit.css';

import garageService from "../../../services/garageService";
import getTransactions from "../../../queries/getTransactions";


class ActiveParking extends Component {


    constructor(props) {
        super(props);

        this.state = {
            user_id: this.props.user_id,
            loading: true
        };
    }

    componentDidMount() {

        garageService.getService(getTransactions({
            user_id: this.state.user_id,
            type: "PARKING",
            completed: false
        }))
            .then((transactions) => {
                transactions = transactions.data.getTransactions.transactions;

                this.setState({
                    currentGarage: transactions[0],
                    loading: false
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Aktiver Parkvorgang
                    <div className="card-header-actions" />
                </CardHeader>
                <CardBody>
                    <FormGroup row className="mb-0">
                        <Col xs="12" md="12">
                            {
                                this.state.loading ?
                                    <div className="sk-rotating-plane"/> :
                                    !this.state.currentGarage ?
                                        <Input type="text" id="activeParking"
                                               name="activeParking"
                                               value="Keiner"
                                               disabled
                                        />
                                        :
                                    <Link to={"/garages/" + this.state.currentGarage.transactionGarage.id}>
                                        <Input type="text" id="activeParking"
                                               name="activeParking"
                                               value={this.state.currentGarage.transactionGarage.name}
                                               disabled
                                        />
                                    </Link>
                            }
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
        );
    }
}

export default ActiveParking;
