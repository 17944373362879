import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import {Link} from "react-router-dom";

import logo from '../../assets/img/brand/logo.svg';
import userService from "../../services/userService";

class ForgotPassword extends Component {

    constructor(props) {
        super(props);

        this.resetPassword = this.resetPassword.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            open: false,
            email: ''
        }
    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    toggle() {
        this.setState({
            open: !this.state.open
        })
    }

    renderModal() {
        return (
            <Modal toggle={this.toggle} isOpen={this.state.open} className={'modal-primary' + this.props.className}>
                <ModalHeader toggle={this.toggle}>Successfully sent</ModalHeader>
                <ModalBody>
                    <p>A confirmation email will be sent to this email address, if such account exists.</p>
                </ModalBody>
            </Modal>
        )
    }

    resetPassword() {
        userService.resetCockpitPassword({
            email: this.state.email
        })
            .then(() => {
                this.setState({
                    open: !this.state.open
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    componentDidMount() {
        document.title = "PAYUCA Cockpit | Passwort vergessen";
    }

    render() {
        return (
            <div className="app appLogin flex-row align-items-center animated fadeIn">
                {this.renderModal()};
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <img src={logo} style={{width: 200}} className="p-3" alt="PAYUCA Logo"/>
                            <Card className="p-4">
                                <CardBody>
                                    <h1>Passwort vergessen</h1>
                                    <p className="text-muted">Setze dein Passwort zurück</p>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-user"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" name="email" value={this.state.email} onChange={this.handleChange} placeholder="E-Mail-Adresse" />
                                    </InputGroup>
                                    <Row>
                                        <Col lg="12" xs="12">
                                            <Button color="primary" className="px-4" onClick={this.resetPassword}>Passwort zurücksetzen</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ForgotPassword;
