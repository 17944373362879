import React, { Component } from 'react';
import {
Button,
Col,
Row
} from 'reactstrap';
import InventoryItem from "./InventoryItem";



export default class InventoryItemList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: this.sanitizeItems(this.props.items),
        };

        this.handleSaveInventory = this.handleSaveInventory.bind(this);
        this.addItem = this.addItem.bind(this);
        this.deleteInventoryEntry = this.deleteInventoryEntry.bind(this);
        this.createInventoryItem = this.createInventoryItem.bind(this);
        this.updateInventoryItem = this.updateInventoryItem.bind(this)
    }

    handleSaveInventory(data) {

        for (let property in data) {
            if (data[property] === '') {
                data[property] = null;
            }
        }

        if (data.id === null) {
            this.createInventoryItem(data)
        } else {
            this.updateInventoryItem(data)
        }
    }

    sanitizeItems(entries) {
        for (let inventoryEntry in entries ) {
            for (let property in entries[inventoryEntry]) {
                if (entries[inventoryEntry][property] === null || entries[inventoryEntry][property] === "null") {
                    entries[inventoryEntry][property] = '';
                }
            }
        }

        return entries;
    }


    deleteInventoryEntry(data) {
        this.props.deleteInventoryEntry(data);
    }

    createInventoryItem(data) {
        this.props.createInventoryItem(data);
    }

    updateInventoryItem(data) {
        this.props.updateInventoryItem(data);
    }

    addItem() {
        this.props.addItem();
    }

    render() {
        return (
            <Row className="row-grid animated fadeIn pb-3">
                { this.props.items.map((item, i) => {
                    return <InventoryItem handleSaveInventory={this.handleSaveInventory} deleteInventoryEntry={this.deleteInventoryEntry} data={item} key={item.id || "newItem"} index={i} />
                }) }
                <Col xs="12" sm="12" md="12" lg="3">
                    <Button className="btn btn-lg btn-block" onClick={this.addItem} disabled={this.props.addItemDisabled}>Artikel hinzufügen</Button>
                </Col>
            </Row>
        )
    }
}
