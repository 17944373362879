import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Collapse
} from "reactstrap";

import Select from "react-select";

import "react-select/dist/react-select.min.css";
import "react-toastify/dist/ReactToastify.css";

import garageService from "../../../services/garageService";
import getGarageById from "../../../queries/getGarageById";

class Access extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGarage: "",
      selectedGateway: "",
      loadingAccess: false,
      collapse: false
    };

    this.saveChanges = this.saveChanges.bind(this);
    this.saveGateway = this.saveGateway.bind(this);
    this.toggle = this.toggle.bind(this);
    this.access = this.access.bind(this);
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  saveChanges(selectedGarage) {
    this.setState(
      {
        selectedGarage,
        selectedGateway: ""
      },
      () => {
        if (this.state.selectedGarage) {
          garageService
            .getService(getGarageById({ id: this.state.selectedGarage.value }))
            .then(garage => {
              let gateways = [];

              if (garage.data.getGarage.garage.garageGateways) {
                gateways = garage.data.getGarage.garage.garageGateways;
                this.setState({
                  gateways: gateways.map(gateway => {
                    return { value: gateway.id, label: gateway.name };
                  })
                });
              }

              if (garage.data.getGarage.garage.garage_gateways && garage.data.getGarage.garage.garage_gateways.length > 0) {
                gateways = garage.data.getGarage.garage.garage_gateways;
                this.setState({
                  gateways: gateways.map(gateway => {
                    return { value: gateway.gwid, label: gateway.name || gateway.gwid, new: true};
                  })
                });
              }
              
              if (garage.data.getGarage.garage.payuca_connect_gateways && garage.data.getGarage.garage.payuca_connect_gateways.length > 0) {
                gateways = garage.data.getGarage.garage.payuca_connect_gateways;
                this.setState({
                  gateways: gateways.map(gateway => {
                    return { value: gateway.gwid, label: gateway.name || gateway.gwid, new: true};
                  })
                });
              }
            })
            .catch(err => {
              let errors = err.networkError.result.errors;
              this.props.unsuccessful(
                errors.map(error => error.message).join(" ")
              );
            });
        }
      }
    );
  }

  saveGateway(selectedGateway) {
    this.setState({ selectedGateway });
  }

  access() {
    this.setState({
      loadingAccess: true
    });

    let data = {
      userId: this.props.user.id,
      garage_id: `"${this.state.selectedGarage.value}"`
    };

    if(this.state.selectedGateway.new){
        data['gwid'] = `"${this.state.selectedGateway.value}"`;
        data['new'] = this.state.selectedGateway.new;
    }else{
        data['gateway_id'] = this.state.selectedGateway.value;
    }

    this.props.access(data);
  }

  render() {
    return (
      <Card className="border-success">
        <CardHeader
          onClick={this.toggle}
          style={{ cursor: !this.state.collapse && "pointer" }}
        >
          <i className="icon-login"></i>
          <strong> Zugang</strong>
        </CardHeader>
        <Collapse isOpen={this.state.collapse}>
          <CardBody>
            {this.props.loading ? (
              <div className="sk-rotating-plane" />
            ) : (
              <Form action="" method="post" className="form-horizontal">
                <FormGroup row>
                  <Col md="4">
                    <Label>
                      <strong>Garage</strong>
                    </Label>
                  </Col>
                  <Col xs="12" md="8">
                    <Select
                      name="selectedGarage"
                      value={this.state.selectedGarage}
                      options={this.props.garages}
                      onChange={this.saveChanges}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="4">
                    <Label>
                      <strong>Gateway</strong>
                    </Label>
                  </Col>
                  <Col xs="12" md="8">
                    <Select
                      name="selectedGateway"
                      value={this.state.selectedGateway}
                      options={this.state.gateways}
                      onChange={this.saveGateway}
                    />
                  </Col>
                </FormGroup>
              </Form>
            )}
          </CardBody>
          <CardFooter>
            <Button
              type="submit"
              size="sm"
              color="primary"
              className={"mr-1"}
              onClick={this.access}
              disabled={!this.state.selectedGateway || this.props.loadingAccess}
            >
              <i className="fa fa-dot-circle-o"></i>
              {this.props.loadingAccess ? "Laden..." : "Tür/Tor öffnen"}
            </Button>
          </CardFooter>
        </Collapse>
      </Card>
    );
  }
}

export default Access;
