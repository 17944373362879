import gql from "graphql-tag";
export default function(params){
    return gql`
        mutation {
            rebootNode(
                id: "${params.id}"
                component: ${params.component || null}
            ) {
                errors
                gateway {
                    id
                }
            }
        }`;
}
