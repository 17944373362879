import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {Link} from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import companyService from "../../services/companyService";
import CustomSearchField from "../../components/CustomSearchField";

class Companies extends Component {

    static ToCompany(cell, row) {
        const id = row.id;
        return <Link to={`/companies/${id}`}> {cell} </Link>;
    }

    static ToWebsite(cell) {
        return <a href={cell}> {cell} </a>;
    }

    static ToPhone(cell) {
        return <a href={"tel:"+cell}> {cell} </a>;
    }

    static CountryTransform(cell) {
        if (cell === 'AT') {
            return 'Österreich'
        }

        if (cell === 'DE') {
            return 'Deutschland'
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            companies: [],
            page: 1,
            totalSize: 0
        };

        this.typingTimer = null;
        this.typingInterval = 500;

        this.onSearch = this.onSearch.bind(this);
        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);
        this.getClearButton = this.getClearButton.bind(this);
    }

    componentDidMount() {

        companyService.getCompanies(this.state.page)
            .then((response) => {
                if(response.data.hasOwnProperty("results")) {
                    this.setState({
                        companies: response.data.results,
                        loading: false,
                        totalSize: response.data.count
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        document.title = "PAYUCA Firmen";
    }


    get tableOptions(){
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            searchField: this.getSearchField,
            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,

            page: this.state.page,
            sizePerPage: 10

        };
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                companies: [],
                searchQuery: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchCompanies, this.typingInterval)
            })
        }
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.searchQuery !== "") {
            this.setState({
                page: 1,
                loading: true,
                companies: [],
                searchQuery: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchCompanies, this.typingInterval)
            })
        }
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "No companies found"
    }

    fetchCompanies() {
        companyService.getCompanies(this.state.page, this.state.searchQuery)
            .then((resp) => {
                this.setState({
                    loading: false,
                    companies:  resp.data.results || [],
                    totalSize: resp.data.count
                });

            })
            .catch((err) => {
                console.log(err);
            });
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            page
        }, this.fetchCompanies)
    }

    render() {

        return (
            <div className="animated">
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-globe" color="info" header="PAYUCA Firmen" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Link to="/companies/addCompany" className="noDecoLink">
                            <Widget
                                icon="icon-plus"
                                color="success"
                                header="Firma hinzufügen"
                                invert
                                style={{cursor: "pointer"}}
                            />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <BootstrapTable data={this.state.companies} version="4" striped remote fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions} search>
                                    <TableHeaderColumn width='150' isKey={true} dataField="name" dataFormat={Companies.ToCompany}>Firmenname</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="website" dataFormat={Companies.ToWebsite}>Website</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="phone_number" dataFormat={Companies.ToPhone}>Telefonnummer</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="city" dataFormat={Companies.ToCompany}>Stadt</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="country" dataFormat={Companies.CountryTransform}>Land</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="invoice_email">Rechnungs-E-Mail</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="iban">IBAN</TableHeaderColumn>
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Companies;
