import gql from "graphql-tag";


export default function query(params) {

    return gql`
        query {
            getLogs(object_id: "${params.object_id}", object_content_type: "${params.object_content_type}", action: "${params.action || ""}" first: ${params.first}, offset: ${params.offset}) {
                errors
                count
                logs {
                    resource_id
                    created_at
                    resource_content_type
                    id
                    actor_email
                    action
                    object_content_type
                    old_value
                    new_value
                }
            }
        }`;
}


