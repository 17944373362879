import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label
} from 'reactstrap';


export default class UserBasicData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            editingPersonalDisabled: true
        };

        this.togglePersonal = this.togglePersonal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateUser = this.updateUser.bind(this);

    }

    componentWillReceiveProps(nextProps){
        if(nextProps.user && this.props.user !== nextProps.user){
            this.setState({
                user: nextProps.user
            });
        }
    }

    togglePersonal() {
        this.setState({
            editingPersonalDisabled: !this.state.editingPersonalDisabled,
        });
    }

    handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        let user = this.state.user;

        if(target.type === "checkbox"){
            value = target.checked;
        }

        user[name] = value;

        this.setState({
            user: user,
        })
    }

    updateUser() {
        this.props.updateUser(this.state.user)
            .then(() => {
                this.setState({
                    editingPersonalDisabled: true,
                })
            })
            .catch(() => {
                this.setState({
                    editingPersonalDisabled: false,
                })
            });
    }

    render() {
        console.log(this.state.user)
        return (
            <Card>
                <CardHeader>
                    Persönliche Daten
                    <div className="card-header-actions">
                        {
                            !this.state.editingPersonalDisabled
                                ?
                                <div>
                                    <Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateUser}>
                                        <i className="fa fa-dot-circle-o"></i> Speichern
                                    </Button>
                                    <Button type="reset" size="sm" color="danger" onClick={this.togglePersonal}>
                                        <i className="fa fa-ban"></i> Abbrechen
                                    </Button>
                                </div>
                                :
                                <a className="card-header-action btn btn-setting" style={{cursor: this.state.user.inactive_reason ? "not-allowed" : "pointer"}} onClick={this.state.user.inactive_reason ? null : this.togglePersonal}>
                                    <i className="icon-pencil"></i>
                                </a>
                        }
                    </div>
                </CardHeader>
                <CardBody className="pb-0">
                    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="gender">Geschlecht</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="select" name="gender" id="gender" value={this.state.user.gender} disabled={this.state.editingPersonalDisabled} onChange={this.handleChange}>
                                    <option value="M">Männlich</option>
                                    <option value="F">Weiblich</option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="pre_title">Vortitel</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="text" id="pre_title" name="pre_title" value={!this.state.user.pre_title ? "k.A." : this.state.user.pre_title} disabled={this.state.editingPersonalDisabled} onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="first_name">Vorname</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="text" id="first_name" name="first_name" value={this.state.user.first_name} disabled={this.state.editingPersonalDisabled} onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="last_name">Nachname</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="text" id="last_name" name="last_name" value={this.state.user.last_name} disabled={this.state.editingPersonalDisabled} onChange={this.handleChange}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="after_title">Nachtitel</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="text" id="after_title" name="after_title" value={!this.state.user.after_title ? "k.A." : this.state.user.after_title} disabled={this.state.editingPersonalDisabled} onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="licence_plates">Kfz-Kennzeichen</Label>
                            </Col>
                            <Col xs="12" md="9">
                                {   !this.state.user.licence_plates[0] ?
                                    <Input type="text" value="k.A." disabled />
                                    :
                                    this.state.user.licence_plates.map((licence_plate, i) => {
                                        return <Input type="text" key={i} id={licence_plate.id} name="licence_plates" value={licence_plate.plate_number} disabled onChange={this.handleChange} className="mb-1"/>
                                    })
                                }
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="is_internal">Benutzertyp</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <FormGroup check inline>
                                    <Input type="select" name="internal" id="internal" value={this.state.user.internal} disabled={this.state.editingPersonalDisabled} onChange={this.handleChange}>
                                        <option value={"none" || null}>-</option>
                                        <option value="user">Interner Benutzer*</option>
                                        <option value="tester">Interner Tester**</option>
                                    </Input>
                                </FormGroup>
                                {/*<FormGroup check inline className={"userType"}>
                                    <Input name="is_internal" type="checkbox" checked={this.state.user.is_internal} value={this.state.user.internal} className="form-check-input ml-2" disabled={this.state.editingPersonalDisabled} onChange={this.handleInternalUser}/>
                                    <Label className="form-check-label" check htmlFor="is_internal">Interner Benutzer</Label>
                                </FormGroup>
                                <FormGroup check inline className={"userType"}>
                                    <Input name="is_external_tester" type="checkbox" checked={this.state.user.is_external_tester} value={this.state.user.is_external_tester} className="form-check-input ml-2" disabled={this.state.editingPersonalDisabled} onChange={this.handleChange}/>
                                    <Label className="form-check-label" check htmlFor="is_external_tester">Interner Tester</Label>
                                </FormGroup>*/}
                                <FormGroup check inline>
                                    <Input name="is_longterm_parker" type="checkbox" checked={this.state.user.is_longterm_parker} value={this.state.user.is_longterm_parker} className="form-check-input ml-2" disabled={this.state.editingPersonalDisabled} onChange={this.handleChange}/>
                                    <Label className="form-check-label" check htmlFor="is_longterm_parker">Langzeitparker***</Label>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                        {this.state.user.inactive_reason &&
                            <FormGroup row>
                                <Col md="3">
                                    <Label htmlFor="is_internal">Begründung</Label>
                                </Col>
                                <Col xs="12" md="9" className="form-check-flex">
                                    <Input disabled value={this.state.user.inactive_reason} className="form-control" disabled/>
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="is_internal">Benutzernotiz</Label>
                            </Col>
                            <Col xs="12" md="9">
                            <Input type="textarea" rows="4" name="note" id="note" style={{whiteSpace: 'pre-line'}}
                                   value={!this.state.user.note ? '' : this.state.user.note}
                                   disabled={this.state.editingPersonalDisabled} onChange={this.handleChange}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <small>
                                    <strong>Interner Benutzer*:</strong> Ist ein firmeninterner App-Benutzer.
                                </small>
                                <br />
                                <small>
                                    <strong>Interner Tester**:</strong> Ist ein firmeninterner App-Benutzer der auch die Umgebung (environment) in der App ändern kann.
                                </small>
                                <br />
                                <small>
                                    <strong>Langzeitparker***:</strong> Ist ein App-Benutzer der ein spezielles (Dauerpark)-Abkommen mit PAYUCA hat.
                                </small>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        )
    }

}
