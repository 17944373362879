import gql from "graphql-tag";


export default function(params){
    return gql`
        mutation {
            writeNFC(user_id: ${params.userId}, id: "${params.id}") {
                errors
                NFC {
                  nfc_tag
                  id
                }
            }
        }`;
}
