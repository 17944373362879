import gql from "graphql-tag";


export default function(params){
    return gql`
            mutation {
                deleteParkingSpace(
                    id: "${params.id}"
                ) {
                    errors
                    parkingSpace {
                        id
                    }
                }
            }`;
}