import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
    AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';

import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import User from "../../views/Users/User";
import Users from "../../views/Users/Users";
import AddGarage from "../../views/Garages/AddGarage";
import Garages from "../../views/Garages/Garages";
import Garage from "../../views/Garages/Garage";
import Coupon from "../../views/Coupons/Coupon";
import Coupons from "../../views/Coupons/Coupons";
import addCoupon from "../../views/Coupons/addCoupon";
import Settings from "../../views/Settings/Settings";
import Contact from "../../views/Contacts/Contact";
import AddContact from "../../views/Contacts/AddContact";
import Contacts from "../../views/Contacts/Contacts";
import Company from "../../views/Companies/Company";
import AddCompany from "../../views/Companies/AddCompany";
import Companies from "../../views/Companies/Companies";
import Gateway from "../../views/Gateways/Gateway";
import Gateways from "../../views/Gateways/Gateways";
import Gateways_2 from "../../views/Gateways/Gateways_2.0";
import OAuthApps from "../../views/OAuthApps/OAuthApps";
import addOAuthApp from "../../views/OAuthApps/addOAuthApp";
import OAuthApp from "../../views/OAuthApps/OAuthApp";
import Stats from "../../views/Stats/Stats";
import GarageList from "../../views/Migration/GarageList";
import GarageOwner from "../../views/GarageOwners/GarageOwner";
import AddGarageOwner from "../../views/GarageOwners/AddGarageOwner";
import GarageOwners from "../../views/GarageOwners/GarageOwners";
import Gateway_2 from "../../views/Gateways/Gateway_2.0";
import VersionBlock from '../../views/VersionBlock/VersionBlock';
import AddVersionBlock from '../../views/VersionBlock/AddVersionBlock';
import UpdateVersionBlock from '../../views/VersionBlock/UpdateVersionBlock';
import PayucaConnectGateways from '../../views/Gateways/Payuca_Connect_Gateways';
import PayucaConnectGateway from '../../views/Gateways/Payuca_Connect_Gateway';

function PrivateRoute ({component: Component, authed, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => !!localStorage.getItem('Access-Token')
                ? <Component {...props} />
                : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
        />
    )
}

class DefaultLayout extends Component {

    render() {
        return (
            <div className="app">
                <AppHeader fixed>
                    <DefaultHeader history={this.props.history} />
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                        <AppSidebarForm />
                        <AppSidebarNav navConfig={navigation} {...this.props} />
                        <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <AppBreadcrumb appRoutes={routes}/>
                        <Container fluid>
                            <Switch>
                                <PrivateRoute path='/users/:id' name='App-Kunde' component={User} />
                                <PrivateRoute path='/users' name='App-Kunden' component={Users} />
                                <PrivateRoute path='/garages/addGarage' name='Garage hinzufügen' component={AddGarage} />
                                <PrivateRoute path='/garages/:id' name='Garage' component={Garage} />
                                <PrivateRoute path='/garages' name='Garagen' component={Garages} />
                                <PrivateRoute path='/coupons/addCoupon' name='Gutschein hinzufügen' component={addCoupon} />
                                <PrivateRoute path='/coupons/:id' name='Gutschein' component={Coupon} />
                                <PrivateRoute path='/coupons' name='Gutscheine' component={Coupons} />
                                <PrivateRoute path='/settings' name='Einstellungen' component={Settings} />
                                <PrivateRoute path='/contacts/addContact' name='Kontaktperson hinzufügen' component={AddContact} />
                                <PrivateRoute path='/contacts/:id' name='Kontaktperson' component={Contact} />
                                <PrivateRoute path='/contacts' name='Kontaktpersonen' component={Contacts} />
                                <PrivateRoute path='/companies/addCompany' name='Firma hinzufügen' component={AddCompany} />
                                <PrivateRoute path='/companies/:id' name='Firma' component={Company} />
                                <PrivateRoute path='/companies' name='Firmen' component={Companies} />
                                <PrivateRoute path='/gateways/:id' name='Gateway' component={Gateway} />
                                <PrivateRoute path='/gateways2/:gwid' name='Gateway 2.0' component={Gateway_2} />
                                <PrivateRoute path='/payuca-connect/:id' name='PC Gateway' component={PayucaConnectGateway} />
                                <PrivateRoute path='/gateways' name='Gateways' component={Gateways} />
                                <PrivateRoute path='/gateways2' name='Gateways 2.0' component={Gateways_2} />
                                <PrivateRoute path='/payuca-connect' name='PC Gateways' component={PayucaConnectGateways} />
                                <PrivateRoute path='/oauthapps/addApp' name='OAuth App hinzufügen' component={addOAuthApp} />
                                <PrivateRoute path='/oauthapps/:id' name='OAuth App' component={OAuthApp} />
                                <PrivateRoute path='/oauthapps' name='OAuth Apps' component={OAuthApps} />
                                <PrivateRoute path='/stats' name='Stats' component={Stats} />
                                <PrivateRoute path='/migration' name='Migration' component={GarageList} />
                                <PrivateRoute path='/addgarageowner' name='Garagenbesitzer hinzufügen' component={AddGarageOwner} />
                                <PrivateRoute path='/garageowners/:id' name='Garagenbesitzer' component={GarageOwner} />
                                <PrivateRoute path='/garageowners' name='Garagenbesitzer' component={GarageOwners} />
                                <PrivateRoute path='/version-block/add' name='Add version block' component={AddVersionBlock} />
                                <PrivateRoute path='/version-block/:id' name='Add version block' component={UpdateVersionBlock} />
                                <PrivateRoute path='/version-block' name='Version Block' component={VersionBlock} />
                                <Redirect from="/" to="/garages" />
                            </Switch>
                        </Container>
                    </main>
                </div>
                <AppFooter>
                    <DefaultFooter />
                </AppFooter>
            </div>
        );
    }
}

export default DefaultLayout;
