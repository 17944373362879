import React, { Component } from 'react';

import {
    Button,
    Card,
    CardBody,
    Col,
    Row
} from 'reactstrap';

import ParkingSpace from "./ParkingSpace";


export default class ParkingSpaceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inactiveSpaces: this.props.inactiveSpaces,
            totalSpaces: this.props.totalSpaces,
            takenSpaces: this.props.takenSpaces,
            spaces: this.sanitizeSpaces(this.props.spaces)
        };

        this.addSpace = this.addSpace.bind(this);
        this.createParkingSpace = this.createParkingSpace.bind(this);
        this.updateParkingSpace = this.updateParkingSpace.bind(this);
        this.deleteParkingSpace = this.deleteParkingSpace.bind(this);
        this.handleSave = this.handleSave.bind(this)
    }

    sanitizeSpaces(spaces) {
        for (let parkingSpace in spaces ) {

            for (let property in spaces[parkingSpace]) {
                if (spaces[parkingSpace][property] === null || spaces[parkingSpace][property] === "null") {
                    spaces[parkingSpace][property] = '';
                }
            }
        }

        return spaces;
    }

    addSpace() {
        this.props.addSpace();
    }

    createParkingSpace(data) {
        this.props.createParkingSpace(data);
    }

    updateParkingSpace(data) {
        this.props.updateParkingSpace(data);
    }

    deleteParkingSpace(data) {
        this.props.deleteParkingSpace(data);
    }

    handleSave(data) {
        // for creating and updating parking space

        for (let property in data) {
            if (data[property] === '') {
                data[property] = null;
            }
        }

        if (data.id === null) {
            this.createParkingSpace(data)
        } else {
            this.updateParkingSpace(data)
        }
    }


    render() {
        return (
            <Row className="row-grid animated fadeIn">
                <Col lg="12">
                    <Row className="row-grid">
                        <Col xs="12" sm="6" md="3">
                            <Card className="text-white bg-success text-center">
                                <CardBody>
                                    <h4>{this.props.totalSpaces == null && this.props.takenSpaces == null ? "k.A." : this.props.totalSpaces - this.props.takenSpaces}</h4>
                                    <strong>freie Plätze</strong>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="12" sm="6" md="3">
                            <Card className="text-white bg-warning text-center">
                                <CardBody>
                                    <h4>{this.props.takenSpaces == null ? "k.A." : this.props.takenSpaces}</h4>
                                    <strong>besetzte Plätze</strong>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="12" sm="6" md="3">
                            <Card className="text-white bg-danger text-center">
                                <CardBody>
                                    <h4>{this.props.inactiveSpaces == null ? "k.A." : this.props.inactiveSpaces}</h4>
                                    <strong>inaktive Plätze</strong>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="12" sm="6" md="3">
                            <Card className="text-white bg-info text-center">
                                <CardBody>
                                    <h4>{this.props.totalSpaces == null ? "k.A." : this.props.totalSpaces + this.props.inactiveSpaces}</h4>
                                    <strong>Plätze insgesamt</strong>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="row-grid pb-3">
                        { this.props.spaces.sort((a,b) => b.active - a.active).map((space, i) => {
                            return <ParkingSpace handleSave={this.handleSave} deleteParkingSpace={this.deleteParkingSpace} garage={this.state} data={space} key={space.id || "newPlace"} index={i} />
                        }) }
                        <Col xs="12" sm="12" md="12" lg="3">
                            <Button className="btn btn-lg btn-block pb-1" onClick={this.addSpace} disabled={this.props.addSpaceDisabled}>Parkplatz hinzufügen</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
