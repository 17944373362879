import React, { Component } from 'react';
import {Route, Switch, Router as BrowserRouter } from 'react-router-dom';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './css/style.css'

// Containers
import { DefaultLayout } from './components';
import Login from './views/Login/Login';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import Page404 from "./views/Page404/Page404";
import SystemOffline from "./views/SystemOffline/SystemOffline";

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// import { renderRoutes } from 'react-router-config';


class App extends Component {

    render() {
        return (
            <BrowserRouter history={history}>
                <Switch>
                    <Route exact path="/forgotPassword" name="Passwort vergessen" component={ForgotPassword} />
                    <Route exact path="/404" name="Nicht gefunden" component={Page404} />
                    <Route exact path="/systemOffline" name="System Offline" component={SystemOffline} />
                    <Route exact path="/login" name="Anmelden" component={Login} />
                    <Route path='/' name="Home" component={DefaultLayout} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
export {
    history
}
