import React, {Component} from 'react';
import {Col, Row, Card, CardBody, CardHeader} from 'reactstrap';
import _ from "lodash";

import Access from './Access/Access';
import UpdateCredits from "./UpdateCredits/UpdateCredits";
import StartParking from "./StartParking/StartParking";
import EndParking from "./EndParking/EndParking";
import JiraTicket from "./JiraTicket/JiraTicket";
import garageService from "../../services/garageService";
import getGaragesQuery from "../../queries/getGarages";
import getTransactionsQuery from "../../queries/getTransactions";
import adminStartParking from "../../mutations/adminStartParking";
import adminEndParking from "../../mutations/adminEndParking";
import adminAccess from "../../mutations/adminAccess";
import supportService from "../../services/supportService";
import privilegeService from "../../services/privilegeService";
import getGarageById from "../../queries/getGarageById";

class SupportWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            currentParking: null,
            loadingGarages: true,
            loadingCurrentParking: true,
            loadingStart: false,
            loadingEnd: false,
            loadingAccess: false,
            deviceDataLoading: true,
            deviceData: {},
            jiraTickets: [],
            loadingJiraTickets: true,
            privileges: [],
            gateways: []
        };

        this.startParking = this.startParking.bind(this);
        this.endParking = this.endParking.bind(this);
        this.access = this.access.bind(this);
        this.getDeviceData = this.getDeviceData.bind(this);
        this.getJiraTickets = this.getJiraTickets.bind(this);
        this.getUserPrivileges = this.getUserPrivileges.bind(this);
        this.getGarageGateways = this.getGarageGateways.bind(this);
    }

    componentDidMount() {
        this.getDeviceData(this.props.user.id)
        this.getJiraTickets(this.props.user.id)
        this.getUserPrivileges(this.props.user.id)
        garageService.getService(getGaragesQuery())
            .then((garages) => {
                this.setState({
                    garages: garages.data.getGarages.garages.map((garage) => {
                        return {value: garage.id, label: garage.name}
                    }),
                    loadingGarages: false,
                });
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

        garageService.getService(getTransactionsQuery({
            user_id: this.state.user.id,
            completed: false,
            type: "PARKING"
        }))
            .then((response) => {

                let transactions = response.data.getTransactions.transactions;
                let currentParking = transactions[0];

                this.setState({
                    currentParking: currentParking || null,
                    loadingCurrentParking: false
                })

                if (transactions.length > 0) {
                    this.getGarageGateways(currentParking.transactionGarage.id)
                }
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

    }

    getGarageGateways(id) {
        garageService
            .getService(getGarageById({id}))
            .then(garage => {
                let gateways = [];
                const criteria = ['CHECK_OUT', 'CHECK_IN_OUT'];

                if (garage.data.getGarage.garage.garageGateways) {
                    gateways = garage.data.getGarage.garage.garageGateways;
                    this.setState({
                        gateways: gateways.filter(x => criteria.includes(x.type)).map(gateway => {
                            return { value: gateway.gwid, label: gateway.name };
                        })
                    });
                }

                if (garage.data.getGarage.garage.garage_gateways && garage.data.getGarage.garage.garage_gateways.length > 0) {
                    gateways = garage.data.getGarage.garage.garage_gateways;
                    this.setState({
                        gateways: gateways.filter(x => criteria.includes(x.type)).map(gateway => {
                            return { value: gateway.gwid, label: gateway.name || gateway.gwid, new: true};
                        })
                    });
                }

                if (garage.data.getGarage.garage.payuca_connect_gateways && garage.data.getGarage.garage.payuca_connect_gateways.length > 0) {
                    gateways = garage.data.getGarage.garage.payuca_connect_gateways;
                    this.setState({
                        gateways: gateways.filter(x => criteria.includes(x.type)).map(gateway => {
                            return { value: gateway.gwid, label: gateway.name || gateway.gwid, new: true};
                        })
                    });
                }
            })
            .catch(err => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(
                    errors.map(error => error.message).join(" ")
                );
            });
    }

    getUserPrivileges(id) {
        privilegeService.getForUser(id)
            .then((resp) => {
                this.setState({
                    privileges: resp.data
                })
            }).catch((err) => {
            this.unsuccessful(err);
        });
    }

    getDeviceData(id) {
        supportService.getDeviceData(id)
            .then((res) => {
                this.setState({
                    deviceDataLoading: false,
                    deviceData: res.data.userData
                })
            })
            .catch((err) => {
                this.setState({
                    deviceDataLoading: false,
                })
                console.log(err)
            })
    }

    getJiraTickets(id) {
        supportService.getJiraTickets(id)
            .then((res) => {
                let data = res.data.issues;
                let issues = data.map((item) => {
                    return {
                        assignee: item.fields.assignee && item.fields.assignee.displayName ? item.fields.assignee.displayName : 'No Assignee',
                        priority: item.fields.priority.name,
                        status: item.fields.status.name,
                        summary: item.fields.summary,
                        key: item.key
                    }
                })
                this.setState({
                    jiraTickets: issues,
                    loadingJiraTickets: false
                })
            })
            .catch((err) => {
                console.log('JIRA ERROR: ', err);
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors);
            })
    }

    access(data) {
        this.setState({
            loadingAccess: true
        });

        garageService.createService(adminAccess(data))
            .then((response) => {
                this.props.success();
                this.setState({
                    selectedGarage: '',
                    selectedGateway: '',
                    loadingAccess: false
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;

                this.props.unsuccessful(errors.map((error) => error.message.errors).join(" "));
                this.setState({
                    loadingAccess: false
                });
            });
    }

    startParking(data) {

        this.setState({
            loadingStart: true
        });

        garageService.createService(adminStartParking(data))
            .then((response) => {
                this.props.success();
                this.setState({
                    currentParking: response.data.adminStartParking.transaction,
                    loadingStart: false
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                console.log(errors);
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
                this.setState({
                    loadingStart: false
                })
            });
    }

    endParking(data) {

        this.setState({
            loadingEnd: true
        });
        garageService.createService(adminEndParking(data))
            .then((response) => {
                this.props.success();
                this.setState({
                    currentParking: null,
                    loadingEnd: false
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
                this.setState({
                    loadingEnd: false
                })
            });
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card className="border-success">
                            <CardHeader>
                                <i className="icon-support"></i><strong> Support Widget</strong>
                            </CardHeader>
                            <CardBody className="pb-0">
                                <Row>
                                    <Col>
                                        <p>Gerät (Amplitude): {_.isEmpty(this.state.deviceData) ? 'Lädt...' : this.state.deviceData.device_type}</p>
                                    </Col>
                                    <Col>
                                        <p>Betriebssystem (Backend): {(_.isEmpty(this.state.user) && !this.state.user.app_os_version) ? 'k.A.' : this.state.user.app_os_version}</p>
                                    </Col>
                                    <Col>
                                        <p>App-Version (Backend): {(_.isEmpty(this.state.user) && !this.state.user.app_version) ? 'k.A.' : this.state.user.app_version}</p>
                                    </Col>
                                    <Col>
                                        <p>App-Sprache (Backend): {(_.isEmpty(this.state.user) && !this.state.user.language) ? 'k.A.' : this.state.deviceData.language}</p>
                                    </Col>
                                    <Col>
                                        <p>Amplitude ID (Amplitude): {_.isEmpty(this.state.deviceData) ? 'Lädt...' : this.state.deviceData.canonical_amplitude_id}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <StartParking
                                            user={this.state.user}
                                            garages={this.state.garages}
                                            currentParking={this.state.currentParking}
                                            loading={this.state.loadingGarages || this.state.loadingCurrentParking}
                                            loadingStart={this.state.loadingStart}
                                            startParking={this.startParking}
                                            privileges={this.state.privileges}
                                        />
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <EndParking
                                            user={this.state.user}
                                            currentParking={this.state.currentParking}
                                            loading={this.state.loadingCurrentParking}
                                            loadingEnd={this.state.loadingEnd}
                                            endParking={this.endParking}
                                            privileges={this.state.privileges}
                                            gateways={this.state.gateways}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <Access
                                            garages={this.state.garages}
                                            user={this.state.user}
                                            loading={this.state.loadingAccess}
                                            success={this.props.success}
                                            unsuccesful={this.props.unsuccessful}
                                            access={this.access}
                                        />
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <UpdateCredits user={this.state.user} uploadCredit={this.props.uploadCredit}/>
                                    </Col>
                                </Row>
                                <Row>
                                    {!this.state.loadingJiraTickets ? this.state.jiraTickets.map((item) => {
                                        return (
                                            <Col lg={4}>
                                                <JiraTicket data={item} key={item.key}
                                                />
                                            </Col>
                                        )
                                    }) : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SupportWidget;
