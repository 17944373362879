import gql from "graphql-tag";


export default function query(params) {
    return gql`
        {
            getTransactions(
                user: ${params.user_id || null}
                first: ${params.limit || null}
                offset: ${params.offset || null}
                
                type: "${params.type || ""}"
                
                garageName: "${params.garageName || ""}"
                garage_id: "${params.garage_id || ""}"
                
                dateFrom: "${params.dateFrom || ""}"
                dateTo: "${params.dateTo || ""}"
                
                completed: ${params.completed === true ? true : (params.completed === false ? false : null)}
            ) {
                errors
                count
                transactions {
                    id
                    type
                    transactionGarage {
                        id
                        name
                    }
                    start_time
                    end_time
                    total_price
                    completed
                    user_licence_plate
                    user_email
                    user
                    extra_info {
                        nfc_parking
                        admin_start_parking
                    }
                }
            }
        }`;
}
