import gql from "graphql-tag";

export default function(params = null) {
    if (params != null) {
        let queryParamsString = Object.keys(params)
            .filter(key => {
                return (params[key] || params[key] === false) && params[key] !== '""';
            })
            .map(key => {
                return `${key}:${params[key]}`;
            })
            .join(",");

        return gql`
            query {
                getPCGateways(
                ${queryParamsString}
                ) {
                errors
                count
                gateways{
                    id
                    device_id
                    garage_id
                    master_id
                    type
                    gwid
                    name
                    node_number
                    relay_timings
                    created_at
                    updated_at
                    health_status {
                        connection
                    }
                    payuca_connect_garage {
                        name
                        id
                        state
                        echarger
                    }
                }
            }
            }`;
    }
    return gql`
        query {
            getPCGateways {
                errors
                count
                gwid
                gateways {
                    id
                    device_id
                    type
                }
            }
        }
    `;
}
