import React, {Component} from 'react';
import {Col, Row, Card, CardBody, CardHeader, FormGroup, Label, Form, Button, Badge} from 'reactstrap';
import {Bar, Line} from 'react-chartjs-2';
import {CustomTooltips} from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { saveAs } from 'file-saver';

import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';

import Widget from "../../components/Widget/Widget";
import DateTimePicker from "react-datetime-picker";
import AsyncSelect from 'react-select/lib/Async';
import Legend from "./Legend";

import datetimeFormatter from '../../utils/datetimeFormatter';
import garageService from "../../services/garageService";
import statsService from "../../services/statsService";
import _ from "lodash"

import getGaragesQuery from "../../queries/getGarages";
import graphData from "./graphData"

const humanizeDuration = require('humanize-duration');

const prettifyDuration = (duration) => {
    return !duration ? 0 : humanizeDuration(duration * 60 * 1000, {language: 'de', round: true})
};

const creditifySums = (sum) => {
    return !sum ? 0 + " Credits" : sum.toFixed(2) + " Credits"
}

const options = {
    tooltips: {
        enabled: false,
        custom: CustomTooltips
    },
    maintainAspectRatio: false
};

class Stats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingUsers: true,
            loadingOverall: true,
            loadingRevenue: true,
            loadingMaster: true,
            loadingVoucher: true,

            overallDateFrom: null,
            overallDateTo: null,
            selectedGarage: null,
            stats: [],
            confirmedStats: {}
        };

        this.onDateChanged = this.onDateChanged.bind(this);
        this.loadGarages = this.loadGarages.bind(this);
        this.onGarageChanged = this.onGarageChanged.bind(this);
        this.fetchStats = this.fetchStats.bind(this);
        this.refreshStats = this.refreshStats.bind(this);
        this.downloadData = this.downloadData.bind(this);
    }

    refreshStats() {
        this.setState({
            overallDateFrom: null,
            overallDateTo: null,
            garage_id: null
        }, () => this.fetchStats())
    }

    fetchStats() {

        let params = {};
        let userParams = {};

        if (this.state.selectedGarage !== null) {
            params['garage_id'] = this.state.selectedGarage;
        }
        if (this.state.overallDateFrom !== null) {
            params['since'] = datetimeFormatter.forStats(this.state.overallDateFrom);
            userParams['since'] = datetimeFormatter.forStats(this.state.overallDateFrom);
        }
        if (this.state.overallDateTo !== null) {
            params['to'] = datetimeFormatter.forStats(this.state.overallDateTo);
            userParams['to'] = datetimeFormatter.forStats(this.state.overallDateTo);
        }

        statsService.getOverallStats(params)
            .then((overallStats) => {
                this.setState({
                    overallStats: overallStats.data,
                    stats: [...this.state.stats, overallStats.data],
                    loadingOverall: false,
                });
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

        statsService.getMasterStats(params)
            .then((masterStats) => {
                this.setState({
                    masterStats: masterStats.data,
                    stats: [...this.state.stats, masterStats.data],
                    loadingMaster: false,
                })
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

        statsService.getRevenueStats(params)
            .then((revenueStats) => {
                this.setState({
                    revenueStats: revenueStats.data,
                    stats: [...this.state.stats, revenueStats.data],
                    loadingRevenue: false,
                })
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

        statsService.getUserStats(userParams)
            .then((userStats) => {
                this.setState({
                    userStats: userStats.data,
                    stats: [...this.state.stats, userStats.data],
                    loadingUsers: false,
                })
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

        statsService.getVerifiedUsers()
            .then((confirmedStats) => {
                this.setState({
                    confirmedStats: confirmedStats.data
                })
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

        statsService.getReedemVoucherTotal(userParams)
        .then((voucherTotalStats)=>{
            this.setState({
                redeemedVouchersTotal: voucherTotalStats.data.sum,
                stats: [...this.state.stats, {redeemedVouchersTotal: voucherTotalStats.data.sum}],
                loadingVoucher: false
            })
        })
    }

    downloadData() {
        let fileName = 'stats.json';
        // Create a blob of the data
        let fileToSave = new Blob([JSON.stringify(this.state.stats)], {
            type: 'application/json',
            name: fileName
        });
        // Save the file
        saveAs(fileToSave, fileName);
    }

    getByHourParkingLabels() {
        return this.state.overallStats.by_hour_parking.map((item) => {
            return item.hour + " Uhr"
        });
    }

    getByHourParkingValues() {
        return this.state.overallStats.by_hour_parking.map((item) => {
            return item.num_total
        });
    }

    getByHourParkings() {
        return (
            {
                labels: this.getByHourParkingLabels(),
                datasets: [
                    {
                        ...graphData,
                        label: 'Amount of parkings per hour',
                        data: this.getByHourParkingValues(),
                    },
                ],
            }
        )
    };

    getByHourReservationLabels() {
        return this.state.overallStats.by_hour_reservation.map((item) => {
            return item.hour + " Uhr"
        });
    }

    getByHourReservationValues() {
        return this.state.overallStats.by_hour_reservation.map((item) => {
            return item.num_total
        });
    }

    getByHourReservations() {
        return (
            {
                labels: this.getByHourReservationLabels(),
                datasets: [
                    {
                        ...graphData,
                        label: 'Amount of reservations per hour',
                        data: this.getByHourReservationValues(),
                    },
                ],
            }
        )
    };


    getByDistrictParkingLabels() {
        return this.state.overallStats.by_district_parking.map((item) => {
            return item.zip_code
        });
    }

    getByDistrictParkingValues() {
        return this.state.overallStats.by_district_parking.map((item) => {
            return item.num_total
        });
    }

    getByDistrictParking() {
        return (
            {
                labels: this.getByDistrictParkingLabels(),
                datasets: [
                    {
                        ...graphData,
                        label: 'Amount of parkings per district',
                        data: this.getByDistrictParkingValues(),
                    },
                ],
            }
        )
    };

    getByDistrictReservationLabels() {
        return this.state.overallStats.by_district_reservation.map((item) => {
            return item.zip_code
        });
    }

    getByDistrictReservationValues() {
        return this.state.overallStats.by_district_reservation.map((item) => {
            return item.num_total
        });
    }

    getByDistrictReservation() {
        return (
            {
                labels: this.getByDistrictReservationLabels(),
                datasets: [
                    {
                        ...graphData,
                        label: 'Amount of reservations per district',
                        data: this.getByDistrictReservationValues(),
                    },
                ],
            }
        )
    };

    onDateChanged(name, fromTo, val) {

        this.setState({
            [name + 'Date' + fromTo[0].toUpperCase() + fromTo.slice(1)]: val
        }, this.fetchStats);

    }


    onGarageChanged(selectedGarage) {
        this.setState({
            selectedGarage: selectedGarage ? selectedGarage.value : null
        }, this.fetchStats);
    }

    loadGarages(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    offset: 0,
                    limit: 10,
                    searchTerm: value
                };

                garageService.getService(getGaragesQuery(params))
                    .then((garages) => {

                        resolve({
                            options: garages.data.getGarages.garages.map((garage) => {
                                return {value: garage.id, label: garage.name}
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            }, this.typingInterval)

        })

    }

    componentDidMount() {
        this.fetchStats()
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12" sm="12" lg="10" md={8}>
                        <Widget icon="icon-chart" color="info" header="PAYUCA Stats"/>
                    </Col>
                    <Col lg="2" md="4" sm="12" xs="12">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Download"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.downloadData}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="align-self-center my-auto">
                                        <Form inline>
                                            <FormGroup className="pr-3">
                                                <Label htmlFor="dateFrom" className="pr-3">Garage: </Label>
                                                <AsyncSelect
                                                    name="selectedGarage"
                                                    loadOptions={this.loadGarages}
                                                    value={this.state.selectedGarage}
                                                    onChange={this.onGarageChanged}
                                                    style={{width: 180}}
                                                    placeholder="Garage wählen.."
                                                />
                                            </FormGroup>
                                            <FormGroup className="pr-2">
                                                <Label htmlFor="dateFrom" className="pr-3">Ab: </Label>
                                                <DateTimePicker locale="de" className="form-control"
                                                                value={datetimeFormatter.forPicker(this.state.overallDateFrom)}
                                                                onChange={this.onDateChanged.bind(this, 'overall', 'from')}
                                                                showLeadingZeros={true}
                                                />
                                            </FormGroup>
                                            <FormGroup className="pr-1">
                                                <Label htmlFor="dateTo" className="pr-3">Bis: </Label>
                                                <DateTimePicker locale="de" className="form-control"
                                                                value={datetimeFormatter.forPicker(this.state.overallDateTo)}
                                                                onChange={this.onDateChanged.bind(this, 'overall', 'to')}
                                                                showLeadingZeros={true}
                                                />
                                            </FormGroup>
                                            <FormGroup className="pr-1">
                                                <Button className="btn btn-block btn-success"
                                                        onClick={this.refreshStats}><i className="fa fa-refresh fa-lg text-white"></i></Button>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Legend overall={this.state.overallStats}/>
                <Row>
                    {
                        this.state.loadingMaster ?
                            <CardBody>
                                <div className="sk-rotating-plane"/>
                            </CardBody>
                            :
                            <>
                                <Col lg={2}>
                                    <Card className="border-primary">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-primary">Average Parking Duration</h6>
                                            <h5>{prettifyDuration(this.state.masterStats.avg_parking_duration)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-primary">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-primary">Average Reservation Duration</h6>
                                            <h5>{prettifyDuration(this.state.masterStats.avg_reservation_duration)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-primary">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-primary">Number of Parkings</h6>
                                            <h5>{this.state.masterStats.num_of_parkings}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-primary">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-primary">Number of Reservations</h6>
                                            <h5>{this.state.masterStats.num_of_reservations}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-primary">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-primary">Amount of Spaces</h6>
                                            <h5>{this.state.masterStats.count_spaces}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-primary">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-primary">Amount of Garages</h6>
                                            <h5>{this.state.masterStats.count_garages}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </>
                    }
                </Row>
                <Row>
                    {
                        this.state.loadingRevenue ?
                            <CardBody>
                                <div className="sk-rotating-plane"/>
                            </CardBody>
                            :
                            <>
                                <Col lg={2}>
                                    <Card className="border-success">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-success">Revenue of Paid Parkings</h6>
                                            <h5>{creditifySums(this.state.revenueStats.sum_real_parking)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-success">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-success">Revenue of Bonus Parkings</h6>
                                            <h5>{creditifySums(this.state.revenueStats.sum_bonus_parking)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-success">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-success">Revenue of Paid Reservations</h6>
                                            <h5>{creditifySums(this.state.revenueStats.sum_real_reservation)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-success">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-success">Revenue of Bonus Reservations</h6>
                                            <h5>{creditifySums(this.state.revenueStats.sum_bonus_reservation)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-success">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-success">Revenue of Paid Parkings & Reservations</h6>
                                            <h5>{creditifySums(this.state.revenueStats.sum_real_parking_reservation)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-success">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-success">Revenue of Bonus Parkings & Reservations</h6>
                                            <h5>{creditifySums(this.state.revenueStats.sum_bonus_parking_reservation)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </>
                    }
                </Row>
                <Row>
                    {
                        this.state.loadingUsers || this.state.loadingVoucher ?
                            <CardBody>
                                <div className="sk-rotating-plane"/>
                            </CardBody>
                            :
                            <>
                                <Col lg={2}>
                                    <Card className="border-secondary">
                                        <CardBody>
                                            <h6 className="font-weight-bold">Active Garages</h6>
                                            <h5>{!this.state.overallStats ? '' : this.state.overallStats.num_active_garages}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Registered Users</h6>
                                            <h5>{this.state.userStats.count_registered}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Migrated Users</h6>
                                            <h5>{this.state.userStats.count_migrated}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Active Users</h6>
                                            <h5>{this.state.userStats.active_users}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Credits Bought</h6>
                                            <h5>{creditifySums(this.state.userStats.credits_bought)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Bonus Credits From Vouchers Redeemed</h6>
                                            <h5>{creditifySums(this.state.redeemedVouchersTotal)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Unused Bought Credits</h6>
                                            <h5>{creditifySums(this.state.userStats.credits_bought - this.state.revenueStats.sum_real_parking_reservation)}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">User Retention (churn)</h6>
                                            <h5>{this.state.userStats.active_users - this.state.userStats.count_registered}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Confirmed Users</h6>
                                            <h5>{_.isEmpty(this.state.confirmedStats) ? "-" : this.state.confirmedStats.confirmed}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={2}>
                                    <Card className="border-warning">
                                        <CardBody>
                                            <h6 className="font-weight-bold text-warning">Unconfirmed Users</h6>
                                            <h5>{_.isEmpty(this.state.confirmedStats) ? "-" : this.state.confirmedStats.unconfirmed}</h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </>
                    }
                </Row>
                <Row>
                    <Col lg="6">
                        <Card>
                            {
                                this.state.loadingOverall ?
                                    <CardBody>
                                        <div className="sk-rotating-plane"/>
                                    </CardBody>
                                    :
                                    <CardBody>
                                        <div className="chart-wrapper">
                                            <Line data={this.getByHourParkings()} options={options}/>
                                        </div>
                                    </CardBody>
                            }
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            {
                                this.state.loadingOverall ?
                                    <CardBody>
                                        <div className="sk-rotating-plane"/>
                                    </CardBody>
                                    :
                                    <CardBody>
                                        <div className="chart-wrapper">
                                            <Line data={this.getByHourReservations()} options={options}/>
                                        </div>
                                    </CardBody>
                            }
                        </Card>
                    </Col>
                </Row>
                {
                    this.state.selectedGarage === null &&
                    <Row>
                        <Col lg="6">
                            <Card>
                                {
                                    this.state.loadingOverall ?
                                        <CardBody>
                                            <div className="sk-rotating-plane"/>
                                        </CardBody>
                                        :
                                        <CardBody>
                                            <div className="chart-wrapper">
                                                <Bar data={this.getByDistrictParking()} options={options}/>
                                            </div>
                                        </CardBody>
                                }
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                {
                                    this.state.loadingOverall ?
                                        <CardBody>
                                            <div className="sk-rotating-plane"/>
                                        </CardBody>
                                        :
                                        <CardBody>
                                            <div className="chart-wrapper">
                                                <Bar data={this.getByDistrictReservation()} options={options}/>
                                            </div>
                                        </CardBody>
                                }
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

export default Stats;
