import React, {Component} from 'react';
import {
    Button,
    Row,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

export default class CheckOutData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disableCheckOutData: true,
            garage: props.garage,
            check_out_address: props.garage.check_out_address === null ? {street: '', street_number: '', zip_code: '', city: ''} : props.garage.check_out_address,
            check_out_coordinates: props.garage.check_out_coordinates === null ? {latitude: '', longitude: ''} : props.garage.check_out_coordinates,
        };

        this.toggleCheckOutData = this.toggleCheckOutData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateGarage = this.updateGarage.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let check_out_address = this.state.check_out_address;
        let check_out_coordinates = this.state.check_out_coordinates;
        let garage = this.state.garage;

        check_out_address[name] = value;
        check_out_coordinates[name] = value;
        garage[name] = value;

        this.setState({
            check_out_address: check_out_address,
            check_out_coordinates: check_out_coordinates,
            garage: garage,
            [name]: value
        })
    }

    toggleCheckOutData() {
        this.props.toggleCheckOutData()
    }

    updateGarage() {
        this.props.updateGarage({
            ...this.state.garage,
            check_out_address: this.state.check_out_address,
            check_out_coordinates: this.state.check_out_coordinates,
            entrance_type_foot: this.state.garage.entrance_type_foot === null ? "VIA_STAIRCASE" : this.state.garage.entrance_type_foot,
            exit_type_foot: this.state.garage.exit_type_foot === null ? "VIA_STAIRCASE" : this.state.garage.exit_type_foot
        })
        .then(() => {
            this.setState({
                disabledGarageData: true
            })
        })
        .catch(() => {
            this.setState({
                disabledGarageData: false
            })
        })
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Check-Out Daten
                    <div className="card-header-actions">
                        {
                            !this.props.disableCheckOutData
                                ?
                                <div>
                                    <Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateGarage}>
                                        <i className="fa fa-dot-circle-o"/> Speichern
                                    </Button>
                                    <Button type="reset" size="sm" color="danger" onClick={this.toggleCheckOutData}>
                                        <i className="fa fa-ban"/> Abbrechen
                                    </Button>
                                </div>
                                :
                                <a className="card-header-action btn btn-setting" onClick={this.toggleCheckOutData}><i className="icon-pencil"></i></a>
                        }
                    </div>
                </CardHeader>
                <CardBody className="pb-0">
                    {
                        this.props.loading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                <Row>
                                    <Col lg={6}>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="strasse">Check-Out Straße</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="street" name="street" value={!this.state.check_out_address ? '' : this.state.check_out_address.street} disabled={this.props.disableCheckOutData} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="nr">Check-Out Nr</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="street_number" name="street_number" value={!this.state.check_out_address ? '' : this.state.check_out_address.street_number} disabled={this.props.disableCheckOutData} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="plz">Check-Out PLZ</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="zip_code" name="zip_code" value={!this.state.check_out_address ? '' : this.state.check_out_address.zip_code} disabled={this.props.disableCheckOutData} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="plz">Check-Out Stadt</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="city" name="city" value={!this.state.check_out_address ? '' : this.state.check_out_address.city} disabled={this.props.disableCheckOutData} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="entrance_type_foot">Eingangstyp</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="select" id="entrance_type_foot" name="entrance_type_foot" value={!this.state.garage.entrance_type_foot ? 'VIA_STAIRCASE' : this.state.garage.entrance_type_foot} disabled={this.props.disableCheckOutData} onChange={this.handleChange}>
                                                    <option value={"VIA_STAIRCASE"}>Über die Treppe</option>
                                                    <option value={"VIA_GATE"}>Über Tor</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="exit_type_foot">Exit-Typ</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="select" id="exit_type_foot" name="exit_type_foot" value={!this.state.garage.exit_type_foot ? 'VIA_STAIRCASE' : this.state.garage.exit_type_foot} disabled={this.props.disableCheckOutData} onChange={this.handleChange}>
                                                    <option value={"VIA_STAIRCASE"}>Über die Treppe</option>
                                                    <option value={"VIA_GATE"}>Über Tor</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="latitude">Breitengrad</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="latitude" name="latitude" value={!this.state.check_out_coordinates ? '' : this.state.check_out_coordinates.latitude} disabled={this.props.disableCheckOutData} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="longitude">Längengrad</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" id="longitude" name="longitude" value={!this.state.check_out_coordinates ? '' : this.state.check_out_coordinates.longitude} disabled={this.props.disableCheckOutData} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                    }
                </CardBody>
            </Card>
        )
    }

}
