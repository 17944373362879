import urls from '../utils/urls';
import axios from "axios";

import errorHandlerService from './errorHandlerService';


const supportService = {

    getDeviceData: (id) => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/users/${id}/amplitude-activity/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en'
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        supportService.getDeviceData, id
                    )
                }
            });
        });

    },

    getJiraTickets: (id) => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/users/${id}/jira-tickets/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en'
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if (err && err.response && err.response.status === 401) {
                    reject("Unauthorized request to Jira.")
                } else {
                    reject(err)
                }
            });
        });

    },

};

export default supportService;
