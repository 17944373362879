import gql from "graphql-tag";

export default function(params=null) {

    if (params && params.garageOwner) {
        return gql`
            query {
                getGarages {
                    errors
                    garages {
                        id
                        name
                        address {
                            street
                            street_number
                            city
                            zip_code
                            country
                        }
                        gateway_type
                    }
                }
            }
        `
    } else if(params != null) {
        return gql`
            query {
                getGarages(first: ${params.limit}, offset: ${params.offset}, searchTerm: "${params.searchTerm}") {
                    errors
                    garages {
                      id
                      name
                      state
                      address {
                          street
                          street_number
                          city
                          zip_code
                      }
                      taken_spaces
                      total_spaces
                      garagePhotos {
                          file_path
                          id
                          type
                      }
                      gateway_type
                  }

                }
            }
        `
    }
    return gql`
         query {
             getGarages {
                 errors
                 garages {
                     id
                     name
                     state
                     address {
                         street
                         street_number
                         city
                         zip_code
                     }
                     taken_spaces
                     total_spaces
                     garagePhotos {
                         file_path
                         id
                         type
                     }
                     gateway_type
                 }
             }
        }`;
}
