import gql from "graphql-tag";


export default function(params){
    return gql`
        mutation {
            importMyDatanetCustomer(
                customer_id: "${params}"
            ) {
                errors
                statusCode
                message
            }
        }`;
}
