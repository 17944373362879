import gql from "graphql-tag";


export default function(params){
    return gql`
            mutation  {
                updateGaragePhoto(
                    id: "${params.id}"
                    type: ${params.type}
                ) {
                    errors
                    garagePhoto {
                        id
                        type
                        file_path
                    }
                }
            }`;
}
