import React, {Component, Fragment} from 'react';
import {CSVLink} from 'react-csv';
import Widget from "../../../components/Widget/Widget";
import 'spinkit/css/spinkit.css';
import {toast, ToastContainer} from 'react-toastify';
import gatewayService from "../../../services/gatewayService";
import getGatewaysQuery from "../../../queries/getGateways";

class DownloadGateways extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            gateways: [],
            gatewaysDownload: [],
            page: 1,
            totalSize: 0,

            searchQuery: "",
            stateFilter: "",
            typeFilter: "",
            assignedFilter: null
        };

        this.downloadGateways = this.downloadGateways.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.csvInstance = React.createRef();
    }

    unsuccessful(msg = "Erfolglos!") {
        return toast.error(msg);
    }

    getQueryParams() {
        return {
            limit: this.props.totalSize,
            offset: 0,
            searchTerm: this.state.searchQuery,
            stateFilter: this.state.stateFilter,
            typeFilter: this.state.typeFilter,
            assignedFilter: this.state.assignedFilter
        }
    }

    downloadGateways() {
        this.setState({loading: true});
        gatewayService.getService(getGatewaysQuery())
            .then((gateways) => {
                let data = gateways.data.getGateways.gateways.map((item) => {
                    return {
                        ...item,
                        gateway_garage_id: !item.gatewayGarage ? null : item.gatewayGarage.id,
                        gateway_garage_name: !item.gatewayGarage ? null : item.gatewayGarage.name
                    }
                })

                let gatewaysDownload = data.map((item) => {
                    delete item.gatewayGarage
                    delete item.__typename

                    for (const property in item) {
                        if (item[property] === null) {
                            item[property] = "N/A"
                        }
                        if (item[property] === false) {
                            item[property] = "false"
                        }
                    }

                    return {
                        ...item
                    }
                })

                this.setState({
                    gatewaysDownload: gatewaysDownload,
                    loading: false,
                })
            })
            .then(() => {
                if (this.state.gatewaysDownload) {
                    this.csvInstance.current.link.click();
                }
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    render() {
        if (this.state.loading) {
            return <div className="sk-rotating-plane"/>;
        }

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <Fragment>
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Widget
                    onClick={() => {
                        this.downloadGateways();
                    }}
                    icon="icon-plus"
                    color="success"
                    header="Download"
                    invert
                    style={{cursor: "pointer"}}
                />
                <CSVLink
                    data={this.state.gatewaysDownload}
                    filename={"payucaGateways.csv"}
                    className="csvDownload"
                    target="_blank"
                    ref={this.csvInstance}
                />
            </Fragment>
        );
    }
}

export default DownloadGateways;
