import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "reactstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import CustomDropdownFilter from "./CustomDropdownFilter";
import CustomSearchField from "./CustomSearchField";
import {Link} from "react-router-dom";

const gatewayType = {
    'CHECK_IN': 'Check In',
    'CHECK_OUT': 'Check Out',
    'CHECK_IN_OUT': 'Check In/Out',
    'MIDGATEWAY': 'Zwischentür',
    'MIDGATEWAY_ONLINE': 'Zwischentür (Online)',
    'MIDGATEWAY_OFFLINE': 'Zwischentür (Offline)',
    'NFC_WRITE': 'NFC Gateway',
    'EXIT_BARRIER': 'Ausfahrtsschranken',
    'EXIT_BARRIER_ONLINE': 'Ausfahrtsschranken (Online)',
    'EXIT_BARRIER_OFFLINE': 'Ausfahrtsschranken (Offline)',
};

const gatewayState = {
    'ONLINE': 'Online',
    'OFFLINE': 'Offline',
};

function enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
}

const propTypes = {
    slaves: PropTypes.array
};

const defaultProps = {
    slaves: []
};

class Slaves extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slaves: this.props.slaves,
            filteredSlaves: null,
            nameFilter: '',
            gwidFilter: '',
            typeFilter: '',
            stateFilter: '',
            totalSize: this.props.slaves.length
        };

        this.handleStateFilter = this.handleStateFilter.bind(this);
        this.getStateFilter = this.getStateFilter.bind(this);

        this.handleTypeFilter = this.handleTypeFilter.bind(this);
        this.getTypeFilter = this.getTypeFilter.bind(this);
    }

    handleStateFilter(val) {
        this.setState({stateFilter: val}, () => this.filterSlaves())
    }

    getStateFilter() {
        return (
            <CustomDropdownFilter
                testId='state-filter'
                options={[{
                    value: "ONLINE", label: "ONLINE"
                }, {
                    value: "OFFLINE", label: "OFFLINE"
                }]}
                filterHandler={this.handleStateFilter}/>
        )
    }

    handleTypeFilter(val) {
        this.setState({typeFilter: val}, () => this.filterSlaves());
    }

    getTypeFilter() {
        let options = [
            {value: "CHECK_IN", label: "Check In"},
            {value: "CHECK_OUT", label: "Check Out"},
            {value: "CHECK_IN_OUT", label: "Check In/Out"},
            {value: "MIDGATEWAY", label: "Zwischentür"},
            {value: "MIDGATEWAY_ONLINE", label: "Zwischentür (Online)"},
            {value: "MIDGATEWAY_OFFLINE", label: "Zwischentür (Offline)"},
            {value: "NFC_WRITE", label: "NFC Gateway"},
            {value: "EXIT_BARRIER", label: "Ausfahrtsschranken"},
            {value: "EXIT_BARRIER_ONLINE", label: "Ausfahrtsschranken (Online)"},
            {value: "EXIT_BARRIER_OFFLINE", label: "Ausfahrtsschranken (Offline)"},
        ];
        return (
            <CustomDropdownFilter
                testId='type-filter'
                defaultValue={''}
                options={options}
                filterHandler={this.handleTypeFilter}/>
        )
    }

    getGWIDFilter = () => {
        return (
            <CustomSearchField
                testId="gwid-filter"
                placeholder="Search by GWID"
                search={this.onGWIDFilter}/>
        )
    };

    getNameFilter = () => {
        return (
            <CustomSearchField
                testId="name-filter"
                placeholder="Search by Name"
                search={this.onNameFilter}/>
        )
    };

    filterSlaves = () => {
        const gwidFilter = this.state.gwidFilter;
        const nameFilter = this.state.nameFilter;
        const typeFilter = this.state.typeFilter.toUpperCase();
        const stateFilter = this.state.stateFilter.toUpperCase();

        if (!gwidFilter && !nameFilter && !typeFilter && !stateFilter) {
            this.setState({filteredSlaves: null});
            return;
        }

        const filteredSlaves = this.state.slaves.filter((slave) => {

            if(!slave.name){
                slave.name = '';
            }

            return slave.gwid.toUpperCase().includes(gwidFilter) &&
                slave.name.toUpperCase().includes(nameFilter) &&
                (typeFilter ? slave.type.toUpperCase() === typeFilter : true) &&
                (stateFilter ? slave.state.toUpperCase() === stateFilter : true)
        });
        this.setState({filteredSlaves});
    };

    onGWIDFilter = (e) => {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ').toUpperCase();
        this.setState({gwidFilter: val}, () => this.filterSlaves());
    };

    onNameFilter = (e) => {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ').toUpperCase();
        this.setState({nameFilter: val}, () => this.filterSlaves());
    };

    static ToGateway(cell, row) {
        const gwid = row.gwid;
        return <Link data-testid='gwid-row' to={`/gateways2/${gwid}`}> {cell} </Link>;
    }

    static StateStyle(cell) {
        if (cell === 'ONLINE') {
            return <div><i className="fa fa-circle text-success Blink"/> {cell}</div>
        } else if (cell === 'OFFLINE') {
            return <div><i className="fa fa-circle text-danger Blink"/> {cell}</div>
        } else {
            return cell;
        }
    }

    getNoDataTableContent() {
        return "Keine Slaves gefunden";
    }

    get tableOptions() {
        return {
            sortIndicator: false,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,
            noDataText: this.getNoDataTableContent(),
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Slaves
                </CardHeader>
                <CardBody data-testid="slaves-table">
                    <BootstrapTable
                        data={this.state.filteredSlaves ? this.state.filteredSlaves : this.state.slaves}
                        version="4" striped remote
                        fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true}
                        options={this.tableOptions}>
                        <TableHeaderColumn width='150' isKey={true} dataField="gwid"
                                           dataFormat={Slaves.ToGateway}
                                           filter={{
                                               type: 'CustomFilter',
                                               getElement: this.getGWIDFilter
                                           }}>GWID</TableHeaderColumn>
                        <TableHeaderColumn width='150' dataField="name"
                                           dataFormat={Slaves.ToGateway}
                                           filter={{
                                               type: 'CustomFilter',
                                               getElement: this.getNameFilter
                                           }}>Name</TableHeaderColumn>
                        <TableHeaderColumn width='150'
                                           dataField="type"
                                           filterFormatted
                                           dataFormat={enumFormatter}
                                           formatExtraData={gatewayType}
                                           filter={{
                                               type: 'CustomFilter',
                                               getElement: this.getTypeFilter
                                           }}>Typ</TableHeaderColumn>
                        <TableHeaderColumn width='130'
                                           dataField="state"
                                           dataFormat={Slaves.StateStyle}
                                           formatExtraData={gatewayState}
                                           filter={{
                                               type: 'CustomFilter',
                                               getElement: this.getStateFilter
                                           }}>State</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        );
    }
}

Slaves.propTypes = propTypes;
Slaves.defaultProps = defaultProps;
export default Slaves;
