import gql from "graphql-tag";

export default function(params = null) {
  if (params != null) {
    let queryParamsString = Object.keys(params)
      .filter(key => {
        return params[key] && params[key] !== '""';
      })
      .map(key => {
        return `${key}:${params[key]}`;
      })
      .join(",");

    return gql`
            query {
                getNewGateways(
                    ${queryParamsString}
                ) {
                    errors
                    count
                    garageGateways{
                        id
                        microtronics_id
                        device_id
                        garage_id
                        name
                        is_master
                        state
                        type
                        gwid
                        note
                        product_type
                        userpool_id
                        tenant_name
                        firmware
                        microtronics_site_id
                        location
                        slaves {
                            gwid
                            name
                            type
                            state
                        }
                        health_status {
                            nfc_state
                            ble_state
                            connection
                            rf_state
                            main_controller_state
                        }
                        garage_gateway {
                            name
                            id
                            state
                            echarger
                            }
                    }
            }
            }`;
  }
  return gql`
    query {
      getNewGateways {
        errors
        count
        gwid
        note
        garageGateways {
          id
          microtronics_id
          device_id
          name
          state
          type
          product_type
          userpool_id
          firmware
          tenant_name
          microtronics_site_id
          location
        }
      }
    }
  `;
}
