import moment from 'moment';

export default {
    forDisplay: (datetime) => {
        return moment(datetime).format("DD.MM.YYYY HH:mm:ss")
    },

    forSubmitting: (datetime) => {
        return moment(datetime).format("YYYY-MM-DDTHH:mm:ssZ")
    },

    forStats: (datetime) => {
        return moment(datetime).format("YYYY-MM-DDTHH:mm:ss")
    },

    forPicker: (datetime) => {
        return datetime ? new Date(datetime) : null
    }
}
