import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';
import garageService from "../../../services/garageService";
import updateGarageContractMutation from "../../../mutations/updateGarageContract";
import datetimeFormatter from "../../../utils/datetimeFormatter";


export default class GarageContract extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editingDisabled: true,
            contract: this.props.contract,
            garageName: this.props.garageName
        };

        this.updateGarageContract = this.updateGarageContract.bind(this);
        this.downloadPDF = this.downloadPDF.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.editContract = this.editContract.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    updateGarageContract() {
        let contract = {...this.state.contract};
        delete contract.file;
        let data = {
            ...contract,
            tariff_reservation: {
                hour: this.state.contract.tariff_reservation ? this.state.contract.tariff_reservation.hour : null,
            },
            garage_id: this.props.garageId
        };
        garageService.createService(updateGarageContractMutation(data, !!this.state.contract.file), this.state.contract.file)
            .then((response) => {
                let file_path = response.data.updateGarageContract.garageContract.file_path;
                this.setState({
                    contract: {...this.state.contract, file_path: file_path},
                    editingDisabled: true

                });
                this.props.success();
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.props.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    downloadPDF() {
        window.open(this.state.contract.file_path, '_blank');
    }

    editContract() {
        this.setState({
            editingDisabled: !this.state.editingDisabled,
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let parent = target.getAttribute('parent');
        let newState = {
            contract: {
                ...this.state.contract,
                [name]: value
            }
        };

        if(parent !== null) {
            newState = {
                contract: {
                    ...this.state.contract,
                    [parent]: {
                        ...this.state.contract[parent],
                        [name]: value
                    }
                }
            }
        }

        this.setState(newState);
    }

    handleFileChange(event) {

        garageService.uploadFile({id: this.state.contract.id}, 'upload/garage-contract', event.target.files[0], 'garage-contract')
            .then((resp) => {
                let state = this.state;
                this.setState({
                    contract: {
                        ...this.state.contract,
                        file: resp.data.garageContract.file_path
                    }
                });
                this.props.success()
            })
            .catch((err) => {
                let errors = err.response.data.errors;
                this.props.unsuccessful(errors.map((error) => error.reason).join(" "));
            });
    }

    render() {
        return (
            <Row className="row-grid animated fadeIn">
                <Col lg="12" sm="12">
                    <Card>
                        <CardHeader>
                            Vertragsdaten
                            <div className="card-header-actions">
                                {
                                    !this.state.editingDisabled
                                        ?
                                        <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateGarageContract}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.editContract}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                        :
                                        <a className="card-header-action btn btn-setting" onClick={this.editContract} aria-controls="collapseOne"><i className="icon-pencil"></i></a>
                                }
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row className="row-grid">
                                <Col xs="12" sm="12" md="12" lg="6">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="tariff">Tarif*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" id="tariff" name="tariff" value={!this.state.contract.tariff ? '' : this.state.contract.tariff} disabled={this.state.editingDisabled} onChange={this.handleChange} required/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="tariff_daily">Tagestarif*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" id="tariff_daily" name="tariff_daily" value={!this.state.contract.tariff_daily ? '' : this.state.contract.tariff_daily} disabled={this.state.editingDisabled} onChange={this.handleChange} required />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="tariff_special">Tariff-Spezial</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" id="tariff_special" name="tariff_special" value={!this.state.contract.tariff_special ? '' : this.state.contract.tariff_special} disabled={this.state.editingDisabled} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="tariff_tact">Taktung*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="select" name="tariff_tact" id="tariff_tact" value={!this.state.contract.tariff_tact ? null : this.state.contract.tariff_tact} disabled={this.state.editingDisabled} onChange={this.handleChange} required>
                                                <option value={null}>-</option>
                                                <option value={1}>1 Std.</option>
                                                <option value={0.75}>45 Min.</option>
                                                <option value={0.5}>30 Min.</option>
                                                <option value={0.25}>15 Min.</option>
                                                <option value={0.0167}>1 Min.</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="parking_spaces">Stellplätze*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="parking_spaces" id="parking_spaces" value={!this.state.contract.parking_spaces ? '' : this.state.contract.parking_spaces} disabled={this.state.editingDisabled} onChange={this.handleChange} required />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="active">Aktiv</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="select" id="active" name="active" value={!this.state.contract.active ? '' : this.state.contract.active} disabled={this.state.editingDisabled} onChange={this.handleChange}>
                                                <option value=''>-</option>
                                                <option value={true}>Ja</option>
                                                <option value={false}>Nein</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="created_at">Erstellt am</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="created_at" id="created_at" value={datetimeFormatter.forDisplay(this.state.contract.created_at)} onChange={this.handleChange} disabled />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="6">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="hour">Reservierung Stundentarif*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" id="hour" name="hour" parent="tariff_reservation" value={!this.state.contract.tariff_reservation ? "" : this.state.contract.tariff_reservation.hour} disabled={this.state.editingDisabled} onChange={this.handleChange} required/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="day">Reservierung Tagestarif</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" id="day" name="day" parent="tariff_reservation" value={!this.state.contract.tariff_reservation ? "" : this.state.contract.tariff_reservation.day} disabled onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="week">Reservierung Wochentarif</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" name="week" id="week" parent="tariff_reservation" value={!this.state.contract.tariff_reservation ? "" : this.state.contract.tariff_reservation.week} disabled onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="month">Reservierung Monatstarif</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" name="month" id="month" parent="tariff_reservation" value={!this.state.contract.tariff_reservation ? "" : this.state.contract.tariff_reservation.month} disabled onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="contractPDF">Vertrag</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {
                                                !this.state.contract.file_path ?
                                                    <Input className="btn-block" type="file" disabled={this.state.editingDisabled} onChange={this.handleFileChange} /> :
                                                    this.state.editingDisabled ?
                                                        <Button onClick={this.downloadPDF} className="btn-block">
                                                            PDF herunterladen
                                                        </Button> :
                                                        <Input className="btn-block" type="file" onChange={this.handleFileChange} />
                                            }
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

}
