import axios from "axios";
import urls from "../utils/urls";
import errorHandlerService from "./errorHandlerService";
import config from "../utils/config";


const statsService = {

    getOverallStats: (params) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/stats/overall/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                },
                params
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getOverallStats, params
                    )
                }
            })
        });
    },

    getMasterStats: (params) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/stats/master/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                },
                params
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getMasterStats, params
                    )
                }
            })
        });
    },

    getRevenueStats: (params) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/stats/revenues/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                },
                params
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getRevenueStats, params
                    )
                }
            })
        });
    },

    getSingleUserStats: (id, params) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/stats/users/${id}/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                },
                params
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getSingleUserStats, params
                    )
                }
            })
        });
    },

    getUserStats: (params) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/stats/users/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                },
                params
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getUserStats, params
                    )
                }
            })
        });
    },

    getGarageReport: (garage_id, params) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/stats/reports/${garage_id}/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                },
                params
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getGarageReport, garage_id, params
                    )
                }
            })
        });
    },

    getReedemVoucherTotal: (params) => {
        return new Promise((resolve, reject)=>{
            axios({
                methid: 'GET',
                url: `${urls.apigateway}/stats/redeemed-vouchers-total/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en', 
                },
                params
            })
            .then((res)=>{
                resolve(res)
            })
            .catch((err)=>{
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getReedemVoucherTotal, params
                    )
                }
            })
        })
    },

    getVerifiedUsers: () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/users/statstan/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                redirect: 'follow'
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;

                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        statsService.getVerifiedUsers
                    )
                }
            })
        });
    },
};


export default statsService;
