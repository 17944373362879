export default {
    items: [
        {
            title: true,
            name: 'Menü',
            wrapper: {
                element: '',
                attributes: {}
            },
            class: ''
        },
        {
            name: 'Garagen',
            url: '/garages',
            icon: 'icon-home',
        },
        {
            name: 'App-Kunden',
            url: '/users',
            icon: 'icon-people',
        },
        {
            name: 'Gateways',
            url: '/gateways',
            icon: 'icon-organization',
        },
        {
            name: 'Gateways 2.0',
            url: '/gateways2',
            icon: 'icon-organization',
        },
        {
            name: 'Payuca Connect',
            url: '/payuca-connect',
            icon: 'icon-organization',
        },
        {
            name: 'Kontakte',
            url: '/businessContacts',
            icon: 'icon-notebook',
            children: [
                {
                    name: 'Firmen',
                    url: '/companies',
                    icon: 'icon-people',
                },
                {
                    name: 'Garagenbesitzer',
                    url: '/garageowners',
                    icon: 'icon-note',
                },
                {
                    name: 'Personen',
                    url: '/contacts',
                    icon: 'icon-user',
                },
            ],
        },
        {
            name: 'Gutscheine',
            url: '/coupons',
            icon: 'icon-tag',
        },
        {
            name: 'Einstellungen',
            url: '/settings',
            icon: 'icon-settings',
        },
        {
            name: 'OAuth Apps',
            url: '/oauthapps',
            icon: 'icon-layers',
        },
        // {
        //     name: 'Stats',
        //     url: '/stats',
        //     icon: 'icon-chart',
        // },
        {
            name: 'Migration',
            url: '/migration',
            icon: 'icon-shuffle'
        },
        /*{
            name: 'Version Block',
            url: '/version-block',
            icon: 'icon-close'
        }*/
    ]
};
