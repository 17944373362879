import gql from "graphql-tag";


export default function query(params) {
    return gql`            
        { 
            getGarage(id: "${params.id}") {
                 errors
                 garage {
                     id
                     name
                     state
                     address {
                        street
                        street_number
                        city
                        zip_code
                        country
                     }
                     check_out_address {
                         street
                         street_number
                         zip_code
                         city
                     }
                     entrance_type_foot
                     exit_type_foot
                     check_out_coordinates {
                         latitude
                         longitude
                     }
                     revenue_share
                     revenue_share_valid_from
                     created_at
                     garage_owner
                     taken_spaces
                     total_spaces
                     echarger
                     narrow
                     barrierfree
                     footprint
                     video_surveilance
                     garage_height
                     indoor
                     type
                     levels
                     tolerance_minutes
                     free_minutes
                     exit_type
                     person_responsible
                     property_management_company
                     door_maintenance_company
                     note
                     max_weight
                     handicapped_spots
                     security_guard
                     position {
                        latitude
                        longitude
                     }
                     parkingSpaces {
                         id
                         number
                         type
                         level
                         state
                         stack_parking
                         state_changed
                         width
                         height
                         length
                         active
                     }
                     garageContract {
                        id
                        parking_spaces
                        tariff
                        tariff_daily
                        tariff_tact
                        tariff_special
                        tariff_reservation {
                            hour
                            day
                            week
                            month
                        }
                        active
                        file_path
                        created_at
                        address {
                            street
                            street_number
                            city
                            zip_code   
                        }
                     }
                     inventoryEntries {
                         id
                         name
                         count
                         description
                     }
                     garagePhotos {
                         file_path
                         id
                         type
                     }
                     garageGateways {
                         id
                         gwid
                         name
                         device_id
                         state
                         type
                     }
                     garage_gateways{
                         gwid
                         device_id
                         name
                         type
                         state
                         is_master
                         health_status {
                            nfc_state
                            ble_state
                            connection
                            rf_state
                            main_controller_state
                        }
                     }
                     payuca_connect_gateways {
                         health_status {
                            nfc_state
                            ble_state
                            connection
                            rf_state
                            main_controller_state
                         }   
                         id
                         gwid
                         device_id
                         type
                         name
                         master_id
                     }
                     gateway_type
                     maintenance_estimated_end
                     maintenance_reason
                     smart_revenue
                     smart_access
                 }
                }
            }`;
}
