import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

class JiraTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.props.data,
        };
    }
    render() {
        return (
            <Card>
                <CardHeader>
                    <strong><a href={"https://payuca.atlassian.net/browse/" + this.state.key}>{this.state.key}</a></strong>
                </CardHeader>
                <CardBody>
                    <Row className={"mb-2"}>
                        <Col>
                            <strong>Summary: </strong> <br/>{this.state.summary}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p><strong>Assignee: </strong> <br/>{this.state.assignee}</p>
                        </Col>
                        <Col>
                            <p><strong>Status: </strong>  <br/>{this.state.status}</p>
                        </Col>
                        <Col>
                            <p><strong>Priority: </strong>  <br/>{this.state.priority}</p>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

export default JiraTicket
