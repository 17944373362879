import convict from 'convict';
import dotenv from 'dotenv';

dotenv.config();


export default convict({
    env: {
        doc: "The application environment.",
        format: ["local", "development", "production"],
        default: "local",
        env: "REACT_APP_COCKPIT_NODE_ENV"
    },

    microtronicsPoolId: {
        doc: "Microtronics gatewy pool id",
        format: "String",
        default: "A63C9188DF85DE4C"
    },

    microtronicsNonCustomerId: {
        doc: "Microtronics gatewy pool id",
        format: "String",
        default: "0000000000000000"
    },

    mcAuthToken: {
        doc: "Basic auth token for microtronics",
        format: "String",
        default: "cGF5dWNhU3VwcG9ydDo9RVN3dXRoMlN0YU4",
        env: "REACT_APP_MC_AUTH_KEY"
    },

    mcRestAuthToken: {
        doc: "Basic auth token for microtronics REST",
        format: "String",
        default: "emVsamtvOlFHRGpRR0Fm",
        env: "REACT_APP_MC_AUTH_KEY_REST"
    },

    mcApiUrl: {
        doc: "MC Api url",
        format: "String",
        default: "https://payuca-test.microtronics.at",
        env: "REACT_APP_MC_API_URL"
    },

    apiGatewayPort: {
        doc: "The port on which gateway runs",
        format: "port",
        default: 8000,
        env: "REACT_APP_API_GATEWAY_PORT"
    },

    apiGatewayHost: {
        doc: "The port on which gateway runs",
        format: "String",
        default: "localhost",
        env: "REACT_APP_API_GATEWAY_HOST"
    },

    appToken: {
        doc: "App Token to acess PAYUCA API",
        format: "String",
        default: "L0qRI4ZJ+bsVEHgKSNnfiVxkBFZTlcatTKwUvOj9TYj4Qgi3h386cH0zI2BEL23nQSZYPC0Jh+ZOqZqF+Oae7F8JR+pFDSK46g4ex1ZL7quUC+pYdD8jxeXCKjfpy3g9+GH3ioAHP5i7L7i1DFNVrhwkYIcjylcbZ4F8UNM+cSuI2PQD0ySMoW2L1wjM/3UpxGs1vT8CsDLVLzajitlIgYWQVZIXuzjEkQ==",
        env: "REACT_APP_APP_TOKEN"
    }
});




