import React, {Component} from "react";
import dictionary from "../utils/dictionary";

export default class HealthStatus extends Component {

    render() {
        const {healthStatus} = this.props;
        let status = { color: '', label: '' };

        if (!healthStatus.ble_state || !healthStatus.connection || !healthStatus.main_controller_state || !healthStatus.nfc_state || !healthStatus.rf_state) {
            status.color = 'text-danger';
            status.label = dictionary.DANGER;
        }
        if (healthStatus.ble_state && healthStatus.connection && healthStatus.main_controller_state && healthStatus.nfc_state && healthStatus.rf_state) {
            status.color = 'text-success';
            status.label = dictionary.OK;
        }
        if ((!healthStatus.ble_state || !healthStatus.nfc_state) && healthStatus.connection && healthStatus.main_controller_state && healthStatus.rf_state) {
            status.color = 'text-warning';
            status.label = dictionary.WARNING;
        }
        return (
            <div><i className={`fa fa-circle ${status.color} Blink`}/> {status.label}</div>
        );
    }
}
