import React, { Component } from 'react';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import {Link} from "react-router-dom";
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from "react-bootstrap-table";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";

import gatewayService from "../../services/gatewayService";
import Widget from "../../components/Widget/Widget";
import getGatewaysQuery from "../../queries/getGateways";
import CustomSearchField from "../../components/CustomSearchField";
import CustomDropdownFilter from "../../components/CustomDropdownFilter";
import config from '../../utils/config';
import DownloadGateways from "./DownloadGateways/DownloadGateways";


const gatewayType = {
    'CHECK_IN': 'Check In',
    'CHECK_OUT': 'Check Out',
    'CHECK_IN_OUT': 'Check In/Out',
    'MIDGATEWAY': 'Zwischentür',
    'NFC_WRITE': 'NFC Gateway',
    'EXIT_BARRIER': 'Ausfahrtsschranken',
};

const gatewayState = {
    'ONLINE': 'Online',
    'OFFLINE': 'Offline',
};

const productTypes = {
    'B2C': 'B2C',
    'B2B': 'B2B',
    'B2B_B2C': 'Hybrid',
};

function enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
}

class Gateways extends Component {

    static IDTooltipFormatter(cell, row) {
        const id = row.id;

        return (
            <div>
                <Link to={`/gateways/${id}`} id={'Tooltip-' + id}>{cell}</Link>
                <UncontrolledTooltip placement="top" target={'Tooltip-' + id}>
                    {cell}
                </UncontrolledTooltip>
            </div>
        )
    }

    static ToGateway(cell, row) {
        const id = row.id;
        return <Link to={`/gateways/${id}`}> {cell} </Link>;
    }

    static Assigned(garage, cell) {

        if(cell.gatewayGarage == null){
            // not pool in microtronics
            if (cell.customer_id === config.get('microtronicsPoolId') || cell.customer_id === config.get('microtronicsNonCustomerId')) {
                return <div>Pool</div>
            } else {
                return <div>MC: {cell.customer_id}</div>
            }

        } else {
            return <Link to={`/garages/${cell.gatewayGarage.id}`}> {cell.gatewayGarage.name} </Link>
        }

    }

    static StateStyle(cell) {
        if (cell === 'ONLINE') {
            return <div><i className="fa fa-circle text-success Blink" /> {cell}</div>
        } else if (cell === 'OFFLINE') {
            return <div><i className="fa fa-circle text-danger Blink" /> {cell}</div>
        } else {
            return cell;
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            gateways: [],
            page: 1,
            totalSize: 0,

            searchQuery: "",
            stateFilter: "",
            typeFilter: "",
            product_type: null,
            tenant_id: "",
            assignedFilter: null
        };

        this.unsuccessful = this.unsuccessful.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.fetchGateways = this.fetchGateways.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onTenantSearch = this.onTenantSearch.bind(this);
        this.getClearButton = this.getClearButton.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);

        this.getStateFilter = this.getStateFilter.bind(this);
        this.handleStateFilter = this.handleStateFilter.bind(this);

        this.getTypeFilter = this.getTypeFilter.bind(this);
        this.handleTypeFilter = this.handleTypeFilter.bind(this);

        this.getProductTypeFilter = this.getProductTypeFilter.bind(this);
        this.handleProductTypeFilter = this.handleProductTypeFilter.bind(this);

        this.getAssignedFilter = this.getAssignedFilter.bind(this);
        this.handleAssignedFilter = this.handleAssignedFilter.bind(this);

        this.getTenantFilter = this.getTenantFilter.bind(this)

        this.typingTimer = null;
        this.typingInterval = 600;

    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg);
    }

    getQueryParams() {
        return {
            limit: 10,
            offset: (this.state.page - 1) * 10,
            searchTerm: this.state.searchQuery,
            stateFilter: this.state.stateFilter,
            typeFilter: this.state.typeFilter,
            product_type: this.state.product_type,
            tenant_id: this.state.tenant_id,
            assignedFilter: this.state.assignedFilter
        }
    }

    componentDidMount() {

        gatewayService.getService(getGatewaysQuery(this.getQueryParams()))
            .then((gateways) => {
                this.setState({
                    gateways: gateways.data.getGateways.gateways,
                    loading: false,
                    totalSize: gateways.data.getGateways.count
                });
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });

        document.title = "PAYUCA Cockpit | Gateways"
    }


    fetchGateways() {
        gatewayService.getService(getGatewaysQuery(this.getQueryParams()))
            .then((gateways) => {
                this.setState({
                    gateways: gateways.data.getGateways.gateways,
                    loading: false,
                    totalSize: gateways.data.getGateways.count
                });
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                gateways: [],
                searchQuery: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchGateways, this.typingInterval)
            })
        }
    }

    onTenantSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.tenant_id !== val) {
            this.setState({
                page: 1,
                loading: true,
                gateways: [],
                tenant_id: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchGateways, this.typingInterval)
            })
        }
    }

    getTenantFilter = () => {
        return (
            <CustomSearchField
                placeholder="Search Tenant ID"
                search={this.onTenantSearch}/>
        )
    };

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.searchQuery !== "") {
            this.setState({
                page: 1,
                loading: true,
                gateways: [],
                searchQuery: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchGateways, this.typingInterval)
            })
        }
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "No gateways found"
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            loading: true,
            gateways: [],
            page
        }, this.fetchGateways)
    }

    get tableOptions() {
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            searchField: this.getSearchField,
            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,

            page: this.state.page,
            sizePerPage: 10
        }
    }

    handleStateFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            stateFilter: val
        }, this.fetchGateways)
    }


    getStateFilter() {
        return (
            <CustomDropdownFilter
                options={[{
                    value: "ONLINE", label:"ONLINE"
                }, {
                    value:"OFFLINE", label: "OFFLINE"
                }]}
                filterHandler={this.handleStateFilter}/>
        )
    }

    handleTypeFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            typeFilter: val
        }, this.fetchGateways)
    }

    getTypeFilter() {
        let options = [
            {value: "CHECK_IN", label: "Check In"},
            {value: "CHECK_OUT", label: "Check Out"},
            {value: "CHECK_IN_OUT", label: "Check In/Out"},
            {value: "MIDGATEWAY", label: "Zwischentür"},
            {value: "MIDGATEWAY_ONLINE", label: "Zwischentür (Online)"},
            {value: "MIDGATEWAY_OFFLINE", label: "Zwischentür (Offline)"},
            {value: "NFC_WRITE", label: "NFC Gateway"},
            {value: "EXIT_BARRIER", label: "Ausfahrtsschranken"},
            {value: "EXIT_BARRIER_ONLINE", label: "Ausfahrtsschranken (Online)"},
            {value: "EXIT_BARRIER_OFFLINE", label: "Ausfahrtsschranken (Offline)"},
        ];
        return (
            <CustomDropdownFilter
                options={options}
                filterHandler={this.handleTypeFilter}/>
        )
    }

    handleProductTypeFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            product_type: val
        }, this.fetchGateways)
    }

    getProductTypeFilter() {
        let options = [
            {value: "B2C", label: "B2C"},
            {value: "B2B", label: "B2B"},
            {value: "B2B_B2C", label: "Hybrid"},
        ];
        return (
            <CustomDropdownFilter
                options={options}
                filterHandler={this.handleProductTypeFilter}/>
        )
    }

    handleAssignedFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            assignedFilter: val === "true" ? true : (val === "false" ? false : null)
        }, this.fetchGateways)
    }

    getAssignedFilter() {
        let options = [
            {value: true, label: "Ja"},
            {value: false, label: "Nein"}
        ];
        return (
            <CustomDropdownFilter
                options={options}
                filterHandler={this.handleAssignedFilter}/>
        )
    }

    render() {

        return (
            <div className="animated">
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-organization" color="info" header="PAYUCA Gateways" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <DownloadGateways totalSize={this.state.totalSize} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <BootstrapTable data={this.state.gateways} version="4" striped remote fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions} search>
                                    <TableHeaderColumn width='150' isKey={true} dataField="id" dataFormat={Gateways.IDTooltipFormatter}>ID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="device_id" dataFormat={Gateways.ToGateway}>Device ID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="name" dataFormat={Gateways.ToGateway}>Name</TableHeaderColumn>
                                    <TableHeaderColumn width='150'
                                                       dataField="type"
                                                       filterFormatted
                                                       dataFormat={ enumFormatter }
                                                       formatExtraData={ gatewayType }
                                                       filter={{ type: 'CustomFilter', getElement: this.getTypeFilter }}>Typ</TableHeaderColumn>
                                    <TableHeaderColumn width='100'
                                                       dataField="state"
                                                       dataFormat={ Gateways.StateStyle }
                                                       formatExtraData={ gatewayState }
                                                       filter={{ type: 'CustomFilter', getElement: this.getStateFilter }}>State</TableHeaderColumn>
                                    <TableHeaderColumn width='170'
                                                       dataField="gatewayGarage"
                                                       filter={{ type: 'CustomFilter', getElement: this.getAssignedFilter }}
                                                       dataFormat={Gateways.Assigned}>Assigned</TableHeaderColumn>
                                    <TableHeaderColumn dataField="tenant_name"
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getTenantFilter
                                                       }}>Tenant Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField="product_type"
                                                       filterFormatted
                                                       dataFormat={ enumFormatter }
                                                       formatExtraData={ productTypes }
                                                       filter={{ type: 'CustomFilter', getElement: this.getProductTypeFilter }}>Product Type</TableHeaderColumn>
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Gateways;
