import React, {Component} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Label, Input, Collapse} from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-toastify/dist/ReactToastify.css';
import datetimeFormatter from "../../../utils/datetimeFormatter";
import userCreditsFormat from "../../../utils/creditsFormatter";
import percentageCalculator from "../../../utils/percentageCalculator";


class StartParking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedGarage: '',
            timestamp: new Date(),
            licence_plate: this.props.user.licence_plates.length ? this.props.user.licence_plates[0].plate_number : "",
            openDoor: false,
            ignoreCredits: false,
            collapse: false,
            maxDate: new Date()
        };

        this.saveChanges = this.saveChanges.bind(this);
        this.openDoor = this.openDoor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.startParking = this.startParking.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({collapse: !this.state.collapse});
    }

    saveChanges(selectedGarage) {
        this.setState({selectedGarage});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        })
    }

    openDoor() {
        this.setState({
            openDoor: !this.state.openDoor,
        });
    }

    dateChange(timestamp) {
        this.setState({timestamp});
    }

    renderPrice() {
        const {id} = this.props.currentParking.transactionGarage;
        const {privileges} = this.props;
        const {total_price} = this.props.currentParking;
        let privilege;
        privilege = privileges.find((item) => {
            if ((item.privileged_object_id === id) || item.privileged_object_id === 'ALL') {
                return item
            }
        })
        let price;

        if (privilege) {
            price = percentageCalculator(privilege.discount_percent, total_price);
            return (
                <strong>{userCreditsFormat(price) || "0"} PAYUCA Credits</strong>
            )
        } else {
            return (
                <strong>{userCreditsFormat(this.props.currentParking.total_price) || "0"} PAYUCA Credits</strong>
            )
        }
    }

    startParking() {
        let data = {
            userId: this.props.user.id,
            licence_plate: this.state.licence_plate,
            garage_id: this.state.selectedGarage.value,
            startTime: datetimeFormatter.forSubmitting(this.state.timestamp),
            openDoor: this.state.openDoor,
            ignoreCredits: this.state.ignoreCredits,
        };

        this.props.startParking(data);
    }

    renderGarageSelect() {
        return this.props.currentParking ? (
            <Select
                name="selectedGarage"
                disabled
                value={this.props.currentParking.transactionGarage.id}
                options={this.props.garages}
            />
        ) : (
            <Select
                name="selectedGarage"
                value={this.state.selectedGarage}
                options={this.props.garages}
                onChange={this.saveChanges}
            />
        )
    }

    renderLicencePlate() {

        return this.props.currentParking ? (
            <Input type="text" name="licence_plate" disabled value={this.props.currentParking.user_licence_plate}/>

        ) : (
            <Input type="select" name="licence_plate" value={this.state.licence_plate} onChange={this.handleChange}>
                {
                    this.props.user.licence_plates.map((licence_plate) => {
                        return <option key={licence_plate.plate_number}
                                       value={licence_plate.plate_number}>{licence_plate.plate_number}</option>
                    })
                }
            </Input>
        )
    }

    renderStartTime() {
        return this.props.currentParking ? (
            <DateTimePicker locale="de" className="form-control"
                            value={datetimeFormatter.forPicker(this.props.currentParking.start_time)} disabled
                            showLeadingZeros={true}/>
        ) : (
            <DateTimePicker locale="de" className="form-control" maxDate={this.state.maxDate}
                            value={datetimeFormatter.forPicker(this.state.timestamp)} onChange={this.dateChange}
                            showLeadingZeros={true}/>
        )
    }

    getCardHeader() {
        return this.props.loading ? "Laden..." : (
            this.props.currentParking ? "Laufender Parkvorgang" : "Neuer Parkvorgang starten"
        )
    }


    render() {
        return (
            <Card className="border-success">
                <CardHeader onClick={this.toggle} style={{cursor: !this.state.collapse && "pointer"}}>
                    <i className="icon-control-play"></i><strong>{this.getCardHeader()}</strong>
                </CardHeader>
                <Collapse isOpen={this.state.collapse}>
                    <CardBody>
                        {this.props.loading ? (
                            <div className="sk-rotating-plane"/>
                        ) : (
                            <Form action="" method="post" className="form-horizontal">
                                <FormGroup row>
                                    <Col md="4">
                                        <Label><strong>Garage</strong></Label>
                                    </Col>
                                    <Col xs="12" md="8">
                                        {this.renderGarageSelect()}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="4">
                                        <Label><strong>Kfc-Kennzeichen</strong></Label>
                                    </Col>
                                    <Col xs="12" md="8">
                                        {this.renderLicencePlate()}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="4">
                                        <Label><strong>Parkvorgang-Start</strong></Label>
                                    </Col>
                                    <Col xs="12" md="8">
                                        {this.renderStartTime()}
                                    </Col>
                                </FormGroup>
                                {!this.props.currentParking && (
                                    <FormGroup row>
                                        <Col md="4">
                                            <Label><strong>Tor/Tür öffnen</strong></Label>
                                        </Col>
                                        <Col xs="12" md="8">
                                            <Button color={!this.state.openDoor ? "danger" : "success"}
                                                    className="btn-block"
                                                    onClick={this.openDoor}>{!this.state.openDoor ? "Nein" : "Ja"}</Button>
                                        </Col>
                                    </FormGroup>
                                )}
                                <FormGroup row>
                                    <Col md="4">
                                        <Label><strong>Ignore credits</strong></Label>
                                    </Col>
                                    <Col xs="12" md="8">
                                        <Input type="select" name="ignoreCredits" value={this.state.ignoreCredits}
                                               onChange={this.handleChange} disabled={this.props.currentParking}>
                                            <option value={false}>Nein</option>
                                            <option value={true}>Ja</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                {this.props.currentParking && (
                                    <FormGroup row>
                                        <Col md="4">
                                            <Label><strong>Laufende Kosten</strong></Label>
                                        </Col>
                                        <Col xs="12" md="8">
                                            {this.props.currentParking && this.renderPrice()}
                                        </Col>
                                    </FormGroup>
                                )}

                            </Form>
                        )}


                    </CardBody>
                    {(!this.props.currentParking && !this.props.loading) && (
                        <CardFooter>
                            <Button type="submit" size="sm" color="primary" className={"mr-1"}
                                    onClick={this.startParking}
                                    disabled={!this.state.selectedGarage || this.props.loadingStart || !this.state.licence_plate}>
                                <i className="fa fa-dot-circle-o"></i> {this.props.loadingStart ? "Wird gemacht..." : "Parkvorgang starten"}
                            </Button>
                        </CardFooter>
                    )}
                </Collapse>
            </Card>
        );
    }
}

export default StartParking;
