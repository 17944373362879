import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Label} from 'reactstrap';
import {CSVLink} from 'react-csv';
import DateTimePicker from "react-datetime-picker";
import Widget from "../../../components/Widget/Widget";
import statsService from "../../../services/statsService";
import datetimeFormatter from "../../../utils/datetimeFormatter";

class GarageReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            garage_id: this.props.garage_id,
            garageReport: [],
            overallDateFrom: null,
            overallDateTo: null,
            modalOpen: false,
            generateVisible: true,
        };

        this.fetchReport = this.fetchReport.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.clearData = this.clearData.bind(this);
    }

    onDateChanged(name, fromTo, val) {

        this.setState({
            [name + 'Date' + fromTo[0].toUpperCase() + fromTo.slice(1)]: val
        });

    }

    clearData() {
        this.setState({
            generateVisible: true,
            overallDateFrom: null,
            overallDateTo: null,
        })
    }

    fetchReport() {
        let params = {};

        if (this.state.overallDateFrom !== null) {
            params['since'] = datetimeFormatter.forStats(this.state.overallDateFrom);
        }
        if (this.state.overallDateTo !== null) {
            params['to'] = datetimeFormatter.forStats(this.state.overallDateTo);
        }

        statsService.getGarageReport(this.props.garage_id, params)
            .then((garageReport) => {
                this.setState({
                    garageReport: [garageReport.data],
                    generateVisible: false,
                })
            })
            .catch((err) => {
                this.setState({
                    generateVisible: false,
                })
                console.log(JSON.stringify(err));
            });
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}>Download Garage Report</ModalHeader>
                <ModalBody>
                    <Row className="pb-3">
                        <Col lg={6}>
                            <Label htmlFor="dateFrom" className="pr-3">Ab: </Label>
                            <DateTimePicker locale="de" className="form-control"
                                            value={datetimeFormatter.forPicker(this.state.overallDateFrom)}
                                            onChange={this.onDateChanged.bind(this, 'overall', 'from')}
                                            showLeadingZeros={true}
                            />
                        </Col>
                        <Col lg={6}>
                            <Label htmlFor="dateTo" className="pr-3">Bis: </Label>
                            <DateTimePicker locale="de" className="form-control"
                                            value={datetimeFormatter.forPicker(this.state.overallDateTo)}
                                            onChange={this.onDateChanged.bind(this, 'overall', 'to')}
                                            showLeadingZeros={true}
                            />
                        </Col>
                    </Row>
                    {
                        this.state.generateVisible ?
                        <Row>
                            <Col lg={12}>
                                <Button className="btn btn-success btn-lg btn-block" onClick={this.fetchReport}>
                                    Generate Report
                                </Button>
                            </Col>
                        </Row>
                            :
                        <CSVLink data={this.state.garageReport}
                                 filename={"garageReport.csv"}
                                 className="noDecoLink"
                                 target="_blank"
                                 onClick={this.clearData}
                        >
                            <Button className="btn btn-success btn-lg btn-block">
                                Download Report
                            </Button>
                        </CSVLink>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.toggleModal}>
                        Abbrechen
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        return (
            <Col xs="12" sm="12" lg="2">
                {this.renderModal()}
                <Widget
                    icon="icon-plus"
                    color="success"
                    header="Report"
                    invert
                    style={{cursor: "pointer"}}
                    onClick={this.toggleModal}
                />
            </Col>
        )
    }
}

export default GarageReport;
