import React, {Component} from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Widget from "../../components/Widget/Widget";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import DateTimePicker from "react-datetime-picker";
import moment from "moment";

import 'spinkit/css/spinkit.css';
import 'react-select/dist/react-select.min.css';

import datetimeFormatter from '../../utils/datetimeFormatter';
import couponService from "../../services/couponService";


class addCoupon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            starts_at: new Date(),
            expires_at: new Date(),
            collective: '',
            code: '',
            value: '',
            active: '',
            new_user_voucher: '',
            showError: false,
            errors: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleSuccess = this.toggleSuccess.bind(this);
        this.validFromHandler = this.validFromHandler.bind(this);
        this.validUntilHandler = this.validUntilHandler.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.addNewCoupon = this.addNewCoupon.bind(this);
        this.mapErrors = this.mapErrors.bind(this);
    }

    handleChange(event) {
        if (!this.state.disabled) {
            const target = event.target;
            const value = target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        }
    }

    toggleSuccess() {
        this.setState({
            success: !this.state.success,
        });
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    validFromHandler = starts_at => this.setState({ starts_at });
    validUntilHandler = expires_at => this.setState({ expires_at });


    addNewCoupon() {

        couponService.createCoupon({
            code: this.state.code,
            value: this.state.value,
            starts_at: datetimeFormatter.forSubmitting(this.state.starts_at),
            expires_at: datetimeFormatter.forSubmitting(this.state.expires_at),
            active: this.state.active,
            collective: this.state.new_user_voucher === 'true' ? true : this.state.collective,
            new_user_voucher: this.state.new_user_voucher
        })
            .then((response) => {
                this.props.history.push("/coupons");
                this.success();
            })
            .catch((err) => {
                this.setState({
                    showError: true,
                    errors: Object.entries(err.response.data.error)
                }, () => {
                    this.mapErrors();
                });
            });
    }

    mapErrors() {
        String.prototype.capitalize = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        };

        return (
            this.state.errors.map((error) => {
                if (error[0] === 'non_field_errors') {
                    this.unsuccessful(error[1][0]);
                } else {
                    this.unsuccessful(error[0].capitalize() + ": " + error[1])
                }
            })
        )
    }

    componentDidMount() {
        document.title = "PAYUCA | Gutschein hinzufügen";

        this.setState({
            loading: false
        })
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (!this.state.loading) {
            return (
                <div className="animated fadeIn">
                    <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                    <Row>
                        <Col xs="12" sm="12" lg="12">
                            <Widget icon="icon-tag" color="info" header="Gutschein hinzufügen"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12" md="12">
                            <Card>
                                <CardHeader>
                                    Gutschein Infos
                                </CardHeader>
                                <CardBody>
                                    <Form action="" method="post" encType="multipart/form-data"
                                          className="form-horizontal">
                                        <Row>
                                            <Col xs="12" sm="12" md="6">
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="selectSm">Code</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" name="code" id="code" value={this.state.code}
                                                               onChange={this.handleChange}/>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="selectSm">Wert</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="text" name="value" id="value"
                                                               value={this.state.value} onChange={this.handleChange}/>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="validityFrom">Gültig ab</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <DateTimePicker locale="de" className="form-control"
                                                                        value={this.state.starts_at}
                                                                        onChange={this.validFromHandler}
                                                                        showLeadingZeros={true}/>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="selectSm">Gültig bis</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <DateTimePicker locale="de" className="form-control"
                                                                        value={this.state.expires_at}
                                                                        onChange={this.validUntilHandler}
                                                                        showLeadingZeros={true}/>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" sm="12" md="6">
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="selectSm">Aktiv</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="select" name="active" id="active"
                                                               value={this.state.active} onChange={this.handleChange}>
                                                            <option value="" disabled defaultValue>Bitte wählen</option>
                                                            <option value={true}>Ja</option>
                                                            <option value={false}>Nein</option>
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="selectSm">Sammelgutschein*</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="select" name="collective" id="collective"
                                                               value={this.state.collective}
                                                               onChange={this.handleChange}>
                                                            <option value="" disabled defaultValue>Bitte wählen</option>
                                                            <option value={true} name="Ja">Ja</option>
                                                            <option value={false} name="Nein">Nein</option>
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md="3">
                                                        <Label htmlFor="selectSm">Nur neue Benutzer*</Label>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                        <Input type="select" name="new_user_voucher" id="new_user_voucher"
                                                               value={this.state.new_user_voucher}
                                                               onChange={this.handleChange}
                                                               disabled={this.state.disabled}>
                                                            <option value="" disabled defaultValue>Bitte wählen</option>
                                                            <option value={true} name="Ja">Ja</option>
                                                            <option value={false} name="Nein">Nein</option>
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <small className="pull-right">
                                                    *<strong>Sammelgutschein</strong>: Jeder kann diesen Gutschein einlösen.
                                                </small>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <small className="pull-right">
                                                    **<strong>Nur neue Benutzer</strong>: Nur Benutzer mit Registrierungsdatum innerhalb der Gutscheincode Gültigkeit und ohne andere Aufladungen
                                                </small>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Button className="pull-right" color="success" disabled={!this.state.code || !this.state.value} onClick={this.addNewCoupon}>Gutschein
                                        hinzufügen</Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    {/* Modals */}

                    {/* Success modal */}
                    <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
                           className={'modal-success ' + this.props.className}>
                        <ModalHeader toggle={this.toggleSuccess}>Gutschein hinzugefügt</ModalHeader>
                        <ModalBody>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                            ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisi ut
                            aliquip ex ea commodo consequat.
                        </ModalBody>
                    </Modal>

                </div>
            );
        } else {
            return (<div className="sk-rotating-plane"/>);
        }
    }
}

export default addCoupon;
