import gql from "graphql-tag";

export default function query(params) {

    return gql`
        mutation {
            deletePCGateway(
                id: "${params.id}"
            ) {
                errors
            }
        }`;
}
