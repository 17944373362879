import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './components/DefaultLayout';


function Loading() {
    return <div>Loading...</div>;
}

const Users = Loadable({
    loader: () => import('./views/Users/Users'),
    loading: Loading,
});

const User = Loadable({
    loader: () => import('./views/Users/User'),
    loading: Loading,
});

const Garage = Loadable({
    loader: () => import('./views/Garages/Garage'),
    loading: Loading,
});

const AddGarage = Loadable({
    loader: () => import('./views/Garages/AddGarage'),
    loading: Loading,
});

const Garages = Loadable({
    loader: () => import('./views/Garages/Garages'),
    loading: Loading,
});

const Coupons = Loadable({
    loader: () => import('./views/Coupons/Coupons'),
    loading: Loading,
});

const Coupon = Loadable({
    loader: () => import('./views/Coupons/Coupon'),
    loading: Loading,
});

const addCoupon = Loadable({
    loader: () => import('./views/Coupons/addCoupon'),
    loading: Loading,
});

const Settings = Loadable({
    loader: () => import('./views/Settings/Settings'),
    loading: Loading,
});

const Contacts = Loadable({
    loader: () => import('./views/Contacts/Contacts'),
    loading: Loading,
});

const Contact = Loadable({
    loader: () => import('./views/Contacts/Contact'),
    loading: Loading,
});

const Companies = Loadable({
    loader: () => import('./views/Companies/Companies'),
    loading: Loading,
});

const Company = Loadable({
    loader: () => import('./views/Companies/Company'),
    loading: Loading,
});

const AddCompany = Loadable({
    loader: () => import('./views/Companies/AddCompany'),
    loading: Loading,
});

const AddContact = Loadable({
    loader: () => import('./views/Contacts/AddContact'),
    loading: Loading,
});

const Gateways = Loadable({
    loader: () => import('./views/Gateways/Gateways'),
    loading: Loading,
});
const VersionBlock = Loadable({
    loader: () => import('./views/VersionBlock/VersionBlock'),
    loading: Loading,
});
const AddVersionBlock  =Loadable({
    loader: () => import('./views/VersionBlock/AddVersionBlock'),
    loading: Loading,
});
const UpdateVersionBlock = Loadable({
    loader: () => import('./views/VersionBlock/UpdateVersionBlock'),
    loading: Loading,
});

const Gateway = Loadable({
    loader: () => import('./views/Gateways/Gateway'),
    loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: '/', name: 'Home', component: DefaultLayout, exact: true },
    { path: '/users/:id', name: 'App-Kunde', component: User },
    { path: '/users', name: 'Kunden', component: Users },
    { path: '/garages/addGarage', name: 'Garage hinzufügen', component: AddGarage },
    { path: '/garages/:id', name: 'Garage', component: Garage },
    { path: '/garages', name: 'Garagen', component: Garages },
    { path: '/coupons/:id', name: 'Gutschein', component: Coupon },
    { path: '/coupons/addCoupon', name: 'Gutschein hinzufügen', component: addCoupon },
    { path: '/coupons', name: 'Gutscheine', component: Coupons },
    { path: '/settings', name: 'Einstellungen', component: Settings },
    { path: '/contacts/contact', name: 'Kontaktperson', component: Contact },
    { path: '/contacts/addContact', name: 'Kontaktperson hinzufügen', component: AddContact },
    { path: '/contacts', name: 'Kontaktpersonen', component: Contacts },
    { path: '/companies/company', name: 'Firma', component: Company },
    { path: '/companies/addCompany', name: 'Firma hinzufügen', component: AddCompany },
    { path: '/companies', name: 'Firmen', component: Companies },
    { path: '/gateways/gateway', name: 'Gateway', component: Gateway },
    { path: '/gateways', name: 'Gateways', component: Gateways },
    { path: '/version-block', name: 'Version Block', component: VersionBlock },
    { path: '/version-block/add', name: 'Add version block', component: AddVersionBlock },
    { path: '/version-block/:id', name: 'Update version block', component: UpdateVersionBlock },
];

export default routes;
