import gql from "graphql-tag";


export default function query(params) {
    return gql`
        mutation {
            updateGarage(
                id: "${params.id}"
                state: ${params.state}
                maintenance_estimated_end: "${params.maintenance_estimated_end}"
                maintenance_reason: ${params.maintenance_reason}
            ) {
                errors
                garage {
                    id
                    state
                    maintenance_estimated_end
                    maintenance_reason
                }
            }
        }`
}
