import gql from 'graphql-tag';

export default function (params) {
    return gql`
    mutation{
        assignPCNode(
        id: "${params.id}"
        master_id: "${params.master_id}"
        ){
            errors
            gateway{
                id
                device_id
                garage_id
                master_id
                type
                gwid
                node_number
                relay_timings
                stm_fw
                rf_fw
                esp_fw
                note
                created_at
                updated_at
                deleted_at
            }
        }
    }
    `;
}
