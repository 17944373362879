import gql from "graphql-tag";


export default function query(params) {

    return gql`
        query {
            getTenants {
                errors,
                tenants {
                    Id,
                    Name
                }
            }
        }`;
}
