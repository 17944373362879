import gql from "graphql-tag";


export default function(params){
    return gql`
        mutation {
            deleteInventoryEntry(
                id: "${params.id}"
            ) {
                errors
                inventoryEntry {
                    id
                }
            }
        }`;
}