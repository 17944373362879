import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import {Link} from "react-router-dom";

import logo from '../../assets/img/brand/logo.svg';

class SystemOffline extends Component {

    componentDidMount() {
        document.title = "PAYUCA Cockpit | System Offline";
    }

    render() {
        return (
            <div className="app appLogin flex-row align-items-center animated fadeIn">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <Link to={"/"}>
                                <img src={logo} style={{width: 200}} className="p-3" alt="PAYUCA Logo"/>
                            </Link>
                            <Card className="p-4">
                                <CardBody>
                                    <h1>System offline</h1>
                                    <p className="text-muted">Bitte versuche es erneut in wenigen Minuten.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default SystemOffline;
