import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";
import "spinkit/css/spinkit.css";
import "react-toastify/dist/ReactToastify.css";
import "ladda/dist/ladda-themeless.min.css";
import Widget from "../../components/Widget/Widget";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import versionBlockService from "../../services/versionBlockService";
import { Redirect } from "react-router-dom";

const options = [
  { value: "ios", label: "iOS only" },
  { value: "android", label: "Android only" },
  { value: "both", label: "Both" },
  { value: "neither", label: "Neither" },
];

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

class UpdateVersionBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      disabled: true,
      redirect: false,
      id: this.props.match.params.id,
      message_de: "",
      message_en: "",
      blocks: undefined,
      version: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.success = this.success.bind(this);
    this.unsuccessful = this.unsuccessful.bind(this);
    this.loadEntry = this.loadEntry.bind(this);
    this.updateEntry = this.updateEntry.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  success() {
    return toast.success("Erfolgreich!");
  }

  unsuccessful(msg = "Erfolglos!") {
    return toast.error(msg);
  }

  getEntryId() {
    const {
      match: { params },
    } = this.props;
    return params.id;
  }

  componentDidMount() {
    this.loadEntry(this.state.id);
  }

  loadEntry(id) {
    versionBlockService.getVersionInfo(id).then((entry) => {
      const { id, ios, android, message_de, version, message_en } = entry;
      const blocks =
        ios && android ? "both" : android ? "android" : ios ? "ios" : "neither";
      this.setState(
        {
          blocks,
          message_de,
          message_en,
          version,
          loading: false,
        },
        () => {
          console.log("[this.state] is of value: ", this.state);
        }
      );
    });
  }
  deleteEntry() {
    versionBlockService
      .updateVersion(this.state.id, { toDelete: true })
      .then(() => {
        this.setState({ redirect: true });
        this.success();
      })
      .catch((err) => {
        this.unsuccessful();
      });
  }
  updateEntry() {
    const { id, message_en, message_de, version, blocks } = this.state;
    let blocksAndroid = false;
    let blocksIOS = false;
    switch (blocks) {
      case "both":
        blocksAndroid = true;
        blocksIOS = true;
        break;

      case "ios":
        blocksIOS = true;
        break;

      case "android":
        blocksAndroid = true;
        break;

      default:
        break;
    }

    versionBlockService
      .updateVersion(id, {
        message_de,
        message_en,
        version,
        blocksAndroid,
        blocksIOS,
      })
      .then(() => {
        this.success();
        this.setState({ redirect: true });
      })
      .catch((err) => {
        console.log("[err] is of value: ", { ...err });
        this.unsuccessful();
      });
  }
  render() {
    let { invoice_emails } = this.state;

    if (this.state.loading) {
      return <div className="sk-rotating-plane" />;
    }

    const containerStyle = {
      zIndex: 1999,
    };
    if (this.state.redirect) {
      return <Redirect to="/version-block" />;
    }

    return (
      <div className="animated fadeIn">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          style={containerStyle}
        />
        <Row>
          <Col xs="8">
            <Widget
              icon="icon-people"
              color="info"
              header={`Updating entry ${this.state.version}`}
            />
          </Col>
          <Col xs="2">
            <Widget
              icon="icon-plus"
              color="success"
              invert
              header={`Update`}
              style={{ cursor: "pointer" }}
              onClick={this.updateEntry}
            />
          </Col>
          <Col xs="2">
            <Widget
              icon="icon-trash"
              color="danger"
              header="Delete"
              invert
              style={{ cursor: "pointer" }}
              onClick={this.deleteEntry}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12">
            <Card>
              <CardHeader>Release info</CardHeader>
              <CardBody>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="version">Version</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            type="text"
                            name="version"
                            id="version"
                            value={this.state.version}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="os">Blocked OS</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Select
                            isSearchable={false}
                            options={options}
                            isMulti={false}
                            value={this.state.blocks}
                            name="os"
                            onChange={(e) => {
                              this.setState({ blocks: e ? e.value : "" });
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="message_de">German message</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            type="text"
                            name="message_de"
                            id="message_de"
                            value={this.state.message_de}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="message_en">English message</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            type="text"
                            name="message_en"
                            id="message_en"
                            value={this.state.message_en}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UpdateVersionBlock;
