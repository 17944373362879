import React, {Component} from 'react';
import {Card, CardBody, CardHeader,} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import moment from 'moment';
import paymentService from "../../../services/paymentsService";

export default class UserFinances extends Component {

    static dateFormatter(cell) {
        if (!cell) {
            return "Nicht verfügbar"
        } else {
            if(cell.endsWith("Z")){
                return moment(cell).format("DD.MM.YYYY HH:mm");
            } else {
                return moment(cell + "Z").format("DD.MM.YYYY HH:mm");

            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            user_id: this.props.user_id,
            loading: true,
            page: 1,
            totalSize: 0,
            sizePerPage: 10,
        };


        this.fetchCreditLogs = this.fetchCreditLogs.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.unsuccessful = this.unsuccessful.bind(this)
    }

    get tableOptions() {
        return {
            sortIndicator: true,
            hideSizePerPage: false,
            paginationSize: Math.ceil(this.state.totalSize / this.state.sizePerPage)  || 5,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,
            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            page: this.state.page,
            sizePerPage: this.state.sizePerPage || 10,
        }
    }

    static renderCreditsAdded(cell, row) {
        return row.credit_added.toFixed(2)
    }

    static renderBonusAdded(cell, row) {
        return row.bonus_added.toFixed(2)
    }

    static renderCreditBalances(cell, row) {
        return row.old_balance_credit.toFixed(2) + " → " + row.new_balance_credit.toFixed(2)
    }

    static renderBonusBalances(cell, row) {
        return row.old_balance_bonus.toFixed(2) + " → " + row.new_balance_bonus.toFixed(2)
    }

    unsuccessful(msg) {
        this.props.unsuccessful(msg);
    }

    getQueryParams() {
        return {
            limit: this.state.sizePerPage || 10,
            offset: (this.state.page - 1) * (this.state.sizePerPage || 10),
            id: this.state.user_id
        };
    }

    fetchCreditLogs() {
        paymentService.getCreditLogs(this.getQueryParams())
            .then((creditLogs) => {
                this.setState({
                    creditLogs: creditLogs.data.log,
                    totalSize: creditLogs.data.count,
                    loading: false,
                })
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
            })
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            loading: true,
            creditLogs: [],
            page,
            sizePerPage
        }, this.fetchCreditLogs)
    }

    componentDidMount() {
        this.fetchCreditLogs();
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "Keine Credit-Logs gefunden"
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Finanztransaktionen
                </CardHeader>
                <CardBody>
                        <BootstrapTable data={this.state.creditLogs} version="4" striped hover pagination remote fetchInfo={{dataTotalSize: this.state.totalSize}} options={this.tableOptions}>
                            <TableHeaderColumn width='90' isKey dataField="id">ID</TableHeaderColumn>
                            <TableHeaderColumn width='130' thStyle={{ whiteSpace: 'normal' }} dataField="credit_added" dataFormat={UserFinances.renderCreditsAdded}>Credits hinzugefügt</TableHeaderColumn>
                            <TableHeaderColumn width='130' thStyle={{ whiteSpace: 'normal' }} dataField="bonus_added" dataFormat={UserFinances.renderBonusAdded}>Bosnus-Credits hinzugefügt</TableHeaderColumn>
                            <TableHeaderColumn width='130' thStyle={{ whiteSpace: 'normal' }} dataFormat={UserFinances.renderCreditBalances}>Credits-Stand</TableHeaderColumn>
                            <TableHeaderColumn width='130' thStyle={{ whiteSpace: 'normal' }} dataFormat={UserFinances.renderBonusBalances}>Bonus-Credits-Stand</TableHeaderColumn>
                            <TableHeaderColumn thStyle={{ whiteSpace: 'normal' }} width='190' dataField="create_date" dataFormat={ UserFinances.dateFormatter }>Transaktionsdatum</TableHeaderColumn>
                            <TableHeaderColumn width='140' dataField="reference" tdStyle={ { whiteSpace: 'normal' } }>Referenz</TableHeaderColumn>
                            <TableHeaderColumn width='260' dataField="note" tdStyle={ { whiteSpace: 'normal' } }>Notiz</TableHeaderColumn>
                        </BootstrapTable>
                </CardBody>
            </Card>
        )
    }
}
