import gql from "graphql-tag";


export default function(params, withFile){
    const withFileMutation = gql`
        mutation {
            updateGarageContract(
                id: "${params.id}"
                garage_id: "${params.garage_id}"
                parking_spaces: ${params.parking_spaces}
                tariff: ${params.tariff}
                tariff_special: ${params.tariff_special}
                tariff_daily: ${params.tariff_daily}
                tariff_tact: ${params.tariff_tact}
                tariff_reservation: {
                    hour: ${params.tariff_reservation.hour}
                }
                active: ${params.active}
            ) {
                errors
                garageContract {
                    id
                    parking_spaces
                    file_path
                }
            }
            }`;

    const noFileMutation = gql`
        mutation {
            updateGarageContract(
                id: "${params.id}"
                garage_id: "${params.garage_id}"
                parking_spaces: ${params.parking_spaces}
                tariff: ${params.tariff}
                tariff_daily: ${params.tariff_daily}
                tariff_tact: ${params.tariff_tact}
                tariff_special: ${params.tariff_special}
                tariff_reservation: {
                    hour: ${params.tariff_reservation.hour}
                }
                active: ${params.active}
            ) {
                errors
                garageContract {
                    id
                    parking_spaces
                    file_path
                }
            }
        }`;

    return withFile ? withFileMutation : noFileMutation;
}
