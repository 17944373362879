import gql from "graphql-tag";


export default function query(params) {

    if (params.type === null && params.tenant_id === null) {
        return gql`
            mutation {
                updateGateway(
                    id: "${params.id}"
                    name: "${params.name}"
                    relay_hold: ${params.relay_hold}
                    led1_hold: ${params.led1_hold}
                    location: "${params.location}"
                    gwid: "${params.gwid}"
                    active: ${!params.active ? false : true}
                    note: "${!params.note ? '' : params.note.replace(/(\r\n|\n|\r)/gm, "\\n")}"
                    type: ${params.type}
                    tenant_id: ${params.tenant_id}
                    product_type: ${params.product_type}
                ) {
                    errors
                    gateway {
                        id
                        microtronics_id
                        note
                        device_id
                        name
                        state
                        type
                        gwid
                        gatewayGarage {
                            id
                            name
                        }
                        connection_device {
                            signal_strength
                        }
                        location
                        relay_hold
                        led1_hold
                        created_at
                        updated_at
                        deleted_at
                        active
                        tenant_id
                        tenant_name
                        product_type
                    }
                }
            }`;
    } else if (params.type !== null && params.tenant_id !== null) {
        return gql`
            mutation {
                updateGateway(
                    id: "${params.id}"
                    name: "${params.name}"
                    relay_hold: ${params.relay_hold}
                    led1_hold: ${params.led1_hold}
                    location: "${params.location}"
                    gwid: "${params.gwid}"
                    active: ${!params.active ? false : true}
                    note: "${!params.note ? '' : params.note.replace(/(\r\n|\n|\r)/gm, "\\n")}"
                    type: "${params.type}"
                    tenant_id: "${params.tenant_id}"
                    product_type: ${params.product_type}
                ) {
                    errors
                    gateway {
                        id
                        microtronics_id
                        note
                        device_id
                        name
                        state
                        type
                        gwid
                        gatewayGarage {
                            id
                            name
                        }
                        connection_device {
                            signal_strength
                        }
                        location
                        relay_hold
                        led1_hold
                        created_at
                        updated_at
                        deleted_at
                        active
                        tenant_id
                        tenant_name
                        product_type
                    }
                }
            }`;
    } else if (params.type === null && params.tenant_id !== null) {
        return gql`
            mutation {
                updateGateway(
                    id: "${params.id}"
                    name: "${params.name}"
                    relay_hold: ${params.relay_hold}
                    led1_hold: ${params.led1_hold}
                    location: "${params.location}"
                    gwid: "${params.gwid}"
                    active: ${!params.active ? false : true}
                    note: "${!params.note ? '' : params.note.replace(/(\r\n|\n|\r)/gm, "\\n")}"
                    type: ${params.type}
                    tenant_id: "${params.tenant_id}"
                    product_type: ${params.product_type}
                ) {
                    errors
                    gateway {
                        id
                        microtronics_id
                        note
                        device_id
                        name
                        state
                        type
                        gwid
                        gatewayGarage {
                            id
                            name
                        }
                        connection_device {
                            signal_strength
                        }
                        location
                        relay_hold
                        led1_hold
                        created_at
                        updated_at
                        deleted_at
                        active
                        tenant_id
                        tenant_name
                        product_type
                    }
                }
            }`;
    } else if (params.type !== null && params.tenant_id === null) {
        return gql`
            mutation {
                updateGateway(
                    id: "${params.id}"
                    name: "${params.name}"
                    relay_hold: ${params.relay_hold}
                    led1_hold: ${params.led1_hold}
                    location: "${params.location}"
                    gwid: "${params.gwid}"
                    active: ${!params.active ? false : true}
                    note: "${!params.note ? '' : params.note.replace(/(\r\n|\n|\r)/gm, "\\n")}"
                    type: "${params.type}"
                    tenant_id: ${params.tenant_id}
                    product_type: ${params.product_type}
                ) {
                    errors
                    gateway {
                        id
                        microtronics_id
                        note
                        device_id
                        name
                        state
                        type
                        gwid
                        gatewayGarage {
                            id
                            name
                        }
                        connection_device {
                            signal_strength
                        }
                        location
                        relay_hold
                        led1_hold
                        created_at
                        updated_at
                        deleted_at
                        active
                        tenant_id
                        tenant_name
                        product_type
                    }
                }
            }`;
    }
}
