import urls from '../utils/urls';
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from 'apollo-client';
import errorHandlerService from "./errorHandlerService";
const { createUploadLink } = require('apollo-upload-client');

const uploadLink = createUploadLink({
    uri: `${urls.apigateway}/services/`,
});

const client = new ApolloClient({
    link: uploadLink,
    cache: new InMemoryCache(),
    credentials: 'same-origin',
});

const gatewayService = {

    getService:  (query) => {
        return new Promise((resolve, reject) => {
            client.query({
                fetchPolicy: 'no-cache',
                query: query,
                context: {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('Access-Token')}`,
                        'Accept-Language': 'en',
                    }
                }
            }).then((resp) => {
                resolve(resp);
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("networkError") && err.networkError.hasOwnProperty("statusCode") && err.networkError.statusCode === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        gatewayService.getService, query
                    )
                }
            })
        });
    }
};

export default gatewayService;
