import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "reactstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import CustomDropdownFilter from "./CustomDropdownFilter";
import CustomSearchField from "./CustomSearchField";
import {Link} from "react-router-dom";

const gatewayType = {
    'CHECK_IN': 'Check In',
    'CHECK_OUT': 'Check Out',
    'CHECK_IN_OUT': 'Check In/Out',
    'MIDGATEWAY': 'Zwischentür',
    'MIDGATEWAY_ONLINE': 'Zwischentür (Online)',
    'MIDGATEWAY_OFFLINE': 'Zwischentür (Offline)',
    'REPEATER': 'Signalverstärker',
    'EXIT_BARRIER': 'Ausfahrtsschranken',
    'EXIT_BARRIER_ONLINE': 'Ausfahrtsschranken (Online)',
    'EXIT_BARRIER_OFFLINE': 'Ausfahrtsschranken (Offline)',
};

function enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
}

const propTypes = {
    nodes: PropTypes.array
};

const defaultProps = {
    nodes: []
};

class Nodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nodes: this.props.nodes,
            filteredNodes: null,
            gwidFilter: '',
            idFilter: '',
            typeFilter: '',
            stateFilter: '',
            totalSize: this.props.nodes.length
        };

        this.handleTypeFilter = this.handleTypeFilter.bind(this);
        this.getTypeFilter = this.getTypeFilter.bind(this);
    }

    handleTypeFilter(val) {
        this.setState({typeFilter: val}, () => this.filterSlaves());
    }

    getTypeFilter() {
        let options = [
            {value: "CHECK_IN", label: "Check In"},
            {value: "CHECK_OUT", label: "Check Out"},
            {value: "CHECK_IN_OUT", label: "Check In/Out"},
            {value: "MIDGATEWAY", label: "Zwischentür"},
            {value: "REPEATER", label: "Signalverstärker"},
            {value: "EXIT_BARRIER", label: "Ausfahrtsschranken"},
        ];
        return (
            <CustomDropdownFilter
                testId='type-filter'
                defaultValue={''}
                options={options}
                filterHandler={this.handleTypeFilter}/>
        )
    }

    getIDFilter = () => {
        return (
            <CustomSearchField
                testId="id-filter"
                placeholder="Search by ID"
                search={this.onIDFilter}/>
        )
    };

    getGwidFilter = () => {
        return (
            <CustomSearchField
                testId="name-filter"
                placeholder="Search by Name"
                search={this.onGwidFilter}/>
        )
    };

    filterSlaves = () => {
        const idFilter = this.state.idFilter;
        const gwidFilter = this.state.gwidFilter;
        const typeFilter = this.state.typeFilter.toUpperCase();

        if (!idFilter && !gwidFilter && !typeFilter) {
            this.setState({filteredNodes: null});
            return;
        }

        const filteredNodes = this.state.nodes.filter((slave) => {

            if(!slave.gwid){
                slave.gwid = '';
            }

            return slave.id.toUpperCase().includes(idFilter) &&
                slave.gwid.toUpperCase().includes(gwidFilter) &&
                (typeFilter ? slave.type.toUpperCase() === typeFilter : true)
        });
        this.setState({filteredNodes});
    };

    onIDFilter = (e) => {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ').toUpperCase();
        this.setState({idFilter: val}, () => this.filterSlaves());
    };

    onGwidFilter = (e) => {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ').toUpperCase();
        this.setState({gwidFilter: val}, () => this.filterSlaves());
    };

    static ToGateway(cell, row) {
        const id = row.id;
        return <Link data-testid='gwid-row' to={`/payuca-connect/${id}`}> {cell} </Link>;
    }

    getNoDataTableContent() {
        return "Keine Nodes gefunden";
    }

    get tableOptions() {
        return {
            sortIndicator: false,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,
            noDataText: this.getNoDataTableContent(),
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Nodes
                </CardHeader>
                <CardBody data-testid="nodes-table">
                    <BootstrapTable
                        data={this.state.filteredNodes ? this.state.filteredNodes : this.state.nodes}
                        version="4" striped remote
                        fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true}
                        options={this.tableOptions}>
                        <TableHeaderColumn width='150' isKey={true} dataField="id"
                                           dataFormat={Nodes.ToGateway}
                                           filter={{
                                               type: 'CustomFilter',
                                               getElement: this.getIDFilter
                                           }}>ID</TableHeaderColumn>
                        <TableHeaderColumn width='150' dataField="gwid"
                                           dataFormat={Nodes.ToGateway}
                                           filter={{
                                               type: 'CustomFilter',
                                               getElement: this.getGwidFilter
                                           }}>GWID</TableHeaderColumn>
                        <TableHeaderColumn width='150'
                                           dataField="type"
                                           filterFormatted
                                           dataFormat={enumFormatter}
                                           formatExtraData={gatewayType}
                                           filter={{
                                               type: 'CustomFilter',
                                               getElement: this.getTypeFilter
                                           }}>Typ</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        );
    }
}

Nodes.propTypes = propTypes;
Nodes.defaultProps = defaultProps;
export default Nodes;
