import gql from "graphql-tag";
import {quoteStringOrNull} from "../utils/quoteStringOrNull";

export default function query(params) {
    if (params.type === null) {
        return gql`
            mutation {
                updateNewGateway(
                    gwid: "${params.gwid}"
                    type: ${params.type}
                    name: "${params.name}"
                    location: ${quoteStringOrNull(params.location)}
                    firmware: ${quoteStringOrNull(params.firmware)}
                    product_type: "${params.product_type}"
                    userpool_id: ${quoteStringOrNull(params.userpool_id)}
                    note: "${!params.note ? '' : params.note.replace(/(\r\n|\n|\r)/gm, "\\n")}"
                ) {
                    errors
                    garageGateway {
                        id
                        microtronics_id
                        microtronics_site_id
                        device_id
                        gwid
                        name
                        location
                        note
                        state
                        master_gwid
                        type
                        slaves {
                            gwid
                            name
                            type
                            state
                        }
                        health_status {
                            nfc_state
                            ble_state
                            connection
                            rf_state
                            main_controller_state
                        }
                        createdAt
                        updatedAt
                        deletedAt
                        connection_device {
                            signal_strength
                        }
                        relay_hold
                        led1_hold
                        customer_id
                        is_master
                        active_relays
                        garage_id
                        product_type
                        userpool_id
                        firmware
                        garage_gateway {
                            name
                            id
                            state
                            echarger
                        }
                    }
                }
            }`;
    } else {
        return gql`
            mutation {
                updateNewGateway(
                    gwid: "${params.gwid}"
                    type: ${params.type}
                    name: "${params.name}"
                    location: ${quoteStringOrNull(params.location)}
                    firmware: ${quoteStringOrNull(params.firmware)}
                    product_type: "${params.product_type}"
                    userpool_id: ${quoteStringOrNull(params.userpool_id)}
                    note: "${!params.note ? '' : params.note.replace(/(\r\n|\n|\r)/gm, "\\n")}"
                ) {
                    errors
                    garageGateway {
                        id
                        microtronics_id
                        microtronics_site_id
                        device_id
                        gwid
                        name
                        location
                        note
                        state
                        master_gwid
                        type
                        slaves {
                            gwid
                            name
                            type
                            state
                        }
                        health_status {
                            nfc_state
                            ble_state
                            connection
                            rf_state
                            main_controller_state
                        }
                        createdAt
                        updatedAt
                        deletedAt
                        connection_device {
                            signal_strength
                        }
                        relay_hold
                        led1_hold
                        customer_id
                        is_master
                        active_relays
                        garage_id
                        product_type
                        userpool_id
                        firmware
                        garage_gateway {
                            name
                            id
                            state
                            echarger
                        }
                    }
                }
            }`;
    }
}
