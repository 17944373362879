import React, {Component} from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from "react-bootstrap-table";
import couponService from "../services/couponService";
import {Link} from "react-router-dom";
import CustomSearchField from "./CustomSearchField";


export default class CouponsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            coupons: [],
            loading: true,
            page: 1,
            totalSize: 0,
            searchQuery: ""
        };

        this.typingTimer = null;
        this.typingInterval = 550;

        this.fetchCoupons = this.fetchCoupons.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.getSearchField = this.getSearchField.bind(this);

        this.getClearButton = this.getClearButton.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);

        this.onPageChange = this.onPageChange.bind(this);

    }

    static toCoupon(cell, row) {
        const id = row.id;
        return <Link to={`/coupons/${id}`}> {cell} </Link>;
    }

    static active(cell) {
        return !cell ? "Nein" : "Ja"
    }

    static collective(cell) {
        return !cell ? "Nein" : "Ja"
    }

    componentDidMount() {
        this.fetchCoupons()
    }

    onPageChange(page) {
        this.setState({
            page
        }, this.fetchCoupons)
    }


    fetchCoupons() {
        couponService.getCoupons(this.tableOptions.sizePerPage, this.state.page, this.state.searchQuery)
            .then((resp) => {
                this.setState({
                    coupons: resp.data.results,
                    loading: false,
                    totalSize: resp.data.count
                });
            });
    }


    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                coupons: [],
                searchQuery: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchCoupons, this.typingInterval)
            })
        }
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.searchQuery !== "") {
            this.setState({
                page: 1,
                loading: true,
                coupons: [],
                searchQuery: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchCoupons, this.typingInterval)
            })
        }
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "Keine Gutscheine gefunden."
    }

    get tableOptions(){
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,
            searchField: this.getSearchField,

            page: this.state.page,
            sizePerPage: 10
        };
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <BootstrapTable data={this.state.coupons} version="4" striped remote fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions} search>
                        <TableHeaderColumn width='150' isKey={true} dataField="code" dataFormat={CouponsTable.toCoupon}>Code</TableHeaderColumn>
                        <TableHeaderColumn width='150' dataField="value" dataFormat={CouponsTable.toCoupon}>Wert</TableHeaderColumn>
                        <TableHeaderColumn width='150' dataField="active" dataSort dataFormat={CouponsTable.active}>Aktiv</TableHeaderColumn>
                        <TableHeaderColumn width='150' dataField="collective" dataSort dataFormat={CouponsTable.collective}>Sammelgutschein</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        )
    }


}
