import React, { Component } from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label
} from 'reactstrap';
import _isEqual from 'lodash/isEqual'
import 'spinkit/css/spinkit.css';
import paymentService from "../../../services/paymentsService";
import companyService from "../../../services/companyService";
import {toast} from "react-toastify";

export default class UserCredits extends Component {


    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            loading: true,
            bonusCredits: "Loading...",
            realCredits: "Loading..."
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_isEqual(prevProps.user, this.props.user)) {
            // props have been changed
            this.setState({
                user: this.props.user,
                bonusCredits: this.props.user.bonusCredits
            })
        }
    }

    componentDidMount() {

        paymentService.getCreditStatus(this.state.user.id)
            .then(resp => {
                this.setState({
                    bonusCredits: resp.data.payuca_bonus_credit,
                    realCredits: resp.data.payuca_credit
                })
            })
            .catch(() => {
                if (!this.props.user.inactive_reason) {
                    toast.error("Es ist ein Fehler aufgetreten. Bitte nochmal versuchen.");
                }
            });
    }

    render() {
        return (

            <Card>
                <CardHeader>
                    Credits
                    <div className="card-header-actions" />
                </CardHeader>
                <CardBody className={"pb-0"}>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="real_credits">Reales</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input disabled type="text" id="real_credits" name="real_credits" value={!this.state.realCredits ? "-" : this.state.realCredits} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="bonus_credits">Bonus</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input disabled type="text" id="bonus_credits" name="bonus_credits" value={!this.state.bonusCredits ? "-" : this.state.bonusCredits} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="total_credits">Insgesamt</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input disabled type="text" id="total_credits" name="total_credits" value={!(this.state.bonusCredits || this.state.realCredits) ? "-" : this.state.bonusCredits + this.state.realCredits} />
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
        );
    }
}

