import React from "react";
import placeholder from '../../../assets/img/placeholder.png'

export default function PlaceholderImage() {
    return (
        <img src={placeholder}
             alt={'Platzhalter'}
             className={'img-fluid'}
        />
    )
}