import React, {Component} from 'react';
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from "react-bootstrap-table";
import {
    Card, CardBody, CardHeader, Button, Col, Row
} from 'reactstrap';
import {Link} from "react-router-dom";
import moment from "moment";
import AsyncSelect from "react-select/lib/Async";
import userService from "../../services/userService";
import couponService from "../../services/couponService";
import CustomSearchField from "../../components/CustomSearchField";


export default class CouponsUsersTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            couponUsers: [],
            coupon: this.props.coupon,

            page: 1,
            loading: true,
            searchQuery: "",
            totalSize:0 ,

            assignUsersDisabled: true,

            assignedUsers: []
        };

        this.userTypingTimer = null;
        this.searchTypingTimer = null;
        this.typingInterval = 550;

        this.loadUsersOptions = this.loadUsersOptions.bind(this);
        this.toggleUsersDisabled = this.toggleUsersDisabled.bind(this);
        this.onPageChange = this.onPageChange.bind(this);

        this.getSearchField = this.getSearchField.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.getClearButton = this.getClearButton.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);

        this.fetchCoupons = this.fetchCoupons.bind(this);

        this.assignUsers = this.assignUsers.bind(this);
        this.unassignUser = this.unassignUser.bind(this);
        this.renderUnassignUser = this.renderUnassignUser.bind(this);
        this.saveUsers = this.saveUsers.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            coupon: {
                ...state.coupon,
                ...props.coupon
            }
        }
    }

    static toUser(cell, row) {
        const id = cell.id;
        return <Link to={`/users/${id}`}>{cell.email}</Link>;
    }

    static redeemed(cell) {
        return !cell ? "Nein" : moment(cell).format('DD.MM.YYYY HH:mm')
    }

    loadUsersOptions(value) {
        return new Promise(resolve => {
            clearTimeout(this.userTypingTimer);
            this.userTypingTimer = setTimeout(() => {
                userService.getUsers(1, "PR", value)
                    .then((resp) => {
                        resolve({
                            options: resp.data.results.map((user) => {
                                return {value: user.id, label: user.email }
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.typingInterval)
        });
    }

    toggleUsersDisabled() {
        this.setState({
            assignUsersDisabled: !this.state.assignUsersDisabled,
        });
    }


    fetchCoupons() {
        couponService.getCouponUsers(this.state.coupon.id, this.tableOptions.sizePerPage, this.state.page, this.state.searchQuery)
            .then((resp) => {
                this.setState({
                    couponUsers: resp.data.results,
                    loading: false,
                    totalSize: resp.data.count
                });
            })
            .catch((err) => {
                console.log(err)
            })
    }

    componentDidMount() {
        this.fetchCoupons()
    }

    onPageChange(page) {
        this.setState({
            page
        }, this.fetchCoupons)
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "No coupons found"
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                couponUsers: [],
                searchQuery: val
            }, () => {
                clearTimeout(this.searchTypingTimer);
                this.searchTypingTimer = setTimeout(this.fetchCoupons, this.typingInterval)
            })
        }
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.searchQuery !== "") {
            this.setState({
                page: 1,
                loading: true,
                couponUsers: [],
                searchQuery: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchCoupons, this.typingInterval)
            })
        }
    }

    assignUsers() {
        couponService.assignUsers(this.state.coupon.id, this.state.assignedUsers.map((user) => {return user.value}))
            .then((response) => {
                this.props.success();
                this.setState({
                    assignedUsers: [],
                    assignUsersDisabled: true
                }, this.fetchCoupons)
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    assignedUsers: []
                });
                this.props.unsuccessful();
            });
    }

    unassignUser(userID) {
        couponService.unassignUsers(this.state.coupon.id, userID)
            .then((response) => {
                this.props.success();
                this.fetchCoupons();
            })
            .catch((err) => {
                this.props.unsuccessful();
            });
    }

    renderUnassignUser(cell, row) {
        const userID = cell.id;
        const redeemed = row.redeemed;
        return (<Button
            className="btn btn-danger btn-block"
            disabled={redeemed}
            onClick={() => {this.unassignUser(userID)}}>
            Zuweisung aufheben
        </Button>)
    }


    saveUsers(assignedUsers) {
        this.setState({ assignedUsers: assignedUsers});
    }

    get tableOptions(){
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,
            searchField: this.getSearchField,

            page: this.state.page,
            sizePerPage: 10
        };
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Zuweisen an
                                <div className="card-header-actions">
                                    {
                                        !this.state.assignUsersDisabled
                                            ?
                                            <div>
                                                <Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.assignUsers}>
                                                    <i className="fa fa-dot-circle-o"></i> Speichern
                                                </Button>
                                                <Button type="reset" size="sm" color="danger" onClick={this.toggleUsersDisabled}>
                                                    <i className="fa fa-ban"></i> Abbrechen
                                                </Button>
                                            </div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleUsersDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <AsyncSelect
                                    value={this.state.assignedUsers}
                                    onChange={this.saveUsers}
                                    disabled={this.state.assignUsersDisabled}
                                    loadOptions={this.loadUsersOptions}
                                    multi
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Gutscheinsnutzer
                            </CardHeader>
                            <CardBody>
                                <BootstrapTable data={this.state.couponUsers} version="4" striped remote fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions} search>
                                    <TableHeaderColumn width='150' isKey={true} dataField="user_data" dataFormat={CouponsUsersTable.toUser}>Email</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="redeemed_at" dataFormat={CouponsUsersTable.redeemed}>Eingelöst</TableHeaderColumn>
                                    {!this.state.coupon.collective && <TableHeaderColumn width='150' dataField="user_data" dataFormat={this.renderUnassignUser}>Aktion</TableHeaderColumn>}
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}
