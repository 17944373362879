import gql from "graphql-tag";


export default function query(params) {
    return gql`   
        {
            getNfcTags(user_id: ${params.userId}) {
                errors
                NFC {
                    status
                    id
                    nfc_tag
                }
            }
        }
    `;
}
