import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';
import 'ladda/dist/ladda-themeless.min.css';
import Widget from "../../components/Widget/Widget";
import {ToastContainer, toast} from "react-toastify";
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import {history} from "../../App";
import garageOwnerService from "../../services/garageOwnerService";
import garageService from "../../services/garageService";
import getGaragesQuery from "../../queries/getGarages";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

class GarageOwner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            disabled: true,
            redirect: false,
            disabledNote: true,
            emailDisabled: true,
            garageDataDisabled: true,
            invoice_emails: [],
            email: '',
            assignedGarage: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleDisabled = this.toggleDisabled.bind(this);
        this.toggleNote = this.toggleNote.bind(this);
        this.toggleEmailDisabled = this.toggleEmailDisabled.bind(this);
        this.toggleGarageData = this.toggleGarageData.bind(this);
        this.addEmail = this.addEmail.bind(this);
        this.deleteEmail = this.deleteEmail.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.updateOwner = this.updateOwner.bind(this);
        this.onGarageChanged = this.onGarageChanged.bind(this);
        this.loadGarages = this.loadGarages.bind(this);
    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let owner = this.state.owner;
        owner[name] = value;

        this.setState({
            owner: owner,
            [name]: value
        });
    }

    selectCountry = (country) => {

        this.setState({
            owner: {
                ...this.state.owner,
                country: !country ? null : country.value
            }
        });
    };

    countryList() {
        let options = [];
        let countryList = countries.getNames("de");

        Object.entries(countryList).forEach(([key, val]) => { options.push({value: key, label: val})});

        this.setState({
            options: options
        })
    }

    /*toggleDelete() {
        this.setState({
            delete: !this.state.delete,
        });
    }*/

    toggleGarageData() {
        this.setState({
            garageDataDisabled: !this.state.garageDataDisabled,
        });
    }

    toggleDisabled() {
        this.setState({
            disabled: !this.state.disabled,
        });
    }

    toggleNote() {
        this.setState({
            disabledNote: !this.state.disabledNote,
        })
    }

    toggleEmailDisabled() {
        this.setState({
            emailDisabled: !this.state.emailDisabled,
        })
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    addEmail() {
        this.state.invoice_emails.push(this.state.email);
        this.setState(this.state);
        this.setState({
            email: ''
        })
    }

    deleteEmail(i){
        this.state.invoice_emails.splice(i, 1);

        this.setState({
            invoice_emails: this.state.invoice_emails
        })
    }

    getGarageOwner(id) {

        garageOwnerService.getOwner(id)
            .then((owner) => {
                owner = owner.data;
                this.setState({
                    assignedGarage: {
                        value: owner.garage,
                        label: owner.garage,
                        garage: owner.garage,
                        garage_address: owner.garage_address,
                        garage_address_number: owner.garage_address_number,
                        garage_city: owner.garage_city,
                        garage_zip: owner.garage_zip,
                        garage_country: owner.garage_country
                    },
                    owner: owner,
                    nameHeader: owner.name,
                    invoice_emails: owner.invoice_emails,
                    loading: false,
                }, () => {
                    document.title = "Garagenbesitzer | " + this.state.owner.name;
                })
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    history.push('/404')
                }
                console.log(err);
            });
    }

    updateOwner() {
        this.setState({
            loadingEdit: true
        });
        let garage = this.state.assignedGarage.value;

        let data = {
            ...this.state.owner,
            owner: {
                invoice_emails: this.state.invoice_emails
            },
            ...this.state.assignedGarage,
            garage: garage
        }

        garageOwnerService.updateOwner(data)
            .then((response) => {
                if (response) {
                    this.setState({
                        owner: response.data,
                        assignedGarage: {
                            value: response.data.garage,
                            label: response.data.garage,
                            garage: response.data.garage,
                            garage_address: response.data.garage_address,
                            garage_address_number: response.data.garage_address_number,
                            garage_city: response.data.garage_city,
                            garage_zip: response.data.garage_zip,
                            garage_country: response.data.garage_country
                        },
                        nameHeader: response.data.name,
                        invoice_emails: response.data.invoice_emails,
                        disabled: true,
                        disabledNote: true,
                        garageDataDisabled: true,
                        emailDisabled: true,
                    }, () => {
                        document.title = "Garagenbesitzer | " + this.state.owner.name;
                    });
                    this.success();
                }
            })
            .catch((err) => {
                this.setState({
                    disabled: false,
                });

                let errors = err.response.data.error;

                Object.entries(errors).map((error) => {
                    return this.unsuccessful(error[0] + ": " + error[1][0]);
                })
            });
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.getGarageOwner(params.id)
        this.countryList()
    }

    onGarageChanged(assignedGarage) {
        this.setState({
            assignedGarage: assignedGarage
        });
    }

    loadGarages(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    garageOwner: true
                };

                garageService.getService(getGaragesQuery(params))
                    .then((garages) => {
                        resolve({
                            options: garages.data.getGarages.garages.map((garage) => {
                                return {value: garage.name, label: garage.name, garage_city: garage.address.city, garage_address: garage.address.street, garage_address_number: garage.address.street_number, garage_zip: garage.address.zip_code, garage_country: garage.address.country }
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            }, this.typingInterval)

        })
    }

    render() {

        let { invoice_emails } = this.state;

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Widget icon="icon-people" color="info" header={this.state.nameHeader}/>
                    </Col>
                    {/*<Col xs="12" sm="12" md="2">
                        <Widget
                            icon="icon-trash"
                            color="danger"
                            header="Firma löschen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.toggleDelete}
                        />
                    </Col>*/}
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                Garage Daten
                                <div className="card-header-actions">
                                    {
                                        !this.state.garageDataDisabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateOwner}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleGarageData}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleGarageData}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="garage">Betrifft Garage</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <AsyncSelect
                                                        name="garage"
                                                        loadOptions={this.loadGarages}
                                                        value={!this.state.assignedGarage ? null : this.state.assignedGarage}
                                                        onChange={this.onGarageChanged}
                                                        disabled={this.state.garageDataDisabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address">Adresse</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_address" id="garage_address" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_address} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address_number">Hausnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_address_number" id="garage_address_number" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_address_number} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="zip">Postleitzahl</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_zip" id="garage_zip" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_zip} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="city">Stadt</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="garage_city" id="garage_city" value={!this.state.assignedGarage ? "" : this.state.assignedGarage.garage_city} onChange={this.handleChange} disabled />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="garage_country">Land</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={!this.state.assignedGarage ? null : this.state.assignedGarage.garage_country}
                                                        options={this.state.options}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                Firmendaten
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateOwner}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleDisabled}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">Firmenwortlaut</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="name" id="name" value={this.state.owner.name} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="for_attention_of">z.H.</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="for_attention_of" id="for_attention_of" value={this.state.owner.for_attention_of} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="registration_number">Firmenbuchnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="registration_number" id="registration_number" value={this.state.owner.registration_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="vat_reg_no">UID</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="vat_reg_no" id="vat_reg_no" value={this.state.owner.vat_reg_no} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="iban">IBAN*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="iban" id="iban" value={!this.state.owner.iban ? "" : this.state.owner.iban} onChange={this.handleChange} disabled={this.state.disabled} required={true}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="bic">BIC*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="bic" id="bic" value={!this.state.owner.bic ? "" : this.state.owner.bic} onChange={this.handleChange} disabled={this.state.disabled} required={true} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address">Adresse</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="address" id="address" value={this.state.owner.address} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address_number">Hausnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="address_number" id="address_number" value={this.state.owner.address_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="zip">Postleitzahl</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="zip" id="zip" value={this.state.owner.zip} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="city">Stadt</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="city" id="city" value={this.state.owner.city} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="country">Land</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={this.state.owner.country}
                                                        onChange={this.selectCountry}
                                                        options={this.state.options}
                                                        disabled={this.state.disabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="service_fee">Servicegebühr</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="service_fee" id="service_fee" value={this.state.owner.service_fee} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <small>* erforderlich</small>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Rechnungs-E-Mails
                                <div className="card-header-actions">
                                    {
                                        !this.state.emailDisabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateOwner}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleEmailDisabled}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleEmailDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="invoice_emails">Rechnungs-E-Mail</Label>
                                    </Col>
                                    <Col xs="12" md="7">
                                        <Input type="text" value={this.state.email} name="email" onChange={this.handleChange} disabled={this.state.emailDisabled} />
                                    </Col>
                                    <Col xs="12" md="2">
                                        <Button className="btn btn-block btn-success" onClick={this.addEmail} disabled={this.state.emailDisabled || !this.state.email}>E-Mail hinzufügen</Button>
                                    </Col>
                                </FormGroup>

                                <hr className="mt-4 mb-4" />

                                {invoice_emails.map((v, i) => {
                                    return (
                                        <FormGroup row key={i}>
                                            <Col md="3">
                                                <Label htmlFor="invoice_emails">E-Mail-Adresse</Label>
                                            </Col>
                                            <Col xs="12" md="7">
                                                <Input type="text" value={v} name="invoice_emails" onChange={this.handleChange} disabled />
                                            </Col>
                                            <Col md="2">
                                                <Button className="btn btn-block btn-danger" onClick={this.deleteEmail.bind(this, i)} disabled={this.state.emailDisabled}>Löschen</Button>
                                            </Col>
                                        </FormGroup>)

                                })}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Firmen Notiz
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabledNote
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateOwner}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleNote}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleNote}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Input type="textarea" rows="7" name="note" id="note"
                                       value={!this.state.owner.note ? '' : this.state.owner.note}
                                       disabled={this.state.disabledNote} onChange={this.handleChange}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Modals */}

                {/* Delete company */}
                {/*<Modal isOpen={this.state.delete} toggle={this.toggleDelete}
                       className={'modal-danger ' + this.props.className}>
                    <ModalHeader toggle={this.toggleDelete}>Firma löschen</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteCompany}>Firma löschen</Button>{' '}
                        <Button color="secondary" onClick={this.toggleDelete}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>*/}

            </div>
        );
    }
}

export default GarageOwner;
