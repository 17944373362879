import gql from 'graphql-tag';

export default function (params) {
    return gql`
    mutation{
        unassignNode(
        gwid: "${params.gwid}"
        ){
            errors
            count
            garageGateway{
                id
                device_id
                name
                state
                garage_gateway{
                    id
                    name
                }
            }
        }
    }
    `;
}