import axios from "axios";
import urls from "../utils/urls";
import errorHandlerService from "./errorHandlerService";

const paymentService = {

    getCreditStatus: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'GET',
                    url: `${urls.apigateway}/payments/${id}`,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en',
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        paymentService.getCreditStatus, id
                    )
                }
            });
        });
    },

    updateCredits: (data) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PUT',
                url: `${urls.apigateway}/payments/${data.id}`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(data)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        paymentService.updateCredits, data.id, data.amount
                    )
                }
            });
        });
    },

    getCreditLogs: (data) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/payments/logs/${data.id}?limit=${data.limit}&offset=${data.offset}`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                }
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if (localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        paymentService.getCreditLogs, data
                    )
                }
            });
        });
    },
};

export default paymentService;
