import config from '../utils/config';
import gql from "graphql-tag";


export default function(params){

    return gql`
        mutation {
            assignGateway(
                gatewayID: "${params.gatewayID}"
                garageTarget: "${params.garageTarget}"
                fromPool: ${params.customer_id === config.get('microtronicsPoolId')}
            ) {
                errors
                gateway {
                    id
                    name
                    device_id
                    state
                    gatewayGarage {
                        id
                        name
                    }
                }
            }
        }`;
}
