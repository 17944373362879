import gql from "graphql-tag";


export default function query(params) {

    return gql`
        query {
            getGatewayById(id: "${params.id}") {
                errors
                gateway {
                    id
                    microtronics_id
                    device_id
                    name
                    state
                    type
                    customer_id
                    connection_device {
                        signal_strength
                    }
                    location
                    relay_hold
                    led1_hold
                    created_at
                    updated_at
                    deleted_at
                    active
                    note
                    gwid
                    microtronics_site_id
                    gatewayGarage {
                        id
                        name
                    }
                    tenant_name
                    tenant_id
                    product_type
                }
            }
        }`;
}
