import gql from "graphql-tag";


export default function (params) {

    return gql`
            mutation {
                convertGateway(
                    id: "${params.id}"
                    gwid: "${params.gwid}"
                ) {
                    errors
                    count
                    garageGateway {
                        id
                        microtronics_id
                        microtronics_site_id
                        device_id
                        gwid
                        name
                        location
                        note
                        state
                        master_gwid
                        garage_gateway {
                            id
                            name
                        }
                        type
                        createdAt
                        updatedAt
                        deletedAt
                        relay_hold
                        led1_hold
                        customer_id
                        is_master
                        garage_id
                        active_relays
                    }
                }
            }`;
}
