import gql from "graphql-tag";


export default function(params=null){

    if(params != null) {
        return gql`
            query {
                getPoolGateways(first: ${params.limit}, offset: ${params.offset}, searchTerm: "${params.searchTerm}") {
                    errors
                    gateways {
                        id
                        device_id
                        customer_id
                        name
                    }
                }
            }
        `
    }

    return gql`
            query {
                getPoolGateways {
                    errors
                    gateways {
                        id
                        device_id
                        customer_id
                        name
                    }
                }
            }`;
}


