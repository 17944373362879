import urls from '../utils/urls';
import axios from "axios";

import errorHandlerService from './errorHandlerService';


const companyService = {

    getCompanies:(page=null, search="") => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/contacts/companies/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en'
                    },
                    params: {
                        page,
                        search: search || null
                    }
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        companyService.getCompanies, page, search
                    )
                }
            });
        });
    },

    getCompany: (id) => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/contacts/companies/${id}/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en'
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        companyService.getCompany, id
                    )
                }
            });
        });

    },

    updateCompany: (company) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/contacts/companies/${company.id}/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(company)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        companyService.updateCompany, company
                    )
                }
            });
        });
    },

    deleteCompany: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${urls.apigateway}/contacts/companies/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        companyService.deleteCompany, id
                    )
                }
            });
        });
    },

    addCompany: (company) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${urls.apigateway}/contacts/companies/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(company)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        companyService.addCompany, company
                    )
                }
            });
        });
    },
};

export default companyService;
