import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import {Link} from "react-router-dom";
import 'spinkit/css/spinkit.css';
import { LazyImage } from "react-lazy-images";
import urls from '../../../utils/urls';


export default class GarageCard extends Component {

    constructor(props) {
        super(props);

        this.translateGarageStateName = this.translateGarageStateName.bind(this);

        if(this.props.garage.garagePhotos.find((photo) => photo.type === 'GATE')) {
            this.state = {
                garageImage: this.props.garage.garagePhotos.find((photo) => photo.type === 'GATE').file_path
            }
        } else {
            this.state = {
                garageImage: !this.props.garage.garagePhotos[0] ? null : this.props.garage.garagePhotos[0].file_path
            }
        }

    }

    translateGarageStateName() {
        let garageState = this.props.garage.state;

        switch(garageState) {
            case "COMING_SOON":
                return <strong>Kommt bald</strong>;
            case "LIVE":
                return <strong>Live</strong>;
            case "INACTIVE":
                return <strong>Offline</strong>;
            case "MAINTENANCE":
                return <strong>Wartung</strong>;
            case "DECLINED":
                return <strong>Demontage</strong>;
            case "DRAFT":
                return <strong>Entwurf</strong>;
            default:
                return <strong>Garage State</strong>
        }
    }

    render() {
        return (
            <Col xs="12" sm="12" md="4" key={this.props.i}>
                <Card className={this.props.garage.state === "COMING_SOON" ? "card-accent-secondary" : this.props.garage.state.toLowerCase()}>
                    <CardHeader>
                        <Link to={"/garages/" + this.props.garage.id} className={this.props.garage.state.toLowerCase() + " noDecoLink"}><h4 className="mb-0">{this.props.garage.name}</h4></Link>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs="12" lg="6">
                                <p>
                                    <strong>{!this.props.garage.address.street ? "Straße: k.A." : this.props.garage.address.street} {!this.props.garage.address.street_number ? "Hausnummer: k.A." : this.props.garage.address.street_number}</strong> <br/>
                                    <strong>{!this.props.garage.address.zip_code ? "PLZ: k.A." : this.props.garage.address.zip_code + ", "} {!this.props.garage.address.city ? "Stadt: k.A." : this.props.garage.address.city}</strong>
                                </p>
                                <p>{this.props.garage.total_spaces - this.props.garage.taken_spaces} freie Parkplätze</p>
                                {this.translateGarageStateName()}
                            </Col>
                            <Col xs="12" lg="6">
                                {!this.props.garage.garagePhotos.find((photo) => photo) || !this.state.garageImage ? <img src="https://via.placeholder.com/1440x810?text=Nicht+verfügbar" alt="garageImage" max-width="1440" max-height="810" className="img-fluid img-thumbnail" /> :
                                    <LazyImage
                                        src={this.state.garageImage || ""}
                                        alt="Garagenbild"
                                        width="1440"
                                        height="810"
                                        className="img-fluid img-thumbnail"
                                        debounceDurationMs={400}
                                        placeholder={({ imageProps, ref }) => (
                                            <img
                                                ref={ref}
                                                src="https://via.placeholder.com/1440x810?text=Lädt..."
                                                alt={imageProps.alt}
                                                className="img-fluid img-thumbnail"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                        actual={({ imageProps }) => (
                                            <img {...imageProps} style={{ width: "100%" }} alt="Garaganbild" />
                                        )}
                                    />
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )
    }

}
