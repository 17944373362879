import axios from "axios";

import urls from "../utils/urls";
import config from "../utils/config";

import {history} from '../App';


export default {

    handleResponseError: (err, isNotAuth, resolve, reject, proceed, ...proceedArgs) => {

        if (err.message === 'Network error: Failed to fetch' || err.message === 'Network Error') {
            history.push('/systemOffline')
        }

        else if(isNotAuth) {
            const lastAccessToken = localStorage.getItem('Access-Token');

            axios.post(`${urls.apigateway}/users/me/login/refresh/`,
                {
                    refresh_token: localStorage.getItem("Refresh-Token")
                },
                {
                    headers: {
                        'App-Token': config.get('appToken'),
                        'Accept-Language': 'en',
                    }
                }
            ).then((response) => {

                localStorage.setItem('Access-Token', response.data.access_token);
                localStorage.setItem('Refresh-Token', response.data.refresh_token);

                proceed(...proceedArgs).then(resolve).catch(reject)

            }).catch((err) => {
                if (err.hasOwnProperty("response") &&
                    err.response.hasOwnProperty("status") &&
                    err.response.status === 401) {
                    if(lastAccessToken !== localStorage.getItem("Access-Token", null)) {
                        proceed(...proceedArgs).then(resolve).catch(reject) // proceeding (token has been rotated)
                    } else {
                        localStorage.removeItem('Access-Token');
                        localStorage.removeItem('Refresh-Token');
                        history.go('/');
                    }
                } else {
                    reject(err)
                }
            });
        } else {
            reject(err)
        }
    }
}
