import gql from "graphql-tag";


export default function(params){
    return gql`
        mutation {
            unassignNFC(
                nfc_tag: "${params.nfcTag}"
            ) {
                errors
            }
        }`;
}
