import gql from "graphql-tag";


export default function query(params) {

    return gql`
        query {
            getByGatewayGwid(gwid: "${params.gwid}") {
                errors
                garageGateway {
                    id
                    microtronics_id
                    device_id
                    garage_id
                    active_relays
                    name
                    state
                    type
                    location
                    is_master
                    createdAt
                    updatedAt
                    health_status {
                        nfc_state
                        ble_state
                        connection
                        rf_state
                        main_controller_state
                    }
                    connection_device {
                        signal_strength
                    }
                    relay_hold
                    led1_hold
                    note
                    gwid
                    product_type
                    userpool_id
                    firmware
                    microtronics_site_id
                    slaves{
                        gwid
                        name
                        type
                        state
                    }
                    garage_gateway {
                        name
                        id
                        state
                        echarger
                    }
                }
            }
        }`;
}
