import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import 'spinkit/css/spinkit.css';

import moment from 'moment';
import garageService from "../../../services/garageService";
import getTransactons from "../../../queries/getTransactions";


class ActiveReservation extends Component {


    constructor(props) {
        super(props);

        this.state = {
            user_id: this.props.user_id,
            loading: true
        };

        this.renderLinkToGarage = this.renderLinkToGarage.bind(this);

    }

    componentDidMount() {

        garageService.getService(getTransactons({
            user_id: this.state.user_id,
            type: "RESERVATION",
        }))
            .then((transactions) => {
                transactions = transactions.data.getTransactions.transactions;
                const filteredTransactions = transactions.filter(transaction => moment(transaction.end_time).isAfter(moment(new Date())));

                this.setState({
                    currentGarage: filteredTransactions.shift(),
                    loading: false
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    renderLinkToGarage() {
        if (!this.state.currentGarage) {
            return <Input type="text" id="activeReservation"
                          name="activeReservation"
                          value={"Keiner"}
                          disabled
                    />
        } else {
            return <Link to={"/garages/" + this.state.currentGarage.transactionGarage.id}>
                        <Input type="text" id="activeReservation"
                               name="activeReservation"
                               value={this.state.currentGarage.transactionGarage.name}
                               disabled
                        />
                   </Link>
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Aktive Reservierung
                    <div className="card-header-actions" />
                </CardHeader>
                <CardBody>
                    <FormGroup row className="mb-0">
                        <Col xs="12" md="12">
                            {
                                this.state.loading ?
                                    <div className="sk-rotating-plane"/> :
                                    this.renderLinkToGarage()
                            }
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
        );
    }
}

export default ActiveReservation;
