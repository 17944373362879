import React, {Component} from 'react';
import {Card, CardBody, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import {Link} from "react-router-dom";
import 'spinkit/css/spinkit.css';
import Widget from "../../components/Widget/Widget";
import {CSVLink} from 'react-csv';
import garageService from "../../services/garageService";
import GarageCard from './GarageComponents/GarageCard';
import getGarages from "../../queries/getGarages";


class Garages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            garages: [],
            state: '',
            searchTerm: '',
            filterSet: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.searchGarages = this.searchGarages.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    searchGarages(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;
        const garages = this.state.unfilteredGarages;

        this.setState({
            [name]: value,
        }, () => {

            const searchTerm = this.state.searchTerm.toLowerCase();

            const searchedGarages = garages.filter(garage => {
                const search = (garage.name.toLowerCase().includes(searchTerm) || garage.address.street.toLowerCase().includes(searchTerm));
                return this.state.state === '' ? search : search && garage.state === this.state.state;
            });

            if (this.state.searchTerm === '' && this.state.state === '') {
                this.setState({garages: this.state.unfilteredGarages});
            } else {
                this.setState({garages: searchedGarages});
            }
        });
    }

    componentDidMount() {

        garageService.getService(getGarages())
            .then((items) => {
                const garages = items.data.getGarages.garages

                const filtered = garages.filter((garage) => {
                    if (garage.state === 'LIVE') {
                        return garage
                    }
                });

                this.setState({
                    garages: filtered.length > 0 ? filtered : garages,
                    unfilteredGarages: garages,
                    state: filtered.length > 0 ? 'LIVE' : '',
                    loading: false,
                }, () => this.stateCalculation());
            })
            .catch((err) => {
                console.log(err);
            });

        document.title = "PAYUCA Garagen"
    }

    stateCalculation() {

        const live = this.state.unfilteredGarages.filter((garage) => {
            if (garage.state === 'LIVE') {
                return garage
            }
        });
        const draft = this.state.unfilteredGarages.filter((garage) => {
            if (garage.state === 'DRAFT') return garage
        });
        const maintenance = this.state.unfilteredGarages.filter((garage) => {
            if (garage.state === 'MAINTENANCE') return garage
        });
        const inactive = this.state.unfilteredGarages.filter((garage) => {
            if (garage.state === 'INACTIVE') return garage
        });
        const declined = this.state.unfilteredGarages.filter((garage) => {
            if (garage.state === 'DECLINED') return garage
        });
        const comingSoon = this.state.unfilteredGarages.filter((garage) => {
            if (garage.state === 'COMING_SOON') return garage
        });

        this.setState({
            live: live.length,
            draft: draft.length,
            maintenance: maintenance.length,
            inactive: inactive.length,
            declined: declined.length,
            comingSoon: comingSoon.length
        })

    }

    render() {

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        return (
            <div className="animated fadeIn">

                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-home" color="info" header="PAYUCA Garagen"/>
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Link to="/garages/addGarage" className="noDecoLink">
                            <Widget
                                icon="icon-plus"
                                color="success"
                                header="Garage hinzufügen"
                                invert
                                style={{cursor: "pointer"}}
                            />
                        </Link>
                    </Col>
                </Row>

                <Row className="mb-1">
                    <Col lg="12">
                        <Card className="bg-light">
                            <CardBody>
                                <Row className="row-grid">
                                    <Col lg="8" md="8" sm="12" xs="12">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-search mr-1"/> Suche
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text" id="searchTerm" bsSize="lg"
                                                   placeholder="z.B. Garage de France" name="searchTerm"
                                                   value={this.state.searchTerm} onChange={this.searchGarages}/>
                                        </InputGroup>
                                    </Col>
                                    <Col lg="2" md="2" sm="12" xs="12">
                                        <Input type="select" bsSize="lg" name="state" id="state"
                                               value={this.state.state} onChange={this.searchGarages}>
                                            <option value="" defaultValue>Alle Zustände</option>
                                            <option value="LIVE">Live</option>
                                            <option value="DRAFT">Entwurf</option>
                                            <option value="MAINTENANCE">Wartung</option>
                                            <option value="INACTIVE">Offline</option>
                                            <option value="DECLINED">Demontage</option>
                                            <option value="COMING_SOON">Kommt bald</option>
                                        </Input>
                                    </Col>
                                    <Col lg="2" md="2" sm="12" xs="12">
                                        <CSVLink data={this.state.garages}
                                                 filename={"payucaGaragen.csv"}
                                                 className="btn btn-success btn-lg btn-block"
                                                 target="_blank">
                                            CSV Export
                                        </CSVLink>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <hr/>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Card><CardBody><strong>Live:</strong> {this.state.live}</CardBody></Card>
                    </Col>
                    <Col md={2}>
                        <Card><CardBody><strong>Entwurf:</strong> {this.state.draft}</CardBody></Card>
                    </Col>
                    <Col md={2}>
                        <Card><CardBody><strong>Wartung:</strong> {this.state.maintenance}</CardBody></Card>
                    </Col>
                    <Col md={2}>
                        <Card><CardBody><strong>Offline:</strong> {this.state.inactive}</CardBody></Card>
                    </Col>
                    <Col md={2}>
                        <Card><CardBody><strong>Demontage:</strong> {this.state.declined}</CardBody></Card>
                    </Col>
                    <Col md={2}>
                        <Card><CardBody><strong>Kommt bald:</strong> {this.state.comingSoon}</CardBody></Card>
                    </Col>
                </Row>

                {!this.state.garages ? <h4>Keine Garage gefunden. Bitte nochmal versuchen.</h4> :
                    <Row>
                        {this.state.garages.sort((a, b) => a.address.zip_code - b.address.zip_code).map((garage, i) =>
                            <GarageCard key={garage.id} garage={garage} i={i}/>
                        )}
                    </Row>
                }
            </div>
        );
    }
}

export default Garages;
