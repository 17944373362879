import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import axios from "axios";

import errorHandlerService from "./errorHandlerService";
import urls from "../utils/urls";
import getVersionBlockInfoQuery from "../queries/getVersionBlockInfo";
import getUpdateVersionBlockEntry from "../queries/getUpdateVersionBlockEntry";

const uploadLink = createUploadLink({
  uri: `${urls.apigateway}/services/`,
});

const client = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});

const versionBlockService = {
  getEntries: (query) => {
    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy: "no-cache",
          query: query,
          context: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("Access-Token")}`,
              "Accept-Language": "en",
            },
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          if (localStorage.getItem("Refresh-Token", null)) {
            let isNotAuth =
              err.hasOwnProperty("networkError") &&
              err.networkError &&
              err.networkError.hasOwnProperty("statusCode") &&
              err.networkError.statusCode === 401;
            errorHandlerService.handleResponseError(
              err,
              isNotAuth,
              resolve,
              reject,
              versionBlockService.getEntries,
              query
            );
          }
        });
    });
  },
  blockVersion: (query) => {
    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy: "no-cache",
          query: query,
          context: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("Access-Token")}`,
              "Accept-Language": "en",
            },
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getVersionInfo: (id) => {
    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy: "no-cache",
          query: getVersionBlockInfoQuery(id),
          context: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("Access-Token")}`,
              "Accept-Language": "en",
            },
          },
        })
        .then((resp) => {
          try {
            if (resp.data.getVersionBlock.versionBlock) {
              resolve(resp.data.getVersionBlock.versionBlock);
            }
          } catch (error) {
            reject(error);
          }
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateVersion: (id, args) => {
    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy: "no-cache",
          query: getUpdateVersionBlockEntry(id, args),
          context: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("Access-Token")}`,
              "Accept-Language": "en",
            },
          },
        })
        .then((resp) => {
          resolve();
        })
        .catch((err) => {
          console.log("[err] is of value: ", { ...err });
          reject(err);
        });
    });
  },
};

export default versionBlockService;
