import gql from "graphql-tag";


export default function(params=null){

    if(params != null) {
        return gql`
            query {
                getGateways(
                        first: ${params.limit}, 
                        offset: ${params.offset}, 
                        searchTerm: "${params.searchTerm}"
                        state: "${params.stateFilter}"
                        type: "${params.typeFilter}"
                        tenant_id: "${params.tenant_id}"
                        product_type: ${!params.product_type ? null : params.product_type}
                        assigned: ${params.assignedFilter === true ? true : (params.assignedFilter === false ? false : null)}
                    ) {
                    errors
                    count
                    gateways {
                        id
                        microtronics_id
                        device_id
                        name
                        state
                        type
                        gwid
                        note
                        gatewayGarage {
                            id
                            name
                        }
                        customer_id
                        tenant_name
                        product_type
                    }
                }
            }`;
    }
    return gql`
        query {
            getGateways {
                errors
                count
                gateways {
                    id
                    microtronics_id
                    device_id
                    name
                    state
                    type
                    gwid
                    note
                    gatewayGarage {
                        id
                        name
                    }
                    customer_id
                    tenant_name
                    product_type
                }
            }
        }`;
}
