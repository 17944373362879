import gql from "graphql-tag";


export default function query(params) {

    return gql`
        query {
            getPCGatewayById(id: "${params.id}") {
                errors
                gateway {
                    id
                    device_id
                    garage_id
                    master_id
                    type
                    gwid
                    product_type
                    node_number
                    relay_timings
                    stm_fw
                    rf_fw
                    esp_fw
                    note
                    name
                    position{
                        lat
                        long
                    }
                    nodes{
                        id
                        gwid
                        type
                        name
                        position{
                            lat
                            long
                        }
                    }
                    created_at
                    updated_at
                    deleted_at
                    payuca_connect_garage {
                        name
                        id
                        state
                        echarger
                    }
                    health_status {
                        nfc_state
                        ble_state
                        rf_state
                        connection
                        main_controller_state
                    }
                    tenant_id
                }
                
            }
        }`;
}
