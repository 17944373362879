import gql from "graphql-tag";
export default function(params){

    return gql`
        mutation {
            triggerHealthCheck(
                gwid: "${params}"
            ) {
                errors
                message
            }
        }`;
}
