import React, {Component} from 'react';
import {Card, CardBody, Col, Row, UncontrolledTooltip, Button} from 'reactstrap';
import {BootstrapTable, ClearSearchButton, SearchField, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import 'spinkit/css/spinkit.css';
import userService from "../../services/userService";
import CustomSearchField from "../../components/CustomSearchField";
import datetimeFormatter from "../../utils/datetimeFormatter";
import UserDownload from "./UserComponents/UserDownload";

class Users extends Component {

    static IDTooltipFormatter(cell, row) {
        const id = row.id;
        const mcID = row.microtronics_id;
        return (
            <div>
                <Link to={`/users/${id}`} id={'Tooltip-' + id}>{cell}</Link> <br/>
                <small>{mcID&& mcID+"*"}</small>
                <UncontrolledTooltip placement="top" target={'Tooltip-' + id}>
                    {cell}
                </UncontrolledTooltip>
            </div>
        )
    }

    static Formatter(cell, row) {
        const id = row.id;
        return <Link to={`/users/${id}`}>{cell}</Link>
    }

    static Plates(cell) {
        return cell.map((plate) => {
            return plate.plate_number;
        });
    }

    static getTac(data) {
        if (data.user_consent.tac_accepted) {
            return (
                <span>
                    <i className="fa fa-institution fa-lg mr-2 text-success" id={"Tooltip-tac" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-tac' + data.id}>
                        {"AGBs akzeptiert @ " + datetimeFormatter.forDisplay(data.user_consent.tac_updated_at)}
                    </UncontrolledTooltip>
                </span>
            )
        } else {
            return (
                <span>
                    <i className="fa fa-institution fa-lg mr-2 text-danger" id={"Tooltip-tac" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-tac' + data.id}>
                        {"AGBs nicht akzeptiert"}
                    </UncontrolledTooltip>
                </span>
            )
        }
    }

    static getPrivacyPolicy(data) {
        if (data.user_consent.privacy_policy_accepted) {
            return (
                <span>
                    <i className="fa fa-legal fa-lg mr-2 text-success" id={"Tooltip-privacyPolicy" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-privacyPolicy' + data.id}>
                        {"Datenschutzerklärung akzeptiert @ " + datetimeFormatter.forDisplay(data.user_consent.privacy_policy_updated_at)}
                    </UncontrolledTooltip>
                </span>
            )
        } else {
            return (
                <span>
                    <i className="fa fa-legal fa-lg mr-2 text-danger" id={"Tooltip-privacyPolicy" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-privacyPolicy' + data.id}>
                        {"Datenschutzerklärung nicht akzeptiert"}
                    </UncontrolledTooltip>
                </span>
            )
        }
    }

    static getNewsletter(data) {
        if (data.user_consent.newsletter_accepted) {
            return (
                <span>
                    <i className="fa fa-envelope fa-lg mr-2 text-success" id={"Tooltip-newsletter" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-newsletter' + data.id}>
                        {"Newsletter akzeptiert @ " + datetimeFormatter.forDisplay(data.user_consent.newsletter_updated_at)}
                    </UncontrolledTooltip>
                </span>
            )
        } else {
            return (
                <span>
                    <i className="fa fa-envelope fa-lg mr-2 text-danger" id={"Tooltip-newsletter" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-newsletter' + data.id}>
                        {"Newsletter nicht akzeptiert"}
                    </UncontrolledTooltip>
                </span>
            )
        }
    }

    static getMarketing(data) {
        if (data.user_consent.marketing_activity_accepted) {
            return (
                <span>
                    <i className="fa fa-commenting fa-lg mr-2 text-success" id={"Tooltip-marketing" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-marketing' + data.id}>
                        {"Marketing Consent akzeptiert @ " + datetimeFormatter.forDisplay(data.user_consent.marketing_activity_updated_at)}
                    </UncontrolledTooltip>
                </span>
            )
        } else {
            return (
                <span>
                    <i className="fa fa-commenting fa-lg mr-2 text-danger" id={"Tooltip-marketing" + data.id} />
                    <UncontrolledTooltip placement="left" target={'Tooltip-marketing' + data.id}>
                        {"Marketing Consent nicht akzeptiert"}
                    </UncontrolledTooltip>
                </span>
            )
        }
    }

    static Consent(cell, row) {
        if (!cell) {
            return "Kein Consent gefunden für den Benutzer"
        }
        return (
            <React.Fragment>
                {Users.getTac(row)}
                {Users.getPrivacyPolicy(row)}
                {Users.getNewsletter(row)}
                {Users.getMarketing(row)}
            </React.Fragment>
        )
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            tooltipOpen: false,
            users: [],
            searchQuery: "",

            page: 1,
            totalSize: 0,
        };

        this.typingTimer = null;
        this.typingInterval = 700;

        this.onSearch = this.onSearch.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);
        this.getClearButton = this.getClearButton.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    unsuccessful(msg='Erfolglos') {
        return toast.error(msg)
    }

    componentDidMount() {

        userService.getUsers(this.state.page, "PR")
            .then((resp) => {
                if(resp.data.hasOwnProperty("results")) {
                    this.setState({
                        users: resp.data.results,
                        loading: false,
                        totalSize: resp.data.count
                    });
                }
            })
            .catch((err) => {
                this.unsuccessful(err);
            });

        document.title = "PAYUCA App Kunden";
    }

    toggle(i) {
        const newArray = this.state.tooltipOpen.map((element, index) => {
            return (index === i ? !element : false);
        });
        this.setState({
            tooltipOpen: newArray,
        });
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                users: [],
                searchQuery: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchUsers, this.typingInterval)
            })
        }
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.searchQuery !== "") {
            this.setState({
                page: 1,
                loading: true,
                users: [],
                searchQuery: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchUsers, this.typingInterval)
            })
        }
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "No users found."
    }

    fetchUsers() {
        userService.getUsers(this.state.page, "PR", this.state.searchQuery)
            .then((resp) => {
                this.setState({
                    loading: false,
                    users:  resp.data.results || [],
                    totalSize: resp.data.count
                });

            })
            .catch((err) => {
                this.unsuccessful(err);
            });
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            page
        }, this.fetchUsers)
    }

    get tableOptions(){
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            searchField: this.getSearchField,
            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,

            page: this.state.page,
            sizePerPage: 10

        };
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-people" color="info" header="PAYUCA App Kunden" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <UserDownload />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <small>* Microtronics ID</small>
                                <BootstrapTable data={this.state.users} version="4" remote striped fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions} search>
                                    <TableHeaderColumn width='150' isKey={true} dataField="id" dataFormat={Users.IDTooltipFormatter}>ID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="email" tdStyle={ { whiteSpace: 'normal' } } dataFormat={Users.Formatter}>Email</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="first_name" dataFormat={Users.Formatter}>Vorname</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="last_name" dataFormat={Users.Formatter}>Nachname</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="phone_number" dataFormat={Users.Formatter}>Telefonnummer</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="licence_plates" dataFormat={Users.Plates}>Kfz-Kennzeichen</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="user_consent" dataFormat={Users.Consent}>Zustimmungen</TableHeaderColumn>
                                </BootstrapTable>
                                <h6>Users insgesamt: <strong>{!this.state.totalSize ? "Lädt..." : this.state.totalSize}</strong></h6>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Users;
