import gql from "graphql-tag";


export default function(params){
    return gql`
            mutation {
                updateInventoryEntry(
                    id: "${params.id}"
                    garage_id: "${params.garage_id}"
                    name: "${params.name}"
                    count: ${params.count}
                    description: "${params.description}"
                ) {
                    errors
                    inventoryEntry {
                        id
                        name
                        count
                        description
                    }
                }
            }`;
}