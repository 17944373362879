import gql from "graphql-tag";

function getVersionBlockEntriesQuery(params) {
  return gql` {
      getVersionBlocks( 
        limit: ${!!params && params.limit || null} offset: ${!!params && params.offset || null}
    ) { 
            errors, 
            count,
            versionBlock { 
                id,
                ios,
                android,
                message_de,
                message_en,
                version,
                updated_at,
                created_at,
                deleted_at,
            } 
        }
    }`;
}

export default getVersionBlockEntriesQuery;
