import React, {Component} from 'react';
import * as ReactDOM from "react-dom";

export default class CustomSearchField extends Component {

    getValue() {
        return ReactDOM.findDOMNode(this).value;
    }

    // It's necessary to implement setValue
    setValue(value) {
        ReactDOM.findDOMNode(this).value = value;
    }

    render() {
        return (
            <input
                data-testid={this.props.testId}
                className={`form-control`}
                type='text'
                defaultValue={this.props.defaultValue}
                placeholder={this.props.placeholder}
                onKeyUp={this.props.search}/>
        );
    }
}
