import React, {Component} from 'react';
import {Card, CardBody, Col, Row, Button} from 'reactstrap';
import {BootstrapTable, ClearSearchButton, SearchField, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Widget from "../../components/Widget/Widget";
import 'spinkit/css/spinkit.css';
import garageService from "../../services/garageService";
import getMyDatanetCustomers from "../../queries/getMyDatanetCustomers";
import importMyDatanetCustomer from "../../mutations/importMyDatanetCustomer";

class GarageList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            customers: [],
            importLoading: null
        };

        this.unsuccessful = this.unsuccessful.bind(this);
        this.fetchCustomers = this.fetchCustomers.bind(this);
        this.import = this.import.bind(this);
        this.importButton = this.importButton.bind(this);
    }

    unsuccessful(msg='Erfolglos') {
        return toast.error(msg)
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    importButton(cell, row) {
        const id = row._uid;
        return <Button color="primary" disabled={this.state.importLoading === id} className="btn-block" onClick={() => this.import(id)}>{this.state.importLoading === id ? "Importing..." : "Import"}</Button>
    }

    fetchCustomers() {
        garageService.getService(getMyDatanetCustomers())
            .then((customers) => {
                this.setState({
                    customers: customers.data.getMyDatanetCustomers.customers,
                    loading: false,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.fetchCustomers();
        document.title = "PAYUCA Garage Migration List";
    }

    import(id) {
        this.setState({
            importLoading: id,
        });
        garageService.createService(importMyDatanetCustomer(id))
            .then((res) => {
                console.log(res);
                this.setState({
                    importLoading: false,
                });
                this.success();
                this.fetchCustomers();
            })
            .catch((err) => {
                this.setState({
                    importLoading: false,
                });
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
                console.log(JSON.stringify(err))
            })
    }

    get tableOptions() {
        return {
            sortIndicator: true,
            alwaysShowAllBtns: false,
            noDataText: this.getNoDataTableContent(),
        }
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "No transactions found"
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Widget icon="icon-people" color="info" header="PAYUCA Garage Migration" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <BootstrapTable data={this.state.customers} options={this.tableOptions} version="4" remote striped>
                                    <TableHeaderColumn width='150' isKey={true} dataField="_uid">ID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="name">Name</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataFormat={this.importButton}>Action</TableHeaderColumn>
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default GarageList;
