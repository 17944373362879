import React, {Component, Fragment} from 'react';
import {CSVLink} from 'react-csv';
import Widget from "../../../components/Widget/Widget";
import userService from "../../../services/userService";
import 'spinkit/css/spinkit.css';
import { toast } from 'react-toastify';

class UserDownload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDownload: [],
      loading: false,
      page: 1,
      searchQuery: ""
    };

    this.downloadUsersData = this.downloadUsersData.bind(this);

    this.csvInstance = React.createRef();
  }

  unsuccessful(msg = "Erfolglos!") {
    return toast.error(msg);
  }

  downloadUsersData() {
    this.setState({ loading: true });
    return userService
      .getUsers(this.state.page, "PR", this.state.searchQuery, 5000)
      .then(resp => {
        const data = resp.data.results;

        data.forEach(item => {
          item.licence_plates.push(
            item.licence_plates.map(item => item.plate_number)
          );
          item.licence_plates = item.licence_plates.slice(-1);
        });

        this.setState({
          userDownload: data,
          loading: false
        });
      })
      .then(()=>{
        if (this.state.userDownload) {
            this.csvInstance.current.link.click();
          }
      })
      .catch(err => {
        this.unsuccessful(err);
      });
  }

  render() {
    if (this.state.loading) {
      return <div className="sk-rotating-plane" />;
    }

    return (
      <Fragment>
        <Widget
          onClick={() => {
            this.downloadUsersData();
          }}
          icon="icon-plus"
          color="success"
          header="Download"
          invert
          style={{ cursor: "pointer" }}
        />
        <CSVLink
          data={this.state.userDownload}
          filename={"payucaUsers.csv"}
          className="csvDownload"
          target="_blank"
          ref={this.csvInstance}
        ></CSVLink>
      </Fragment>
    );
  }
}

export default UserDownload;
