import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";
import "spinkit/css/spinkit.css";
import "react-select/dist/react-select.min.css";
import "react-toastify/dist/ReactToastify.css";
import "ladda/dist/ladda-themeless.min.css";

import Widget from "../../components/Widget/Widget";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import versionBlockService from "../../services/versionBlockService";
import blockRelease from "../../mutations/blockRelease";

const options = [
  { value: "ios", label: "iOS only" },
  { value: "android", label: "Android only" },
  { value: "both", label: "Both" },
];

class AddVersionBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,

      version: "",
      blocks: "both",
      custom_message_de: "",
      custom_message_en: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.doVersionBlock = this.doVersionBlock.bind(this);
    this.unsuccessful = this.unsuccessful.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  unsuccessful(msg = "Erfolglos!") {
    return toast.error(msg);
  }

  doVersionBlock() {
    const {
      version,
      custom_message_en,
      custom_message_de,
      blocks,
    } = this.state;
    let blocksAndroid = false;
    let blocksIOS = false;
    switch (blocks) {
      case "both":
        blocksAndroid = true;
        blocksIOS = true;
        break;

      case "ios":
        blocksIOS = true;
        break;

      case "android":
        blocksAndroid = true;
        break;

      default:
        break;
    }
    versionBlockService
      .blockVersion(
        blockRelease({
          version,
          custom_message_en,
          custom_message_de,
          blocksAndroid,
          blocksIOS,
        })
      )
      .then((response) => {
        this.setState({
          redirect: true,
        });
      })
      .catch(() => {
        return this.unsuccessful();
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/version-block" />;
    }

    const containerStyle = {
      zIndex: 1999,
    };

    return (
      <div className="animated fadeIn">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          style={containerStyle}
        />
        <Row>
          <Col xs="12" sm="12" lg="10">
            <Widget icon="icon-people" color="info" header={"Block release"} />
          </Col>
          <Col xs="12" sm="12" md="2">
            <Widget
              icon="icon-plus"
              color="success"
              header="Submit"
              invert
              onClick={this.doVersionBlock}
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12">
            <Card>
              <CardHeader>Release info</CardHeader>
              <CardBody>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="version">Version</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            type="text"
                            name="version"
                            id="version"
                            placeholder={"3.0.16"}
                            value={this.state.version}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="os">Blocked OS</Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Select
                            isSearchable={false}
                            options={options}
                            isMulti={false}
                            value={this.state.blocks}
                            name="os"
                            onChange={(e) => {
                              this.setState({ blocks: e ? e.value : "" });
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="custom_message_de">
                            Custom German message
                          </Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            type="text"
                            name="custom_message_de"
                            id="custom_message_de"
                            placeholder={"Hallo Welt!"}
                            value={this.state.custom_message_de}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">
                          <Label htmlFor="custom_message_en">
                            Custom English message
                          </Label>
                        </Col>
                        <Col xs="12" md="9">
                          <Input
                            type="text"
                            name="custom_message_en"
                            id="custom_message_en"
                            placeholder={"Hello World!"}
                            value={this.state.custom_message_en}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddVersionBlock;
