import gql from "graphql-tag";

export default function getUpdateVersionBlockEntry(
  id,
  {
    message_de = "",
    message_en = "",
    blocksAndroid = false,
    blocksIOS = false,
    version = "",
    toDelete = false,
  }
) {
  return gql`mutation { 
    updateVersionBlockEntry(
        id: "${id}", 
        toDelete: ${toDelete}, 
        version: "${version}", 
        blocksIOS: ${blocksIOS},
        blocksAndroid: ${blocksAndroid},
        message_de: "${message_de}", 
        message_en: "${message_en}"
    ) { 
      errors, 
      versionBlock { 
        id, 
        message_de, 
        message_en, 
        version, 
        ios, 
        android 
      } 
    } 
  }
`;
}
