import gql from "graphql-tag";


export default function (params) {

    return gql`
        mutation {
            adminStartParking(
                userId: ${params.userId}
                licence_plate: "${params.licence_plate}"
                garage_id: "${params.garage_id}"
                startTime: "${params.startTime}"
                openDoor: ${params.openDoor}
                ignoreCredits: ${params.ignoreCredits === "true" ? true : false}
            ) {
                errors
                transaction {
                    id
                    type
                    transactionGarage {
                        id
                        name
                    }
                    start_time
                    end_time
                    total_price
                    completed
                    user_licence_plate
                }
            }
        }`;
}
