import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';
import Widget from "../../components/Widget/Widget";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Redirect} from "react-router-dom";
import 'ladda/dist/ladda-themeless.min.css';
import companyService from "../../services/companyService";
import contactService from "../../services/contactService";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import {history} from "../../App";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

class Company extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            disabled: true,
            delete: false,
            redirect: false,
            disabledNote: true,
            iban: '',
            bank: ''
        };

        this.handleChange = this.handleChange.bind(this);
        // this.toggleDelete = this.toggleDelete.bind(this);
        this.toggleDisabled = this.toggleDisabled.bind(this);
        this.toggleNote = this.toggleNote.bind(this);
        // this.deleteCompany = this.deleteCompany.bind(this);
        this.success = this.success.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.updateCompany = this.updateCompany.bind(this);
        this.onCeoChange = this.onCeoChange.bind(this);

        this.typingTimer = null;
        this.typingInterval = 600;

        this.loadContacts = this.loadContacts.bind(this);
        this.onContactClick = this.onContactClick.bind(this);
    }


    handleChange(event) {
        if (!this.state.disabled || !this.state.disabledNote) {
            const target = event.target;
            const value = target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        }
    }

    onCeoChange(ceo) {
        this.setState({
            ceo: ceo
        });
    }

    selectCountry = (country) => {
        this.setState({
            country: country.value
        });
    };

    countryList() {
        let options = [];
        let countryList = countries.getNames("de");

        Object.entries(countryList).forEach(([key, val]) => { options.push({value: key, label: val})});

        this.setState({
            options: options
        })
    }

    loadContacts(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);

            this.typingTimer = setTimeout(() => {
                contactService.getContacts(1, value)
                    .then(response => {
                        resolve({
                            options: response.data.results.map(contact => {
                                return {value: contact.id, label: contact.first_name + " " + contact.last_name}
                            })
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })

            }, this.typingInterval)

        })
    }

    /*toggleDelete() {
        this.setState({
            delete: !this.state.delete,
        });
    }*/

    toggleDisabled() {
        this.setState({
            disabled: !this.state.disabled,
        });
    }

    toggleNote() {
        this.setState({
            disabledNote: !this.state.disabledNote,
        })
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    unsuccessful(msg='Erfolglos!') {
        return toast.error(msg)
    }

    onContactClick(contact) {
        this.props.history.push("/contacts/" + contact.value);
    }

    /*deleteCompany() {
        companyService.deleteCompany(this.state.id)
            .then((response) => {
                this.setState({
                    redirect: true,
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }*/

    updateCompany() {
        this.setState({
            loadingEdit: true
        });

        companyService.updateCompany({
            id: this.state.id,
            ceo: this.state.ceo ? this.state.ceo.value : null,
            name: this.state.name,
            company_type: this.state.company_type,
            registration_number: this.state.registration_number,
            note: this.state.note,
            address: this.state.address,
            address_number: this.state.address_number,
            city: this.state.city,
            zip: this.state.zip,
            country: this.state.country,
            email: this.state.email,
            vat_reg_no: this.state.vat_reg_no,
            invoice_email: this.state.invoice_email,
            phone_number: this.state.phone_number,
            cell_number: this.state.cell_number,
            fax_number: this.state.fax_number,
            linkedin: this.state.linkedin,
            facebook: this.state.facebook,
            twitter: this.state.twitter,
            website: this.state.website,
            bank: this.state.bank,
            iban: !this.state.iban ? null : this.state.iban,
        })
            .then((response) => {
                if (response) {
                    let _ceo = !response.data.ceo ? null : {
                        value: response.data.ceo.id,
                        label: response.data.ceo.first_name + " " + response.data.ceo.last_name
                    };
                    this.setState({
                        ceo: _ceo,
                        name: response.data.name,
                        company_type: response.data.company_type,
                        nameHeader: response.data.name,
                        company_typeHeader: response.data.company_type,
                        registration_number: response.data.registration_number,
                        note: response.data.note,
                        address: response.data.address,
                        address_number: response.data.address_number,
                        city: response.data.city,
                        zip: response.data.zip,
                        country: response.data.country,
                        email: response.data.email,
                        phone_number: response.data.phone_number,
                        vat_reg_no: response.data.vat_reg_no,
                        invoice_email: response.data.invoice_email,
                        cell_number: response.data.cell_number,
                        fax_number: response.data.fax_number,
                        linkedin: response.data.linkedin,
                        facebook: response.data.facebook,
                        twitter: response.data.twitter,
                        website: response.data.website,
                        bank: response.data.bank,
                        iban: response.data.iban,
                        loadingEdit: false,
                        disabled: true,
                        disabledNote: true,
                    }, () => {
                        document.title = "Firma | " + this.state.name + " " + this.state.company_type;
                    });
                    this.success();
                } else {
                    this.setState({
                        disabledNote: !this.state.disabledNote ? false : true,
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    disabled: false,
                });

                let errors = err.response.data.error;

                Object.entries(errors).map((error) => {
                    return this.unsuccessful(error[0] + ": " + error[1][0]);
                })
            });
    }

    componentDidMount() {

        const { match: { params } } = this.props;

        companyService.getCompany(params.id)
            .then((company) => {

                company = company.data;
                let _ceo = !company.ceo ? null : {
                    value: company.ceo.id,
                    label: company.ceo.first_name + " " + company.ceo.last_name
                };

                this.setState({
                    id: company.id,
                    ceo: _ceo,
                    name: company.name,
                    company_type: company.company_type,
                    nameHeader: company.name,
                    company_typeHeader: company.company_type,
                    registration_number: company.registration_number,
                    vat_reg_no: company.vat_reg_no,
                    invoice_email: company.invoice_email,
                    note: company.note,
                    address: company.address,
                    address_number: company.address_number,
                    city: company.city,
                    zip: company.zip,
                    country: company.country,
                    email: company.email,
                    phone_number: company.phone_number,
                    cell_number: company.cell_number,
                    fax_number: company.fax_number,
                    linkedin: company.linkedin,
                    facebook: company.facebook,
                    twitter: company.twitter,
                    website: company.website,
                    bank: company.bank,
                    iban: company.iban,
                    loading: false,
                }, () => {
                    document.title = "Firma | " + this.state.name + " " + this.state.company_type;
                })
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    history.push('/404')
                }
                console.log(err);
            });

        this.countryList()

    }

    render() {

        if(this.state.redirect) {
            return <Redirect to="/companies"/>
        }

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Widget icon="icon-people" color="info" header={this.state.nameHeader + " " + this.state.company_typeHeader}/>
                    </Col>
                    {/*<Col xs="12" sm="12" md="2">
                        <Widget
                            icon="icon-trash"
                            color="danger"
                            header="Firma löschen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.toggleDelete}
                        />
                    </Col>*/}
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                Firmendaten
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateCompany}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleDisabled}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">Firmenname</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="name" id="name" value={this.state.name} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="company_type">Gesellschaftstyp</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="company_type" id="company_type" value={this.state.company_type} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="ceo">Geschäftsführer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <AsyncSelect
                                                        value={!this.state.ceo ? null : this.state.ceo}
                                                        loadOptions={this.loadContacts}
                                                        onChange={this.onCeoChange}
                                                        onValueClick={this.onContactClick}
                                                        disabled={this.state.disabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address">Straße</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="address" id="address" value={this.state.address} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="address_number">Hausnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="address_number" id="address_number" value={this.state.address_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="zip">Postleitzahl</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="zip" id="zip" value={this.state.zip} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="city">Stadt</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="city" id="city" value={this.state.city} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="country">Land</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={this.state.country}
                                                        onChange={this.selectCountry}
                                                        options={this.state.options}
                                                        disabled={this.state.disabled}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="registration_number">Firmenbuchnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="registration_number" id="registration_number" value={this.state.registration_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="vat_reg_no">UID</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="vat_reg_no" id="vat_reg_no" value={this.state.vat_reg_no} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="vat_reg_no">Rechnungs-E-Mail</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="invoice_email" id="invoice_email" value={this.state.invoice_email} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="6">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="phone_number">Telefonnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="phone_number" id="phone_number" value={this.state.phone_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="cell_number">Mobiltelefonnummer</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="cell_number" id="cell_number" value={this.state.cell_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="fax_number">Fax</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="fax_number" id="fax_number" value={this.state.fax_number} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="email">E-Mail-Adresse</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="email" id="email" value={this.state.email} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="website">Website</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="website" id="website" value={this.state.website} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="linkedin">Linkedin</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="linkedin" id="linkedin" value={this.state.linkedin} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="twitter">Twitter</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="twitter" id="twitter" value={this.state.twitter} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="facebook">Facebook</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="facebook" id="facebook" value={this.state.facebook} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="bank">Bank</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="bank" id="bank" value={this.state.bank} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="iban">IBAN</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" name="iban" id="iban" value={!this.state.iban ? "" : this.state.iban} onChange={this.handleChange} disabled={this.state.disabled} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Card>
                            <CardHeader>
                                Firmen Notiz
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabledNote
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateCompany}><i className="fa fa-dot-circle-o"></i> Speichern</Button> <Button type="reset" size="sm" color="danger" onClick={this.toggleNote}><i className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting" onClick={this.toggleNote}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Input type="textarea" rows="7" name="note" id="note"
                                       value={!this.state.note ? '' : this.state.note}
                                       disabled={this.state.disabledNote} onChange={this.handleChange}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Modals */}

                {/* Delete company */}
                {/*<Modal isOpen={this.state.delete} toggle={this.toggleDelete}
                       className={'modal-danger ' + this.props.className}>
                    <ModalHeader toggle={this.toggleDelete}>Firma löschen</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.deleteCompany}>Firma löschen</Button>{' '}
                        <Button color="secondary" onClick={this.toggleDelete}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>*/}

            </div>
        );
    }
}

export default Company;
