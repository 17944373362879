import gql from 'graphql-tag';

export default function (params) {
    return gql`
    mutation{
        unassignPCNode(
        id: "${params.id}"
        ){
            errors
        }
    }
    `;
}
