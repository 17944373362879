import React, {Component, Fragment} from 'react';
import {CSVLink} from 'react-csv';
import Widget from "../../../components/Widget/Widget";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import gatewayService from "../../../services/gatewayService";
import getGatewaysQuery from "../../../queries/getGateways_2";

class DownloadGateways2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            gatewaysDownload: [],
            page: 1,
            totalSize: 0,

            nameFilter: "",
            gwidFilter: "",
            deviceIDFilter: "",
            stateFilter: "",
            typeFilter: "",
            assignedFilter: null,

            assign: false,
            nodeGwid: '',
            assignedNode: '',
        };

        this.downloadGateways = this.downloadGateways.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.csvInstance = React.createRef();
    }

    unsuccessful(msg = "Erfolglos!") {
        return toast.error(msg);
    }

    getQueryParams() {
        return {
            first: this.props.totalSize,
            offset: 0,
            name: `"${this.state.nameFilter}"`,
            gwid: `"${this.state.gwidFilter}"`,
            device_id: `"${this.state.deviceIDFilter}"`,
            state: this.state.stateFilter,
            type: this.state.typeFilter,
            assigned: this.state.assignedFilter
        }
    }

    downloadGateways() {
        this.setState({loading: true});

        gatewayService.getService(getGatewaysQuery(this.getQueryParams()))
            .then((gateways) => {

                let data = gateways.data.getNewGateways.garageGateways.map((item) => {
                    return {
                        ...item,
                        gateway_garage_name: !item.garage_gateway ? null : item.garage_gateway.name,
                        gateway_garage_state: !item.garage_gateway ? null : item.garage_gateway.state,
                    }
                })

                let gatewaysDownload = data.map((item) => {
                    delete item.garage_gateway
                    delete item.health_status
                    delete item.__typename
                    delete item.microtronics_id
                    delete item.slaves


                    for (const property in item) {
                        if (item[property] === null) {
                            item[property] = "N/A"
                        }
                        if (item[property] === false) {
                            item[property] = "false"
                        }
                    }

                    return {
                        ...item
                    }
                })

                this.setState({
                    gatewaysDownload: gatewaysDownload,
                    loading: false,
                });
            })
            .then(() => {
                if (this.state.gatewaysDownload) {
                    this.csvInstance.current.link.click();
                }
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    render() {
        if (this.state.loading) {
            return <div className="sk-rotating-plane"/>;
        }

        const containerStyle = {
            zIndex: 1999
        };

        return (
            <Fragment>
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Widget
                    onClick={() => {
                        this.downloadGateways();
                    }}
                    icon="icon-plus"
                    color="success"
                    header="Download"
                    invert
                    style={{cursor: "pointer"}}
                />
                <CSVLink
                    data={this.state.gatewaysDownload}
                    filename={"payucaGateways2.csv"}
                    className="csvDownload"
                    target="_blank"
                    ref={this.csvInstance}
                />
            </Fragment>
        );
    }
}

export default DownloadGateways2;
