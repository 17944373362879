import gql from "graphql-tag";


export default function(params){
    return gql`
        mutation {
            setNewNfcGateway(
                gateway_id: "${params}"
            ) {
                errors
                gateway {
                    type
                }
            }
        }`;
}
