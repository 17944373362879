import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import 'spinkit/css/spinkit.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import userService from "../../services/userService";

class OAuthApps extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            apps: [],
        };

        this.unsuccessful = this.unsuccessful.bind(this);

    }

    unsuccessful(msg='Erfolglos') {
        return toast.error(msg)
    }

    componentDidMount() {

        userService.getOAuthApps()
            .then((resp) => {

                    this.setState({
                        apps: resp.data,
                        loading: false,
                    }, () => {
                        document.title = "PAYUCA Cockpit | OAuth Apps";
                    });

            })
            .catch((err) => {
                this.unsuccessful(err);
            });
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };


        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        return (
            <div className="animated">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-layers" color="info" header="PAYUCA OAuth Apps" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Link to="/oauthapps/addApp" className="noDecoLink">
                            <Widget
                                icon="icon-plus"
                                color="success"
                                header="OAuth App hinzufügen"
                                invert
                                style={{cursor: "pointer"}}
                            />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    {
                        this.state.apps.map((app) => {
                            return (
                                <Col xs="12" sm="12" lg="3" key={app.id}>
                                    <Card>
                                        <CardBody>
                                            <Link to={`/oauthapps/${app.id}`}><h4 className="mt-1">{app.name}</h4></Link>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        );
    }
}

export default OAuthApps;
