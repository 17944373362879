import gql from "graphql-tag";
export default function(params){
    return gql`
        mutation {
            rebootModule(
                gwid: "${params.gwid}"
                ble: ${params.ble || false}
                nfc: ${params.nfc || false}
                main_controller: ${params.main_controller || false}
                rf_module: ${params.rf_module || false}
            ) {
                errors
                garageGateway{
                    gwid
                }
            }
        }`;
}
