import gql from "graphql-tag";


export default function query(params) {
    if (params.gateway_type === null) {
        return gql`
            mutation {
                updateGarage(
                    id: "${params.id}"
                    garage_owner: ${params.garage_owner}
                    state: ${params.state}
                    name: "${params.name}"
                    address: {
                        street: "${params.address.street}"
                        street_number: "${params.address.street_number}"
                        zip_code: "${params.address.zip_code}"
                        city: "${params.address.city}"
                        country: "${params.address.country}"
                    }
                    position: {
                        latitude: ${params.position.latitude}
                        longitude: ${params.position.longitude}
                    }
                    check_out_address: {
                        street: "${params.check_out_address ? params.check_out_address.street : "null"}"
                        street_number: "${params.check_out_address ? params.check_out_address.street_number : "null"}"
                        zip_code: "${params.check_out_address ? params.check_out_address.zip_code : "null"}"
                        city: "${params.check_out_address ? params.check_out_address.city : "null"}"
                    }
                    check_out_coordinates: {
                        latitude: ${params.check_out_coordinates ? params.check_out_coordinates.latitude : "null"}
                        longitude: ${params.check_out_coordinates ? params.check_out_coordinates.longitude : "null"}
                    }
                    exit_type_foot: ${params.exit_type_foot}
                    entrance_type_foot: ${params.entrance_type_foot}
                    echarger: ${params.echarger}
                    narrow: ${params.narrow}
                    barrierfree: ${params.barrierfree}
                    video_surveilance: ${params.video_surveilance}
                    footprint: ${params.footprint}
                    exit_type: "${params.exit_type}"
                    garage_height: ${params.garage_height}
                    indoor: ${params.indoor}
                    levels: ${params.levels}
                    revenue_share: ${params.revenue_share}
                    type: "${params.type}"
                    tolerance_minutes: ${params.tolerance_minutes}
                    free_minutes: ${params.free_minutes}
                    person_responsible: ${params.person_responsible && params.person_responsible.length ? `[${params.person_responsible}]` : "[]"}
                    property_management_company: ${params.property_management_company}
                    door_maintenance_company: ${params.door_maintenance_company}
                    note: "${params.note ? params.note.replace(/(\r\n|\n|\r)/gm, "\\n") : ''}"
                    max_weight: ${params.max_weight === '' ? null : params.max_weight}
                    handicapped_spots: ${params.handicapped_spots === '' ? null : params.handicapped_spots}
                    security_guard: ${params.security_guard === '' ? null : params.security_guard}
                    gateway_type: ${params.gateway_type}
                    smart_revenue: ${params.smart_revenue}
                    smart_access: ${params.smart_access}
                ) {
                    errors
                    garage {
                        id
                        name
                        garage_owner
                        address {
                            street
                            street_number
                            zip_code
                            city
                            country
                        }
                        position {
                            latitude
                            longitude
                        }
                        revenue_share
                        revenue_share_valid_from
                        echarger
                        barrierfree
                        video_surveilance
                        footprint
                        exit_type
                        garage_height
                        indoor
                        levels
                        type
                        tolerance_minutes
                        free_minutes
                        person_responsible
                        property_management_company
                        door_maintenance_company
                        state
                        note
                        max_weight
                        handicapped_spots
                        security_guard
                        smart_revenue
                        smart_access
                    }
                }
            }`
    }
    return gql`
        mutation {
            updateGarage(
                id: "${params.id}"
                garage_owner: ${params.garage_owner}
                state: ${params.state}
                name: "${params.name}"
                address: {
                    street: "${params.address.street}"
                    street_number: "${params.address.street_number}"
                    zip_code: "${params.address.zip_code}"
                    city: "${params.address.city}"
                    country: "${params.address.country}"
                }
                position: {
                    latitude: ${params.position.latitude}
                    longitude: ${params.position.longitude}
                }
                check_out_address: {
                    street: "${params.check_out_address ? params.check_out_address.street : "null"}"
                    street_number: "${params.check_out_address ? params.check_out_address.street_number : "null"}"
                    zip_code: "${params.check_out_address ? params.check_out_address.zip_code : "null"}"
                    city: "${params.check_out_address ? params.check_out_address.city : "null"}"
                }
                check_out_coordinates: {
                    latitude: ${params.check_out_coordinates ? params.check_out_coordinates.latitude : "null"}
                    longitude: ${params.check_out_coordinates ? params.check_out_coordinates.longitude : "null"}
                }
                exit_type_foot: ${params.exit_type_foot}
                entrance_type_foot: ${params.entrance_type_foot}
                echarger: ${params.echarger}
                narrow: ${params.narrow}
                barrierfree: ${params.barrierfree}
                video_surveilance: ${params.video_surveilance}
                footprint: ${params.footprint}
                exit_type: "${params.exit_type}"
                garage_height: ${params.garage_height}
                indoor: ${params.indoor}
                levels: ${params.levels}
                revenue_share: ${params.revenue_share}
                type: "${params.type}"
                tolerance_minutes: ${params.tolerance_minutes}
                free_minutes: ${params.free_minutes}
                person_responsible: ${params.person_responsible && params.person_responsible.length ? `[${params.person_responsible}]` : "[]"}
                property_management_company: ${params.property_management_company}
                door_maintenance_company: ${params.door_maintenance_company}
                note: "${params.note ? params.note.replace(/(\r\n|\n|\r)/gm, "\\n") : ''}"
                max_weight: ${params.max_weight === '' ? null : params.max_weight}
                handicapped_spots: ${params.handicapped_spots === '' ? null : params.handicapped_spots}
                security_guard: ${params.security_guard === '' ? null : params.security_guard}
                gateway_type: "${params.gateway_type}"
                smart_revenue: ${params.smart_revenue}
                smart_access: ${params.smart_access}
            ) {
                errors
                garage {
                    id
                    name
                    garage_owner
                    address {
                        street
                        street_number
                        zip_code
                        city
                        country
                    }
                    position {
                        latitude
                        longitude
                    }
                    revenue_share
                    revenue_share_valid_from
                    echarger
                    narrow
                    barrierfree
                    video_surveilance
                    footprint
                    exit_type
                    garage_height
                    indoor
                    levels
                    type
                    tolerance_minutes
                    free_minutes
                    person_responsible
                    property_management_company
                    door_maintenance_company
                    state
                    note
                    max_weight
                    handicapped_spots
                    security_guard
                    smart_revenue
                    smart_access
                }
            }
        }`
}
