import config from '../utils/config';
import urls from '../utils/urls';
import axios from "axios";

import errorHandlerService from "./errorHandlerService";

const userService = {

    login: async (user) => {
        try {
            const response = await axios.post(`${urls.apigateway}/users/me/login/`, user,
                {
                    headers: {
                        'App-Token': config.get('appToken'),
                        'Accept-Language': 'en',
                    }
                });

            if (response.status === 200) {
                localStorage.setItem('Access-Token', response.data.access_token);
                localStorage.setItem('Refresh-Token', response.data.refresh_token);
                return response;
            }

        } catch (err) {
            return err;
        }
    },

    logout: () => {

       return new Promise((resolve, reject) => {
           axios({
               method: 'POST',
               url: `${urls.apigateway}/users/me/logout/`,
               headers: {
                   'App-Token': config.get('appToken'),
                   'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                   'Accept-Language': 'en',
               }
           })
               .then(() => {
                   localStorage.removeItem('Access-Token');
                   localStorage.removeItem('Refresh-Token');
                   resolve()
               })
               .catch((err) => {
                   reject(err);
               })
       })

    },

    getUsers: (page=null, user_type=null, search="", page_size) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/users/`,
                params: {
                    page,
                    user_type,
                    search: search || null,
                    page_size: page_size || null,
                },
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.getUsers, page, user_type
                    )
                }
            });
        });
    },

    getUser: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/users/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.getUser, id
                    )
                }
            });
        });
    },

    getUserVoucher: (id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${urls.apigateway}/vouchers/users/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.getUserVoucher, id
                    )
                }
            });
        });
    },

    updateUser: (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/users/${user.id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(user)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.updateUser, user
                    )
                }
            });
        });
    },

    deleteUser: (user) =>{
        return new Promise((resolve, reject)=>{
            axios({
                method: 'DELETE',
                url: `${urls.apigateway}/users/delete/${user.email}/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: {inactive_reason: user.reason}
            })
            .then((response)=>{
                resolve(response);
            })
            .catch((err)=>{
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.deleteUser, user
                    )
                }
            })
        })
    },

    lockUser: (data, id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/users/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(data)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.lockUser, data, id
                    )
                }
            });
        });
    },

    unlockUser: (data, id) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/users/${id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(data)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.unlockUser, data, id
                    )
                }
            });
        });
    },

    getCockpitUser: () => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/users/me/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en',
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.getCockpitUser
                    )
                }
            });
        });
    },

    getCockpitUsers: () => {
        return new Promise((resolve, reject) => {
            axios.get(`${urls.apigateway}/users/?type=AD`,
                {
                    headers: {
                        'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en',
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.getCockpitUsers
                    )
                }
            });
        });
    },

    getOAuthApps: () => {
        return new Promise(((resolve, reject) => {
            axios.get(`${urls.apigateway}/oauthapps/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en',
                    }
                }).then((resp) => {
                    resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.getOAuthApps
                    )
                }
            })
        }))
    },

    getOAuthApp: (id) => {
        return new Promise(((resolve, reject) => {
            axios.get(`${urls.apigateway}/oauthapps/${id}/`,
                {
                    headers: {
                        'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                        'Accept-Language': 'en',
                    }
                }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.getOAuthApps
                    )
                }
            })
        }))
    },

    updateOAuthApp: (app) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/oauthapps/${app.id}/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(app)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.updateOAuthApp, app
                    )
                }
            });
        });
    },

    addOAuthApp: (app) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${urls.apigateway}/oauthapps/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(app)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.addOAuthApp, app
                    )
                }
            });
        });
    },

    changeCockpitPassword: (user) => {

        return new Promise((resolve, reject) => {
            axios({
                method: 'PUT',
                url: `${urls.apigateway}/users/me/password/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(user)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.changeCockpitPassword, user
                    )
                }
            });
        });
    },

    resetCockpitPassword: (email) => {

        return new Promise((resolve, reject) => {
            axios({
                method: 'PUT',
                url: `${urls.apigateway}/users/me/password/reset/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: email
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.resetCockpitPassword, email
                    )
                }
            });
        });
    },

    updateCockpitUser: (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${urls.apigateway}/users/me/`,
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('Access-Token'),
                    'Content-Type': 'application/json',
                    'Accept-Language': 'en',
                },
                data: JSON.stringify(user)
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        userService.updateCockpitUser, user
                    )
                }
            });
        });
    },

    updateCockpitUserEmail: (email) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PUT',
                url: `${urls.apigateway}/users/me/email/`,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en',
                },
                data: email
            }).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                reject(err);
            });
        });
    },
};

export default userService;
