import React, { Component } from 'react';
import urls from "../../../utils/urls";
import {Button, Row, Input} from "reactstrap";


export default class GarageImage extends Component {

    constructor(props){
        super(props);
        this.state = {
            imgSrc: "https://via.placeholder.com/1440x810?text=Lädt...",
            type: this.props.type
        };

        this.deletePhoto = this.deletePhoto.bind(this);
        this.updateGaragePhoto = this.updateGaragePhoto.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }


    deletePhoto() {
        this.props.deletePhoto(this.props.id)
    }

    updateGaragePhoto() {
        this.props.updateGaragePhoto({id: this.props.id, type: this.state.type})
    }

    handleTypeChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            this.updateGaragePhoto();
        });
    }

    render() {

        return (
            <div className="card">
                <img src={this.props.file_path} className="img-fluid card-img-top" />
                {this.props.deletePhoto &&
                <div className="card-body">
                    <div className="input-group">
                        <Input type="select" className="form-control" name="type" value={this.state.type} onChange={this.handleTypeChange}>
                            <option value="GATE">Einfahrtstor</option>
                            <option value="DOOR">Fußgänger Zugang</option>
                            <option value="DRIVEWAY">Auf- & Abfahrt</option>
                            <option value="PARKING_SPACES">Parkplätze</option>
                            <option value="STAIRCASE">Stiegenhaus</option>
                            <option value="ELEVATOR">Lift</option>
                            <option value="INTERMEDIATE_DOOR">Zwischentüre</option>
                            <option value="NONE">None</option>
                        </Input>
                    </div>
                    <Button className="btn-danger btn-block mt-1" onClick={this.deletePhoto}>Delete</Button>
                </div>
                }
            </div>

        )
    }





}
