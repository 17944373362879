import gql from "graphql-tag";


export default function(params){
    return gql`
            mutation {
                createParkingSpace(
                    garage_id: "${params.id}"
                    number: "${params.number}"
                    type: "${params.type}"
                    active: ${params.active}
                    level: "${params.level}"
                    state: "${params.state}"
                    stack_parking: ${params.stack_parking}
                    height: ${params.height}
                    width: ${params.width}
                    length: ${params.length}
                ) {
                    errors
                    parkingSpace {
                        id
                        number
                        active
                        type
                        level
                        state
                        stack_parking
                        width
                        height
                        length
                    }
                }
            }`;
}
