import React, {Component} from 'react';
import {Col, Row, Badge} from 'reactstrap';

class Legend extends Component {

    render() {
        return (
            <Row className="pb-3">
                <Col lg={12}>
                    <Badge color="success" className="float-right mr-2">Revenue Stats</Badge>
                    <Badge color="primary" className="float-right mr-2">Master Stats</Badge>
                    <Badge color="warning" className="float-right mr-2">User Stats</Badge>
                    <Badge color="secondary" className="float-right mr-2">Overall Stats</Badge>
                </Col>
            </Row>
        )
    }
}

export default Legend
