import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Input,
} from 'reactstrap';
import _isEqual from "lodash/isEqual";


export default class GarageNote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabledNote: true
        };

        this.toggleNote = this.toggleNote.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateGarage = this.updateGarage.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            garage: state.garage || props.garage
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_isEqual(prevProps.garage, this.props.garage)) {
            // props have been changed
            this.setState({
                garage: this.props.garage
            })
        }
    }

    toggleNote() {
        this.props.toggleNote()
    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let newState = {
            garage: {
                ...this.state.garage,
                [name]: value
            }
        };

        this.setState(newState);
    }

    updateGarage() {
        this.props.updateGarage(this.state.garage)
            .then(() => {
                this.setState({
                    disabledNote: true
                })
            })
            .catch(() => {
                this.setState({
                    disabledNote: false
                })
            })
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Garage Notiz
                    <div className="card-header-actions">
                        {
                            !this.props.garageNote
                                ?
                                <div>
                                    <Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateGarage}>
                                        <i className="fa fa-dot-circle-o"/> Speichern
                                    </Button>
                                    <Button type="reset" size="sm" color="danger" onClick={this.toggleNote}>
                                        <i className="fa fa-ban"/> Abbrechen
                                    </Button>
                                </div>
                                :
                                <a className="card-header-action btn btn-setting" onClick={this.toggleNote}>
                                    <i className="icon-pencil"/>
                                </a>
                        }
                    </div>
                </CardHeader>
                <CardBody>
                    {
                        this.props.loading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Input type="textarea" rows="7" name="note" id="note" style={{whiteSpace: 'pre-line'}}
                                   value={!this.state.garage.note ? '' : this.state.garage.note}
                                   disabled={this.props.garageNote} onChange={this.handleChange}/>
                    }
                </CardBody>
            </Card>
        )
    }

}
