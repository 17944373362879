import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import axios from "axios";

import errorHandlerService from './errorHandlerService';
import urls from '../utils/urls';


const uploadLink = createUploadLink({
    uri: `${urls.apigateway}/services/`,
});


const client = new ApolloClient({
    link: uploadLink,
    cache: new InMemoryCache(),
});


const garageService =  {
    getService: (query) => {
        return new Promise((resolve, reject) => {
            client.query({
                fetchPolicy: 'no-cache',
                query: query,
                context: {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('Access-Token')}`,
                        'Accept-Language': 'en'
                    }
                }
            }).then((resp) => {
                resolve(resp);
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("networkError") && err.networkError && err.networkError.hasOwnProperty("statusCode") && err.networkError.statusCode === 401;
                    errorHandlerService.handleResponseError(err, isNotAuth, resolve, reject, garageService.getService, query)
                }
            })
        });
    },

    createService: (mutation, file = null) => {
        let options = {
            fetchPolicy: 'no-cache',
            mutation: mutation,
            context: {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('Access-Token')}`,
                    'Accept-Language': 'en'
                }
            },
        };
        if (file) {
            options = Object.assign({variables: {file}}, options)
        }

        return new Promise((resolve, reject) => {
            return client.mutate(options).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.networkError && err.networkError.statusCode && err.networkError.statusCode === 401;
                    errorHandlerService.handleResponseError(err, isNotAuth, resolve, reject, garageService.createService, mutation, file)
                }
            })
        })
    },

    uploadFile: (qs, endpoint, file, fieldName) => {
        let formData = new FormData();
        formData.append(fieldName, file);

        return new Promise((resolve, reject) => {
            axios.post(`${urls.apigateway}/services/${endpoint}/`, formData, {
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('Access-Token'),
                    'Accept-Language': 'en'
                },
                params: qs
            }).then(resp => {
                resolve(resp)
            }).catch(err =>{
                if(localStorage.getItem("Refresh-Token", null)) {
                    let isNotAuth = err.hasOwnProperty("response") &&
                        err.response &&
                        err.response.hasOwnProperty("status") &&
                        err.response.status === 401;
                    errorHandlerService.handleResponseError(
                        err, isNotAuth,
                        resolve, reject,
                        garageService.uploadFile, qs, endpoint, file
                    )
                }
            })
        });
    }
};


export default garageService;
