import React, {Component} from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Row
} from 'reactstrap';

import garageService from "../../../services/garageService";
import writeNfcMutation from "../../../mutations/writeNfc";
import unassignNfcMutation from "../../../mutations/unassignNfc";
import getNfcTagsByUser from "../../../queries/getNfcTags";


export default class UserNFC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toAssignNFC: null,
            writingNfc: false,
            loadingNfcTags: true,
            assignedNfcTags: [],
            openNfcTags: []
        };

        this.unassignNfc = this.unassignNfc.bind(this);
        this.assignNfc = this.assignNfc.bind(this);
        this.toggleAssignWarning = this.toggleAssignWarning.bind(this);
        this.getUserNfcTags = this.getUserNfcTags.bind(this);

    }

    getUserNfcTags() {
        garageService.getService(getNfcTagsByUser({userId: this.props.userId}))
            .then((resp) => {
                const assignedNfcTags = resp.data.getNfcTags.NFC.filter((n) => n.status === 'ACTIVE');
                const openNfcTags = resp.data.getNfcTags.NFC.filter((n) => n.status === 'ORDERED');

                this.setState({
                    loadingNfcTags: false,
                    assignedNfcTags,
                    openNfcTags,
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
                this.setState({
                    loadingNfcTags: false
                })
            }).finally();
    }


    assignNfc() {
        if (!this.state.toAssignNFC) {
            return
        }

        const nfcTag = this.state.toAssignNFC;

        this.setState({
            writingNfc: true,
            toAssignNFC: null,
        });

        garageService.createService(writeNfcMutation({userId: this.props.userId, id: nfcTag}))
            .then((resp) => {
                this.props.success();
                this.setState({
                    writingNfc: false,
                    assignedNfcTags: [...this.state.assignedNfcTags, resp.data.writeNFC.NFC]
                });
                this.getUserNfcTags();
            })
            .catch((err) => {
                this.setState({
                    writingNfc: false,
                });
                this.props.unsuccessful(err.networkError.result.errors.map((error) => error.message).join(" "))
            })
    }

    unassignNfc(nfcTag) {
        garageService.createService(unassignNfcMutation({nfcTag}))
            .then((resp) => {
                this.props.success();
                this.setState({
                    nfcTags: this.state.assignedNfcTags.filter(tag => tag.nfc_tag !== nfcTag)
                })
                this.getUserNfcTags();
            })
            .catch((err) => {
                this.props.unsuccessful(err.networkError.result.errors.map((error) => error.message).join(" "))
            })

    }


    componentDidMount() {
       this.getUserNfcTags()
    }

    renderLoadingIndicator() {
        if (this.state.writingNfc){
            return (
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <p className="text-center">Assigning chip...</p>
                        <div className="card-body">
                            <div className="sk-spinner sk-spinner-pulse"/>
                        </div>
                    </Col>
                </Row>
            )
        }

        if (this.state.loadingNfcTags) {
            return (
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <p className="text-center">Loading nfc tags...</p>
                        <div className="card-body">
                            <div className="sk-spinner sk-spinner-pulse"/>
                        </div>
                    </Col>
                </Row>
            )

        }

        return "";
    }

    toggleAssignWarning({ target: { value: id } }) {
        if (id === 'default') {
            return
        }

        this.setState({
            toAssignNFC: this.state.toAssignNFC ? null : id,
        });
    }

    renderNfcTags() {
        return this.state.assignedNfcTags.length < 1 ? (
            <Col xs="12" sm="12" lg="12">
                <p>Derzeit keine zugewiesene NFC Tags.</p>
            </Col>
        ) : this.state.assignedNfcTags.map((tag) => {
            return (
                <Col xs="12" sm="12" lg="8" key={tag.id}>
                    <FormGroup row>
                        <Col xs="6" sm="6" md="8" className="mb-2">
                            <Input type="text" id="chip" name="chip" value={tag.nfc_tag} disabled/>
                        </Col>
                        <Col xs="6" sm="6" md="4">
                            <Button color="danger" onClick={() => {
                                this.unassignNfc(tag.nfc_tag)
                            }}>
                                Chip entfernen
                            </Button>
                        </Col>
                    </FormGroup>
                </Col>
            )
        })
    }

    render() {
        return (
            <>
            <Modal isOpen={this.props.visible} toggle={this.props.toggleNfc}
                   className={'modal-info modal-lg ' + this.props.className}>
                <ModalHeader toggle={this.props.toggleNfc}>NFC Chip</ModalHeader>
                <ModalBody>
                    {this.state.loadingNfcTags || this.state.writingNfc ? (
                        this.renderLoadingIndicator()
                    ) : (
                        <Row>
                            <Col xs="8" sm="8" lg="8">
                                {this.renderNfcTags()}
                            </Col>

                            <Col xs="4" sm="4" lg="4">
                                <Label htmlFor="nfcHeader">{this.state.openNfcTags.length > 0 ? "Chip hinzufügen": "Keine offenen chips"}</Label>
                                    <Input disabled={this.state.openNfcTags.length <= 0} xs="8" sm="8" lg="12" type="select" id="chips" name="chips"
                                        onChange={this.toggleAssignWarning} required>
                                        <option value='default'>Select chip</option>
                                        {this.state.openNfcTags.map((t) => <option value={t.id}>{t.id}</option>)}
                                </Input>
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={this.state.writingNfc} onClick={this.props.toggleNfc}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/* Assign Warning */}
            <Modal isOpen={this.state.toAssignNFC !== null} toggle={this.toggleAssignWarning} className={'modal-warning'}>
                <ModalHeader toggle={this.toggleUnassign}>NFC Zuweisen</ModalHeader>
                <ModalBody>
                        <Label htmlFor="assignNfcTagWarning">Bist du sicher, dass du den NFC Chip den User zuweisen willst?</Label>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.toggleAssignWarning}>Abbrechen</Button>
                    <Button color="warning" onClick={this.assignNfc}>NFC Chip assignen</Button>
                </ModalFooter>
     </Modal></>
        )
    }


}
