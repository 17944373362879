import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row, Form, FormGroup, Input, Label,} from 'reactstrap';

import 'spinkit/css/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';

import {Redirect} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import Select from 'react-select';
import Widget from "../../components/Widget/Widget";

import userService from "../../services/userService";

class addOAuthApp extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            success: false,
            authOptions: [
                {value: "password", label: "password"},
                {value: "client-credentials", label: "client-credentials"}
            ],
            name: '',
            client_id: '',
            client_secret: '',
            app_token: '',
            client_type: '',
            scope: '',
            authorization_grant_type: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.addApp = this.addApp.bind(this);
        this.authHandler = this.authHandler.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    authHandler(authTypes) {
        this.setState({
            authorization_grant_type: authTypes.map(authType => authType.value)
        });
    }

    unsuccessful(msg='Erfolglos') {
        return toast.error(msg)
    }

    addApp() {

        return new Promise((resolve, reject) => {

            userService.addOAuthApp({
                name: this.state.name,
                scope: this.state.scope,
                authorization_grant_type: this.state.authorization_grant_type,
                client_type: this.state.client_type
            })
                .then(() => {
                    resolve();
                    this.setState({
                        success: true,
                    })
                })
                .catch((err) => {
                    reject();
                    let errors = err.response.data.error;
                    this.unsuccessful(errors);
                });
        })

    }

    componentDidMount() {

        this.setState({
            loading: false,
        }, () => {
            document.title = "PAYUCA OAuth App hinzufügen";
        });
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>);
        }

        if(this.state.success) {
            return <Redirect to="/oauthapps"/>
        }

        return (
            <div className="animated">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" lg="10">
                        <Widget icon="icon-layers" color="info" header="PAYUCA OAuth App hinzufügen" />
                    </Col>
                    <Col xs="12" sm="12" lg="2">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Speichern"
                            invert
                            style={{cursor: this.state.name.length < 3 ? "not-allowed" : "pointer"}}
                            onClick={this.addApp}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardHeader>
                                App hinzufügen
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="12" lg="6">
                                        <Form action="" method="post" encType="multipart/form-data"
                                              className="form-horizontal">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">Name</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="name" name="name"
                                                           value={this.state.name}
                                                           onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="clientID">Client ID</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="client_id" name="client_id"
                                                           value={this.state.client_id}
                                                           onChange={this.handleChange}
                                                           disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="clientSecret">Client Secret</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="clientSecret" name="clientSecret"
                                                           defaultValue="Not public information!"
                                                           disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="clientType">Client Type</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" id="client_type" name="client_type"
                                                           value={this.state.client_type}
                                                           onChange={this.handleChange}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="public">Public</option>
                                                        <option value="confidential">Confidential</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="Authorization Grant Type">Authorization Grant Type</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Select
                                                        value={!this.state.authorization_grant_type ? null : this.state.authorization_grant_type}
                                                        options={this.state.authOptions}
                                                        onChange={this.authHandler}
                                                        multi
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col xs="12" sm="12" lg="6">
                                        <Form action="" method="post" encType="multipart/form-data"
                                              className="form-horizontal">
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="name">Scope</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="textarea" name="scope" id="scope" rows="10"
                                                           value={this.state.scope}
                                                           onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default addOAuthApp;
