import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Collapse,
    ModalHeader, ModalBody, InputGroup, InputGroupAddon, InputGroupText, ModalFooter, Modal
} from 'reactstrap';

import 'spinkit/css/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import {AppSwitch} from "@coreui/react";
import DateTimePicker from "react-datetime-picker";
import {toast} from "react-toastify";
import paymentService from "../../../services/paymentsService";
import dictionary from '../../../utils/dictionary';


class UpdateCredits extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            bonusAmount: 0,
            bonusType: 'payuca_bonus_credit',
            note: '',
            bookIt: true,
            collapse: false
        };

        this.handleChangeAmount = this.handleChangeAmount.bind(this);
        this.uploadCredit = this.uploadCredit.bind(this);
        this.confirmUploadCredit = this.confirmUploadCredit.bind(this);
        this.getUserCredits = this.getUserCredits.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.getUserCredits();
    }

    getUserCredits() {
        paymentService.getCreditStatus(this.state.user.id)
            .then(resp => {
                this.setState({
                    bonusCredits: resp.data.payuca_bonus_credit,
                    realCredits: resp.data.payuca_credit
                })
            })
            .catch((err) => {
                if (!this.props.user.inactive_reason) {
                    toast.error('Es ist ein Fehler aufgetreten. Bitte nochmal versuchen.');
                }
            });
    }

    toggle() {
        this.setState({collapse: !this.state.collapse});
    }

    handleChangeAmount(event) {
        this.setState({
            amount: (event.target.value),
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    confirmUploadCredit() {
        this.setState({
            modalOpen: true
        });
    }

    invalidBonusAmount() {
        toast.error('Sie können nicht mehr Guthaben abziehen, als der Benutzer besitzt');
    }

    uploadCredit() {

        this.setState({
            modalOpen: false
        });

        if((this.state.bonusCredits + this.state.realCredits) + (+this.state.bonusAmount) < 0){
            return this.invalidBonusAmount();
        }
        

        this.setState({
            creditLoading: true
        });

        let data = {
            id: this.state.user.id,
            [this.state.bonusType]: parseFloat(this.state.bonusAmount),
            note: this.state.note
        };

        if(this.state.bonusType === 'payuca_credit') {
            data['book_it'] = this.state.bookIt;
        }

        this.props.uploadCredit(data);

        /*
         TODO 
         this is quick workaround that will need refactor to correctly
         upate user credits after they are changed
        */
        setTimeout(()=>{
            this.getUserCredits();
        }, 3000);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.confirmUploadCredit();
        }
    }

    handleBookItChange = () =>{
        this.handleChange({target: { value: !this.state.bookIt, name: 'bookIt'}});
    }

    render() {

        return (
            <>
                <Modal data-testid="credits-confirm-modal" isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Bitte bestätigen Sie diese Aktion</ModalHeader>
                    <ModalBody>
                        {
                            (this.state.bonusType === 'payuca_credit' && this.state.bonusAmount < 0) &&
                            <div>{`Sie reduzieren das Konto um ${this.state.bonusAmount} Reale-Credits und ein Gutschrift wird an den Kunden versendet.`}</div>
                        }
                        {
                            (this.state.bonusType === 'payuca_credit' && this.state.bonusAmount > 0) &&
                            <div>{`Sie erhöhen das Konto um ${this.state.bonusAmount} Reale-Credits und eine Rechnung wird an den Kunden versendet.`}</div>
                        }
                        {
                            (this.state.bonusType === 'payuca_bonus_credit' && this.state.bonusAmount < 0) &&
                            <div>{`Sie reduzieren das Konto um ${this.state.bonusAmount} Bonus-Credits.`}</div>
                        }
                        {
                            (this.state.bonusType === 'payuca_bonus_credit' && this.state.bonusAmount > 0) &&
                            <div>{`Sie erhöhen das Konto um ${this.state.bonusAmount} Bonus-Credits.`}</div>
                        }
                        Möchten Sie diese Aktion fortsetzen?
                    </ModalBody>
                    <ModalFooter>
                        <Button data-testid="credits-confirm-button" color="success" onClick={this.uploadCredit}>
                            Bestätigen
                        </Button>

                        <Button color="secondary" onClick={this.toggleModal}>
                            Abbrechen
                        </Button>
                    </ModalFooter>
                </Modal>

                <Card className="border-success">
                    <CardHeader onClick={this.toggle} style={{cursor: !this.state.collapse && "pointer"}}>
                        <i className="icon-tag"></i><strong> Credits</strong>
                    </CardHeader>
                    <Collapse isOpen={this.state.collapse}>
                        <CardBody>
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Kunde</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <p>{this.state.user.first_name + " " + this.state.user.last_name}</p>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Art des Credits</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="select" name="bonusType" id="bonusType" value={this.state.bonusType}
                                           onChange={this.handleChange}>
                                        <option value="payuca_bonus_credit">Bonus</option>
                                        <option value="payuca_credit">Reale</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            {this.state.bonusType === 'payuca_credit' &&<FormGroup row>
                                <Col md="4">
                                    <Label><strong>{dictionary.BOOK_IT}</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="checkbox" name="book_it" id="book_it" checked={this.state.bookIt} value={this.state.bookIt} onChange={this.handleBookItChange}/>
                                </Col>
                            </FormGroup>}
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Betrag*</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input type="number" name="bonusAmount" id="bonusAmount"
                                           value={this.state.bonusAmount}
                                           onChange={this.handleChange}
                                           onKeyPress={this.state.bonusAmount === 0 ? null : this.handleKeyPress}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                    <Label><strong>Notiz</strong></Label>
                                </Col>
                                <Col xs="12" md="8">
                                    <Input data-testid="credits-update-note" type="textarea" name="note"
                                           value={this.state.note}
                                           onChange={this.handleChange}/>
                                </Col>
                            </FormGroup>
                            <small>*kann auch einen negativen Wert haben</small>
                        </CardBody>
                        <CardFooter>
                            <Button size="sm" color="primary" className={"mr-1"} onClick={this.confirmUploadCredit}
                                    disabled={this.state.note.length < 10}><i
                                className="fa fa-dot-circle-o"></i> Aufladen</Button>
                        </CardFooter>
                    </Collapse>
                </Card>
            </>
        );
    }
}

export default UpdateCredits;
